import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { LOADER_ID_APP } from './app.component';
import * as AppActions from './core/store/app.actions';
import * as AppSelectors from './core/store/app.selectors';

@Injectable({
  providedIn: 'root',
})
export class AppFacadeService {
  constructor(private store: Store) {}

  // SELECTORS
  isLoaderActive$ = (id: string = LOADER_ID_APP) => {
    return this.store.select(AppSelectors.isLoaderActive(id));
  };

  // ERROR REDIRECTS

  unauthorizedRedirect = (url: string) => {
    this.store.dispatch(AppActions.unauthorizedRedirect({ url }));
  };

  // LOADER

  loaderStart = (id: string = LOADER_ID_APP) => {
    this.store.dispatch(AppActions.loaderStart({ id }));
  };

  loaderStop = (id: string = LOADER_ID_APP) => {
    this.store.dispatch(AppActions.loaderStop({ id }));
  };
}
