import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListConfigsListsComponent } from './components/list-configs-lists/list-configs-lists.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '../core/material.module';
import { ListModule } from '../core/list/list.module';
import * as fromListConfig from './store/list-config.reducer';
import { ListConfigEffects } from './store/list-config.effects';
import { ListConfigCreateDialogFormComponent } from './components/list-config-create-dialog-form/list-config-create-dialog-form.component';
import { ListConfigListsActionsCellRendererComponent } from './components/list-configs-lists/cell-renderers/list-config-list-actions-cell-renderer.component';
import { RouterModule } from '@angular/router';
import { CaslModule } from '../core/casl/casl.module';

@NgModule({
  declarations: [
    ListConfigsListsComponent,
    ListConfigCreateDialogFormComponent,
    ListConfigListsActionsCellRendererComponent,
  ],
  imports: [
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    StoreModule.forFeature(
      fromListConfig.listConfigFeatureKey,
      fromListConfig.reducers
    ),
    EffectsModule.forFeature(ListConfigEffects),
    TranslateModule.forChild({
      extend: true,
    }),
    MaterialModule,
    CaslModule,
    ListModule,
  ],
  exports: [ListConfigsListsComponent],
})
export class ListConfigsModule {}
