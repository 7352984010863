import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchbarComponent } from './searchbar/searchbar.component';
import { NavigationBarComponent } from './navigation-bar/components/navigation-bar/navigation-bar.component';
import { CascadingSearchformModule } from './cascading-searchform/cascading-searchform.module';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '../core/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { LoaderComponent } from './loader/loader.component';
import { LoaderModalComponent } from './loader-modal/loader-modal.component';
import { LoaderWrapperComponent } from './loader-wrapper/loader-wrapper.component';

@NgModule({
  declarations: [
    SearchbarComponent,
    NavigationBarComponent,
    LoaderComponent,
    LoaderModalComponent,
    LoaderWrapperComponent,
  ],
  imports: [
    CommonModule,
    CascadingSearchformModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    TranslateModule.forChild({
      extend: true,
    }),
  ],
  exports: [
    SearchbarComponent,
    NavigationBarComponent,
    LoaderModalComponent,
    LoaderWrapperComponent,
  ],
})
export class LayoutModule {}
