import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NotificationType, NotifyService } from '../notify/notify.service';

@Injectable({
  providedIn: 'root',
})
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private notify: NotifyService, private zone: NgZone) {}

  handleError(error: any): void {
    this.zone.run(() =>
      this.notify.notify({
        type: NotificationType.Toast,
        message: 'General error',
      })
    );
    
    // for Cypress the error needs to be thrown to fail the test
    if (environment.name.includes('CYPRESS')) {
      throw error;
    }
    
    console.error('Error from global error handler', error);
  }
}
