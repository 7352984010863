import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  ColumnState,
  IServerSideDatasource,
  IServerSideGetRowsParams,
} from 'ag-grid-community';
import { FilterModel } from 'src/app/core/list/list.model';
import { Entity } from 'src/app/list-configs/list-configs.model';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseTabPageComponent } from 'src/app/core/base/base-tab-page.component';
import { Id, Link } from 'src/app/core/model';
import { CustomersFacadeService } from './../../../customers-facade.service';
import { customerCreateLink } from '../../../customers-routing-helper';
import { MatDialog } from '@angular/material/dialog';
import { ListConfigsFacadeService } from 'src/app/list-configs/list-configs-facade.service';
import { ListConfigCreateDialogFormComponent } from 'src/app/list-configs/components/list-config-create-dialog-form/list-config-create-dialog-form.component';
import {
  NotificationType,
  NotifyService,
} from 'src/app/core/notify/notify.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { ROUTE_QUERY_PARAM_CUSTOMER_IDS, ROUTE_QUERY_PARAM_FILTER, ROUTE_QUERY_PARAM_LIST_CONFIG_CONFIG } from 'src/app/core/route-params';
import { mailCreateLink } from 'src/app/mails/mails-routing-helper';

@Component({
  selector: 'app-customers-overview-tab',
  templateUrl: './customers-overview-tab.component.html',
  styleUrls: ['./customers-overview-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomersOverviewTabComponent extends BaseTabPageComponent {
  tabLinks = [] as Link[];

  customerCreateLink = customerCreateLink();

  constructor(
    public facade: CustomersFacadeService,
    public listConfigFacade: ListConfigsFacadeService,
    public listConfigDialog: MatDialog,
    protected router: Router,
    protected route: ActivatedRoute,
    protected activatedRoute: ActivatedRoute,
    private notifyService: NotifyService,
    private clipboard: Clipboard
  ) {
    super(router, route);
  }

  datasource: IServerSideDatasource = {
    getRows: (params: IServerSideGetRowsParams) => {
      this.facade.getCustomersRows$(params.request).subscribe(
        (result) => {
          params.success(result);
          this.facade.pullConfigNewBlock();
        },
        (error) => {
          console.error(error);
          params.fail();
        }
      );
    },
  };

  onMailToFilter(): void {
    const stringifiedListConfig = this.activatedRoute.snapshot.queryParams[ROUTE_QUERY_PARAM_LIST_CONFIG_CONFIG]; // @TODO improve this
    const filter = stringifiedListConfig
      ? JSON.parse(stringifiedListConfig).filter
      : {};
      this.router.navigate(
        mailCreateLink(),
        { 
          queryParams: { 
            [ROUTE_QUERY_PARAM_FILTER]: JSON.stringify(filter)
          }
        }
      );
  }

  onMailToSelection(): void {
    const stringifiedListConfig = this.activatedRoute.snapshot.queryParams[ROUTE_QUERY_PARAM_LIST_CONFIG_CONFIG]; // @TODO improve this
    const selection = stringifiedListConfig
      ? JSON.parse(stringifiedListConfig).selection
      : [];
      this.router.navigate(
        mailCreateLink(),
        { 
          queryParams: { 
            [ROUTE_QUERY_PARAM_CUSTOMER_IDS]: JSON.stringify(selection)
          }
        }
      );
  }

  onSaveListConfig(): void {
    const listSubmitDialog = this.listConfigDialog.open(
      ListConfigCreateDialogFormComponent,
      {
        width: '600px',
      }
    );

    listSubmitDialog.afterClosed().subscribe((result) => {
      if (result.update) {
        this.listConfigFacade.updateListConfigByRoute();
      } else {
        this.listConfigFacade.createListConfig({
          title: result.title,
          entity: Entity.customer,
        });
      }
    });
  }

  onShareListConfig(): void {
    this.clipboard.copy(window.location.href);
    this.notifyService.notify({
      type: NotificationType.Toast,
      message: 'Link in Zwischenablage gespeichert',
    });
  }

  onFilterChanged(filter: FilterModel): void {
    this.facade.pushFilterCustomerConnection({ filter }, true);
  }

  onColumnsStateChanged(columnsState: ColumnState[]): void {
    this.facade.pushFilterCustomerConnection({ columnsState }, true);
  }

  onSelectionChanged(selection: Id[]): void {
    this.facade.pushFilterCustomerConnection({ selection });
  }
}
