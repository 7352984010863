import { ContactOption, ContactOptionType } from 'src/app/core/graphql.model';

const getEmptyContactOption = (): ContactOption => ({
  id: null,
  version: null,
  prio: 1,
  type: null,
  category: null,
  value: '',
});

export const getEmptyContactOptionPhone = (): ContactOption => ({
  ...getEmptyContactOption(),
  type: ContactOptionType.PHONE,
  value: '+49 ',
});

export const getEmptyDefaultContactOptionPhone = (): ContactOption => ({
  ...getEmptyContactOptionPhone(),
  prio: 0,
});

export const getEmptyContactOptionEmail = (): ContactOption => ({
  ...getEmptyContactOption(),
  type: ContactOptionType.EMAIL,
});

export const getEmptyDefaultContactOptionEmail = (): ContactOption => ({
  ...getEmptyContactOptionEmail(),
  prio: 0,
});
