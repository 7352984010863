import {
  columnTypes,
  defaultColDef,
  sideBar,
  statusBar,
} from '../../../core/list/list.definitions';
import { ColDef, FirstDataRenderedEvent } from 'ag-grid-community';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ListConfigListsActionsCellRendererComponent } from './cell-renderers/list-config-list-actions-cell-renderer.component';
import { ListConfig } from '../../list-configs.model';
import { EditCellRendererComponent } from 'src/app/core/list/cell-renderers/edit-cell-renderer.component';

@Component({
  selector: 'app-list-configs-lists',
  templateUrl: './list-configs-lists.component.html',
  styleUrls: ['./list-configs-lists.component.scss'],
})
export class ListConfigsListsComponent {
  @Input() data: ListConfig[] = [];
  @Input() columnDefs: ColDef[];

  @Output() cellValueChanged: EventEmitter<ListConfig> = new EventEmitter();

  components = {
    actionsCellRenderer: ListConfigListsActionsCellRendererComponent,
    editCellRenderer: EditCellRendererComponent,
  };

  columnTypes = columnTypes;
  defaultColDef = defaultColDef;
  sideBar = sideBar;
  statusBar = statusBar;

  constructor() {}

  onFirstDataRendered = (event: FirstDataRenderedEvent) => {
    event.columnApi.autoSizeAllColumns();
  }
}
