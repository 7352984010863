<h3>Coupon</h3>

<p>Bearbeitungsgebür: +{{ _handlingFee | price }}</p>

<p>Subotal: {{ _totalWithoutDiscountWithTax | price }}</p>

<input [(ngModel)]="couponCode">
<button (click)="onAdd()" [disabled]="!couponCode">Einlösen</button>
<p>codes: {{ _booking.couponCodes | json }}</p>

<p>Discount: -{{ _discountWithTax | price }}</p>

<p>Total: {{ _booking.totalWithTax | price }}</p>
