import { recordingEditLink } from '../../recordings-routing-helper';
import { Id, Link } from '../../../core/model';

export enum RouteNames {
  DATA = '',
  BOOKINGS = 'bookings',
  WAITING_LIST = 'waitinglist',
}

export const recordingEditLinkData = (id: Id): Link =>
  recordingEditLink(id); /*.concat(RouteNames.DATA);*/
export const recordingEditLinkBookings = (id: Id): Link =>
  recordingEditLink(id).concat(RouteNames.BOOKINGS);
export const recordingEditLinkWaitingList = (id: Id): Link =>
  recordingEditLink(id).concat(RouteNames.WAITING_LIST);
