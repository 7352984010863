import {
  Component,
  forwardRef,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { ProductFormService } from './product-form.service';
import {
  FormGroup,
  Validator,
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
} from '@angular/forms';
import { Product, ProductType } from 'src/app/core/graphql.model';
import { TranslateService } from '@ngx-translate/core';
import { BaseSubFormComponent } from 'src/app/core/base/base-sub-form.component';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'app-product-form',
  templateUrl: './product-form.component.html',
  styleUrls: ['./product-form.component.scss'],
  providers: [
    ProductFormService,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ProductFormComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ProductFormComponent),
      multi: true,
    },
  ],
})
export class ProductFormComponent
  extends BaseSubFormComponent
  implements ControlValueAccessor, Validator {
  form: FormGroup = this.formService.form;

  productTypeOptions = new Map<ProductType, string>();

  @Input() idx: number | string;

  @Input() set product(product: Product) {
    this.formService.product = product;
  }

  @Input() set disabled(disabled: boolean) {
    this.setDisabledState(disabled);
  }

  @Output() remove: EventEmitter<void> = new EventEmitter();

  @Output() formSubmit: EventEmitter<Product> = new EventEmitter();

  constructor(
    private formService: ProductFormService,
    private translate: TranslateService,
  ) {
    super();
    this.form = this.formService.form;
    Object.values(ProductType).forEach((key) =>
      this.productTypeOptions.set(
        key,
        this.translate.instant(`ENUM.PRODUCT_TYPE.${key}`)
      )
    );
  }

  writeValue(val: any): void {
    if (val) {
      // @TODO why is form setter not called?
      const {optionGroups, ...rest} = val;
      this.form.setValue(rest, { emitEvent: false });
    }
  }

  registerOnChange(fn: any): void {
    const f = (newValue: any) => {
      fn(this.formService.product);
    };
    this.form.valueChanges
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(f);
  }

  onRemove(): void {
    this.remove.emit();
  }

  onSubmit(): void {
    this.formSubmit.emit(this.formService.product);
  }
}
