<div class="c-recording-header u-bgcolor-secondary-90 u-pv-12">
  <div class="o-container">
    <h1 class="o-type-h5 u-color-white">
      {{ recording?.title }}
      <span class="o-type-body-small u-ml-16">
        {{ recording?.eventStart | date:'fullDate' }}, {{ recording?.eventStart | date:'shortTime' }} - {{ recording?.eventEnd | date:'shortTime' }}
      </span>
    </h1>
  </div>
</div>
