import { Action, combineReducers, ActionReducer } from '@ngrx/store';
import * as fromCustomerEntities from './entities/customer-entities.reducer';
import * as fromCustomerLists from './lists/customer-lists.reducer';

export const customerFeatureKey = 'customer';

export interface State {
  [fromCustomerEntities.customerEntitiesFeatureKey]: fromCustomerEntities.State;
  [fromCustomerLists.customerListsFeatureKey]: fromCustomerLists.State;
}

export function reducers(
  state: State | undefined,
  action: Action
): ActionReducer<State, any> {
  // @ts-ignore
  return combineReducers({
    [fromCustomerEntities.customerEntitiesFeatureKey]:
      fromCustomerEntities.reducer,
    [fromCustomerLists.customerListsFeatureKey]: fromCustomerLists.reducer,
  })(state, action);
}
