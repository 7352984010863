import { Injectable } from '@angular/core';
import {
  ColDef,
  ValueGetterFunc,
  ValueGetterParams,
} from 'ag-grid-community';
import { TranslateService } from '@ngx-translate/core';
import {
  CustomerColumnIds,
  CustomerListColumnDefsService,
} from 'src/app/customers/store/lists/customer-list-column-defs.service';
import {
  BaseColumnIds,
  BaseColumnDefsService,
} from 'src/app/core/list/base-column-defs-service';
import { AdminBookingLine } from '@blab/blab-graphql';

export enum BookingsColumnIds {
  DETAILS = 'details',
  CUSTOMER = 'customer',
  TOTAL_RECORDING_TICKETS = 'totalRecordingTickets',
  ORDER_PLACED_AT = 'orderPlacedAt',
  STATE = 'state',
  CHECK_IN_COMPLETE = 'checkInComplete',
  CHECK_IN_DATE = 'checkinDate',
  TOTAL_QUANTITY = 'totalQuantity',
  RECORDING_QUANTITY = 'recordingQuantity',
  TOTAL_WITH_TAX = 'totalWithTax',
  RECORDING = 'recording',
  PARTICIPANT_CUSTOMER_NUMBER = 'participantCustomerNumber',
  PARTICIPANT_SHOW = 'participantShow',
  PARTICIPANT_PRODUCT_NAME = 'participantProductName',
  PARTICIPANT_SALUTATION = 'participantSalutation',
  PARTICIPANT_FIRST_NAME = 'participantFirstName',
  PARTICIPANT_LAST_NAME = 'participantLastName',
  PARTICIPANT_BIRTH_DATE = 'participantBirthDate',
}

@Injectable({
  providedIn: 'root',
})
export class BookingListColumnDefService extends BaseColumnDefsService{
  columnDefsMap = new Map<string, ColDef>([
    [
      BaseColumnIds.SELECT,
      this.baseColumnDefsMap.get(BaseColumnIds.SELECT),
    ],
    [
      BookingsColumnIds.DETAILS,
      {
        headerName: this.translate.instant('BOOKINGS.COLUMNS.DETAILS'),
        sortable: false,
        resizable: false,
        filter: false,
        lockVisible: true,
        cellStyle: { 'padding-left': '7px' },
        minWidth: 32,
        maxWidth: 32,
        width: 32,
        cellRenderer: 'agGroupCellRenderer',
        valueGetter: () => ' ',
      },
    ],
    [BaseColumnIds.ID, this.baseColumnDefsMap.get(BaseColumnIds.ID)],
    [
      BaseColumnIds.CREATED_AT,
      this.baseColumnDefsMap.get(BaseColumnIds.CREATED_AT),
    ],
    [
      BookingsColumnIds.CUSTOMER,
      {
        headerName: this.translate.instant('BOOKINGS.COLUMNS.CUSTOMER'),
        field: 'customer',
        valueGetter(params): string {
          if (!params.data.customer ) {
            return '';
          }
          return `${params.data.customer.firstName} ${params.data.customer.lastName}`;
        },
        suppressMenu: true,
      },
    ],
    // [ @TODO
    //   BookingsColumnIds.TOTAL_RECORDING_TICKETS,
    //   {
    //     headerName: 'Recording',
    //     field: 'recording.title',
    //   },
    // ],
    [
      BookingsColumnIds.ORDER_PLACED_AT,
      {
        headerName: this.translate.instant('BOOKINGS.COLUMNS.ORDER_PLACED_AT'),
        field: 'orderPlacedAt',
        type: 'dateColumn',
      },
    ],
    [
      BookingsColumnIds.STATE,
      {
        headerName: this.translate.instant('BOOKINGS.COLUMNS.STATE'),
        field: 'state',
        filter: 'agSetColumnFilter',
      },
    ],
    [
      BookingsColumnIds.CHECK_IN_COMPLETE,
      {
        headerName: this.translate.instant('BOOKINGS.COLUMNS.CHECK_IN_COMPLETE'),
        valueGetter: (params) => (params.data.lines.length > 0 ? params.data.lines[0].checkInComplete : null) ? 'Ja' : 'Nein',
        filter: 'agSetColumnFilter',
      },
    ],
    [
      BookingsColumnIds.CHECK_IN_DATE,
      {
        headerName: this.translate.instant('BOOKINGS.COLUMNS.CHECK_IN_DATE'),
        valueGetter: (params) => params.data.lines.length > 0 ? params.data.lines[0].checkinDate : null,
        type: 'dateColumn',
      },
    ],
    [
      BookingsColumnIds.TOTAL_QUANTITY,
      {
        headerName: this.translate.instant('BOOKINGS.COLUMNS.TOTAL_QUANTITY'),
        field: 'totalQuantity',
      },
    ],
    [
      BookingsColumnIds.RECORDING_QUANTITY,
      {
        headerName: this.translate.instant('BOOKINGS.COLUMNS.RECORDING_QUANTITY'),
        valueGetter: (params =>
          params.data.lines.reduce((sum: number, line: AdminBookingLine) => sum + line.quantity, 0)
        ), // sum over sanatised order lines
      },
    ],
    [
      BookingsColumnIds.TOTAL_WITH_TAX,
      {
        headerName: this.translate.instant('BOOKINGS.COLUMNS.TOTAL_WITH_TAX'),
        field: 'totalWithTax',
      },
    ],
    // [ @TODO
    //   BookingsColumnIds.RECORDING,
    //   {
    //     headerName: 'Recording',
    //     field: 'recording.title',
    //   },
    // ],
    [
      BaseColumnIds.ACTIONS,
      this.baseColumnDefsMap.get(BaseColumnIds.ACTIONS),
    ],
    [
      BookingsColumnIds.PARTICIPANT_CUSTOMER_NUMBER,
      {
        headerName: this.translate.instant('BOOKINGS.COLUMNS.PARTICIPANT_CUSTOMER_NUMBER'),
        field: 'customerNumber',
      }
    ],
    [
      BookingsColumnIds.PARTICIPANT_SHOW,
      {
        headerName: this.translate.instant('BOOKINGS.COLUMNS.PARTICIPANT_SHOW'),
        field: 'show',
      }
    ],
    [
      BookingsColumnIds.PARTICIPANT_PRODUCT_NAME,
      {
        headerName: this.translate.instant('BOOKINGS.COLUMNS.PARTICIPANT_PRODUCT_NAME'),
        field: 'productName',
      }
    ],
    [
      BookingsColumnIds.PARTICIPANT_SALUTATION,
      {
        headerName: this.translate.instant('BOOKINGS.COLUMNS.PARTICIPANT_SALUTATION'),
        field: 'salutation',
      }
    ],
    [
      BookingsColumnIds.PARTICIPANT_FIRST_NAME,
      {
        headerName: this.translate.instant('BOOKINGS.COLUMNS.PARTICIPANT_FIRST_NAME'),
        field: 'firstName',
      }
    ],
    [
      BookingsColumnIds.PARTICIPANT_LAST_NAME,
      {
        headerName: this.translate.instant('BOOKINGS.COLUMNS.PARTICIPANT_LAST_NAME'),
        field: 'lastName',
      }
    ],
    [
      BookingsColumnIds.PARTICIPANT_BIRTH_DATE,
      {
        headerName: this.translate.instant('BOOKINGS.COLUMNS.PARTICIPANT_BIRTH_DATE'),
        field: 'birthDate',
        type: 'dateColumn',
      }
    ]
  ]);

  columnDefs: ColDef[] = Array.from(this.columnDefsMap.keys()).map((key) => ({
    colId: key,
    ...this.columnDefsMap.get(key),
  }));

  mapCustomerData = (customerColumnDef: ColDef): ColDef => {
    return {
      ...customerColumnDef,
      sortable: true,
      field: !customerColumnDef.field
        ? customerColumnDef.field
        : 'customer.' + customerColumnDef.field,
      valueGetter: !customerColumnDef.valueGetter
        ? customerColumnDef.valueGetter
        : (params: ValueGetterParams) => {
            return (customerColumnDef.valueGetter as ValueGetterFunc)({
              ...params,
              data: params.data.customer,
            });
          },
    };
  };

  customerBaseColumnIds = [
    CustomerColumnIds.FIRST_NAME,
    CustomerColumnIds.LAST_NAME,
    CustomerColumnIds.DEFAULT_EMAIL,
    CustomerColumnIds.PRIMARY_CITY,
  ] as string[];

  customerColumnIds = [
    BookingsColumnIds.DETAILS,
    BaseColumnIds.CREATED_AT,
    BookingsColumnIds.CUSTOMER,
    BookingsColumnIds.ORDER_PLACED_AT,
    BookingsColumnIds.STATE,
    BookingsColumnIds.TOTAL_QUANTITY,
    BookingsColumnIds.TOTAL_WITH_TAX,
  ] as string[];
  
  bookingColumnIds = [
    BookingsColumnIds.ORDER_PLACED_AT,
    BookingsColumnIds.RECORDING_QUANTITY,
    BookingsColumnIds.STATE,
    BookingsColumnIds.CHECK_IN_COMPLETE,
    BookingsColumnIds.CHECK_IN_DATE,
  ] as string[];

  bookingParticipantColumnIds = [
    BookingsColumnIds.PARTICIPANT_CUSTOMER_NUMBER,
    BookingsColumnIds.PARTICIPANT_SHOW,
    BookingsColumnIds.PARTICIPANT_PRODUCT_NAME,
    BookingsColumnIds.PARTICIPANT_SALUTATION,
    BookingsColumnIds.PARTICIPANT_FIRST_NAME,
    BookingsColumnIds.PARTICIPANT_LAST_NAME,
    BookingsColumnIds.PARTICIPANT_BIRTH_DATE,
  ] as string[];

  customerColumnDefs: ColDef[] = this.columnDefs.filter((columnDef) =>
  this.customerColumnIds.includes(columnDef.colId)
)

  recordingColumnDefs = 
    this.columnDefs.filter((bookingColumnDef) =>
        bookingColumnDef.colId === BookingsColumnIds.DETAILS, // @TODO improve this
      )
    .concat(
      this.customerListColmnDefsService.columnDefs
        .filter((customerColumnDef) =>
          this.customerBaseColumnIds.includes(customerColumnDef.colId)
        )
        .map(this.mapCustomerData),
      this.columnDefs.filter((bookingColumnDef) =>
        this.bookingColumnIds.includes(bookingColumnDef.colId)
      )
    );

  recordingParticipantColumnDefs = this.columnDefs.filter((bookingParticipantColumnDef) =>
  this.bookingParticipantColumnIds.includes(bookingParticipantColumnDef.colId)
)

  // recordingColumnDefs = this.customerListColmnDefsService.columnDefs.map(
  //   (customerColumnDef) => {
  //     return {
  //       ...customerColumnDef,
  //       field: !customerColumnDef.field
  //         ? customerColumnDef.field
  //         : 'customer.' + customerColumnDef.field,
  //       valueGetter: !customerColumnDef.valueGetter
  //         ? customerColumnDef.valueGetter
  //         : (params: ValueGetterParams) => {
  //             return (customerColumnDef.valueGetter as ValueGetterFunc)({
  //               ...params,
  //               data: params.data.customer,
  //             });
  //           },
  //     } as ColDef
  //   }
  // ).concat(this.columnDefs)

  constructor(
    protected translate: TranslateService,
    private customerListColmnDefsService: CustomerListColumnDefsService
  ) {
    super(translate);
  }
}
