<div class="u-bgcolor-grey-10 u-p-12 u-mb-24">
  <div class="u-flex u-ai-center u-mb-32">
    <div class="o-circle o-type-body-small u-bgcolor-grey-60 u-color-white u-mr-8">
      2.
    </div>
    <div class="o-type-overline">
      Personlasierte Tickets
    </div>
  </div>
  <p class="o-type-overline u-mb-16">
    Produkt {{ bookingParticipant.productVariant.name }}
  </p>

  <div class="u-flex">
    <!--    <p class="o-type-body-small">Weitere Person:</p>-->
    <p class="o-type-body-small u-mr-56">
      <span class="o-type-overline u-mr-6">
        Name:
      </span>
      {{ bookingParticipant.lastName }}
    </p>
    <p class="o-type-body-small u-mr-56">
      <span class="o-type-overline u-mr-6">
        Vorname:
      </span>
      {{ bookingParticipant.firstName }}
    </p>
    <p class="o-type-body-small u-mr-56">
      <span class="o-type-overline u-mr-6">
        Geb Dat:
      </span>
      {{ bookingParticipant.birthDate }}
    </p>
    <p class="o-type-body-small u-mr-56">
      <span class="o-type-overline u-mr-6">
        Einzelpreis:
      </span>
      {{ bookingParticipant.productVariant.price | price }}
    </p>
  </div>
</div>
