import {Component, Input, Output, EventEmitter} from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { sanatisedCopy } from 'src/app/core/data-helpers';
import {
  Address,
  AdminBooking,
  BookingParticipant,
  CreateBillingAddressInput,
  Customer,
  ProductType,
  Recording,
} from 'src/app/core/graphql.model';
import { DeepPartial, VId } from 'src/app/core/model';
import { BookingItemForm, BookingParticipantForm } from './booking-form.model';
import { BookingFormService } from './booking-form.service';

@Component({
  selector: 'app-booking-form',
  templateUrl: './booking-form.component.html',
  styleUrls: ['./booking-form.component.scss'],
  providers: [BookingFormService],
})
export class BookingFormComponent {
  form: FormGroup = this.formService.form;
  _recording: Recording;
  _addrresses: Address;
  _bookingItem: BookingItemForm;
  _bookingParticipant: BookingParticipantForm;

  state = [
    {},
    {
      formVisible: true,
      overviewVisible: false,
      submitted: false,
    },
    {
      formVisible: false,
      overviewVisible: false,
      submitted: false,
    },
    {
      formVisible: false,
      overviewVisible: false,
      submitted: false,
    },
    {
      formVisible: false,
      overviewVisible: false,
      submitted: false,
    },
    {
      formVisible: false,
      overviewVisible: false,
      submitted: false,
    },
  ];

  stepCompleteVisible = false;

  @Input() set booking(booking: AdminBooking) {
    // this.formService.booking = booking; @TODO
      if (!!booking && !this.state[4].submitted) {
        this.onNextStepComplete(4, 'notes')
      }
  }

  @Input() customer: Customer;
  @Output() newAddress: EventEmitter<Address> = new EventEmitter();

  @Output() formSubmit: EventEmitter<{booking: DeepPartial<AdminBooking>, address: CreateBillingAddressInput}> = new EventEmitter();

  get BookingItemForms(): FormArray {
    return this.form.controls.items as FormArray;
  }

  get BookingParticipantForms(): FormArray {
    return this.form.controls.participants as FormArray;
  }

  constructor(private formService: BookingFormService) {}

  onNextStepComplete(step: number, formControlName: string): void {
    this.state[step].submitted = true;
    if (this.form.get(formControlName).invalid) {
      return;
    }
    this.state[step].formVisible = false;
    this.state[step].overviewVisible = true;
    this.state[step + 1].formVisible = true;
  }

  onLastStepComplete(step: number, formControlName: string): void {
    if (this.form.get(formControlName).invalid) {
      return;
    }
    this.state[step].formVisible = false;
    this.state[step].overviewVisible = true;
    this.stepCompleteVisible = true;
  }

  onEdit(step: number): void {
    for (let i = 1; i < this.state.length; i++) {
      if (this.state[i].formVisible) {
        this.state[i].formVisible = false;
        this.state[i].overviewVisible = true;
      }
    }
    this.state[step].formVisible = true;
    this.state[step].overviewVisible = false;
  }

  onSubmit(): void {
    const formBooking = this.formService.booking;
    const optionsAgg = formBooking.participants.reduce(
      (result, participant) => {
        Object.keys(participant.options).forEach((optionId) => {
          const optionActive = participant.options[+optionId];
          if (optionActive) {
            if (!result.get(optionId)) {
              result.set(optionId, []);
            }
            const { productVariant, options, ...rest } = participant;
            result.get(optionId).push(rest);
          }
        });
        return result;
      },
      new Map<VId, BookingParticipant[]>()
    );
    const booking: DeepPartial<AdminBooking> = {
      id: formBooking.id,
      lines: [
        // participants
        ...formBooking.items
          .filter((formItem: BookingItemForm) => formItem.amount > 0)
          .map((formItem: BookingItemForm) => ({
            productVariant: formItem.productVariant,
            quantity: formItem.amount,
            participants: formBooking.participants
              .filter(
                (formParticipant: BookingParticipantForm) =>
                  formParticipant.productVariant.id ===
                  formItem.productVariant.id
              )
              .map((formParticipant: BookingParticipantForm) => {
                const { productVariant, options, ...rest } = formParticipant;
                return rest;
              }),
          })),
        // options
        ...Array.from(optionsAgg.keys()).map((optionId) => ({
          productVariant: this._recording.productVariants.find(
            (productVariant) => productVariant.id === optionId
          ),
          quantity: optionsAgg.get(optionId).length,
          participants: optionsAgg.get(optionId),
        })),
      ],
      // @TODO
      // note: formBooking.notes.note,
    };
    const address: CreateBillingAddressInput =
      sanatisedCopy<CreateBillingAddressInput>(
        formBooking.addressPayment.invoiceAddress,
        [
          'salutation',
          'firstName',
          'lastName',
          'company',
          'address',
          'postCode',
          'city',
          'country',
        ]
      );
    this.formSubmit.emit({ booking, address });
  }

  onChangedRecording(recording: Recording): void {
    this.BookingItemForms.clear();

    this._recording = recording;
    recording.productVariants
      .filter(
        (productVariant) => productVariant.product.type === ProductType.TICKET
      )
      .forEach((productVariant) => {
        this.formService.addBookingItem({
          productVariant,
          amount: 0,
        });
      });
  }

  onChangedAmount(bookingItem: BookingItemForm): void {
    const participantCount = this.form.value.participants.filter(
      (bookingParticipant: BookingParticipantForm) =>
        bookingParticipant.productVariant.id === bookingItem.productVariant.id
    ).length;
    const delta = bookingItem.amount - participantCount;
    if (delta === 0) {
      return;
    }
    if (delta < 0) {
      alert('Too many participants - please remove manually'); // @TODO improve this and add validator
      return;
    }
    for (let i = 0; i < delta; i++) {
      this.formService.addBookingParticipant({
        firstName: '',
        lastName: '',
        birthDate: '',
        productVariant: bookingItem.productVariant,
        options: this._recording.productVariants.filter(
          (productVariant) => productVariant.product.type !== ProductType.TICKET
        ),
      });
    }
  }

  onNewAddress(address: Address): void {
    this.newAddress.emit(address);
  }

  log(): void {
    console.log(this.form.status, this.form.value);
  }
}
