import { Injectable } from '@angular/core';
import { ColDef } from 'ag-grid-community';
import { TranslateService } from '@ngx-translate/core';
import {
  BaseColumnIds,
  BaseColumnDefsService,
} from 'src/app/core/list/base-column-defs-service';

export enum UserColumnIds {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  EMAIL = 'email',
  PHONE_NUMBER = 'phoneNumber',
  ACTIVE = 'active',
  ROLES = 'roles',
  DEFAULT_PHONE = 'defaultPhone',
}

@Injectable({
  providedIn: 'root',
})
export class UserListColumnDefsService extends BaseColumnDefsService {
  columnDefsMap = new Map<string, ColDef>([
    [
      BaseColumnIds.ID,
      this.baseColumnDefsMap.get(BaseColumnIds.ID),
    ],
    [
      UserColumnIds.FIRST_NAME,
      {
        headerName: this.translate.instant('USERS.COLUMNS.FIRST_NAME'),
        field: 'firstName',
      },
    ],
    [
      UserColumnIds.LAST_NAME,
      {
        headerName: this.translate.instant('USERS.COLUMNS.LAST_NAME'),
        field: 'lastName',
      },
    ],
    [
      UserColumnIds.EMAIL,
      {
        headerName: this.translate.instant('USERS.COLUMNS.EMAIL'),
        field: 'email',
      },
    ],
    [
      UserColumnIds.PHONE_NUMBER,
      {
        headerName: this.translate.instant('USERS.COLUMNS.PHONE_NUMBER'),
        field: 'phoneNumber',
      },
    ],
    [
      UserColumnIds.ACTIVE,
      {
        headerName: this.translate.instant('USERS.COLUMNS.ACTIVE'),
        field: 'active',
        cellRenderer: (params: any) => {
          return `<input type='checkbox' onclick="return false;" ${params.value ? 'checked' : ''} />`;
          // @TODO use material
        }
      },
    ],
    [
      UserColumnIds.ROLES,
      {
        headerName: this.translate.instant('USERS.COLUMNS.ROLES'),
        field: 'roles',
      },
    ],
    [
      BaseColumnIds.ACTIONS,
      this.baseColumnDefsMap.get(BaseColumnIds.ACTIONS),
    ],
  ]);

  columnDefs: ColDef[] = Array.from(this.columnDefsMap.keys())
    .map((key) => ({
      colId: key,
      ...this.columnDefsMap.get(key),
    }))
    // sort is not yet implemented for connections
    .map((col) => ({
      ...col,
      sortable: false,
      filterParams: {
        ...col.filterParams,
        suppressAndOrCondition: true,
      },
    }));

  constructor(protected translate: TranslateService) {
    super(translate);
  }
}
