import { Action, combineReducers, ActionReducer } from '@ngrx/store';
import * as fromListConfigEntities from './entities/list-config-entities.reducer';
import * as fromListConfigLists from './lists/list-config-lists.reducer';

export const listConfigFeatureKey = 'listConfig';

export interface State {
  [fromListConfigEntities.listConfigEntitiesFeatureKey]: fromListConfigEntities.State;
  [fromListConfigLists.listConfigListsFeatureKey]: fromListConfigLists.State;
}

export function reducers(
  state: State | undefined,
  action: Action
): ActionReducer<State, any> {
  // @ts-ignore
  return combineReducers({
    [fromListConfigEntities.listConfigEntitiesFeatureKey]:
      fromListConfigEntities.reducer,
    [fromListConfigLists.listConfigListsFeatureKey]:
      fromListConfigLists.reducer,
  })(state, action);
}
