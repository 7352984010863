import { Component } from '@angular/core';
import { UsersFacadeService } from '../../users-facade.service';

@Component({
  selector: 'app-users-page',
  templateUrl: './users-page.component.html',
  styleUrls: ['./users-page.component.scss'],
})
export class UsersPageComponent {
  constructor(public facade: UsersFacadeService) {}
}
