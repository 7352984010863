import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { AppFacadeService } from 'src/app/app-facade.service';
import { LOADER_ID_ACTION } from 'src/app/app.component';
import { User } from 'src/app/core/graphql.model';

@Component({
  selector: 'app-user-profil-tab',
  templateUrl: './user-profil-tab.component.html',
  styleUrls: ['./user-profil-tab.component.scss'],
})
export class UserProfilTabComponent {
  @Input() user: User;

  @Output() formSubmit: EventEmitter<User> = new EventEmitter();

  actionLoaderActive$: Observable<boolean>;

  constructor(public appFacade: AppFacadeService) {
    this.actionLoaderActive$ = this.appFacade.isLoaderActive$(LOADER_ID_ACTION);
  }

  onSubmit(user: User): void {
    this.formSubmit.emit(user);
  }
}
