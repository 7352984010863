import {
  FormBuilder,
  FormArray,
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';
import { Injectable } from '@angular/core';
import { updateNestedFormElements } from 'src/app/core/form.helpers';
import { Format, FormatReleaseState } from 'src/app/core/graphql.model';

export interface FormFormat extends Omit<Omit<Format, 'cmsId'>, 'cmsFormat'> {}

@Injectable()
export class FormatFormService {
  form: FormGroup = this.createForm();
  formFormat: FormFormat;

  set format(format: Format) {
    if (!format) {
      return;
    }
    // map object data to form data
    const { cmsId, cmsFormat, ...rest } = format;
    this.formFormat = {
      ...rest,
    };
    updateNestedFormElements(this.form, 'contacts', format.contacts.length);
    updateNestedFormElements(this.form, 'products', format.products.length);
    // update form values
    this.form.patchValue(this.formFormat);
  }

  get format(): Format {
    const {isDeletable, ...rest} = this.form.value;
    const result = {
      ...rest,
      releaseState: this.form.value.releaseState || FormatReleaseState.ACTIVE,
    };
    return result;
  }

  constructor(private fb: FormBuilder) {}

  private createForm(): FormGroup {
    return this.fb.group({
      id: [''], // hidden
      title: ['', Validators.required],
      audienceCapacity: [],
      minimumAge: [],
      releaseState: [''],
      contacts: this.fb.array([]),
      products: this.fb.array([]),
      tags: [],
      isDeletable: [false], // hidden
    });
    // @TODO check required and validators
  }

  public addContact(): void {
    const ContactFormArray = this.form.get('contacts') as FormArray;
    ContactFormArray.push(
      new FormControl({
        id: null,
        type: null,
        name: null,
        phone: null,
        email: null,
        isDeletable: true,
      })
    );
  }

  public removeContact(idx: number): void {
    const ContactFormArray = this.form.get('contacts') as FormArray;
    ContactFormArray.removeAt(idx);
  }

  public addProduct(): void {
    const ProductFormArray = this.form.get('products') as FormArray;
    ProductFormArray.push(new FormControl({
      id: null,
      name: null,
      type: null,
      isDeletable: true,
    }));
  }

  public removeProduct(idx: number): void {
    const ProductFormArray = this.form.get('products') as FormArray;
    ProductFormArray.removeAt(idx);
  }
}
