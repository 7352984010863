// @TODO get this data from somewhere else

interface CustomerRole {
  value: string;
  viewValue: string;
}

export const roles: CustomerRole[] = [
  { value: 'kunde-1', viewValue: 'Kunde' },
  { value: 'personal-2', viewValue: 'Personal' },
  { value: 'komparse-3', viewValue: 'Komparse' },
  { value: 'partner-4', viewValue: 'Partner' },
  { value: 'emailAdressKunde-5', viewValue: 'E-Mail Adress Kunde ' },
];

interface BookingOption {
  selectedValue: string;
  viewValue: string;
}

export const bookingOptions: BookingOption[] = [
  { selectedValue: 'buchbar im Webshop', viewValue: 'buchbar im Webshop' },
  { selectedValue: 'buchbar intern', viewValue: 'buchbar Intern' },
  { selectedValue: 'ticketalarm an', viewValue: 'ticketalarm an' },
  {
    selectedValue: 'nicht buchbar im Webshop, ticketalarm an',
    viewValue: 'nicht buchbar im Webshop, ticketalarm an',
  },
];

interface BookingActions {
  value: string;
  viewValue: string;
}

export const actions: BookingActions[] = [
  { value: 'Wer wird Millionär-1', viewValue: 'aktion-Wer wird Millionär' },
  {
    value: 'Deutschland sucht den Superstar-2',
    viewValue: 'aktion-Deutschland sucht den Superstar',
  },
  { value: 'bitte auswählen-3', viewValue: '--bitte auswählen--' },
];

interface PersonsAmount {
  personValue: any;
  personViewValue: any;
}

export const persons: PersonsAmount[] = [
  { personValue: 1, personViewValue: 1 },
  { personValue: 2, personViewValue: 2 },
  { personValue: 3, personViewValue: 3 },
  { personValue: 4, personViewValue: 4 },
  { personValue: 5, personViewValue: 5 },
  { personValue: 6, personViewValue: 6 },
  { personValue: 7, personViewValue: 7 },
  { personValue: 8, personViewValue: 8 },
  { personValue: 9, personViewValue: 9 },
  {
    personValue: 'individuelle Gruppe',
    personViewValue: 'individuelle Gruppe',
  },
];

interface BookingOption2 {
  value: string;
  viewValue: string;
}

export const bookingOptions2: BookingOption2[] = [
  { value: 'Hotline', viewValue: 'Hotline' },
  { value: 'Web', viewValue: 'Web' },
  { value: 'Hotline & Web', viewValue: 'Hotline & Web' },
  { value: 'XY', viewValue: 'XY' },
];
