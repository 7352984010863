<div class="o-container u-height-1/1 u-flex u-fd-col u-pb-24 u-pt-12">
  <h1 class="o-type-h4 u-mb-24">Tags</h1>

  <app-tags-select
    [selected]="(facade.tags$ | async) || []"
    [enableCreate]="canCreate"
    [enableDelete]="canDelete"
    [allowEmpty]="true"
    (create)="onCreate($event)"
    (delete)="onDelete($event)"
  ></app-tags-select>

  <!-- @TODO has Tag a deletable field? -->
</div>
