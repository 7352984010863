import { ColDef, FirstDataRenderedEvent } from 'ag-grid-community';
import { Component, Input } from '@angular/core';
import { User } from 'src/app/core/graphql.model';
import {
  columnTypes,
  defaultColDef,
  sideBar,
  statusBar,
} from 'src/app/core/list/list.definitions';
import { UserActionsCellRendererComponent } from './cell-renderers/user-actions-cell-renderer.component';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss'],
})
export class UsersListComponent {
  @Input() data: User[] = [];
  @Input() columnDefs: ColDef[];

  components = {
    actionsCellRenderer: UserActionsCellRendererComponent,
  };

  columnTypes = columnTypes;
  defaultColDef = defaultColDef;
  sideBar = sideBar;
  statusBar = statusBar;

  constructor() {}

  onFirstDataRendered = (event: FirstDataRenderedEvent) => {
    event.columnApi.autoSizeAllColumns();
  }
}
