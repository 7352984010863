<app-loader-wrapper [active]="actionLoaderActive$ | async" style="height: 100%">
  <app-recording-form
    [recording]="facade.currentRecording$ | async"
    [format]="(facade.currentRecording$ | async).format"
    [disabled]="!('update' | able: 'Recording')"
    (delete)="onRemove($event)"
    (formSubmit)="onRecordingSubmit($event)"
  >
  </app-recording-form>
</app-loader-wrapper>
