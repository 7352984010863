import { ColDef } from 'ag-grid-community';
import { TranslateService } from '@ngx-translate/core';

export enum BaseColumnIds {
  SELECT = 'select',
  ID = 'id',
  CREATED_AT = 'createdAt',
  UPDATED_AT = 'updatedAt',
  ACTIONS = 'actions',
}

export class BaseColumnDefsService {
  constructor(protected translate: TranslateService) {}

  baseColumnDefsMap = new Map<string, ColDef>([
    [
      BaseColumnIds.SELECT,
      {
        headerName: this.translate.instant('COLUMNS.SELECT'),
        type: 'noMenu',
        sortable: false,
        resizable: false,
        filter: false,
        checkboxSelection: true,
        headerCheckboxSelection: true,
        headerClass: 'u-pl-8',
        cellStyle: { 'padding-left': '7px' },
        minWidth: 32,
        maxWidth: 32,
        width: 32,
      },
    ],
    [
      BaseColumnIds.ID,
      {
        headerName: this.translate.instant('COLUMNS.ID'),
        field: 'id',
        hide: true,
      },
    ],
    [
      BaseColumnIds.CREATED_AT,
      {
        field: 'createdAt',
        headerName: this.translate.instant('COLUMNS.CREATED_AT'),
        type: 'dateColumn',
      },
    ],
    [
      BaseColumnIds.UPDATED_AT,
      {
        field: 'updatedAt',
        headerName: this.translate.instant('COLUMNS.UPDATED_AT'),
        type: 'dateColumn',
      },
    ],
    [
      BaseColumnIds.ACTIONS,
      {
        cellRenderer: 'actionsCellRenderer',
        type: 'noMenu',
        pinned: 'right',
        filter: false,
        headerClass: 'u-pl-8',
        cellStyle: { 'padding-left': '7px' },
        minWidth: 32,
        maxWidth: 120,
        width: 32,
      },
    ],
  ]);
}
