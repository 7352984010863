import { Component } from '@angular/core';

import { ListConfigsFacadeService } from 'src/app/list-configs/list-configs-facade.service';

@Component({
  selector: 'app-customers-lists-overview-tab',
  templateUrl: './customers-lists-overview-tab.component.html',
  styleUrls: ['./customers-lists-overview-tab.component.scss'],
})
export class CustomersListsOverviewTabComponent {
  constructor(public listConfigFacade: ListConfigsFacadeService) {}
}
