import { recordingsLink } from '../app-routing.module';
import { Id, Link } from '../core/model';

export enum RoutesNames {
  LIST = '',
  CREATE = 'create',
  CREATE_BY_FORMAT = 'create-by-format',
  CREATE_BY_RECORDING_CLONE = 'clone',
}

export const recordingsListLink = (): Link => recordingsLink(); /*.concat(RoutesNames.LIST)*/
export const recordingCreateLink = (): Link => recordingsLink().concat(RoutesNames.CREATE);
export const recordingCreateByFormatLink = (formatId: Id): Link => recordingsLink().concat(RoutesNames.CREATE_BY_FORMAT, formatId);
export const recordingCreateByRecordingCloneLink = (recordingId: Id): Link => recordingsLink().concat(RoutesNames.CREATE_BY_RECORDING_CLONE, recordingId);
export const recordingEditLink = (id: Id): Link => recordingsLink().concat(id);
