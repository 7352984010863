import { createReducer, on } from '@ngrx/store';
import { AuthenticationResult } from 'src/app/core/model';
import * as UserAuthActions from './user-auth.actions';
import { User } from '@blab/blab-graphql';

export const userAuthFeatureKey = 'auth';

export interface State {
  session: AuthenticationResult | undefined;
  user: User;
  rules: any;
}

export const initialState: State = {
  session: undefined,
  user: undefined,
  rules: undefined,
};

export const reducer = createReducer(
  initialState,

  on(UserAuthActions.loginTokenSuccess, (state, action) => ({
    ...state,
    session: action.data,
  })),

  on(UserAuthActions.getUserSuccess, (state, action) => ({
    ...state,
    user: action.data,
  })),

  on(UserAuthActions.logout, (state) => ({
    ...state,
    session: undefined,
  })),

  on(UserAuthActions.updateAbilitiesSuccess, (state, action) => ({
    ...state,
    rules: action.rules,
  })),
);
