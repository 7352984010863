<ag-grid-angular
  style="width: 100%; height: 100%"
  class="ag-theme-material"
  [components]="components"
  [columnTypes]="columnTypes"
  [defaultColDef]="defaultColDef"
  [columnDefs]="columnDefs"
  [sideBar]="sideBar"
  [statusBar]="statusBar"
  [rowData]="data"
  (firstDataRendered)="onFirstDataRendered($event)"
>
</ag-grid-angular>
