import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { AdminBooking } from 'src/app/core/graphql.model';
import * as BookingEntitiesActions from './booking-entities.actions';

export const bookingEntitiesFeatureKey = 'entities';

export interface State extends EntityState<AdminBooking> {
  currentDraftOrderId: string;
}

export const adapter: EntityAdapter<AdminBooking> =
  createEntityAdapter<AdminBooking>();

export const initialState: State = adapter.getInitialState({
  currentDraftOrderId: null,
});

export const reducer = createReducer(
  initialState,

  // CREATE

  on(BookingEntitiesActions.createBookingSuccess, (state, action) =>
    adapter.upsertOne(action.data, {
      ...state,
      currentDraftOrderId: action.data.id,
    })
  ),

  // READ

  on(BookingEntitiesActions.loadBookingsSuccess, (state, action) =>
    adapter.setAll(action.data, state)
  ),

  // UPDATE

  on(BookingEntitiesActions.addBookingCouponCodeSuccess, (state, action) =>
    adapter.upsertOne(action.data, {
      ...state,
      currentDraftOrderId: action.data.id,
    })
  ),

  on(BookingEntitiesActions.completeBookingDraftSuccess, (state, action) =>
    adapter.upsertOne(action.data, {
      ...state,
      currentDraftOrderId: action.data.id,
    })
  ),

  // DELETE
);
