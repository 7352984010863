
<!-- @TODO refactor -->
<div class="appComponent_container">
  <app-searchbar class="searchbar"></app-searchbar>
  <app-navigation-bar class="app-navBar_style-size">
    <router-outlet></router-outlet>
  </app-navigation-bar>
<!--  <div>-->
<!--    <i>footer</i>-->
<!--  </div>-->
  <app-loader-modal *ngIf="appLoaderActive$ | async"></app-loader-modal>
</div>
