import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { GlobalSearchformService } from './global-searchform.service';

@Component({
  selector: 'app-global-searchform',
  templateUrl: './global-searchform.component.html',
  styleUrls: ['./global-searchform.component.scss'],
  providers: [GlobalSearchformService],
})
export class GlobalSearchformComponent {
  form: FormGroup = this.formService.form;

  constructor(private formService: GlobalSearchformService) {}
}
