import { ListConfigConfig } from '../../../core/list/list.model';
import { CustomerConnection } from '../../../core/graphql.model';
import { IServerSideGetRowsRequest, ColDef } from 'ag-grid-community';
import { createAction, props } from '@ngrx/store';

export const init = createAction(
  '[Customer] Init',
  props<{ columnDefs: ColDef[] }>()
);

export const pushConfig = createAction(
  '[Customer] Push Config',
  props<{ config: Partial<ListConfigConfig>; resetSsrm: boolean }>()
);

export const pullConfig = createAction(
  '[Customer] Pull Config',
  props<{ config: ListConfigConfig }>()
);

export const pullConfigNewBlock = createAction(
  '[Customer] Pull Config New Block'
);

export const loadCustomerConnection = createAction(
  '[Customer] Load Connection',
  props<{ uuid: string; request: IServerSideGetRowsRequest }>()
);
export const loadCustomerConnectionSuccess = createAction(
  '[Customer] Load Connection Success',
  props<{
    uuid: string;
    request: IServerSideGetRowsRequest;
    result: CustomerConnection;
  }>()
);
export const loadCustomerConnectionFailure = createAction(
  '[Customer] Load Connection Failure',
  props<{ uuid: string; request: IServerSideGetRowsRequest; error: any }>()
);
