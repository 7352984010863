import { ColDef } from 'ag-grid-community';
import { createAction, props } from '@ngrx/store';

export const init = createAction(
  '[Recording] Init',
  props<{
    columnDefsOverview: ColDef[];
    columnDefsClone: ColDef[];
    columnDefsFormat: ColDef[];
  }>()
);

export const overviewListToggleGrouped = createAction(
  '[Recording] Overview List Toggle Grouped'
);
