import { formatEditLink } from './../../../formats-routing-helper';
import { Router } from '@angular/router';
import { ICellRendererParams } from 'ag-grid-community';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Component } from '@angular/core';
import { Link } from 'src/app/core/model';
import { ROUTE_QUERY_PARAM_FORMAT_ID } from 'src/app/core/route-params';
import { recordingCreateLink } from 'src/app/recordings/recordings-routing-helper';

@Component({
  selector: 'app-format-actions-cell',
  templateUrl: './format-actions-cell-renderer.component.html',
})
export class FormatActionsCellRendererComponent
  implements ICellRendererAngularComp
{
  params: ICellRendererParams;
  linkDetails: Link;
  linkCreateRecording: Link;
  queryParamsCreateRecording: any;

  constructor(private router: Router) {}

  agInit(params: ICellRendererParams): void {
    this.updateHrefs(params);
  }

  refresh(params: ICellRendererParams): boolean {
    this.updateHrefs(params);
    return true;
  }

  updateHrefs(params: ICellRendererParams): void {
    this.params = params;
    this.linkDetails = formatEditLink(this.params.data?.id);
    this.linkCreateRecording = recordingCreateLink();
    this.queryParamsCreateRecording = {
      [ROUTE_QUERY_PARAM_FORMAT_ID]: this.params.data?.id,
    };
  }

  onDetails(e: any): void {
    e.preventDefault();
    this.router.navigate(this.linkDetails);
  }

  onCreateRecording(e: any): void {
    e.preventDefault();
    this.router.navigate(this.linkCreateRecording, {
      queryParams: this.queryParamsCreateRecording,
    });
  }
}
