import { Injectable } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { BookingCreateSelectRecordingForm } from '../booking-form/booking-form.model';

@Injectable()
export class BookingCreateSelectRecordingFormService {
  form: FormGroup = this.createForm();

  set createSelectRecording(
    createSelectRecording: BookingCreateSelectRecordingForm
  ) {
    if (!createSelectRecording) {
      return;
    }
    this.form.patchValue(createSelectRecording);
  }

  get createSelectRecording(): BookingCreateSelectRecordingForm {
    return this.form.value;
  }

  constructor(private fb: FormBuilder) {}

  private createForm(): FormGroup {
    return this.fb.group({
      format: ['', [Validators.required]],
      campain: [''],
      amount: [''],
      date: [''],
      recording: ['', [Validators.required]],
    });
    // @TODO check required and validators
  }
}
