import { Component } from '@angular/core';
import { map, withLatestFrom } from 'rxjs';
import { BookingsFacadeService } from 'src/app/bookings/bookings-facade.service';
import * as RecordingSelectors from '../../../store/recording.selectors';
import * as fromRecording from '../../../store/recording.reducer';

import { Store } from '@ngrx/store';

@Component({
  selector: 'app-recording-booking-tab',
  templateUrl: './recording-booking-tab.component.html',
  styleUrls: ['./recording-booking-tab.component.scss'],
})
export class RecordingBookingTabComponent {
  constructor(
    public bookingsFacade: BookingsFacadeService,
    private store: Store<fromRecording.State>,

  ) {}

  sanitisedBookings$ = this.bookingsFacade.bookings$.pipe(
    withLatestFrom(
      this.store.select(RecordingSelectors.getEntitiesCurrentRecordingId)
    ),
    map(([bookings, recordingId]) =>
      bookings.map(booking => ({
        ...booking,
        lines: booking.lines.filter(line => line.productVariant.recording.id === recordingId)
      }))
    )
  )
}
