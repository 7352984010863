import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { delay, first, mergeMap, startWith } from 'rxjs/operators';
import { of } from 'rxjs';
import * as WaitingListListsActions from './waiting-list-lists.actions';
import { WaitingListListsColumnDefsService } from './waiting-list-lists-column-defs.service';

@Injectable()
export class WaitingListListsEffects {
  constructor(
    private actions$: Actions,
    private baseColumnDefsService: WaitingListListsColumnDefsService
  ) {}

  init$ = createEffect(() =>
    this.actions$.pipe(
      startWith({ type: 'INIT_TRIGGER' }),
      first(),
      delay(100), // see https://github.com/ngrx/platform/issues/683#issuecomment-517888082
      mergeMap(() =>
        of(
          WaitingListListsActions.init({
            customerColumnDefs: this.baseColumnDefsService.customerColumnDefs,
            formatColumnDefs: this.baseColumnDefsService.formatColumnDefs,
            recordingColumnDefs: this.baseColumnDefsService.recordingColumnDefs,
          })
        )
      )
    )
  );
}
