import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  CreateWaitingListEntryInput,
  Format,
  WaitingListEntryType,
} from 'src/app/core/graphql.model';

@Component({
  selector: 'app-waiting-list-create-form',
  templateUrl: './waiting-list-create-form.component.html',
  styleUrls: ['./waiting-list-create-form.component.scss'],
})
export class WaitingListCreateFormComponent {
  selectedFormat: Format;

  @Input() formats: Format[];

  @Output() createWaitingListEntry: EventEmitter<CreateWaitingListEntryInput> =
    new EventEmitter();

  constructor() {}

  onCreateWaitingList(): void {
    this.createWaitingListEntry.emit({
      customerId: null, // will be set in effect from route
      formatOrRecordingId: this.selectedFormat.id,
      type: WaitingListEntryType.WAITINGLIST,
    });
  }
}
