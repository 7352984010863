import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as WaitingListSelectors from './store/waiting-list.selectors';
import * as WaitingListActions from './store/waiting-list.actions';
import { CreateWaitingListEntryInput } from '../core/graphql.model';

@Injectable({
  providedIn: 'root',
})
export class WaitingListsFacadeService {
  constructor(private store: Store) {}

  // SELECTORS

  waitingList$ = this.store.select(WaitingListSelectors.getEntitiesWaitingList);
  listCustomer$ = this.store.select(WaitingListSelectors.getListCustomer);
  listFormat$ = this.store.select(WaitingListSelectors.getListFormat);
  listRecording$ = this.store.select(WaitingListSelectors.getListRecording);

  // ENTITIES CREATE

  createWaitingListEntry = (input: CreateWaitingListEntryInput) => {
    this.store.dispatch(
      WaitingListActions.entities.createWaitingListEntry({ input })
    );
  };

  // ENTITIES READ

  loadWaitingList = () => {
    this.store.dispatch(WaitingListActions.entities.loadWaitingList());
  };

  // ENTITIES DELETE

  deleteWaitingListEntry = (id: number) => {
    this.store.dispatch(
      WaitingListActions.entities.deleteWaitingListEntry({ id })
    );
  };
}
