import { Component, forwardRef, Input, Output, EventEmitter } from '@angular/core';import { InternalTicketFormService } from './internal-ticket-form.service';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  Validator,
} from '@angular/forms';
import { BaseSubFormComponent } from 'src/app/core/base/base-sub-form.component';

@Component({
  selector: 'app-internal-ticket-form',
  templateUrl: './internal-ticket-form.component.html',
  styleUrls: ['./internal-ticket-form.component.scss'],
  providers: [
    InternalTicketFormService,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InternalTicketFormComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => InternalTicketFormComponent),
      multi: true,
    },
  ],
})
export class InternalTicketFormComponent
  extends BaseSubFormComponent
  implements ControlValueAccessor, Validator
{

  // INPUT PARAMETERS

  @Input() idx: number | string;

  @Input() set disabled(disabled: boolean) {
    this.setDisabledState(disabled);
  }

  // EVENT EMITTERS

  @Output() remove: EventEmitter<void> = new EventEmitter();

  // DATA
  // none

  // DATA GETTERS
  // none

  // CONSTRUCTOR

  constructor(private formService: InternalTicketFormService) {
    super();
    this.form = this.formService.form;
  }

  // CVA METHODS

  writeValue(val: any): void {
    if (val) {
      this.formService.internalTicket = val;
    }
  }

  // EVENT HANDLERS
  
  onRemove(): void {
    this.remove.emit();
  }

  // METHODS
  // none

}
