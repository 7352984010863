import { Component, Input } from '@angular/core';
import { BookingCreateSelectRecordingForm } from '../booking-form/booking-form.model';

@Component({
  selector: 'app-booking-create-select-recording-overview',
  templateUrl: './booking-create-select-recording-overview.component.html',
  styleUrls: ['./booking-create-select-recording-overview.component.scss'],
})
export class BookingCreateSelectRecordingOverviewComponent {
  @Input() createSelectRecording: BookingCreateSelectRecordingForm;

  constructor() {}
}
