import { RecordingFilter } from 'src/app/core/graphql.model';
import { ProductVariant } from './../core/graphql.model';
import { productVariantToAddProductVariantInput, recordingToCreateRecordingInput, recordingToUpdateRecordingInput, removeGraphQlFields } from './recordings.helpers';
import { Id } from '../core/model';
import { map, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { ApolloQueryResult, FetchResult, WatchQueryFetchPolicy } from '@apollo/client/core';
import recordingsQuery from './graphql/recordings-query.graphql';
import recordingQuery from './graphql/recording-query.graphql';
import recordingCreateMutation from './graphql/recording-create-mutation.graphql';
import recordingDeleteMutation from './graphql/recording-delete-mutation.graphql';
import {
  appendArrayMutations,
  removeDefaultFields,
  removeStatisticFields,
} from '../core/graphql.helpers';
import recordingUpdateMutation from './graphql/recording-update-mutation.graphql';
import internalTicketUpdateMutation from './graphql/internal-ticket-update-mutation.graphql';
import productVariantUpdateMutation from './graphql/product-variant-update-mutation.graphql';
import recordingAddInternalTicketMutation from './graphql/recording-add-internal-ticket-mutation.graphql';
import recordingAddProductVariantMutation from './graphql/recording-add-product-variant-mutation.graphql';
import recordingRemoveInternalTicketMutation from './graphql/recording-remove-internal-ticket-mutation.graphql';
import recordingRemoveProductVariantMutation from './graphql/recording-remove-product-variant-mutation.graphql';
import combinedQuery from 'ts-blink-graphql-combine-query';
import recordingsByFormatQuery from './graphql/recordings-by-format-query.graphql';
import recordingsByFilterQuery from './graphql/recordings-by-filter-query.graphql';
import { InternalTicket, Recording } from '../core/graphql.model';

@Injectable({
  providedIn: 'root',
})
export class RecordingsService {
  constructor(private apollo: Apollo) {}

  // CREATE

  createRecording = (recording: Recording): Observable<Recording> => {
      const input = recordingToCreateRecordingInput(recording);
    return this.apollo
      .mutate({
        mutation: recordingCreateMutation,
        variables: {
          input,
        },
      })
      .pipe(
        map((result: FetchResult<any>) => {
          return result.data?.createRecording?.recording as Recording;
        })
      );
  };

  // READ

  loadRecordings = (): Observable<Recording[]> => {
    return this.apollo
      .watchQuery<Recording[]>({
        query: recordingsQuery ,
      })
      .valueChanges.pipe(
        map((result: ApolloQueryResult<any>) =>
          result.data?.recordings.map((recording: Recording) =>
            removeGraphQlFields(recording)
          )
        )
      );
  };

  loadRecordingsByFormat = (formatId: number): Observable<Recording[]> => {
    return this.apollo
      .watchQuery<Recording[]>({
        query: recordingsByFormatQuery ,
        variables: {
          formatId,
        },
      })
      .valueChanges.pipe(
        map((result: ApolloQueryResult<any>) =>
          result.data?.recordings.map((recording: Recording) =>
            removeGraphQlFields(recording)
          )
        )
      );
  };

  loadRecordingsByFilter = (
    filter: RecordingFilter
  ): Observable<Recording[]> => {
    return this.apollo
      .watchQuery<Recording[]>({
        query: recordingsByFilterQuery ,
        variables: {
          filter,
        },
      })
      .valueChanges.pipe(
        map((result: ApolloQueryResult<any>) =>
          result.data?.recordings.map((recording: Recording) =>
            removeGraphQlFields(recording)
          )
        )
      );
  };

  loadRecording = (id: Id, force: boolean = false): Observable<Recording> => {
    const forcedOptions = force ? { fetchPolicy: 'no-cache' as WatchQueryFetchPolicy } : {}
    return this.apollo
      .watchQuery<Recording>({
        query: recordingQuery ,
        variables: {
          id,
        },
        ...forcedOptions,
      })
      .valueChanges.pipe(
        map((result: ApolloQueryResult<any>) =>
          removeGraphQlFields(result.data?.recording)
        )
      );
  };

  // UPDATE

  updateRecording = (
    recording: Recording,
    oldRecording?: Recording
  ): Observable<Recording> => {
    // prepare base diff
    const { internalTickets: _1, productVariants: _2, ...input } = recording;
    // create combined mutation
    const { document, variables } = ((): any => {
      let cq = combinedQuery('CombinedRecordingUpdate').add(
        recordingUpdateMutation,
        {
          updateRecordingInput: recordingToUpdateRecordingInput(recording),
        }
      );
      cq = appendArrayMutations(
        cq,
        'internalTicket',
        recording.internalTickets,
        oldRecording?.internalTickets || [],
        // add
        recordingAddInternalTicketMutation,
        (internalTicket: InternalTicket) => ({
          input_internalTicktes_add: {
            name: internalTicket.name,
            quantity: internalTicket.quantity,
            recordingId: recording.id,
          },
        }),
        // remove
        recordingRemoveInternalTicketMutation,
        (id: Id) => ({
          input_internalTicket_remove: {
            id,
          }
        }),
        // update
        internalTicketUpdateMutation,
        (internalTicket: InternalTicket) => {
          const input2 = removeStatisticFields(internalTicket);
          return { updateInternalTicketInput: input2 };
        }
      );
      cq = appendArrayMutations(
        cq,
        'product_variant',
        recording.productVariants,
        oldRecording?.productVariants || [],
        // add
        recordingAddProductVariantMutation,
        (productVariant: ProductVariant) => ({
          input_productVariant_add: productVariantToAddProductVariantInput(productVariant, recording)
        }),
        // remove
        recordingRemoveProductVariantMutation,
        (id: Id) => ({
          input_productVariant_remove: {
            id,
          }
        }),
        // update
        productVariantUpdateMutation,
        (productVariant: ProductVariant) => {
          const { product: _1, recording: _2, name: _3, sku: _4, ...rest} = removeStatisticFields(productVariant);
          return {
            updateProductVariantInput: {
              ...rest,
              price: +rest.price
            }
          }  
          
        }
      );
      return cq;
    })();

    // execute mutation
    return this.apollo
      .mutate({
        mutation: document as any,
        variables,
      })
      .pipe(
        switchMap(() => {
          return this.loadRecording(recording.id, true)
        })
      );
  };

  // DELETE

  deleteRecording = (id: Id): Observable<null> => {
    return this.apollo
      .mutate({
        mutation: recordingDeleteMutation,
        variables: {
          input: {
            id,
          },
        },
      })
      .pipe(
        map((result: FetchResult<any>) => {
          return null;
        })
      );
  };
}
