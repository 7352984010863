import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { merge, Observable, of, throwError, TimeoutError } from 'rxjs';
import {
  catchError,
  first,
  mergeMap,
  timeout,
  takeUntil,
  map,
  exhaustMap,
  withLatestFrom,
} from 'rxjs/operators';
import * as fromUser from './store/user.reducer';
import * as UserSelectors from './store/user.selectors';
import * as UserActions from './store/user.actions';
import { environment } from '../../environments/environment';

export const AUTH_HEADER = 'Authorization';
export const AUTH_SCHEMA = 'Bearer';
const HTTP_STATUS_TIMEOUT = 999;
const ERROR_MESSAGE_EXPIRED_TOKEN = 'Expired JWT Token';

@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {
  private isRefreshigToken: boolean;

  constructor(
    private store: Store<fromUser.State>,
    private actions$: Actions
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> | Observable<any> {
    return this.store.select(UserSelectors.getAuthToken).pipe(
      first(),
      mergeMap((token) => {
        if (token) {
          req = req.clone({
            headers: req.headers.set(AUTH_HEADER, `${AUTH_SCHEMA} ${token}`),
          });
        }

        return next.handle(req).pipe(
          timeout(environment.timeoutSec * 1000),
          map((response: any) => {
            const errorUnauthorized = response.body?.errors?.find(
              (error: any) => error.extensions?.originalError?.statusCode === 401
            );
            if (errorUnauthorized) {
              const httpError: HttpErrorResponse = new HttpErrorResponse({
                status: errorUnauthorized.extensions?.originalError?.statusCode,
                statusText: errorUnauthorized.extensions?.originalError?.message,
              });
              return this.handleRequestError(httpError, req, next);
            }
            return response;
          }),
          catchError((error) => {
            const httpError: HttpErrorResponse =
              error instanceof TimeoutError
                ? new HttpErrorResponse({
                    status: HTTP_STATUS_TIMEOUT,
                    statusText: error.message,
                  })
                : (error as HttpErrorResponse);
            if (!httpError.error) {
              return throwError(httpError);
            }
            return this.handleRequestError(httpError, req, next);
          })
        );
      })
    );
  }

  private handleRequestError(
    httpError: HttpErrorResponse,
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<any> {
    const error: Error = httpError.error as Error;
    switch (httpError.status) {
      case 200:
        // should never end up here anyways
        return of(null);
      case 401:
        if (error?.message === ERROR_MESSAGE_EXPIRED_TOKEN) {
          return this.handleExpiredToken(req, next);
        } else {
          this.removeAuthentication(error);
        }
        break;
      case 404:
        break;
      case HTTP_STATUS_TIMEOUT:
        break;
      default:
        break;
    }
    return throwError(httpError);
  }

  private removeAuthentication(error: Error): void {
    this.store.dispatch(UserActions.auth.logout({soft: true}));
  }

  private handleExpiredToken(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!this.isRefreshigToken) {
      this.store.dispatch(UserActions.auth.logout({soft: true}));
    }
    // at the moment we have no refreshing
    return this.actions$.pipe(
      ofType(UserActions.auth.loginSuccess),
      takeUntil(merge(
        this.actions$.pipe(ofType(UserActions.auth.loginTokenFailure)),
        this.actions$.pipe(ofType(UserActions.auth.getUserFailure)),
      )),
      first(),
      withLatestFrom(UserSelectors.getAuthToken),
      exhaustMap((token) => {
        this.isRefreshigToken = false;
        req = req.clone({
          headers: req.headers.set(AUTH_HEADER, `${AUTH_SCHEMA} ${token}`),
        });
        return next.handle(req);
      })
    );
  }
}
