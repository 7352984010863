import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Format } from '../core/graphql.model';
import { Id } from '../core/model';
import * as FormatActions from './store/format.actions';
import * as FormatSelectors from './store/format.selectors';

@Injectable({
  providedIn: 'root',
})
export class FormatsFacadeService {
  constructor(private store: Store) {}

  // SELECTORS

  formats$ = this.store.select(FormatSelectors.getEntitiesFormats);
  currentFormat$ = this.store.select(FormatSelectors.getEntitiesCurrentFormat);
  listOverview$ = this.store.select(FormatSelectors.getListOverview);

  // ENTITIES CREATE

  createFormat = (format: Format) => {
    this.store.dispatch(FormatActions.entities.createFormat({ data: format }));
  };

  // ENTITIES READ

  loadFormats = () => {
    this.store.dispatch(FormatActions.entities.loadFormats());
  };

  loadCurrentFormat = () => {
    this.store.dispatch(FormatActions.entities.loadCurrentFormat());
  };

  // ENTITIES UPDATE

  updateFormat = (format: Format) => {
    this.store.dispatch(FormatActions.entities.updateFormat({ data: format }));
  };

  // ENTITIES DELETE

  deleteFormat = (id: Id) => {
    this.store.dispatch(FormatActions.entities.deleteFormat({ id }));
  };

  // LISTS
}
