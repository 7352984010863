<div class="recordingsSearchForm__container">
  <!-- <mat-card> -->
  <form class="form__container">
    <div>
      <mat-label for="recordings"> Aufzeichnung: </mat-label>
      <mat-form-field class="recordingsSearchForm__fields" appearance="outline">
        <input
          matInput
          type="text"
          placeholder="Geben Sie bitte eine Aufzeichnung ein"
          id="title"
          formControlName="title"
        />
      </mat-form-field>
    </div>

    <div>
      <mat-label for="createdAt"> aufgezeichnet am: </mat-label>
      <mat-form-field
        appearance="outline"
        class="recordingsSearchForm__fields-datum"
      >
        <input
          matInput
          type="text"
          placeholder="Aufgezeichnet"
          [matDatepicker]="picker"
          id="createdAt"
          formControlName="createdAt"
        />
        <mat-datepicker-toggle matSuffix [for]="picker">
        </mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>

      <mat-label for="publishedAt"> veröffentlicht am: </mat-label>
      <mat-form-field
        appearance="outline"
        class="recordingsSearchForm__fields-datum"
      >
        <input
          matInput
          type="text"
          placeholder="Veröffentlicht"
          [matDatepicker]="picker"
          id="publishedAt"
          formControlName="publishedAt"
        />
        <mat-datepicker-toggle matSuffix [for]="picker">
        </mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>
  </form>
  <!-- </mat-card> -->
</div>
