<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <mat-accordion>
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header class="recording-form-panel-header">
        <mat-panel-title>Aufzeichnung</mat-panel-title>
      </mat-expansion-panel-header>
      <!-- Panel Content-->
      <input type="hidden" id="id" formControlName="id" class=""/>
      <input type="hidden" id="version" formControlName="version" />

      <div class="u-ph-16">
        <div class="o-grid">
          <div class="o-grid__col u-20/36">
            <mat-form-field class="u-1/1">
              <mat-label for="title">
                {{ "RECORDINGS.RECORDING_FORM.LABEL" | translate }}
              </mat-label>
              <input
                matInput
                type="text"
                [placeholder]="'RECORDINGS.RECORDING_FORM.LABEL' | translate"
                id="title"
                formControlName="title"
              />
            </mat-form-field>
          </div>
          <div class="o-grid__col u-6/36">
            <mat-form-field class="u-1/1">
              <mat-label for="title">
                {{ "RECORDINGS.RECORDING_FORM.RECORDING_TYPE.LABEL" | translate }}
              </mat-label>
              <mat-select
                formControlName="type"
                name="recordingOptions"
                id="recordingType"
                placeholder="wähle Aufzeichnungstyp"
              >
                <mat-option
                  *ngFor="let recordingOption of recordingOptions | keyvalue"
                  [value]="recordingOption.key"
                >
                  {{ recordingOption.value }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="o-grid__col u-10/36">
            <mat-form-field class="u-1/1">
              <mat-label for="season">
                <!-- Staffel -->
                {{ "RECORDINGS.RECORDING_FORM.SEASON.LABEL" | translate }}
              </mat-label>

              <mat-select
                formControlName="seasonId"
                name="Options"
                id="season"
                placeholder="Staffel"
              >
                <mat-option
                  *ngFor="let season of format.cmsFormat?.data?.seasons?.iv"
                  [value]="season.id"
                >
                  {{ season.data?.name?.iv }}
                  <span *ngIf="season.id === format.cmsFormat?.data?.currentSeason?.iv[0]?.id">(aktuelle)</span>
                </mat-option>
              </mat-select>
              <!-- <input
                id="season"
                matInput
                formControlName="seasonId"
              /> -->
            </mat-form-field>
          </div>
        </div>

        <!-- @TODO make the date-time-fields work-->

        <div class="o-grid">
          <div class="o-grid__col u-6/36">
            <mat-form-field class="u-1/1">
              <mat-label for="productionDate">
                {{ "RECORDINGS.RECORDING_FORM.PRODUCTION_START.LABEL" | translate }}
              </mat-label>
              <input
                id="productionDate"
                matInput
                [matDatepicker]="productionDatePicker"
                formControlName="productionDate"
              />
              <mat-datepicker-toggle matSuffix [for]="productionDatePicker">
              </mat-datepicker-toggle>
              <mat-datepicker #productionDatePicker></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="o-grid__col u-6/36">
            <mat-form-field class="u-1/1">
              <mat-label for="checkInBegin">
                {{ "RECORDINGS.RECORDING_FORM.CHECK_IN_BEGIN.LABEL" | translate }}
              </mat-label>
              <input
                id="checkInBegin"
                matInput
                [matDatepicker]="checkInBeginPicker"
                formControlName="checkInBegin"
              />
              <mat-datepicker-toggle matSuffix [for]="checkInBeginPicker">
              </mat-datepicker-toggle>
              <mat-datepicker #checkInBeginPicker></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="o-grid__col u-6/36">
            <mat-form-field class="u-1/1">
              <mat-label for="checkInEnd">
                {{ "RECORDINGS.RECORDING_FORM.CHECK_IN_END.LABEL" | translate }}
              </mat-label>
              <input
                id="checkInEnd"
                matInput
                [matDatepicker]="checkInEndPicker"
                formControlName="checkInEnd"
              />
              <mat-datepicker-toggle matSuffix [for]="checkInEndPicker">
              </mat-datepicker-toggle>
              <mat-datepicker #checkInEndPicker></mat-datepicker>
            </mat-form-field>
          </div>
        </div>

        <div class="o-grid">
          <div class="o-grid__col u-4/36">
            <mat-form-field class="u-1/1">
              <mat-label for="eventStartTime">
                {{ "RECORDINGS.RECORDING_FORM.EVENT_START.LABEL" | translate }}
              </mat-label>
              <input
                id="eventStartTime"
                matInput
                type="text"
                formControlName="eventStartTime"
              />
            </mat-form-field>
          </div>
          <div class="o-grid__col u-4/36">
            <mat-form-field class="u-1/1">
              <mat-label for="productionStartTime">
                {{ "RECORDINGS.RECORDING_FORM.PRODUCTION_TIME_START.LABEL" | translate }}
              </mat-label>
              <input
                id="productionStartTime"
                matInput
                type="text"
                formControlName="productionStartTime"
              />
            </mat-form-field>
          </div>
          <div class="o-grid__col u-4/36">
          <mat-form-field class="u-1/1">
            <mat-label for="eventEndTime">
              {{ "RECORDINGS.RECORDING_FORM.EVENT_TIME_END.LABEL" | translate }}
            </mat-label>
            <input
              id="eventEndTime"
              matInput
              type="text"
              formControlName="eventEndTime"
            />
          </mat-form-field>
        </div>
        </div>

        <hr />
      </div>

      <div class="u-ph-16 u-mt-24">
        <div class="o-grid">
          <div class="o-grid__col u-5/36">
            <mat-form-field class="u-1/1">
              <mat-label for="audienceCapacity">
                {{ "RECORDINGS.RECORDING_FORM.AUDIENCE_CAPACITY.LABEL" | translate }}
              </mat-label>
              <input
                id="audienceCapacity"
                matInput
                type="number"
                min="0"
                formControlName="audienceCapacity"
              />
            </mat-form-field>
          </div>

          <div class="o-grid__col u-5/36">
            <mat-form-field class="u-1/1">
              <mat-label for="wheelchairCapacity">
                davon max. Rollstuhlbelegung
              </mat-label>
              <input
                id="wheelchairCapacity"
                matInput
                type="number"
                min="0"
                formControlName="wheelchairCapacity"
              />
            </mat-form-field>
          </div>

          <div class="o-grid__col u-5/36">
            <mat-form-field class="u-1/1">
              <mat-label for="additionalCapacity">
                Überbuchungskapazität
              </mat-label>
              <input
                id="additionalCapacity"
                matInput
                type="number"
                min="0"
                formControlName="additionalCapacity"
              />
            </mat-form-field>
          </div>
        </div>

        <!--  internal ticket component -->
        <div class="o-grid">
          <div class="o-grid__col u-9/36 "> <!--u-push-2/36-->
            <section>
              <mat-checkbox>Sender/Redaktionskarten</mat-checkbox>
            </section>
          </div>


          <div class="o-grid__col u-27/36 u-flex u-fd-col">
            <div class="u-bgcolor-teal-10 u-pv-12 recording-form-border--total">
              <div class="u-push-1/26">
                <div
                  formArrayName="internalTickets"
                  *ngFor="let x of internalTicketsFormItems.controls; index as i"
                >
                  <app-internal-ticket-form
                    [formControlName]="i"
                    [idx]="'internalTickets-' + i"
                    [disabled]="disabled"
                    (remove)="onRemoveInternalTicket(i)"
                  >
                  </app-internal-ticket-form>
                </div>


                <div class="o-grid">
                  <div class="o-grid__col u-19/26">
                    <div class="c-formfield-placeholder u-inline-flex">
                      <button
                        *ngIf="!disabled"
                        mat-mini-fab
                        color="accent"
                        type="button"
                        (click)="onAddInternalTicket()"
                      >
                        <mat-icon>add</mat-icon>
                      </button>
                    </div>
                  </div>

                  <!--sollte das evtl. ins internal ticket ausgelagert werden?-->
                  <div class="o-grid__col u-4/26">
                    <mat-form-field class="u-1/1">
                      <mat-label for="total"> Gesamt </mat-label>
                      <input
                        disabled
                        matInput
                        type="number"
                        placeholder="total"
                        id="total"
                        [value]="getInternalTicketSum()"
                      />
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- total amount for entire recording -->
        <div class="recording-form-border u-bgcolor-teal-40 u-mt-56">
          <div class="o-type-overline u-flex u-jc-end u-pv-10 ">
            <mat-label class="o-type-overline u-flex u-ai-center" for="totalNumber"> Gesamt buchbare Plätze </mat-label>
            <!--@TODO fragen: soll an dieser stelle ein input sein?-->
            <input
              class="recording-form-input-style o-type-body-large u-ml-12"
              disabled
              id="totalNumber"
              matInput
              type="number"
              [value]="
                formService.recording.audienceCapacity +
                formService.recording.additionalCapacity -
                getInternalTicketSum()
              "
            />
            <!-- use function instead? -->
          </div>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>

  <h2 class="o-type-h5 u-mt-40 u-mb-8">
    + Produkte anlegen
  </h2>
  <!-- product-variant-form -->
  <div
    formArrayName="productVariants"
    *ngFor="let x of productVariantFormItems.controls; index as i"
  >
    <app-product-variant-form
      [formControlName]="i"
      [idx]="'productVariants-' + i"
      [products]="_format.products"
      [disabled]="disabled"
      [baseDate]="productionDate"
      (remove)="onRemoveProductVariant(i)"
    ></app-product-variant-form>
  </div>

  <div *ngIf="unboundProducts.length > 0 && !disabled" class="u-mb-24">
    <app-product-variant-type-menu
      [products]="unboundProducts"
      (addProductVariant)="onAddProductVariant($event)"
    ></app-product-variant-type-menu>
  </div>

<!--  <div class="o-grid u-mt-24">-->
<!--    <div class="o-grid__col u-20/36 u-flex u-ai-start">-->
<!--      <mat-form-field class="u-1/1">-->
<!--        <mat-label for="condition"> Bedingung hinzufügen </mat-label>-->
<!--        <input-->
<!--          id="condition"-->
<!--          matInput-->
<!--          type="text"-->
<!--          formControlName="condition"-->
<!--        />-->
<!--      </mat-form-field>-->
<!--      -->
<!--      <div class="c-formfield-placeholder">-->
<!--        <button-->
<!--          class="u-ml-8"-->
<!--          mat-mini-fab-->
<!--          color="accent"-->
<!--          type="button"-->
<!--        >-->
<!--          <mat-icon>add</mat-icon>-->
<!--        </button>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->

  <div class="u-flex u-ai-center u-jc-between u-mt-36">
    <input type="hidden" id="isDeletable" formControlName="isDeletable" />
    <button
      mat-flat-button
      color="warn"
      type="button"
      [disabled]="!form.value.isDeletable || disabled"
      (click)="onDelete()"
    >
      löschen
      <!-- @TODO auth - editmode (edit vs. create) -->
      <!-- @TODO disable on create-->
    </button>

    <!--        <button-->
    <!--          class="u-mr-8"-->
    <!--          mat-button-->
    <!--          color="primary"-->
    <!--          type="submit"-->
    <!--          [disabled]="form.invalid || disabled"-->
    <!--        >-->
    <!--          Veröffentlichen-->
    <!--        </button>-->

    <button
      mat-fab
      extended
      color="primary"
      type="submit"
      [disabled]="form.invalid || disabled"
    >
      Speichern
    </button>
  </div>

</form>
