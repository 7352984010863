import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AdminBooking, CreateBillingAddressInput } from '../core/graphql.model';
import { DeepPartial } from '../core/model';
import * as BookingActions from './store/booking.actions';
import * as BookingSelectors from './store/booking.selectors';

@Injectable({
  providedIn: 'root',
})
export class BookingsFacadeService {
  constructor(private store: Store) {}

  // SELECTORS

  bookings$ = this.store.select(BookingSelectors.getEntitiesBookings);
  currentBooking$ = this.store.select(
    BookingSelectors.getEntitiesCurrentBooking
  );
  currentDraftOrder$ = this.store.select(
    BookingSelectors.getEntitiesCurrentDraftOrder
  );
  listCustomer$ = this.store.select(BookingSelectors.getListCustomer);
  listRecording$ = this.store.select(BookingSelectors.getListRecording);
  listRecordingParticipants$ = this.store.select(BookingSelectors.getListRecordingParticipant);

  // ENTITIES CREATE

  createBooking = (
    booking: DeepPartial<AdminBooking>,
    address: CreateBillingAddressInput
  ) => {
    this.store.dispatch(
      BookingActions.entities.createBooking({ booking, address })
    );
  };

  // ENTITIES READ

  loadBookings = () => {
    this.store.dispatch(BookingActions.entities.loadBookings());
  };

  // ENTITIES UPDATE

  addCouponCode = (couponCode: string) => {
    this.store.dispatch(BookingActions.entities.addBookingCouponCode({ couponCode }))
  }

  completeDraftBooking = () => {
    this.store.dispatch(BookingActions.entities.completeBookingDraft())
  }

  // ENTITIES DELETE

  // LISTS
}
