import { AddressFormService } from './address-form.service';
import { Component, forwardRef, Input, OnDestroy } from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  Validator,
} from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Salutation } from 'src/app/core/graphql.model';
import { Subscription } from 'rxjs';
import { BaseSubFormComponent } from 'src/app/core/base/base-sub-form.component';

@Component({
  selector: 'app-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss'],
  providers: [
    AddressFormService,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AddressFormComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AddressFormComponent),
      multi: true,
    },
  ],
})
export class AddressFormComponent
  extends BaseSubFormComponent
  implements OnDestroy, ControlValueAccessor, Validator
{
  companyVisible = false;
  Salutation = Salutation;
  subscription: Subscription;

  @Input() isPrimary = false;

  @Input() idx: number | string;

  _disabled: boolean = false;
  @Input() set disabled(disabled: boolean) {
    this._disabled = disabled;
    this.setDisabledState(disabled);
  }
  get disabled(): boolean {
    return this._disabled;
  }

  constructor(private formService: AddressFormService) {
    super();
    this.form = this.formService.form;
    this.subscription = this.form.valueChanges.subscribe((val) =>
      this.onChange(val)
    );
  }

  onChange = (_: any) => {};

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  writeValue(val: any): void {
    if (val) {
      this.formService.address = val;
      this.form.setValue(this.formService.form.value, { emitEvent: false });
    }
    this.companyVisible = !!val?.comapny;
  }

  registerOnChange(fn: (_: any) => void): void {
    this.onChange = (value: number) => {
      fn(this.transform(value));
    };
  }

  onToggleCompanyVisibility(event: MatSlideToggleChange): void {
    this.companyVisible = event.checked;
  }

  private transform(val: any): any {
    return this.formService?.address;
  }
}
