import {
  ColDef,
  SideBarDef,
  StatusPanelDef,
  ValueFormatterParams,
} from 'ag-grid-community';
import { formatDate, formatTime } from '../i18n-helpers';

export const columnTypes: {[key: string]: ColDef} = {
  dateColumn: {
    filter: 'agDateColumnFilter',
    filterParams: {
      comparator: (filterLocalDateAtMidnight: Date, cellValue: Date) => {
        const cellDate: Date = cellValue;
        cellDate.setHours(0, 0, 0, 0);
        if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
          return 0;
        }
        if (cellDate < filterLocalDateAtMidnight) {
          return -1;
        }
        if (cellDate > filterLocalDateAtMidnight) {
          return 1;
        }
        return null;
      },
    },
    valueFormatter: (params: ValueFormatterParams) => formatDate(params.value),
  },
  timeColumn: {
    valueFormatter: (params: ValueFormatterParams) => formatTime(params.value),
  },
  numberColumn: {
    filter: 'agNumberColumnFilter',
  },
  noMenu: {
    suppressColumnsToolPanel: true,
    suppressFiltersToolPanel : true,
  }
};

export const defaultColDef: ColDef = {
  lockPinned: true,
  hide: false,
  sortable: true,
  filter: 'agTextColumnFilter',
  floatingFilter: true,
  resizable: true,
};

export const sideBar: SideBarDef = {
  toolPanels: [
    {
      id: 'columns',
      labelDefault: 'Columns',
      labelKey: 'columns',
      iconKey: 'columns',
      toolPanel: 'agColumnsToolPanel',
      toolPanelParams: {
        suppressRowGroups: true,
        suppressValues: true,
        suppressPivots: true,
        suppressPivotMode: true,
        suppressSideButtons: true,
        suppressColumnFilter: true,
        suppressColumnSelectAll: true,
        suppressColumnExpandAll: true,
      },
    },
    {
      id: 'filters',
      labelDefault: 'Filters',
      labelKey: 'filters',
      iconKey: 'filter',
      toolPanel: 'agFiltersToolPanel',
    },
  ],
  position: 'right',
  defaultToolPanel: '',
};

export const statusBar: { statusPanels: StatusPanelDef[];} = {
  statusPanels: [
    { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
  ],
};
