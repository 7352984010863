<!--<mat-accordion>
  <mat-expansion-panel [expanded]="true">
    <mat-expansion-panel-header>
      <mat-panel-title>Produkte auswählen: Schritt 2</mat-panel-title>
    </mat-expansion-panel-header>

  </mat-expansion-panel>
</mat-accordion> -->

<!-- @TODO relocate into accordion again when decided where the main page shall be-->
<form [formGroup]="form">
  <div class="booking-item-container-border u-bgcolor-ocean-50">
    <input type="hidden" formControlName="productVariant" />
    <div class=" u-flex u-fd-col u-jc-center  u-pv-24 u-ph-84">
      <!--  formControlName="productVariants"-->
      <p class="o-type-body-large u-text-center">{{ _bookingItem.productVariant.product.name }}</p>
      <p class="o-type-body-large u-text-center">{{ _bookingItem.productVariant.price | price }}</p>
      <mat-form-field appearance="fill" class="u-p-20">
        <mat-select
          formControlName="amount"
          (selectionChange)="onAmountSelected()"
        >
          <mat-option
            *ngFor="let person of persons"
            [value]="person.personValue"
          >
            {{ person.personViewValue }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <p class="o-type-body u-text-center u-color-teal-20 dummy-data">noch verfügbaren Tickets</p>
    </div>
  </div>
</form>

