import { Store } from '@ngrx/store';
import { BaseResolver } from '../core/base/base.resolver';
import { Actions } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import * as FormatsActions from './store/format.actions';

@Injectable({
  providedIn: 'root',
})
export class CurrentFormatResolver extends BaseResolver {
  requestAction = FormatsActions.entities.loadCurrentFormat;
  successAction = FormatsActions.entities.loadFormatSuccess;
  failureAction = FormatsActions.entities.loadFormatFailure;
  constructor(protected store: Store, protected actions$: Actions) {
    super();
  }
}
