import {
  handleQueryError,
  handleMutationError,
} from '../../../core/graphql.helpers';
import {
  NotificationType,
  NotifyService,
} from '../../../core/notify/notify.service';
import { Customer } from 'src/app/core/graphql.model';
import { CustomersService } from '../../customers.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { of } from 'rxjs';
import * as CustomerEntitiesActions from './customer-entities.actions';
import * as CustomerSelectors from '../customer.selectors';
import * as fromCustomer from './customer-entities.reducer';
import { Store } from '@ngrx/store';
import { loaderStart, loaderStop } from 'src/app/core/store/app.actions';
import { LOADER_ID_ACTION } from 'src/app/app.component';

@Injectable()
export class CustomerEntitiesEffects {
  constructor(
    private actions$: Actions,
    private store: Store<fromCustomer.State>,
    private service: CustomersService,
    private notifyService: NotifyService
  ) {}

  // CREATE

  createCustomer$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CustomerEntitiesActions.createCustomer),
      map((action) => action.data),
      mergeMap((data) =>
        this.service.createCustomer(data).pipe(
          map((customer: Customer) =>
            CustomerEntitiesActions.createCustomerSuccess({
              data: customer,
            })
          ),
          catchError((error) =>
            of(
              CustomerEntitiesActions.createCustomerFailure({
                error: error.message,
              })
            )
          )
        )
      )
    );
  });
  createCustomerSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CustomerEntitiesActions.createCustomerSuccess),
        tap(() =>
          this.notifyService.notify({
            type: NotificationType.Toast,
            message: 'Successfully created', // @TODO i18n
          })
        )
      ),
    { dispatch: false }
  );

  // READ

  loadCustomers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CustomerEntitiesActions.loadCustomers),
      switchMap(() => {
        return this.service.loadCustomers().pipe(
          switchMap((customers) =>
            of(
              CustomerEntitiesActions.loadCustomersSuccess({ data: customers })
            )
          ),
          catchError((error) => {
            return of(
              CustomerEntitiesActions.loadCustomersFailure({
                error: error.message,
              })
            );
          })
        );
      })
    );
  });

  loadCurrentCustomer$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CustomerEntitiesActions.loadCurrentCustomer),
      withLatestFrom(
        this.store.select(CustomerSelectors.getEntitiesCurrentCustomerId)
      ),
      switchMap(([, id]) => {
        if (!id) {
          return of(CustomerEntitiesActions.loadCustomerFailure({ error: {} }));
        }
        return this.service.loadCustomer(id).pipe(
          switchMap((customer) =>
            of(CustomerEntitiesActions.loadCustomerSuccess({ data: customer }))
          ),
          catchError((error) => {
            return of(
              CustomerEntitiesActions.loadCustomerFailure({
                error: error.message,
              })
            );
          })
        );
      })
    );
  });

  // UPDATE

  updateCustomer$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CustomerEntitiesActions.updateCustomer),
      map((action) => action.data),
      mergeMap((data) =>
        of(data).pipe(
          // TODO can this be improved
          withLatestFrom(
            this.store.select(CustomerSelectors.getEntitiesCustomer(data.id))
          ),
          switchMap(([, oldCustomer]) => {
            return this.service.updateCustomer(data, oldCustomer).pipe(
              map((customer: Customer) =>
                CustomerEntitiesActions.updateCustomerSuccess({
                  data: customer,
                })
              ),
              catchError((error) =>
                of(
                  CustomerEntitiesActions.updateCustomerFailure({
                    error: error.message,
                  })
                )
              )
            );
          })
        )
      )
    );
  });
  updateCustomerSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CustomerEntitiesActions.updateCustomerSuccess),
        tap(() =>
          this.notifyService.notify({
            type: NotificationType.Toast,
            message: 'Successfully updated', // @TODO i18n
          })
        )
      ),
    { dispatch: false }
  );

  addNewAddressToCurrentCustomer$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CustomerEntitiesActions.addNewAddressToCurrentCustomer),
      withLatestFrom(
        this.store.select(CustomerSelectors.getEntitiesCurrentCustomer)
      ),
      switchMap(([action, currentCustomer]) => {
        const address = action.data;
        const newCustomer = {
          ...currentCustomer,
          addresses: [...currentCustomer.addresses, address],
        };
        return of(
          CustomerEntitiesActions.updateCustomer({ data: newCustomer })
        );
      })
    );
  });

  // DELETE

  // FAILURES

  queryFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          CustomerEntitiesActions.loadCustomersFailure,
          CustomerEntitiesActions.loadCustomerFailure
        ),
        map((action) => action.error),
        tap((error) => handleQueryError(error, this.notifyService, this.store))
      ),
    { dispatch: false }
  );

  mutationFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          CustomerEntitiesActions.createCustomerFailure,
          CustomerEntitiesActions.updateCustomerFailure
        ),
        map((action) => action.error),
        tap((error) =>
          handleMutationError(error, this.notifyService, this.store)
        )
      ),
    { dispatch: false }
  );

  // LOADERS

  loaderStart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        CustomerEntitiesActions.createCustomer,
        CustomerEntitiesActions.updateCustomer
      ),
      mergeMap(() => of(loaderStart({ id: LOADER_ID_ACTION })))
    )
  );

  loaderStop$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        CustomerEntitiesActions.createCustomerSuccess,
        CustomerEntitiesActions.createCustomerFailure,
        CustomerEntitiesActions.updateCustomerSuccess,
        CustomerEntitiesActions.updateCustomerFailure
      ),
      mergeMap(() => of(loaderStop({ id: LOADER_ID_ACTION })))
    )
  );
}
