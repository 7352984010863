import { Routes } from '@angular/router';
import { Action, Subject } from '@blab/blab-authorization';
import { LOADER_ID_TAB } from 'src/app/core/base/base-tab-page.component';
import { CaslAuthGuard } from 'src/app/core/casl/casl-auth.guard';
import { Permission } from 'src/app/core/model';
import { CurrentFormatRecordingsResolver } from 'src/app/recordings/recordings-current-format.resolver';
import { TagsResolver } from 'src/app/tags/tags.resolver';
import { WaitingListsResolver } from 'src/app/waiting-lists/waiting-lists.resolver';
import { FormatDataTabComponent } from './format-data-tab/format-data-tab.component';
import { FormatRecordingsTabComponent } from './format-recordings-tab/format-recordings-tab.component';
import { RouteNames } from './format-routing-helper';
import { FormatWaitingListsTabComponent } from './format-waiting-lists-tab/format-waiting-lists-tab.component';

export const routes: Routes = [
  {
    path: RouteNames.DATA,
    component: FormatDataTabComponent,
    pathMatch: 'full',
    canActivate: [CaslAuthGuard],
    resolve: {
      tabs: TagsResolver,
    },
    data: {
      loader: LOADER_ID_TAB,
      auth: [
        {
          subject: Subject.Tag,
          action: Action.Read,
        },
      ] as Permission[],
    },
  },
  {
    path: RouteNames.FORMAT_RECORDINGS,
    component: FormatRecordingsTabComponent,
    canActivate: [CaslAuthGuard],
    resolve: {
      recordings: CurrentFormatRecordingsResolver,
    },
    data: {
      loader: LOADER_ID_TAB,
      auth: [
        {
          subject: Subject.Recording,
          action: Action.Read,
        },
      ] as Permission[],
    },
  },
  {
    path: RouteNames.WAITING_LIST,
    component: FormatWaitingListsTabComponent,
    canActivate: [CaslAuthGuard],
    resolve: {
      waitingLists: WaitingListsResolver,
    },
    data: {
      loader: LOADER_ID_TAB,
      auth: [
        {
          subject: Subject.WaitingList,
          action: Action.Read,
        },
      ] as Permission[],
    },
  },
];
