<div class="c-user-header u-bgcolor-primary-purple-80 u-color-white u-pv-12">
  <div class="o-container">
    <div class="u-flex u-jc-between">
      <h1 class="o-type-h4">{{ _customer?.firstName }} {{ _customer?.lastName }}</h1>
      <mat-chip-set>
        <mat-chip color="accent"> {{ _primaryAddress?.postCode }} {{ _primaryAddress?.city }}</mat-chip>
      </mat-chip-set>
    </div>
<!--    <p>-->
<!--      {{ _primaryAddress?.address }} {{ _primaryAddress?.postCode }}-->
<!--      {{ _primaryAddress?.city }}-->
<!--    </p>-->
    <mat-chip-set class="u-mt-16">
      <mat-chip color="accent"> chip test </mat-chip>
    </mat-chip-set>
  </div>
</div>


