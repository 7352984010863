import { getMergedRoute } from './../../core/store/router/router-state.selectors';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Id } from 'src/app/core/model';
import * as fromUserEntities from './entities/user-entities.reducer';
import * as fromUserLists from './lists/user-lists.reducer';
import * as fromUserAuth from './auth/user-auth.reducer';
import * as fromUser from './user.reducer';
import * as AuthSelectors from './auth/user-auth.selectors';
import * as EntitiesSelectors from './entities/user-entities.selectors';
import * as ListsSelectors from './lists/user-lists.selectors';

export const selectUserState = createFeatureSelector<fromUser.State>(
  fromUser.userFeatureKey
);

// AUTH

export const selectAuthState = createSelector(
  selectUserState,
  (state) => state[fromUserAuth.userAuthFeatureKey]
);

export const getAuthRules = createSelector(
  selectAuthState,
  AuthSelectors.getRules
);

export const isAuthAuthenticated = createSelector(
  selectAuthState,
  AuthSelectors.isAuthenticated
);

export const getAuthToken = createSelector(
  selectAuthState,
  AuthSelectors.getToken
);

export const getAuthUser = createSelector(
  selectAuthState,
  AuthSelectors.getUser
);

// ENTITIES

export const selectEntitiesState = createSelector(
  selectUserState,
  (state) => state[fromUserEntities.userEntitiesFeatureKey]
);

export const getEntitiesUsers = createSelector(
  selectEntitiesState,
  EntitiesSelectors.getUsers
);

export const getEntitiesCurrentUserId = createSelector(
  getMergedRoute,
  EntitiesSelectors.getCurrentUserId
);

export const getEntitiesCurrentUser = createSelector(
  selectEntitiesState,
  getEntitiesCurrentUserId,
  (state, id) => state.entities[id]
);

export const getEntitiesUser = (id: Id) =>
  createSelector(selectEntitiesState, (state) => state.entities[id]);

// LISTS

export const selectListState = createSelector(
  selectUserState,
  (state) => state[fromUserLists.userListsFeatureKey]
);

export const getListOverview = createSelector(
  selectListState,
  ListsSelectors.getOverview
);
