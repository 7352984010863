import { removeGraphQlFields as removeGraphQlFieldsBasic } from '../core/graphql.helpers';
import { Format } from '../core/graphql.model';

export const removeGraphQlFields = (format: Format): Format => ({
  ...removeGraphQlFieldsBasic(format),
  contacts: format.contacts.map((contact) =>
    removeGraphQlFieldsBasic(contact)
  ),
  products: format.products.map((product) =>
    removeGraphQlFieldsBasic(product)
  ),
});
