import { take, map, takeUntil } from 'rxjs/operators';
import { Actions, ofType } from '@ngrx/effects';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

export abstract class BaseResolver implements Resolve<any> {
  protected abstract store: Store;
  protected abstract actions$: Actions;
  protected abstract requestAction: any;
  protected abstract successAction: any;
  protected abstract failureAction: any;

  constructor() {}

  resolve(): Observable<any> {
    this.store.dispatch(this.requestAction());
    return this.actions$.pipe(
      ofType(this.successAction),
      takeUntil(this.actions$.pipe(ofType(this.failureAction))),
      take(1),
      map(() => {
        return true;
      })
    );
  }
}
