import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { delay, first, mergeMap, startWith } from 'rxjs/operators';
import { of } from 'rxjs';
import { BookingListColumnDefService } from './booking-list-column-def.service';
import * as BookingListsActions from './booking-lists.actions';

@Injectable()
export class BookingListsEffects {
  constructor(
    private actions$: Actions,
    private baseColumnDefsService: BookingListColumnDefService
  ) {}

  init$ = createEffect(() =>
    this.actions$.pipe(
      startWith({ type: 'INIT_TRIGGER' }),
      first(),
      delay(100), // see https://github.com/ngrx/platform/issues/683#issuecomment-517888082
      mergeMap(() =>
        of(
          BookingListsActions.init({
            // columnDefs: this.baseColumnDefsService.columnDefs,
            customerColumnDefs: this.baseColumnDefsService.customerColumnDefs,
            recordingColumnDefs: this.baseColumnDefsService.recordingColumnDefs,
            recordingParticipantColumnDefs: this.baseColumnDefsService.recordingParticipantColumnDefs,
          })
        )
      )
    )
  );
}
