import { Customer } from 'src/app/core/graphql.model';
import { Id } from 'src/app/core/model';
import { ROUTE_PARAM_CUSTOMER_ID } from 'src/app/core/route-params';
import { MergedRoute } from 'src/app/core/store/router/merged-route';
import { State } from './customer-entities.reducer';

export const getCustomers = (state: State) =>
  Object.values(state).filter((customer): customer is Customer => !!customer);

export const getCurrentCustomerId = (mergedRoute: MergedRoute) =>
  +mergedRoute?.params[ROUTE_PARAM_CUSTOMER_ID];

export const getCustomer = (state: State, id: Id) => state.entities[id];
