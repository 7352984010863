import { Id } from 'src/app/core/model';
import { MergedRoute } from 'src/app/core/store/router/merged-route';
import { State } from './user-entities.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromUser from './user-entities.reducer';
import { ROUTE_PARAM_USER_ID } from 'src/app/core/route-params';
import { User } from 'src/app/core/graphql.model';

export const selectUserState = createFeatureSelector<fromUser.State>(
  fromUser.userEntitiesFeatureKey
);

export const getUsers = (state: State) =>
  Object.values(state.entities).filter((user): user is User => !!user);

export const getCurrentUserId = (mergedRoute: MergedRoute) =>
  +mergedRoute?.params[ROUTE_PARAM_USER_ID];

export const getCurrentUser = createSelector(
  selectUserState,
  getCurrentUserId,
  (state, id) => state.entities[id]
);

export const getUser = (state: State, id: Id) => state.entities[id];
