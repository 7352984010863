import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Product } from 'src/app/core/graphql.model';

@Injectable()
export class ProductFormService {
  form: FormGroup = this.createForm();

  set product(product: Product) {
    this.form.patchValue(product);
    // @TODO update validators when needed
    // switch (
    //   product.type
    // ) {
    //   case ProductType.STUDIO_TOUR:
    //     this.form.get('type');
    //     break;
    //   case ProductType.TICKET:
    //     this.form.get('type');
    //     break;
    //   default:
    //     this.form.get('type');
    //     break;
    // }
    // this.form.get('type').updateValueAndValidity();
  }
  get product(): Product {
    const {isDeletable, ...rest} = this.form.value;
    return rest;
  }

  constructor(private fb: FormBuilder) {}

  private createForm(): FormGroup {
    return this.fb.group({
      id: [''], // hidden
      name: [''],
      type: [''],
      isDeletable: [''],
    });
    // @TODO check required and validators
  }
}
