import {
  NotificationType,
  NotifyService,
} from '../../../core/notify/notify.service';
import { ListConfig } from '../../list-configs.model';
import { getMergedRoute } from '../../../core/store/router/router-state.selectors';
import { ListConfigsService } from '../../list-configs.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { of } from 'rxjs';
import * as ListConfigEntitiesActions from './list-config-entities.actions';
import * as fromListConfig from './list-config-entities.reducer';
import { Store } from '@ngrx/store';
import {
  ROUTE_QUERY_PARAM_LIST_CONFIG_CONFIG,
  ROUTE_QUERY_PARAM_LIST_CONFIG_ID,
} from 'src/app/core/route-params';
import {
  handleMutationError,
  handleQueryError,
} from 'src/app/core/graphql.helpers';

@Injectable()
export class ListConfigEntitiesEffects {
  constructor(
    private actions$: Actions,
    private store: Store<fromListConfig.State>,
    private service: ListConfigsService,
    private notifyService: NotifyService
  ) {}

  // CREATE

  createListConfig$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ListConfigEntitiesActions.createListConfig),
      withLatestFrom(this.store.select(getMergedRoute)),
      map(([action, route]) => ({
        ...action.data,
        config: route.queryParams[ROUTE_QUERY_PARAM_LIST_CONFIG_CONFIG],
      })),
      mergeMap((data) => {
        return this.service.createListConfig(data).pipe(
          map((listConfig: ListConfig) =>
            ListConfigEntitiesActions.createListConfigSuccess({
              data: listConfig,
            })
          ),
          catchError((error) =>
            of(
              ListConfigEntitiesActions.createListConfigFailure({
                error: error.message,
              })
            )
          )
        );
      })
    );
  });

  createListConfigSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ListConfigEntitiesActions.createListConfigSuccess),
        tap(() =>
          this.notifyService.notify({
            type: NotificationType.Toast,
            message: 'Successfully created', // @TODO i18n
          })
        )
      ),
    { dispatch: false }
  );

  // READ

  loadListConfigs$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ListConfigEntitiesActions.loadListConfigs),
      switchMap(() => {
        return this.service.loadListConfigs().pipe(
          map((ListConfigs) =>
            ListConfigEntitiesActions.loadListConfigsSuccess({
              data: ListConfigs,
            })
          ),
          catchError((error) => {
            return of(
              ListConfigEntitiesActions.loadListConfigsFailure({
                error: error.message,
              })
            );
          })
        );
      })
    );
  });

  // UPDATE

  updateListConfig$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ListConfigEntitiesActions.updateListConfig),
      map((action) => action.data),
      mergeMap((data) =>
        this.service.updateListConfig(data).pipe(
          map((listConfig: ListConfig) =>
            ListConfigEntitiesActions.updateListConfigSuccess({
              data: listConfig,
            })
          ),
          catchError((error) =>
            of(
              ListConfigEntitiesActions.updateListConfigFailure({
                error: error.message,
              })
            )
          )
        )
      )
    );
  });

  updateListConfigByRoute$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ListConfigEntitiesActions.updateListConfigByRoute),
      withLatestFrom(this.store.select(getMergedRoute)),
      map(([, route]) => ({
        id: +route.queryParams[ROUTE_QUERY_PARAM_LIST_CONFIG_ID],
        config: route.queryParams[ROUTE_QUERY_PARAM_LIST_CONFIG_CONFIG],
      })),
      mergeMap((data) => {
        return this.service.updateListConfig(data).pipe(
          map((listConfig: ListConfig) =>
            ListConfigEntitiesActions.updateListConfigSuccess({
              data: listConfig,
            })
          ),
          catchError((error) =>
            of(
              ListConfigEntitiesActions.updateListConfigFailure({
                error: error.message,
              })
            )
          )
        );
      })
    );
  });

  updateListConfigSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ListConfigEntitiesActions.updateListConfigSuccess),
        tap(() =>
          this.notifyService.notify({
            type: NotificationType.Toast,
            message: 'Successfully updated', // @TODO i18n
          })
        )
      ),
    { dispatch: false }
  );

  // DELETE

  deleteListConfig$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ListConfigEntitiesActions.deleteListConfig),
      mergeMap((action) =>
        this.service.deleteListConfig(action.id).pipe(
          map(() =>
            ListConfigEntitiesActions.deleteListConfigSuccess({
              id: action.id,
            })
          ),
          catchError((error) => {
            return of(
              ListConfigEntitiesActions.deleteListConfigFailure({
                error: error.message,
              })
            );
          })
        )
      )
    );
  });
  deleteListConfigFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ListConfigEntitiesActions.deleteListConfigFailure),
        tap(() =>
          this.notifyService.notify({
            type: NotificationType.Toast,
            message: 'Successfully deleted', // @TODO i18n
          })
        )
      ),
    { dispatch: false }
  );

  // FAILURES

  queryFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ListConfigEntitiesActions.loadListConfigsFailure),
        map((action) => action.error),
        tap((error) => handleQueryError(error, this.notifyService, this.store))
      ),
    { dispatch: false }
  );

  mutationFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          ListConfigEntitiesActions.createListConfigFailure,
          ListConfigEntitiesActions.updateListConfigFailure,
          ListConfigEntitiesActions.deleteListConfigFailure
        ),
        map((action) => action.error),
        tap((error) =>
          handleMutationError(error, this.notifyService, this.store)
        )
      ),
    { dispatch: false }
  );

  // LOADERS
}
