import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  Validator,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Address } from 'src/app/core/graphql.model';
import { AddressCreateDialogFormComponent } from 'src/app/customers/components/address-create-dialog-form/address-create-dialog-form.component';
import { BookingAddressPaymentFormService } from './booking-address-payment-form.service';
import { CustomersFacadeService } from 'src/app/customers/customers-facade.service';
import { BookingAddressPaymentForm } from '../booking-form/booking-form.model';
import { TranslateService } from '@ngx-translate/core';
import { BaseSubFormComponent } from 'src/app/core/base/base-sub-form.component';

@Component({
  selector: 'app-booking-address-payment-form',
  templateUrl: './booking-address-payment-form.component.html',
  styleUrls: ['./booking-address-payment-form.component.scss'],
  providers: [
    BookingAddressPaymentFormService,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BookingAddressPaymentFormComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => BookingAddressPaymentFormComponent),
      multi: true,
    },
  ],
})
export class BookingAddressPaymentFormComponent
  extends BaseSubFormComponent
  implements ControlValueAccessor, Validator
{
  _addressPayment: BookingAddressPaymentForm;

  @Input() set addressPayment(addressPayment: BookingAddressPaymentForm) {
    this.formService.addressPayment = addressPayment;
  }

  @Input() addresses: Address[];

  @Input() set submitted(submitted: boolean) {
    if (submitted === true) {
      Object.values(this.form.controls).forEach((control) =>
        control.markAsTouched()
      );
    }
  }

  @Output() newAddress: EventEmitter<Address> = new EventEmitter();

  // @TODO
  // paymentOptions = new Map<BookingPaymentType, string>();

  constructor(
    public facade: CustomersFacadeService,
    private formService: BookingAddressPaymentFormService,
    public dialog: MatDialog,
    private translate: TranslateService
  ) {
    super();
    this.form = this.formService.form;
    // @TODO
    // Object.values(BookingPaymentType).forEach((key) =>
    //   this.paymentOptions.set(
    //     key,
    //     this.translate.instant(`ENUM.BOOKING_PAYMENT_TYPE.${key}`)
    //   )
    // );
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(AddressCreateDialogFormComponent, {
      width: '1000px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.newAddress.emit(result);
    });
  }

  writeValue(val: any): void {
    if (val) {
      this.formService.addressPayment = val;
      this._addressPayment = val;
      this.form.setValue(this.formService.form.value, { emitEvent: false });
    }
  }
}
