import { formatCmsHref } from './../../formats-routing-helper';
import { FormArray, FormGroup } from '@angular/forms';
import { FormatFormService } from './format-form.service';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Format, Tag } from 'src/app/core/graphql.model';
import { bookingOptions2 } from 'src/app/core/tmp';
import { minimumAges } from 'src/app/core/data';
import { Id } from 'src/app/core/model';

@Component({
  selector: 'app-format-form',
  templateUrl: './format-form.component.html',
  styleUrls: ['./format-form.component.scss'],
  providers: [FormatFormService],
})
export class FormatFormComponent {
  form: FormGroup = this.formService.form;

  formatCmsHref = formatCmsHref;

  _format: Format;
  enviroment = environment;

  bookingOptions = bookingOptions2;
  minimumAges = minimumAges;

  @Input() set format(format: Format) {
    this.formService.format = format;
    this._format = format;
  }
  get format(): Format {
    return this._format
  }

  get isCreateMode(): boolean {
    return !this._format?.id;
  }

  @Input() tags: Tag[];

  _disabled: boolean = false;
  @Input() set disabled(disabled: boolean) {
    this._disabled = disabled;
    this.setDisabledState(disabled);
  }
  get disabled(): boolean {
    return this._disabled;
  }

  @Output() formSubmit: EventEmitter<Format> = new EventEmitter();

  @Output() delete: EventEmitter<Id> = new EventEmitter();

  get contactsFormItems(): FormArray {
    return this.form.controls.contacts as FormArray;
  }

  get productsFormItems(): FormArray {
    return this.form.controls.products as FormArray;
  }

  constructor(private formService: FormatFormService) {}

  onSubmit(): void {
    this.formSubmit.emit(this.formService.format);
  }

  onDelete(): void {
    this.delete.emit(this.formService.format.id);
  }

  onAddContact(): void {
    this.formService.addContact();
  }

  onRemoveContact(idx: number): void {
    this.formService.removeContact(idx);
  }

  onAddProduct(): void {
    this.formService.addProduct();
  }

  onRemoveProduct(idx: number): void {
    this.formService.removeProduct(idx);
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.form.disable() : this.form.enable();
  }
}
