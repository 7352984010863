import { Ability, AbilityBuilder, AbilityClass } from '@casl/ability';
import { Injectable } from '@angular/core';
import { Action, createForUser, Subjects } from '@blab/blab-authorization';
import { User } from '../graphql.model';

export type AppAbility = Ability<[Action, Subjects]>;

@Injectable({
  providedIn: 'root',
})
export class CaslAbilityFactory {
  createFor(user: User) {
    const builder = new AbilityBuilder<Ability<[Action, Subjects]>>(
      Ability as AbilityClass<AppAbility>
    );
    // const user = { roles: roles?.map((role: UserRole) => Role[role]), ...rest }; // @TODO
    return createForUser(user, builder);
  }
}
