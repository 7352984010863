import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Id } from '../core/model';
import { Recording, RecordingFilter } from '../core/graphql.model';
import * as RecordingActions from './store/recording.actions';
import * as RecordingSelectors from './store/recording.selectors';

@Injectable({
  providedIn: 'root',
})
export class RecordingsFacadeService {
  constructor(private store: Store) {}

  // SELECTORS

  recordings$ = this.store.select(RecordingSelectors.getEntitiesRecordings);
  currentRecording$ = this.store.select(
    RecordingSelectors.getEntitiesCurrentRecording
  );
  listOverview$ = this.store.select(RecordingSelectors.getListOverview);
  listClone$ = this.store.select(RecordingSelectors.getListClone);
  listFormat$ = this.store.select(RecordingSelectors.getListFormat);
  recordingsByFormat$ = (formatId: number) => {
    return this.store.select(
      RecordingSelectors.getEntitiesRecordingsByFormat(formatId)
    );
  };
  recordingsByCurrentFormat$ = this.store.select(
    RecordingSelectors.getEntitiesRecordingByCurrentFormat
  );

  // ENTITIES CREATE

  createRecording = (data: Recording) => {
    this.store.dispatch(
      RecordingActions.entities.createRecording({ data })
    );
  };

  // ENTITIES READ

  loadRecordings = () => {
    this.store.dispatch(RecordingActions.entities.loadRecordings());
  };

  loadRecordingsByFormat = (formatId: number) => {
    this.store.dispatch(
      RecordingActions.entities.loadRecordingsByFormat({ formatId })
    );
  };

  loadRecordingsByCurrentFormat = () => {
    this.store.dispatch(
      RecordingActions.entities.loadRecordingsByCurrentFormat()
    );
  };

  loadRecordingByFilter = (filter: RecordingFilter) => {
    this.store.dispatch(
      RecordingActions.entities.loadRecordingsByFilter({ filter })
    );
  };

  loadCurrentRecording = () => {
    this.store.dispatch(RecordingActions.entities.loadCurrentRecording());
  };

  // ENTITIES UPDATE

  updateRecording = (recording: Recording) => {
    this.store.dispatch(
      RecordingActions.entities.updateRecording({ data: recording })
    );
  };

  // ENTITIES DELETE

  deleteRecording = (id: Id) => {
    this.store.dispatch(RecordingActions.entities.deleteRecording({ id }));
  };

  // LISTS

  overviewListToggleGrouped = () => {
    this.store.dispatch(RecordingActions.lists.overviewListToggleGrouped());
  };
}
