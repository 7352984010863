import { createAction, props } from '@ngrx/store';
import {
  AdminBooking,
  CreateBillingAddressInput,
} from 'src/app/core/graphql.model';
import { DeepPartial } from 'src/app/core/model';

// CREATE

export const createBooking = createAction(
  '[Booking] Create',
  props<{
    booking: DeepPartial<AdminBooking>;
    address: CreateBillingAddressInput;
  }>()
);
export const createBookingSuccess = createAction(
  '[Booking] Create Success',
  props<{ data: AdminBooking }>()
);
export const createBookingFailure = createAction(
  '[Booking] Create Failure',
  props<{ error: any }>()
);

// READ

export const loadBookings = createAction('[Booking] Load All');

export const loadBookingsSuccess = createAction(
  '[Booking] Load All Success',
  props<{ data: AdminBooking[] }>()
);

export const loadBookingsFailure = createAction(
  '[Booking] Load All Failure',
  props<{ error: any }>()
);

// UPDATE

export const addBookingCouponCode = createAction(
  '[Booking] Add Coupon Code',
  props<{
    couponCode: string
  }>()
);

export const addBookingCouponCodeSuccess = createAction(
  '[Booking] Add Coupon Code Success',
  props<{ data: AdminBooking }>()
);

export const addBookingCouponCodeFailure = createAction(
  '[Booking] Add Coupon Code Failure',
  props<{ error: any }>()
);

export const completeBookingDraft = createAction('[Booking] Complete Draft');

export const completeBookingDraftSuccess = createAction(
  '[Booking] Complete Draft Success',
  props<{ data: AdminBooking }>()
);

export const completeBookingDraftFailure = createAction(
  '[Booking] Complete Draft Failure',
  props<{ error: any }>()
);

// DELETE
