import { Component } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { Observable } from 'rxjs';
import { AppFacadeService } from './app-facade.service';

// @TODO review this

export const LOADER_ID_APP = 'app';
export const LOADER_ID_ACTION = 'action';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'BLAB ticketing app';

  appLoaderActive$: Observable<boolean>;

  constructor(
    private facade: AppFacadeService,
  ) {
    registerLocaleData(localeDe, 'de-DE', localeDeExtra);
    this.appLoaderActive$ = this.facade.isLoaderActive$(LOADER_ID_APP);
  }
}
