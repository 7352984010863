import { bookingOptions } from '../../../core/tmp';
import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
} from '@angular/core';
import { ProductVariantFormService } from './product-variant-form.service';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  Validator,
} from '@angular/forms';
import { Product, ProductVariant } from 'src/app/core/graphql.model';
import { BaseSubFormComponent } from 'src/app/core/base/base-sub-form.component';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'app-product-variant-form',
  templateUrl: './product-variant-form.component.html',
  styleUrls: ['./product-variant-form.component.scss'],
  providers: [
    ProductVariantFormService,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ProductVariantFormComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ProductVariantFormComponent),
      multi: true,
    },
  ],
})
export class ProductVariantFormComponent
  extends BaseSubFormComponent
  implements ControlValueAccessor, Validator
{

  // INPUT PARAMETERS

  @Input() idx: number | string;

  _products: Product[];
  @Input() set products(products: Product[]) {
    this._products = products;
    this.formService.products = products;
  }
  get products(): Product[] {
    return this._products;
  }

  @Input() set disabled(disabled: boolean) {
    this.setDisabledState(disabled);
  }

  @Input() set baseDate(baseDate: string) {
    this.formService.baseDate = new Date(baseDate);
  }

  // EVENT EMITTERS

  @Output() remove: EventEmitter<void> = new EventEmitter();

  @Output() formSubmit: EventEmitter<ProductVariant> = new EventEmitter();
  
  // DATA
  // none

  // DATA GETTERS
  // none

  // COMPONENT

  constructor(private formService: ProductVariantFormService) {
    super();
    this.form = this.formService.form;
  }

  // CVA METHODS

  writeValue(val: any): void {
    if (val) {
      this.formService.productVariant = val;
    }
  }

  registerOnChange(fn: any): void {
    const f = (newValue: any) => {
      fn(this.formService.productVariant);
    };
    this.form.valueChanges
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(f);
  }

  // EVENT HANDLERS
  
  onRemove(): void {
    this.remove.emit();
  }

  // METHODS
  // none


  // @TODO OBSOLETE

  bookingOptions = bookingOptions;

  // @TODO prevents accordion to open when dropdown is clicked
  onClickStopPropagation(event: Event): void {
    event.stopPropagation();
  }
}
