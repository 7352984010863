import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AdminBooking } from '@blab/blab-graphql';

@Component({
  selector: 'app-booking-draft-coupon',
  templateUrl: './booking-draft-coupon.component.html',
  styleUrls: ['./booking-draft-coupon.component.scss'],
})
export class BookingDraftCouponComponent {
  _booking: AdminBooking;
  _discountWithTax: number;
  _totalWithoutDiscountWithTax: number;
  _handlingFee: number;
  @Input() set booking(booking: AdminBooking) {
    this._booking = booking;

    this._discountWithTax = this._booking.discounts
      .map(discount => discount.amountWithTax)
      .reduce((sum, discountAmountWithTax) => sum + discountAmountWithTax, 0);
    this._totalWithoutDiscountWithTax = this._booking.totalWithTax - this._discountWithTax;
    this._handlingFee = this._booking.totalWithTax - this._booking.subTotalWithTax;
  };

  @Output() add: EventEmitter<string> = new EventEmitter();
  
  couponCode: string;

  constructor() {}

  onAdd = () => {
    this.add.emit(this.couponCode);
    this.couponCode = '';
  }

}
