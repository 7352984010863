<ag-grid-angular
  style="width: 100%; height: 100%"
  class="ag-theme-material"
  [components]="components"
  [columnTypes]="columnTypes"
  [defaultColDef]="defaultColDef"
  [columnDefs]="columnDefs"
  [suppressClickEdit]="true"
  [sideBar]="sideBar"
  [statusBar]="statusBar"
  [rowData]="data"
  (firstDataRendered)="onFirstDataRendered($event)"
>
</ag-grid-angular>

<!--@TODO ask: if it's needed to save/submit the change by clicking out of the grid: then add below -->
<!--   [stopEditingWhenCellsLoseFocus]="true"-->
<!--[icons]="icons"-->
