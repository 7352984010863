import { Component, OnInit } from '@angular/core';
import { Action, Subject } from '@blab/blab-authorization';
import { CaslService } from 'src/app/core/casl/casl.service';
import { strip } from 'src/app/core/form.helpers';
import { Id } from 'src/app/core/model';
import { TagsFacadeService } from '../../tags-facade.service';

@Component({
  selector: 'app-tags-page',
  templateUrl: './tags-page.component.html',
  styleUrls: ['./tags-page.component.scss']
})
export class TagsPageComponent implements OnInit {

  canCreate = false;
  canDelete = false;

  // COMPONENT

  constructor(
    public facade: TagsFacadeService,
    private caslService: CaslService,
  ) { }

  ngOnInit(): void {
    this.canCreate = this.caslService.can([{
      action: Action.Create,
      subject: Subject.Tag,
    }]);
    this.canDelete = this.caslService.can([{
      action: Action.Delete,
      subject: Subject.Tag,
    }]);
    // @TODO does this handle user change correctly?
  };

  // HANDLERS

  onDelete = (id: Id) => {
    this.facade.deleteTag(id);
  };

  onCreate = (name: string) => {
    const strippedName = strip(name);
    if (!strippedName) {
      return;
    }
    this.facade.createTag({
      id: null, // @TODO use input type here
      name: strippedName
    });
  };

}
