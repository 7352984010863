<div style="height: 100%; display: flex; flex-direction: column">
  <app-customers-list
    style="flex: 1"
    [columnDefs]="(facade.listOverview$ | async)?.columnDefs"
    [datasource]="datasource"
    [filter]="(facade.listOverview$ | async)?.config.filter"
    [columnsState]="(facade.listOverview$ | async)?.config.columnsState"
    [selection]="(facade.listOverview$ | async)?.config.selection"
    (filterChanged)="onFilterChanged($event)"
    (columnsStateChanged)="onColumnsStateChanged($event)"
    (selectionChanged)="onSelectionChanged($event)"
  >
  </app-customers-list>
  <p class="u-pt-16" *ngIf="(facade.mergedRoute$ | async)?.queryParams['list-id']">
    <strong>Aktuell geladene Liste:</strong> {{ (facade.mergedRoute$ | async)?.queryParams["list-title"] }} ({{
      (facade.mergedRoute$ | async)?.queryParams["list-id"]
    }})
  </p>
  <div class="listBtn__container">

    <button
      mat-flat-button
      color="accent"
      type="button"
      (click)="onMailToFilter()"
    >
      Email an Filter
    </button>

    <button
      mat-flat-button
      color="accent"
      type="button"
      (click)="onMailToSelection()"
    >
      Email an Auswahl
    </button>
    <!-- @TODO disable when no selection made-->

    <button
      *ngIf="'create' | able: 'ListConfig'"
      mat-flat-button
      color="accent"
      type="button"
      (click)="onSaveListConfig()"
    >
      Liste speichern
    </button>

    <button
      mat-flat-button
      color="accent"
      type="button"
      (click)="onShareListConfig()"
    >
      Liste teilen
    </button>
  </div>
</div>
