import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromTag from './tag.reducer';
import * as fromTagEntities from './entities/tag-entities.reducer';
import { Id } from 'src/app/core/model';
import * as EntitiesSelectors from './entities/tag-entities.selectors';

export const selectTagState = createFeatureSelector<fromTag.State>(
  fromTag.tagFeatureKey
);

// ENTITIES

export const selectEntitiesState = createSelector(
  selectTagState,
  (state) => state[fromTagEntities.tagEntitiesFeatureKey]
);

export const getEntitiesTags = createSelector(
  selectEntitiesState,
  EntitiesSelectors.getTags
);

export const getEntitiesTag = (id: Id) =>
  createSelector(selectEntitiesState, (state) =>
    EntitiesSelectors.getTag(state, id)
  );
