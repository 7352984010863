import { Address, Customer } from 'src/app/core/graphql.model';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-customer-header',
  templateUrl: './customer-header.component.html',
  styleUrls: ['./customer-header.component.scss'],
})
export class CustomerHeaderComponent {
  _customer: Customer;
  _primaryAddress: Address;

  @Input() set customer(customer: Customer) {
    if (!customer) {
      return;
    }
    this._customer = customer;
    this._primaryAddress = customer.addresses[0];
  }

  constructor() {}
}
