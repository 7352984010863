import { LogoutGuard } from './users/logout-guard.service';
import { LoginPageComponent } from './users/pages/login-page/login-page.component';
import { NotFoundPageComponent } from './core/errors/not-found-page/not-found-page.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserAuthGuard } from './users/user-auth.guard';
import { LoginGuard } from './users/login-guard.service';
import { Link } from './core/model';
import { CaslAuthGuard } from './core/casl/casl-auth.guard';
import { UnauthorizedPageComponent } from './core/errors/unauthorized-page/unauthorized-page.component';

export enum RoutesNames {
  CUSTOMERS = 'customers',
  FORMATS = 'formats',
  RECORDINGS = 'recordings',
  MAILS = 'mails',
  ADMINISTRATION = 'administration',
  LOGIN = 'login',
  LOGOUT = 'logout',
  UNAUTHORIZED = 'unauthorized',
}

export const homeLink = (): Link => ['/'];
export const customersLink = (): Link => ['/', RoutesNames.CUSTOMERS];
export const formatsLink = (): Link => ['/', RoutesNames.FORMATS];
export const recordingsLink = (): Link => ['/', RoutesNames.RECORDINGS];
export const mailsLink = (): Link => ['/', RoutesNames.MAILS];
export const administrationLink = (): Link => ['/', RoutesNames.ADMINISTRATION];
export const loginLink = (): Link => ['/', RoutesNames.LOGIN];
export const logoutLink = (): Link => ['/', RoutesNames.LOGOUT];
export const unauthorizedLink = (): Link => ['/', RoutesNames.UNAUTHORIZED];

const routes: Routes = [
  {
    path: '',
    redirectTo: RoutesNames.RECORDINGS,
    pathMatch: 'full',
  },
  {
    path: RoutesNames.CUSTOMERS,
    loadChildren: () =>
      import('./customers/customers.module').then((m) => m.CustomersModule),
    canActivate: [UserAuthGuard, CaslAuthGuard],
  },
  {
    path: RoutesNames.FORMATS,
    loadChildren: () =>
      import('./formats/formats.module').then((m) => m.FormatsModule),
    canActivate: [UserAuthGuard, CaslAuthGuard],
  },
  {
    path: RoutesNames.RECORDINGS,
    loadChildren: () =>
      import('./recordings/recordings.module').then((m) => m.RecordingsModule),
    canActivate: [UserAuthGuard, CaslAuthGuard],
  },
  {
    path: RoutesNames.MAILS,
    loadChildren: () =>
      import('./mails/mails.module').then((m) => m.MailsModule),
    canActivate: [UserAuthGuard, CaslAuthGuard],
  },
  {
    path: RoutesNames.ADMINISTRATION,
    loadChildren: () =>
      import('./administration/administration.module').then(
        (m) => m.AdministrationModule
      ),
    canActivate: [UserAuthGuard, CaslAuthGuard],
  },
  {
    path: RoutesNames.LOGIN,
    component: LoginPageComponent,
    canActivate: [LoginGuard],
  },
  {
    path: RoutesNames.LOGOUT,
    component: NotFoundPageComponent, // will not be displayed, as canActivate redirects before
    canActivate: [LogoutGuard],
  },
  {
    path: RoutesNames.UNAUTHORIZED,
    component: UnauthorizedPageComponent,
  },
  {
    path: '**',
    component: NotFoundPageComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
