import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AppFacadeService } from 'src/app/app-facade.service';
import {
  BaseTabPageComponent,
  LOADER_ID_TAB,
} from 'src/app/core/base/base-tab-page.component';
import { Link } from 'src/app/core/model';
import {
  customersListOverviewLink,
  customersListConfigLink,
} from './customers-list-routing-helper';
import {customerCreateLink} from "../../customers-routing-helper";

@Component({
  selector: 'app-customers-page',
  templateUrl: './customers-page.component.html',
  styleUrls: ['./customers-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomersPageComponent
  extends BaseTabPageComponent
  implements OnInit
{
  customerCreateLink = customerCreateLink();
  tabLinks = [customersListOverviewLink(), customersListConfigLink()] as Link[];
  tabLoaderActive$: Observable<boolean>;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    public appFacade: AppFacadeService
  ) {
    super(router, route);
    this.tabLoaderActive$ = this.appFacade.isLoaderActive$(LOADER_ID_TAB);
  }

  ngOnInit(): void {
    this.route.parent.url.subscribe(() => {
      this.pullTabIndex();
    });
  }
}
