import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { removeGraphQlFields } from './bookings.helpers';
import { Apollo } from 'apollo-angular';
import { ApolloQueryResult, FetchResult } from '@apollo/client/core';
import bookingCreateMutation from './graphql/booking-create-mutation.graphql';
import bookingAddCouponCodeMutation from './graphql/booking-add-coupon-code-mutation.graphql';
import bookingTransitionToStateMutation from './graphql/booking-transition-to-state-mutation.graphql';
import {
  AdminBooking,
  AdminBookingLine,
  BookingFilterParameter,
  CreateBillingAddressInput,
  CreateDraftBookingInput,
  Customer,
} from '../core/graphql.model';
import { DeepPartial, Id } from '../core/model';
import bookingsQuery from './graphql/bookings-query.graphql';

@Injectable({
  providedIn: 'root',
})
export class BookingsService {
  constructor(private apollo: Apollo) {}

  // CREATE

  createBooking = (
    booking: DeepPartial<AdminBooking>,
    address: CreateBillingAddressInput
  ): Observable<AdminBooking> => {
    const bookingInput: CreateDraftBookingInput = {
      customerId: (booking.customer as Customer).id,
      items: booking.lines.map((line: AdminBookingLine) => ({
        productVariantId: line.productVariant.id,
        amount: line.quantity,
        participants: line.participants,
      })),
      billingAddress: address,
    };

    return this.apollo
      .mutate({
        mutation: bookingCreateMutation as any,
        variables: {
          input: bookingInput,
        },
      })
      .pipe(
        map((result: FetchResult<any>) => {
          return result.data?.createDraftBooking?.draftBooking;
        })
      );
  };

  // READ

  loadBookings = (
    customerId: Id,
    recordingId: Id
  ): Observable<AdminBooking[]> => {
    let filter: BookingFilterParameter;
    if (customerId) {
      filter = { customerId: { eq: customerId } };
    } else if (recordingId) {
      filter = { recordingId: { eq: recordingId } };
    }
    filter.active = { eq: false };
    return this.apollo
      .watchQuery<AdminBooking>({
        query: bookingsQuery as any,
        variables: {
          filter,
        },
      })
      .valueChanges.pipe(
        map((result: ApolloQueryResult<any>) =>
          result.data?.bookings.items.map((booking: AdminBooking) =>
            removeGraphQlFields(booking)
          )
        )
      );
  };

  // UPDATE

  addBookingCouponCode = (
    id: string,
    couponCode: string,
  ): Observable<AdminBooking> => {
    return this.apollo
      .mutate({
        mutation: bookingAddCouponCodeMutation as any,
        variables: {
          draftBookingId: id,
          couponCode
        },
      })
      .pipe(
        map((result: FetchResult<any>) => {
          return result.data?.applyCouponCodeToDraftBooking?.draftBooking;
        })
      );
  };

  transitionOrderToState = (
    id: string,
    state: string,
  ): Observable<AdminBooking> => {
    return this.apollo
      .mutate({
        mutation: bookingTransitionToStateMutation as any,
        variables: {
          bookingId: id,
          state
        },
      })
      .pipe(
        map((result: FetchResult<any>) => {
          return result.data?.transitionDraftBookingToState?.draftBooking;
        })
      );
  };

  // DELETE
}
