import { Action, Subject } from '@blab/blab-authorization';

export type Id = number;
export type CId = string; // CMS
export type VId = string; // Shop

export type Link = any[];

export type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;

export interface Permission {
  action: Action;
  subject: Subject;
}

export type AuthenticationResult = any;

export const DraftOrderState = 'Draft';
