import { NotifyServiceInterface, Notification } from './notify.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class NotifyConsoleService implements NotifyServiceInterface {
  notify(notification: Notification): void {
    console.log(notification.message);
  }
}
