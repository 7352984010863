<div class="searchForm_container">
  <form class="global-searchform__container">
    <div>
      <mat-label for="searchWhat"> Was: </mat-label>
      <mat-form-field class="searchForm_fields" appearance="outline">
        <input
          matInput
          type="text"
          placeholder="Geben Sie die in den Informationen erhaltenen Keywords ein"
          id="searchWhat"
          formControlName="searchWhat"
        />
      </mat-form-field>
    </div>

    <div>
      <mat-label for="searchWho"> Wer: </mat-label>
      <mat-form-field class="searchForm_fields" appearance="outline">
        <input
          class="searchInput_fields"
          matInput
          type="text"
          placeholder="Geben Sie einen Namen ein"
          id="searchWho"
          formControlName="searchWho"
        />
      </mat-form-field>
    </div>

    <div>
      <mat-label for="searchWhere"> Wo: </mat-label>
      <mat-form-field class="searchForm_fields" appearance="outline">
        <input
          matInput
          type="text"
          placeholder="Geben Sie Formular oder Bereich an"
          id="searchWhere"
          formControlName="searchWhere"
        />
      </mat-form-field>
    </div>

    <div>
      <mat-label for="searchWhithout"> Ohne: </mat-label>
      <mat-form-field class="searchForm_fields" appearance="outline">
        <input
          class="searchInput_fields"
          matInput
          type="text"
          placeholder="Nicht in den Formularen enthaltene Schlagwörter"
          id="searchWhithout"
          formControlName="searchWhithout"
        />
      </mat-form-field>
    </div>

    <div>
      <mat-label for="searchDateStart"> Datum: </mat-label>
      <mat-form-field appearance="outline" class="searchForm_fields-datum">
        <input
          matInput
          type="text"
          placeholder="Startdatum"
          [matDatepicker]="picker"
          id="searchDateStart"
          formControlName="searchDateStart"
        />
        <mat-datepicker-toggle matSuffix [for]="picker">
        </mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
      <mat-label for="searchDateEnd"> bis: </mat-label>
      <mat-form-field class="searchForm_fields-datum" appearance="outline">
        <input
          matInput
          type="text"
          placeholder="Enddatum"
          [matDatepicker]="picker"
          id="searchDateEnd"
          formControlName="searchDateEnd"
        />
        <mat-datepicker-toggle matSuffix [for]="picker">
        </mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>
  </form>
</div>
