import {
  NotificationType,
  NotifyService,
} from '../../../core/notify/notify.service';
import { WaitingListsService } from '../../waiting-lists.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import * as WaitingListEntitiesActions from './waiting-list-entities.actions';
import * as fromWaitingList from './../waiting-list.reducer';
import { getMergedRoute } from 'src/app/core/store/router/router-state.selectors';
import { MergedRoute } from 'src/app/core/store/router/merged-route';
import {
  ROUTE_PARAM_CUSTOMER_ID,
  ROUTE_PARAM_FORMAT_ID,
  ROUTE_PARAM_RECORDING_ID,
} from 'src/app/core/route-params';
import {
  CreateWaitingListEntryInput,
  WaitingListEntry,
  WaitingListEntryType,
} from 'src/app/core/graphql.model';
import {
  handleMutationError,
  handleQueryError,
} from 'src/app/core/graphql.helpers';

@Injectable()
export class WaitingListEntitiesEffects {
  constructor(
    private actions$: Actions,
    private store: Store<fromWaitingList.State>,
    private service: WaitingListsService,
    private notifyService: NotifyService
  ) {}

  // CREATE

  createWaitingList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WaitingListEntitiesActions.createWaitingListEntry),
      withLatestFrom(this.store.select(getMergedRoute)),
      // @ts-ignore
      // @TODO fix typeScript-error
      map(([action, route]) => [action.input, route]),
      mergeMap(([input, route]: [CreateWaitingListEntryInput, MergedRoute]) => {
        // optiionally get ids from route
        const mergedInput = { ...input };
        if (!mergedInput.customerId) {
          mergedInput.customerId = +route.params[ROUTE_PARAM_CUSTOMER_ID];
        }
        if (!mergedInput.formatOrRecordingId) {
          switch (mergedInput.type) {
            case WaitingListEntryType.WAITINGLIST:
              mergedInput.formatOrRecordingId =
                +route.params[ROUTE_PARAM_FORMAT_ID];
              break;
            case WaitingListEntryType.TICKETALARM:
              mergedInput.formatOrRecordingId =
                +route.params[ROUTE_PARAM_RECORDING_ID];
              break;
          }
        }
        return this.service.createWaitingListEntry(mergedInput).pipe(
          map((waitingListEntry: WaitingListEntry) =>
            WaitingListEntitiesActions.createWaitingListEntrySuccess({
              data: waitingListEntry,
            })
          ),
          catchError((error) =>
            of(
              WaitingListEntitiesActions.createWaitingListEntryFailure({
                error: error.message,
              })
            )
          )
        );
      })
    );
  });

  createWaitingListSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(WaitingListEntitiesActions.createWaitingListEntrySuccess),
        tap(() =>
          this.notifyService.notify({
            type: NotificationType.Toast,
            message: 'Successfully created', // @TODO i18n
          })
        )
      ),
    { dispatch: false }
  );
  // @TODO failure notification?

  // READ

  loadWaitingList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WaitingListEntitiesActions.loadWaitingList),
      withLatestFrom(this.store.select(getMergedRoute)),
      switchMap(([, route]) => {
        return this.service
          .loadWaitingList(
            +route?.params[ROUTE_PARAM_CUSTOMER_ID],
            +route?.params[ROUTE_PARAM_FORMAT_ID],
            +route?.params[ROUTE_PARAM_RECORDING_ID]
          )
          .pipe(
            map((waitingLists) =>
              WaitingListEntitiesActions.loadWaitingListSuccess({
                data: waitingLists,
              })
            ),
            catchError((error) => {
              return of(
                WaitingListEntitiesActions.loadWaitingListFailure({
                  error: error.message,
                })
              );
            })
          );
      })
    );
  });

  // UPDATE

  // DELETE

  deleteWaitingListEntry$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WaitingListEntitiesActions.deleteWaitingListEntry),
      mergeMap((action) =>
        this.service.deleteWaitingListEntry(action.id).pipe(
          map(() =>
            WaitingListEntitiesActions.deleteWaitingListEntrySuccess({
              id: action.id,
            })
          ),
          catchError((error) => {
            return of(
              WaitingListEntitiesActions.deleteWaitingListEntryFailure({
                error: error.message,
              })
            );
          })
        )
      )
    );
  });

  // FAILURES

  queryFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(WaitingListEntitiesActions.loadWaitingListFailure),
        map((action) => action.error),
        tap((error) => handleQueryError(error, this.notifyService, this.store))
      ),
    { dispatch: false }
  );

  mutationFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          WaitingListEntitiesActions.createWaitingListEntryFailure,
          WaitingListEntitiesActions.deleteWaitingListEntryFailure
        ),
        map((action) => action.error),
        tap((error) =>
          handleMutationError(error, this.notifyService, this.store)
        )
      ),
    { dispatch: false }
  );

  // LOADERS
}
