import { Routes } from '@angular/router';
import { WaitingListsResolver } from 'src/app/waiting-lists/waiting-lists.resolver';
import { RecordingBookingTabComponent } from './recording-booking-tab/recording-booking-tab.component';
import { RecordingDataTabComponent } from './recording-data-tab/recording-data-tab.component';
import { RouteNames } from './recording-routing-helper';
import { RecordingWaitingListsTabComponent } from './recording-waiting-lists-tab/recording-waiting-lists-tab.component';
import { BookingsResolver } from '../../../bookings/bookings.resolver';
import { LOADER_ID_TAB } from 'src/app/core/base/base-tab-page.component';
import { CaslAuthGuard } from 'src/app/core/casl/casl-auth.guard';
import { Permission } from 'src/app/core/model';
import { Action, Subject } from '@blab/blab-authorization';

export const routes: Routes = [
  {
    path: RouteNames.DATA,
    component: RecordingDataTabComponent,
    pathMatch: 'full',
    data: {
      loader: LOADER_ID_TAB,
    },
  },
  {
    path: RouteNames.BOOKINGS,
    component: RecordingBookingTabComponent,
    canActivate: [CaslAuthGuard],
    resolve: {
      bookings: BookingsResolver,
    },
    data: {
      loader: LOADER_ID_TAB,
      auth: [
        {
          subject: Subject.Booking,
          action: Action.Read,
        },
      ] as Permission[],
    },
  },
  {
    path: RouteNames.WAITING_LIST,
    component: RecordingWaitingListsTabComponent,
    canActivate: [CaslAuthGuard],
    resolve: {
      waitingLists: WaitingListsResolver,
    },
    data: {
      loader: LOADER_ID_TAB,
      auth: [
        {
          subject: Subject.WaitingList,
          action: Action.Read,
        },
      ] as Permission[],
    },
  },
];
