import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import 'ag-grid-enterprise';
import { GraphQLHandler, setupWorker } from 'msw';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));

if (Object.keys(environment).includes('mockConfig')) {
  const handlers: GraphQLHandler[] = (environment as any)?.mockConfig?.handlers;
  setupWorker(...handlers).start({
    //onUnhandledRequest: 'bypass',
    onUnhandledRequest: ({ method, url }) => {
      if (url.pathname.startsWith("/assets")) {
        return;
      }
      console.warn(`[MSW] Unhandled ${method} request to ${url}`);
    },
  });
}
