import {
  NotificationType,
  NotifyService,
} from '../../../core/notify/notify.service';
import { TagsService } from '../../tags.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  tap,
} from 'rxjs/operators';
import { of } from 'rxjs';
import * as TagEntitiesActions from './tag-entities.actions';
import * as fromTag from '../tag.reducer';
import { Store } from '@ngrx/store';
import { Tag } from 'src/app/core/graphql.model';
import { loaderStart, loaderStop } from 'src/app/core/store/app.actions';
import { LOADER_ID_ACTION } from 'src/app/app.component';
import {
  handleMutationError,
  handleQueryError,
} from 'src/app/core/graphql.helpers';

@Injectable()
export class TagEntitiesEffects {
  constructor(
    private actions$: Actions,
    private store: Store<fromTag.State>,
    private service: TagsService,
    private notifyService: NotifyService
  ) {}

  // CREATE

  createTag$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TagEntitiesActions.createTag),
      map((action) => action.data),
      mergeMap((data) =>
        this.service.createTag(data).pipe(
          map((tag: Tag) =>
            TagEntitiesActions.createTagSuccess({ data: tag })
          ),
          catchError((error) =>
            of(
              TagEntitiesActions.createTagFailure({
                error: error.message,
              })
            )
          )
        )
      )
    );
  });
  createTagSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TagEntitiesActions.createTagSuccess),
        tap(() =>
          this.notifyService.notify({
            type: NotificationType.Toast,
            message: 'Successfully created', // @TODO i18n
          })
        )
      ),
    { dispatch: false }
  );

  // READ

  loadTags$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TagEntitiesActions.loadTags),
      switchMap(() => {
        return this.service.loadTags().pipe(
          map((tags) =>
            TagEntitiesActions.loadTagsSuccess({ data: tags })
          ),
          catchError((error) => {
            return of(
              TagEntitiesActions.loadTagsFailure({ error: error.message })
            );
          })
        );
      })
    );
  });

  // UPDATE

  // DELETE

  deleteTag$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TagEntitiesActions.deleteTag),
      mergeMap((action) =>
        this.service.deleteTag(action.id).pipe(
          map(() =>
            TagEntitiesActions.deleteTagSuccess({
              id: action.id,
            })
          ),
          catchError((error) => {
            return of(
              TagEntitiesActions.deleteTagFailure({
                error: error.message,
              })
            );
          })
        )
      )
    );
  });
  deleteTagSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TagEntitiesActions.deleteTagSuccess),
        tap(() =>
          this.notifyService.notify({
            type: NotificationType.Toast,
            message: 'Successfully deleted', // @TODO i18n
          })
        )
      ),
    { dispatch: false }
  );

  // FAILURES

  queryFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          TagEntitiesActions.loadTagsFailure,
        ),
        map((action) => action.error),
        tap((error) => handleQueryError(error, this.notifyService, this.store))
      ),
    { dispatch: false }
  );

  mutationFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          TagEntitiesActions.createTagFailure,
          TagEntitiesActions.deleteTagFailure,
        ),
        map((action) => action.error),
        tap((error) =>
          handleMutationError(error, this.notifyService, this.store)
        )
      ),
    { dispatch: false }
  );

  // LOADERS

  loaderStart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        TagEntitiesActions.createTag,
      ),
      mergeMap(() => of(loaderStart({ id: LOADER_ID_ACTION })))
    )
  );

  loaderStop$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        TagEntitiesActions.createTagSuccess,
        TagEntitiesActions.createTagFailure,
      ),
      mergeMap(() => of(loaderStop({ id: LOADER_ID_ACTION })))
    )
  );
}
