import { RecordingCloneActionsCellRendererComponent } from './cell-renderers/recording-clone-actions-cell-renderer.component';
import { RecordingFullWidthCellRendererComponent } from './cell-renderers/recording-full-width-cell-renderer.component';
import { RecordingsFacadeService } from './../../recordings-facade.service';
import { ColDef, FirstDataRenderedEvent, RowNode } from 'ag-grid-community';
import { Component, Input } from '@angular/core';
import { RecordingActionsCellRendererComponent } from './cell-renderers/recording-actions-cell-renderer.component';
import { Recording } from 'src/app/core/graphql.model';
import {
  columnTypes,
  defaultColDef,
  sideBar,
  statusBar,
} from 'src/app/core/list/list.definitions';

@Component({
  selector: 'app-recordings-list',
  templateUrl: './recordings-list.component.html',
  styleUrls: ['./recordings-list.component.scss'],
})
export class RecordingsListComponent {
  @Input() data: Recording[] = [];
  @Input() columnDefs: ColDef[];
  @Input() groupableByFormat = false;

  components = {
    actionsCellRenderer: RecordingActionsCellRendererComponent,
    cloneActionsCellRenderer: RecordingCloneActionsCellRendererComponent,
    fullWidthCellRenderer: RecordingFullWidthCellRendererComponent,
  };

  columnTypes = columnTypes;
  defaultColDef = defaultColDef;
  sideBar = sideBar;
  statusBar = statusBar;

  // @TODO explain special functions
  autoGroupColumnDef: ColDef = {
    headerName: 'Format',
    cellRendererParams: {
      suppressCount: false,
    },
    valueFormatter: (params) => {
      return '';
    },
  };

  isFullWidthCell = (rowNode: RowNode) => {
    return rowNode.footer;
  };

  constructor(public facade: RecordingsFacadeService) {}

  onFirstDataRendered = (event: FirstDataRenderedEvent) => {
    event.columnApi.autoSizeAllColumns();
  }

}
