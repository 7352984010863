<form [formGroup]="form" (ngSubmit)="onSubmit()">
<mat-accordion>
  <mat-expansion-panel [expanded]="true">
    <mat-expansion-panel-header>
      <mat-panel-title> Stammdaten </mat-panel-title>
      <!-- <mat-panel-description>
        Customer Address (for now)
      </mat-panel-description> -->
    </mat-expansion-panel-header>
    <!-- Panel Content-->
      <input type="hidden" id="id" formControlName="id" />
      <input type="hidden" id="version" formControlName="version" />

      <div class="o-grid">
        <div class="o-grid__col u-2/36">
          <div class="c-formfield-placeholder u-color-grey-60">
            <mat-icon>person</mat-icon>
          </div>
        </div>
        <!--Salutation -->
        <div class="o-grid__col u-6/36">
          <mat-button-toggle-group
            formControlName="salutation"
            aria-label="Salutation"
            class="u-1/1"
          >
            <mat-button-toggle class="u-1/1" [value]="Salutation.MRS">Frau</mat-button-toggle>
            <mat-button-toggle class="u-1/1" [value]="Salutation.MR">Herr</mat-button-toggle>
            <mat-button-toggle class="u-1/1" [value]="Salutation.THEY">Divers</mat-button-toggle>
          </mat-button-toggle-group>
          &nbsp;
        </div>
        <!--First Name -->
        <div class="o-grid__col u-13/36">
          <mat-form-field class="u-1/1">
            <mat-label for="firstName">
              {{ "CUSTOMERS.CUSTOMER_FORM.FIRST_NAME.LABEL" | translate }}
            </mat-label>
            <input
              matInput
              type="text"
              [placeholder]="'CUSTOMERS.CUSTOMER_FORM.FIRST_NAME.PLACEHOLDER' | translate"
              id="firstName"
              formControlName="firstName"
            />
            <mat-error>
              <ng-container [ngSwitch]="true">
                <div *ngSwitchCase="form.get('firstName').errors?.required">
                  {{ "CUSTOMERS.CUSTOMER_FORM.FIRST_NAME.ERRORS.REQUIRED" | translate }}
                </div>
              </ng-container>
            </mat-error>
          </mat-form-field>
        </div>
        <!--Last Name -->
        <div class="o-grid__col u-13/36">
          <mat-form-field class="u-1/1">
            <mat-label for="lastName">
              {{ "CUSTOMERS.CUSTOMER_FORM.LAST_NAME.LABEL" | translate }}
            </mat-label>
            <input
              matInput
              type="text"
              [placeholder]="'CUSTOMERS.CUSTOMER_FORM.LAST_NAME.PLACEHOLDER' | translate "
              id="lastName"
              formControlName="lastName"
            />
            <mat-error>
              <ng-container [ngSwitch]="true">
                <div *ngSwitchCase="form.get('lastName').errors?.required">
                  {{ "CUSTOMERS.CUSTOMER_FORM.LAST_NAME.ERRORS.REQUIRED" | translate }}
                </div>
              </ng-container>
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="o-grid">
        <div class="o-grid__col u-6/36 u-push-2/36">
          <!--Birthdate-->
          <mat-form-field class="u-1/1">
            <mat-label for="birthDate">
              {{ "CUSTOMERS.CUSTOMER_FORM.BIRTH_DATE.LABEL" | translate }}
            </mat-label>
            <input
              id="birthDate"
              matInput
              [matDatepicker]="picker"
              formControlName="birthDate"
              [placeholder]="'CUSTOMERS.CUSTOMER_FORM.BIRTH_DATE.PLACEHOLDER' | translate"
            />
            <mat-datepicker-toggle matSuffix [for]="picker">
            </mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <!-- @TODO fix datePicker: does not understand a corrected input after error validation occured-->
            <mat-error>
              <ng-container [ngSwitch]="true">
                <div *ngSwitchCase="form.get('birthDate').errors?.required">
                  {{ "CUSTOMERS.CUSTOMER_FORM.BIRTH_DATE.ERRORS.REQUIRED" | translate }}
                </div>
              </ng-container>
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <!--phone option start-->
        <div class="o-grid">
          <div class="o-grid__col u-2/36">
            <div class="u-flex u-jc-center u-ai-start u-height-1/1">
              <div class="c-formfield-placeholder u-color-grey-60">
                <mat-icon>call</mat-icon>
              </div>
            </div>
          </div>

          <div class="o-grid__col u-19/36">
            <div
              formArrayName="phoneOptions"
              *ngFor="let x of phoneOptionsFormItems.controls; index as i"
            >
              <app-contact-option-form
                [formControlName]="i"
                [isPrimary]="x.value.prio === 0"
                [idx]="'phone-' + i"
                [disabled]="disabled"
              >
              </app-contact-option-form>
              <div class="u-flex u-jc-start u-mb-32" *ngIf="(x.value.prio !== 0 || phoneOptionsFormItems.controls.length === 1) && !disabled">
                <button
                  mat-mini-fab
                  color="accent"
                  type="button"
                  (click)="onRemoveAdditionalPhone(i)"
                >
                  <mat-icon>delete</mat-icon>
                </button>
              </div>
            </div>
          </div>

          <div class="o-grid__col u-2/36">
            <div
              class="u-flex u-ai-end u-height-1/1"
              [class.u-pb-20]="phoneOptionsFormItems?.controls?.length === 1"
              [class.u-pb-96]="phoneOptionsFormItems?.controls?.length > 1"
            >
              <div class="c-formfield-placeholder">
                <button
                  *ngIf="!disabled"
                  mat-mini-fab
                  color="accent"
                  type="button"
                  (click)="onAddAdditionalPhone()"
                >
                  <mat-icon>add</mat-icon>
                </button>
              </div>
            </div>
          </div>
        </div>

        <!--email option start-->
        <div class="o-grid">
          <div class="o-grid__col u-2/36">
            <div class="u-flex u-jc-center u-ai-start u-height-1/1">
              <div class="c-formfield-placeholder u-color-grey-60">
                <mat-icon>mail</mat-icon>
              </div>
            </div>
          </div>

          <div class="o-grid__col u-19/36">
            <div
              formArrayName="emailOptions"
              *ngFor="let x of emailOptionsFormItems.controls; index as i"
            >
              <app-contact-option-form
                [formControlName]="i"
                [isPrimary]="x.value.prio === 0"
                [idx]="'email-' + i"
                [disabled]="disabled"
              >
              </app-contact-option-form>

              <div class="u-flex u-jc-start u-mb-32" *ngIf="x.value.prio !== 0 && !disabled">
                <button
                  mat-mini-fab
                  color="accent"
                  type="button"
                  (click)="onRemoveAdditionalEmail(i)"
                >
                  <mat-icon>delete</mat-icon>
                </button>
              </div>
            </div>
          </div>

          <div class="o-grid__col u-2/36">
            <div
              class="u-flex u-ai-end u-height-1/1"
              [class.u-pb-20]="emailOptionsFormItems?.controls?.length === 1"
              [class.u-pb-96]="emailOptionsFormItems?.controls?.length > 1"
            >
              <div class="c-formfield-placeholder u-pb-2">
                <button
                  *ngIf="!disabled"
                  mat-mini-fab
                  color="accent"
                  type="button"
                  (click)="onAddAdditionalEmail()"
                >
                  <mat-icon>add</mat-icon>
                </button>
              </div>
            </div>
          </div>
        </div>

      <!--Address Input start-->
      <div class="o-grid u-mb-2">
        <div class="o-grid__col u-2/36 u-flex u-ai-start u-jc-center u-height-1/1">
          <div class="c-formfield-placeholder u-color-grey-60">
            <mat-icon>location_on</mat-icon>
          </div>
        </div>
        <div class="o-grid__col u-4/36 u-flex u-ai-center">
          <h3 class="o-type-overline">
            {{ "CUSTOMERS.CUSTOMER_FORM.PRIMARY_ADDRESS.HEADLINE" | translate }}
          </h3>
        </div>
      </div>

      <div *ngIf="!!form.get('primaryAddress').value" class="o-grid">
        <div class="o-grid__col u-push-2/36 u-32/36">
          <app-address-form
            formControlName="primaryAddress"
            [isPrimary]="true"
            idx="primary"
            [disabled]="disabled"
          >
          </app-address-form>
        </div>
      </div>

      <div *ngIf="!form.get('primaryAddress').value" class="o-grid__col u-3/36 u-push-33/36 u-mt-16 u-mb-32">
        <button
          *ngIf="!disabled"
          mat-mini-fab
          color="accent"
          type="button"
          (click)="onAddPrimaryAddress()"
        >
          <mat-icon>add</mat-icon>
        </button>
      </div>

      <div *ngIf="!!form.get('primaryAddress').value && form.get('primaryAddress').value.id === null && additionalAddressesFormItems.controls.length === 0" class="o-grid__col u-3/36 u-push-33/36 u-mt-16 u-mb-32">
        <button
          *ngIf="!disabled"
          mat-mini-fab
          color="accent"
          type="button"
          (click)="onRemovePrimaryAddress()"
        >
          <mat-icon>delete</mat-icon>
        </button>
      </div>

    <!-- additional address -->
        <div *ngIf="!!form.get('primaryAddress').value" class="o-grid">
          <div
            class="o-grid__col u-1/1 u-mt-32"
            formArrayName="additionalAddresses"
            *ngFor="let x of additionalAddressesFormItems.controls; index as i"
          >
            <!--
              x is not needed as data is binded via formArrayName
              we only need the loop to create n elements with an index
            -->
            <div class="o-grid">
                <div class="o-grid__col u-2/36">
                  <div class="c-formfield-placeholder u-color-grey-60">
                    <mat-icon>location_on</mat-icon>
                  </div>
                </div>

<!--            @TODO ask:  u-ml-16 makes the box line up with text above - not designed in figma -->
              <div class="additional-address-container o-grid__col u-32/36 u-pt-12">
                <h3 class="o-type-overline u-mb-16">
                  Adresse
                </h3>

                <div class="o-grid">
                  <div class="o-grid__col u-32/36 u-ml-64">
                    <app-address-form
                      [formControlName]="i"
                      [idx]="i"
                      [disabled]="disabled"
                    >
                    </app-address-form>
                    <div class="u-flex u-ai-center u-jc-end u-mt-32 u-mb-48">
                      <button
                        *ngIf="!disabled"
                        mat-mini-fab
                        color="accent"
                        type="button"
                        (click)="onRemoveAdditionalAddress(i)"
                      >
                        <mat-icon>delete</mat-icon>
                      </button>

                      <button
                        class="u-ml-12 u-uppercase u-color-black u-bgcolor-grey-10"
                        mat-fab extended
                        type="submit"
                        [disabled]="form.invalid || disabled"
                      >
                        als Primär Adresse Verwenden
                        <!-- @TODO now required fields are not marked as error -->
                      </button>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="o-grid__col u-3/36 u-push-33/36 u-mt-16 u-mb-32">
            <button
              *ngIf="!disabled"
              mat-mini-fab
              color="accent"
              type="button"
              (click)="onAddAdditionalAddress()"
            >
              <mat-icon>add</mat-icon>
            </button>
          </div>
<!-- @TODO ask: better in grid or with m-auto ? -->
<!--          <div class="u-ml-auto u-mt-16 u-mr-16">-->
<!--            <button-->
<!--              *ngIf="!disabled"-->
<!--              mat-mini-fab-->
<!--              color="accent"-->
<!--              type="button"-->
<!--              (click)="onAddAdditionalAddress()"-->
<!--            >-->
<!--              <mat-icon>add</mat-icon>-->
<!--            </button>-->
<!--          </div>-->

        </div>
  </mat-expansion-panel>
</mat-accordion>

<mat-accordion class="u-block u-mt-32">
  <mat-expansion-panel [expanded]="true">
    <mat-expansion-panel-header>
      <mat-panel-title> Interessen </mat-panel-title>
    </mat-expansion-panel-header>

    <div class="u-ph-24">
      <app-tags-select
        formControlName="tags"
        [tags]="tags"
        [enableAdd]="!disabled"
        [enableDelete]="!disabled"
      ></app-tags-select>
      <!-- @TODO permissions -->
    </div>

  </mat-expansion-panel>
</mat-accordion>

  <div class="u-flex u-jc-end u-ai-center u-mt-36">
<!--    <button-->
<!--      class="u-ml-8"-->
<!--      mat-button-->
<!--      type="submit"-->
<!--      color="primary"-->
<!--    >-->
<!--      Abbrechen-->
<!--    </button>-->

    <button
      mat-fab
      extended
      type="submit"
      color="primary"
      [disabled]="form.invalid || disabled"
    >
      Speichern
      <!-- @TODO now required fields are not marked as error -->
    </button>
  </div>

</form>
