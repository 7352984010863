import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { User, UserRole } from 'src/app/core/graphql.model';
import { UserFormService } from './user-form.service';

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss'],
  providers: [UserFormService],
})
export class UserFormComponent {
  form: FormGroup = this.formService.form;
  allRoles = Object.values(UserRole);

  @Input() set user(user: User) {
    this.formService.user = user;
  }

  @Input() actionLoaderActive: boolean;

  _disabled: boolean = false;
  @Input() set disabled(disabled: boolean) {
    this._disabled = disabled;
    this.setDisabledState(disabled);
  }
  get disabled(): boolean {
    return this._disabled;
  }

  @Output() formSubmit: EventEmitter<User> = new EventEmitter();

  constructor(private formService: UserFormService) {}

  onSubmit(): void {
    this.formSubmit.emit(this.formService.user);
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.form.disable() : this.form.enable();
  }
}
