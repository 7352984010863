import {
  ColumnState,
  IServerSideGetRowsRequest,
} from 'ag-grid-community';
import { Id } from '../model';

export type FilterModel = { [key: string]: any };

export interface ListConfigConfig {
  filter: FilterModel;
  columnsState: ColumnState[];
  selection: Id[];
}

export const getEmptyListConfigConfig = (): ListConfigConfig => {
  return {
    filter: {},
    columnsState: [],
    selection: [],
  };
};

/* Loading Connections in SSRM
  AG-Grid uses `startRow` and `endRow` to address the pagination block, as the api uses the `cursor` of the previous block.
  This means, that we need to finish the previous block of an request to have the `cursor` to start from.
  To map this we keep a mapping in the `blocks` attribute in the store.
  Furthermore we must mark the actions with an uuid for the datasource/getRows to wait for its own result as multiple blocks may be called in parallel
*/

export interface SsrmState<T> {
  lastRequest?: IServerSideGetRowsRequest;
  lastResult?: T;
  lastError?: any;
  blocks?: SsrmBlocksState;
}
export interface SsrmBlocksState {
  [key: number]: {
    endCursor: string;
  };
}
