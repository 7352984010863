import { formatCreateLink } from './../../formats-routing-helper';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormatsFacadeService } from '../../formats-facade.service';

@Component({
  selector: 'app-formats-page',
  templateUrl: './formats-page.component.html',
  styleUrls: ['./formats-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormatsPageComponent {
  formatCreateLink = formatCreateLink();

  constructor(public facade: FormatsFacadeService) {}
}
