<div class="u-p-24">
  <form [formGroup]="form">
    <input type="hidden" id="id" formControlName="id" />
    <app-address-form formControlName="address"> </app-address-form>

    <button
      mat-flat-button
      color="accent"
      (click)="onNoClick()">
      abbrechen
    </button>
    &nbsp;
    <button
      mat-flat-button
      color="accent"
      type="submit"
      [mat-dialog-close]="formService.address"
      cdkFocusInitial
    >
      anlegen
    </button>
  </form>
</div>
