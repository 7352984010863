import { ColDef, ExcelCell, ExcelRow, ExcelStyle, FirstDataRenderedEvent, GridApi, GridReadyEvent, IDetailCellRendererParams, ProcessCellForExportParams, ProcessRowGroupForExportParams, ValueFormatterParams } from 'ag-grid-community';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  columnTypes,
  defaultColDef,
  sideBar,
  statusBar,
} from '../../../core/list/list.definitions';
import { AdminBooking, AdminBookingLine, BookingParticipant, Customer, Recording } from 'src/app/core/graphql.model';
import { BookingsListActionsCellRendererComponent } from './cell-renderers/bookings-list-actions-cell-renderer.component';
import { BookingsDetailCellRendererComponent } from './detail-cell-renderer/bookings-detail-cell-renderer.component';
import { formatDate } from 'src/app/core/i18n-helpers';

const salutationTextMap = { // @TODO use enum
  'MRS': 'Frau',
  'MR': 'Herr',
  'THEY': 'Divers',
}

interface BookingParticipantCombined {
  ref: {
    order: AdminBooking,
    orderLine: AdminBookingLine,
    participant: BookingParticipant,
  }
  customerNumber: string;
  show: string;
  productName: string;
  salutation: string;
  firstName: string;
  lastName: string;
  birthDate: Date;
}

@Component({
  selector: 'app-bookings-list',
  templateUrl: './bookings-list.component.html',
  styleUrls: ['./bookings-list.component.scss'],
})
export class BookingsListComponent implements OnInit {
  @Input() data: AdminBooking[] = [];
  @Input() columnDefs: ColDef[];
  @Input() columnDefsParticipants: ColDef[];

  @Output() cellValueChanged: EventEmitter<AdminBooking> = new EventEmitter();

  components = {
    actionsCellRenderer: BookingsListActionsCellRendererComponent,
    detailCellRenderer: BookingsDetailCellRendererComponent,
  };

  columnTypes = columnTypes;
  defaultColDef = defaultColDef;
  sideBar = sideBar;
  statusBar = statusBar;
  gridApi: GridApi;

  public detailCellRendererParams: any = {
    detailGridOptions: {
      // columnDefs: ,
      defaultColDef: {
        flex: 1,
      },
      columnTypes: columnTypes
    },
    getDetailRowData: (params) => {
      params.successCallback(this.getBookingParticipantCombined(params.data));
    },
  } as IDetailCellRendererParams<AdminBooking, BookingParticipantCombined>;

  excelStyles: ExcelStyle[] = [
    {
      id: 'header',
      interior: {
        color: '#aaaaaa',
        pattern: 'Solid',
      },
    },
    {
      id: 'body',
      interior: {
        color: '#dddddd',
        pattern: 'Solid',
      },
    },
  ];

  constructor() {}

  ngOnInit(): void {
    this.detailCellRendererParams.detailGridOptions.columnDefs = this.columnDefsParticipants;
  }

  onFirstDataRendered = (event: FirstDataRenderedEvent) => {
    event.columnApi.autoSizeAllColumns();
  }

  onGridReady = (params: GridReadyEvent): void => {
    this.gridApi = params.api;
  }

  onExport() {    
    this.gridApi.exportDataAsExcel({
      getCustomContentBelowRow: (params) => this.getExportRowsParticipants(params) as ExcelRow[],
      processCellCallback: this.proceddCellCallback,
      fileName: 'Teilnehmerliste.xlsx',
    });
  }

  getExportRowsParticipants = (params: ProcessRowGroupForExportParams) => {
    const rows = [
      {
        outlineLevel: 1,
        cells: [
          this.cell(''),
          ...this.columnDefsParticipants.map(colDef => this.cell(colDef.headerName, 'header'))
        ]
      },
    ]
    .concat(
      ...this.getBookingParticipantCombined(params.node.data).map((participant) => [
        {
          outlineLevel: 1,
          cells: [
            this.cell('PARTICIPANT'),
            ...this.columnDefsParticipants.map(colDef => {
              if (colDef.type === 'dateColumn') { // @TODO improve this
                return this.cell(formatDate(participant[colDef.field]), 'body')
              } else {
                return this.cell(participant[colDef.field], 'body')
              }
            })
          ],
        },
      ])
    );
    return rows;
  };

  proceddCellCallback = (params: ProcessCellForExportParams) => {
    const colDef: ColDef = params.column.getColDef();
    if (colDef.valueFormatter) {
      if (typeof colDef.valueFormatter === 'string') {
        return params.value;
      } else {
        const p: ValueFormatterParams = { ...params, node: params.node, colDef, data: params.node.data };
        return colDef.valueFormatter(p);
      }
    }

    return params.value;
  }

  cell(text: string, styleId?: string): ExcelCell {
    return {
      styleId: styleId,
      data: {
        type: /^\d+$/.test(text) ? 'Number' : 'String',
        value: String(text),
      },
    };
  }

  getBookingParticipantCombined = (order: AdminBooking): BookingParticipantCombined[] => {
    const list: BookingParticipantCombined[] = [];
    order.lines?.forEach(orderLine => {
      list.push(...(orderLine.participants || []).map(participant => ({
        customerNumber: (order.customer as Customer)?.customerNumber,
        show: `${(orderLine.productVariant.recording as Recording).format.title} ${formatDate((orderLine.productVariant.recording as Recording).productionStart)}`,
        productName: orderLine.productVariant.name.substring(0, orderLine.productVariant.name.length - 25), // WORKAROUND cut off last part
        salutation: salutationTextMap[participant?.salutation] || '', 
        firstName: participant?.firstName,
        lastName: participant?.lastName,
        birthDate: participant?.birthDate ? new Date(participant?.birthDate) : null,
        ref: {
          order,
          orderLine,
          participant
        },
      })))
    })
    return list;
  }
}
