<div *ngIf="!params.node.group">
  <button
    mat-icon-button
    color="primary"
    aria-label="Format Detailansicht anzeigen"
    matTooltip="Format Detailansicht anzeigen"
    matTooltipShowDelay="500"
    [routerLink]="linkDetails"
    (click)="onDetails($event)"
  >
    <mat-icon>arrow_circle_right</mat-icon>
  </button>
  <button
    *ngIf="'create' | able: 'Recording'"
    class="u-ml-8"
    mat-icon-button
    color="accent"
    aria-label="Neue Aufzeichnung anlegen"
    matTooltip="Neue Aufzeichnung anlegen"
    matTooltipShowDelay="500"
    [routerLink]="linkCreateRecording"
    [queryParams]="queryParamsCreateRecording"
    (click)="onCreateRecording($event)"
  >
    <mat-icon>note_add</mat-icon>
  </button>
</div>
