import { Injectable } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { VId } from 'src/app/core/model';
import { BookingItemForm } from '../booking-form/booking-form.model';

export interface FormBookingItem
  extends Omit<BookingItemForm, 'productVariant'> {
  productVariantId: VId;
}

@Injectable()
export class BookingItemFormService {
  form: FormGroup = this.createForm();

  set bookingItem(bookingItem: BookingItemForm) {
    if (!bookingItem) {
      return;
    }
    this.form.patchValue(bookingItem);
  }

  get bookingItem(): BookingItemForm {
    return this.form.value;
  }

  constructor(private fb: FormBuilder) {}

  private createForm(): FormGroup {
    return this.fb.group({
      productVariant: [''], // hidden
      amount: ['', [Validators.required]],
    });
    // @TODO check required and validators
  }
}
