import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../core/material.module';
import { ListModule } from '../core/list/list.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { BookingFormComponent } from './components/booking-form/booking-form.component';
import { BookingCreateSelectRecordingFormComponent } from './components/booking-create-select-recording-form/booking-create-select-recording-form.component';
import * as fromBooking from './store/booking.reducer';
import { BookingEffects } from './store/booking.effects';
import { BookingParticipantFormComponent } from './components/booking-participant-form/booking-participant-form.component';
import { BookingAddressPaymentFormComponent } from './components/booking-address-payment-form/booking-address-payment-form.component';
import { BookingAddressPaymentOverviewComponent } from './components/booking-address-payment-overview/booking-address-payment-overview.component';
import { BookingCreateSelectRecordingOverviewComponent } from './components/booking-create-select-recording-overview/booking-create-select-recording-overview.component';
import { BookingItemParticipantOverviewComponent } from './components/booking-item-participant-overview/booking-item-participant-overview.component';
import { BookingNotesFormComponent } from './components/booking-notes-form/booking-notes-form.component';
import { BookingNotesOverviewComponent } from './components/booking-notes-overview/booking-notes-overview.component';
import { BookingSuccessComponent } from './components/booking-success/booking-success.component';
import { FormatsModule } from '../formats/formats.module';
import { BookingsListComponent } from './components/bookings-list/bookings-list.component';
import { BookingsListActionsCellRendererComponent } from './components/bookings-list/cell-renderers/bookings-list-actions-cell-renderer.component';
import { RouterModule } from '@angular/router';
import { BookingsDetailCellRendererComponent } from './components/bookings-list/detail-cell-renderer/bookings-detail-cell-renderer.component';
import { BookingItemFormComponent } from './components/booking-item-form/booking-item-form.component';
import { CaslModule } from '../core/casl/casl.module';
import { BookingDraftCouponComponent } from './components/booking-draft-coupon/booking-draft-coupon.component';
import { pricePipe } from './price.pipe';

@NgModule({
  declarations: [
    BookingFormComponent,
    BookingCreateSelectRecordingFormComponent,
    BookingItemFormComponent,
    BookingParticipantFormComponent,
    BookingAddressPaymentFormComponent,
    BookingAddressPaymentOverviewComponent,
    BookingCreateSelectRecordingOverviewComponent,
    BookingItemParticipantOverviewComponent,
    BookingNotesFormComponent,
    BookingNotesOverviewComponent,
    BookingSuccessComponent,
    BookingsListComponent,
    BookingsListActionsCellRendererComponent,
    BookingsDetailCellRendererComponent,
    BookingDraftCouponComponent,
    pricePipe
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    StoreModule.forFeature(fromBooking.bookingFeatureKey, fromBooking.reducers),
    EffectsModule.forFeature(BookingEffects),
    TranslateModule.forChild({
      extend: true,
    }),
    MaterialModule,
    CaslModule,
    ListModule,
    FormatsModule,
    RouterModule,
  ],
  exports: [
    BookingFormComponent,
    BookingSuccessComponent,
    BookingsListComponent,
    BookingDraftCouponComponent,
  ],
})
export class BookingsModule {}
