import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ROUTE_QUERY_PARAM_LIST_CONFIG_ID } from 'src/app/core/route-params';
import { ListConfig } from '../../list-configs.model';
import { ListConfigsCreateDialogFormService } from './list-config-create-dialog-form.service';

export interface ListConfigsCreateDialogData {
  // IN:
  listConfig: ListConfig;
  // OUT:
}

@Component({
  selector: 'app-list-config-create-dialog-form',
  templateUrl: './list-config-create-dialog-form.component.html',
  styleUrls: ['./list-config-create-dialog-form.component.scss'],
  providers: [ListConfigsCreateDialogFormService],
})
export class ListConfigCreateDialogFormComponent implements OnInit {
  form: FormGroup = this.formService.form;
  updateListDisabled = false;

  @Output() updateSubmit: EventEmitter<null> = new EventEmitter();

  constructor(
    private activatedRoute: ActivatedRoute,
    public formService: ListConfigsCreateDialogFormService,
    public listSubmitDialog: MatDialogRef<ListConfigCreateDialogFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ListConfigsCreateDialogData
  ) {}

  onNoClick(): void {
    this.listSubmitDialog.close();
  }

  // onUpdateSubmit() {
  //   this.updateSubmit.emit()
  // }

  ngOnInit(): void {
    // this.activatedRoute.snapshot.queryParams[ROUTE_QUERY_PARAM_LIST_CONFIG_ID]
    this.updateListDisabled =
      !this.activatedRoute.snapshot.queryParams[
        ROUTE_QUERY_PARAM_LIST_CONFIG_ID
      ];
  }
}
