<div class="customerSearchForm__container">
  <!-- <mat-card> -->
  <form class="form__container" [formGroup]="form">
    <div>
      <mat-label for="firstName"> Vorname: </mat-label>
      <mat-form-field class="customerSearchForm__fields" appearance="outline">
        <input
          matInput
          type="text"
          placeholder="Geben Sie bitte einen Vornamen ein"
          id="firstName"
          formControlName="firstName"
        />
      </mat-form-field>
    </div>

    <div>
      <mat-label for="lastName"> Nachname: </mat-label>
      <mat-form-field class="customerSearchForm__fields" appearance="outline">
        <input
          class="customerSearchFormInput_fields"
          matInput
          type="text"
          placeholder="Geben Sie bitte einen Nachnamen ein"
          id="lastName"
          formControlName="lastName"
        />
      </mat-form-field>
    </div>
    <div>
      <mat-label for="address"> Adresse: </mat-label>
      <mat-form-field class="customerSearchForm__fields" appearance="outline">
        <input
          class="customerSearchFormInput__fields"
          matInput
          type="text"
          placeholder="Bitte geben Sie bitte die Adresse ein"
          id="address"
          formControlName="address"
        />
      </mat-form-field>
    </div>
  </form>
</div>
