import { Link } from '../../../../core/model';
import { ROUTE_PARAM_CUSTOMER_ID } from '../../../../core/route-params';
import { ActivatedRoute } from '@angular/router';
import { customerBookingsCreateLink } from '../../../customers-routing-helper';
import { Component, OnInit } from '@angular/core';
import { BookingsFacadeService } from 'src/app/bookings/bookings-facade.service';

@Component({
  selector: 'app-customer-bookings-tab',
  templateUrl: './customer-bookings-tab.component.html',
  styleUrls: ['./customer-bookings-tab.component.scss'],
})
export class CustomerBookingsTabComponent implements OnInit {
  customerBookingsCreateLink: Link;

  constructor(
    private route: ActivatedRoute,
    public bookingsFacade: BookingsFacadeService
  ) {}

  ngOnInit(): void {
    const id = this.route.snapshot.parent.params[ROUTE_PARAM_CUSTOMER_ID]; // @TODO check if updated when id changes
    this.customerBookingsCreateLink = customerBookingsCreateLink(id);
  }
}
