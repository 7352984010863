import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

// @TODO review/refactor whole module

@Component({
  selector: 'app-searchbar',
  templateUrl: './searchbar.component.html',
  styleUrls: ['./searchbar.component.scss'],
  // host: {
  //   "(window:click)": "onDropdownClicked()"
  // }
})
export class SearchbarComponent implements OnInit {
  searchBarValue: string = '  Search for Customer';
  searchControl = new FormControl();

  options: string[] = [
    'Customer',
    'Addresses',
    'Recordings',
    'Formats',
    'Users',
    'Shop',
  ];
  filteredOptions: Observable<string[]>;

  dropDownSelected = false;

  constructor() {}

  ngOnInit(): void {
    this.filteredOptions = this.searchControl.valueChanges.pipe(
      startWith(''),
      map((value) => this.filterOptions(value))
    );
  }

  private filterOptions(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }

  onDropdownClicked() {
    this.dropDownSelected = !this.dropDownSelected;
  }

  // if decided to close the menue by clicking outside of it activate the host & use:
  // toggleMenu($event:any) {
  //   $event.stopPropagation();
  //   this.dropDownSelected = !this.dropDownSelected;
  // }
  // onDropdownClicked() {
  //   this.dropDownSelected = false;
  // }
  // and (click)="toggleMenu($event)" inside the html
}
