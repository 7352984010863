import { Store } from '@ngrx/store';
import { BaseResolver } from './../core/base/base.resolver';
import { Actions } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import * as RecordingsActions from './store/recording.actions';

@Injectable({
  providedIn: 'root',
})
export class RecordingsResolver extends BaseResolver {
  requestAction = RecordingsActions.entities.loadRecordings;
  successAction = RecordingsActions.entities.loadRecordingsSuccess;
  failureAction = RecordingsActions.entities.loadRecordingsFailure;
  constructor(protected store: Store, protected actions$: Actions) {
    super();
  }
}
