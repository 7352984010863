import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';
import { AppFacadeService } from 'src/app/app-facade.service';
import { LOADER_ID_ACTION } from 'src/app/app.component';
import { User } from 'src/app/core/graphql.model';
import { UsersFacadeService } from '../../users-facade.service';

@Component({
  selector: 'app-user-page',
  templateUrl: './user-page.component.html',
  styleUrls: ['./user-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserPageComponent {
  actionLoaderActive$: Observable<boolean>;

  constructor(
    public facade: UsersFacadeService,
    public appFacade: AppFacadeService
  ) {
    this.actionLoaderActive$ = this.appFacade.isLoaderActive$(LOADER_ID_ACTION);
  }

  onProfilSubmit(user: Partial<User>): void {
    this.facade.updateUser(user);
  }
}
