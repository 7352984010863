<span class="u-flex u-ai-center">
  <button
    *ngIf="canEdit"
    mat-icon-button
    aria-label="Titel editieren"
    matTooltip="Titel editieren"
    matTooltipShowDelay="500"
    (click)="onButtonClicked()"
  >
    <i class="material-icons">edit</i>
  </button>
  <span class="u-pl-4">
    {{ params.value }}
  </span>
</span>
