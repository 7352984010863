<!-- -->
<form [formGroup]="form">
  <div class="dialogContainer u-pb-32">
    <div class="u-flex u-ai-center u-ph-32 u-pt-32 u-pb-32" *ngIf="!updateListDisabled">
      <p class="u-weight-bold">Vorhandene Vorlage Aktualisieren?</p>
      <button
        class="u-ml-32"
        mat-flat-button
        color="accent"
        type="submit"
        [mat-dialog-close]="{ update: true }"
        [disabled]="updateListDisabled"
      >
        aktualisieren
      </button>
    </div>
    <hr *ngIf="!updateListDisabled" />
    <div
      class="u-flex u-ph-32 u-pt-32"
      style="--mdc-shape-small: 0; --mdc-filled-button-container-shape: 0 4px 4px 0;"
    >
      <mat-form-field class="u-fg-1">
        <mat-label>Listenname eingeben</mat-label>
        <input matInput formControlName="title" />
      </mat-form-field>

      <button
        style="height: 56px"
        mat-flat-button
        color="primary"
        type="submit"
        [mat-dialog-close]="{ update: false, title: formService.listConfig }"
        cdkFocusInitial
      >
        speichern
      </button>
    </div>
    <div class="u-flex u-jc-end u-ph-32 u-pt-32">
      <button
        mat-flat-button
        type="button"
        (click)="onNoClick()"
      >
        abbrechen
      </button>
    </div>
  </div>
</form>
