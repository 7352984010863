import { Action, ActionReducer, combineReducers } from '@ngrx/store';
import * as fromUserAuth from './auth/user-auth.reducer';
import * as fromUserEntities from './entities/user-entities.reducer';
import * as fromUserLists from './lists/user-lists.reducer';

export const userFeatureKey = 'user';

export interface State {
  [fromUserAuth.userAuthFeatureKey]: fromUserAuth.State;
  [fromUserEntities.userEntitiesFeatureKey]: fromUserEntities.State;
  [fromUserLists.userListsFeatureKey]: fromUserLists.State;
}

export function reducers(
  state: State | undefined,
  action: Action
): ActionReducer<State, any> {
  // @ts-ignore
  return combineReducers({
    [fromUserAuth.userAuthFeatureKey]: fromUserAuth.reducer,
    [fromUserEntities.userEntitiesFeatureKey]: fromUserEntities.reducer,
    [fromUserLists.userListsFeatureKey]: fromUserLists.reducer,
  })(state, action);
}
