import { CustomersFacadeService } from './../../../customers-facade.service';
import { Customer } from 'src/app/core/graphql.model';
import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { AppFacadeService } from 'src/app/app-facade.service';
import { LOADER_ID_ACTION } from 'src/app/app.component';
import { TagsFacadeService } from 'src/app/tags/tags-facade.service';

@Component({
  selector: 'app-customer-profil-tab',
  templateUrl: './customer-profil-tab.component.html',
  styleUrls: ['./customer-profil-tab.component.scss'],
})
export class CustomerProfilTabComponent {
  actionLoaderActive$: Observable<boolean>;

  constructor(
    public facade: CustomersFacadeService,
    public tagsFacade: TagsFacadeService,
    public appFacade: AppFacadeService
  ) {
    this.actionLoaderActive$ = this.appFacade.isLoaderActive$(LOADER_ID_ACTION);
  }

  onSubmit(customer: Customer): void {
    this.facade.updateCustomer(customer);
  }
}
