import { Component, ChangeDetectionStrategy } from '@angular/core';
import { FormatsFacadeService } from 'src/app/formats/formats-facade.service';
import { RecordingsFacadeService } from '../../recordings-facade.service';
import { AppFacadeService } from 'src/app/app-facade.service';
import { Observable } from 'rxjs';
import { LOADER_ID_ACTION } from 'src/app/app.component';
import { Recording } from 'src/app/core/graphql.model';

@Component({
  selector: 'app-recording-create-by-format-page',
  templateUrl: './recording-create-by-format-page.component.html',
  styleUrls: ['./recording-create-by-format-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecordingCreateByFormatPageComponent {
  actionLoaderActive$: Observable<boolean>;

  constructor(
    public recordingsFacade: RecordingsFacadeService,
    public formatsFacade: FormatsFacadeService,
    public appFacade: AppFacadeService
  ) {
    this.actionLoaderActive$ = this.appFacade.isLoaderActive$(LOADER_ID_ACTION);
  }

  onSubmit(recording: Recording): void {
    this.recordingsFacade.createRecording(recording);
  }
}
