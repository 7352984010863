
<div
  class="u-bgcolor-secondary-90 u-ph-14 u-pv-8 u-flex u-ai-center"
  *ngIf="groupableByFormat === true"
>
  <mat-checkbox
    [checked]="(facade.listOverview$ | async).grouped"
    (change)="facade.overviewListToggleGrouped()"
    class="mat-mdc-checkbox--white"
  >
    Gruppiert nach Formaten
  </mat-checkbox>
</div>

<!-- @TODO no store functions here -->
<!-- not toggle but set -->

<!-- @TODO explain special functions -->
<ag-grid-angular
  style="width: 100%; height: 100%"
  class="ag-theme-material"
  [components]="components"
  [columnTypes]="columnTypes"
  [defaultColDef]="defaultColDef"
  [columnDefs]="columnDefs"
  [sideBar]="sideBar"
  [statusBar]="statusBar"
  [rowData]="data"
  [showOpenedGroup]="true"
  [groupIncludeFooter]="true"
  [autoGroupColumnDef]="autoGroupColumnDef"
  [isFullWidthCell]="isFullWidthCell"
  fullWidthCellRenderer="fullWidthCellRenderer"
  (firstDataRendered)="onFirstDataRendered($event)"
></ag-grid-angular>
