<div class="c-user-header u-bgcolor-primary-purple-80 u-color-white u-pv-12">
  <div class="o-container">
    <div class="u-flex u-jc-between">
      <div class="u-flex u-ai-center">
        <div class="userHeader__avatar">
          <img class="user__profilePic-size" src="../../assets/dummyPic.jpg" alt="" />
        </div>
        <div class="userHeaderuserSpecifics">
          <p>{{ user?.firstName }} {{ user?.lastName }}</p>
          <p>{{ user?.email }}</p>
        </div>
      </div>
      <div>
<!--          <p class="u-mb-12">spaceholder: Angemeldet seit: 20.01.2022</p>-->
          <app-loader-wrapper [active]="actionLoaderActive" style="height: 100%">
            <mat-slide-toggle *ngIf="'update' | able: 'User'" [checked]="user?.active" (change)="onToggleActive($event)"> <span class="u-color-white">aktiv</span> </mat-slide-toggle>
          </app-loader-wrapper>
      </div>
    </div>
    <div class="u-mt-12">
      <mat-chip-set>
        <mat-chip disableRipple>
          {{ user?.roles }}
        </mat-chip>
      </mat-chip-set>
    </div>
  </div>
</div>
