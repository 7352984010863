import { Store } from '@ngrx/store';
import { BaseResolver } from '../core/base/base.resolver';
import { Actions } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import * as TagsActions from './store/tag.actions';

@Injectable({
  providedIn: 'root',
})
export class TagsResolver extends BaseResolver {
  requestAction = TagsActions.entities.loadTags;
  successAction = TagsActions.entities.loadTagsSuccess;
  failureAction = TagsActions.entities.loadTagsFailure;
  constructor(protected store: Store, protected actions$: Actions) {
    super();
  }
}
