import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';
import { of } from 'rxjs';
import * as UserAuthActions from './user-auth.actions';
import { UsersService } from '../../users.service';
import { User } from 'src/app/core/graphql.model';
import { Store } from '@ngrx/store';
import { AppFacadeService } from 'src/app/app-facade.service';
import { LOADER_ID_APP } from 'src/app/app.component';
import { CaslAbilityFactory } from 'src/app/core/casl/casl-ability.factory';
import { Ability } from '@casl/ability';
import { handleMutationError } from 'src/app/core/graphql.helpers';
import { NotifyService } from 'src/app/core/notify/notify.service';
import { loginLink, logoutLink, homeLink } from 'src/app/app-routing.module';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationResult } from 'src/app/core/model';

@Injectable()
export class UserAuthEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private service: UsersService,
    private appFacade: AppFacadeService,
    private router: Router,
    private ability: Ability,
    private abilityFactory: CaslAbilityFactory,
    private notifyService: NotifyService,
    private authService: MsalService,
    private userService: UsersService,
  ) {}

  // init$ = createEffect(
  //   () =>
  //     this.actions$.pipe(
  //       startWith({ type: 'INIT_TRIGGER' }),
  //       first(),
  //       delay(100), // see https://github.com/ngrx/platform/issues/683#issuecomment-517888082
  //     ),
  // );

  login$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserAuthActions.login),
        switchMap(() => {
          this.appFacade.loaderStart(LOADER_ID_APP);
          return of(UserAuthActions.loginToken());
        })
      )
  );

  loginToken$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserAuthActions.loginToken),
      switchMap(() => {
        return this.authService.loginPopup().pipe(
          map((data: AuthenticationResult) => {
            if (this.authService.instance.getAllAccounts().length > 0) {
              return UserAuthActions.loginTokenSuccess({ data: {
                ...data,
                expiresOn: data.expiresOn.toISOString(),
                extExpiresOn: data.extExpiresOn.toISOString(),
              } })
            } else {
              return UserAuthActions.loginTokenFailure({ error: {} });
            }
          }),
          catchError((error) => {
            console.log(error);
            return of(UserAuthActions.loginTokenFailure({ error }));
          })
        );
      })
    );
  });

  loginTokenSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserAuthActions.loginTokenSuccess),
        switchMap(() => {
          return of(UserAuthActions.getUser({isLogin: true}));
        })
      )
  );

  getUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserAuthActions.getUser),
      switchMap((action) => {
        return this.userService.loadMe().pipe(
          switchMap((data: User) => of(UserAuthActions.getUserSuccess({ data, isLogin: action.isLogin }))),
          catchError((error) => {
            return of(UserAuthActions.getUserFailure({ error: error.message }));
          })
        )
      })
    );
  });

  getUserSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserAuthActions.getUserSuccess),
        switchMap((action) => {
          if (action.isLogin) {
            return of(UserAuthActions.loginSuccess({user: action.data}));
          }
          const ability = this.abilityFactory.createFor(action.data);
          return of(UserAuthActions.updateAbilities({
            rules: ability.rules,
          }))
        })
      )
  );

  loginSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserAuthActions.loginSuccess),
        map((action) => action.user),
        switchMap((user) => {
          const ability = this.abilityFactory.createFor(user);
          return of(UserAuthActions.updateAbilities({
            rules: ability.rules,
            redirect: homeLink(),
          }))
        })
      )
  );

  loginRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserAuthActions.loginRedirect),
        tap(() => this.router.navigate(logoutLink()))
      ),
    { dispatch: false }
  );

  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserAuthActions.logout),
        switchMap((actions) => {
          if (!actions.soft) {
            this.authService.logoutPopup();
          }
          return of(UserAuthActions.updateAbilities({
            rules: [],
            redirect: loginLink(),
          }))
        })
      )
  );

  updateAbilities$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserAuthActions.updateAbilities),
        switchMap((action) => {
          this.ability.update(action.rules);
          return of(UserAuthActions.updateAbilitiesSuccess({
            rules: action.rules,
            redirect: action.redirect,
          }))
        })
      )
  );

  updateAbilitiesSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserAuthActions.updateAbilitiesSuccess),
        tap((action) => {
          if (action.redirect) {
            this.router.navigate(action.redirect);
          }
        })
      ),
    { dispatch: false }
  );

  // FAILURES

  mutationFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          UserAuthActions.loginTokenFailure,
          UserAuthActions.getUserFailure,
        ),
        map((action) => action.error),
        tap((error) => {
          handleMutationError(error, this.notifyService, this.store);
          this.appFacade.loaderStop(LOADER_ID_APP);
        })
      ),
    { dispatch: false }
  );
}
