import {
  Component,
  Input,
  Output,
  EventEmitter,
  forwardRef,
} from '@angular/core';
import { FormatsFacadeService } from 'src/app/formats/formats-facade.service';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  Validator,
} from '@angular/forms';
import { RecordingsFacadeService } from 'src/app/recordings/recordings-facade.service';
import { BookingCreateSelectRecordingFormService } from './booking-create-select-recording-form.service';
import { Recording } from 'src/app/core/graphql.model';
import { actions } from 'src/app/core/tmp';
import { BookingCreateSelectRecordingForm } from '../booking-form/booking-form.model';
import { BaseSubFormComponent } from 'src/app/core/base/base-sub-form.component';
import { Id } from 'src/app/core/model';

@Component({
  selector: 'app-booking-create-select-recording-form',
  templateUrl: './booking-create-select-recording-form.component.html',
  styleUrls: ['./booking-create-select-recording-form.component.scss'],
  providers: [
    BookingCreateSelectRecordingFormService,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BookingCreateSelectRecordingFormComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => BookingCreateSelectRecordingFormComponent),
      multi: true,
    },
  ],
})
export class BookingCreateSelectRecordingFormComponent
  extends BaseSubFormComponent
  implements ControlValueAccessor, Validator
{
  _createSelectRecording: BookingCreateSelectRecordingForm;

  @Input() set submitted(submitted: boolean) {
    if (submitted === true) {
      Object.values(this.form.controls).forEach((control) =>
        control.markAsTouched()
      );
    }
  }

  @Output() recordingChange: EventEmitter<Recording> = new EventEmitter();

  selectedFormatId: Id;

  actions = actions;

  constructor(
    private formService: BookingCreateSelectRecordingFormService,
    public formatsFacade: FormatsFacadeService,
    public recordingsFacade: RecordingsFacadeService
  ) {
    super();
    this.form = this.formService.form;
  }

  writeValue(val: any): void {
    if (val) {
      this.formService.createSelectRecording = val;
      this._createSelectRecording = val;
      this.form.setValue(this.formService.form.value, { emitEvent: false });
    }
  }

  onFormatChange(): void {
    this.selectedFormatId = this.form.get('format').value.id;
    this.recordingsFacade.loadRecordingByFilter({
      format: {
        is: {
          id: {
            equals: this.selectedFormatId,
          },
        },
      },
    });
  }

  onDateChange(): void {
    this.recordingsFacade.loadRecordingByFilter({
      productionStart: {
        equals: this.form.get('date').value.toISOString(),
      },
    });
  }

  onRecordingChanged(event: any): void {
    this.recordingChange.emit(event.value);
  }
}
