import {
  initialGrouped,
  RecordingColumnIds,
} from './recording-list-column-defs.service';
import { ColDef } from 'ag-grid-community';
import { createReducer, on } from '@ngrx/store';
import * as RecordingListsActions from './recording-lists.actions';

export const recordingListsFeatureKey = 'lists';

export interface State {
  overview: {
    columnDefs: ColDef[];
    grouped: boolean;
  };
  clone: {
    columnDefs: ColDef[];
  };
  format: {
    columnDefs: ColDef[];
  };
}

export const initialState: State = {
  overview: {
    columnDefs: [],
    grouped: initialGrouped,
  },
  clone: {
    columnDefs: [],
  },
  format: {
    columnDefs: [],
  },
};

export const reducer = createReducer(
  initialState,

  on(RecordingListsActions.init, (state, action) => ({
    ...state,
    overview: {
      ...state.overview,
      columnDefs: action.columnDefsOverview,
    },
    clone: {
      ...state.clone,
      columnDefs: action.columnDefsClone,
    },
    format: {
      ...state.format,
      columnDefs: action.columnDefsFormat,
    },
  })),

  on(RecordingListsActions.overviewListToggleGrouped, (state, action) => {
    return {
      ...state,
      overview: {
        ...state.overview,
        grouped: !state.overview.grouped,
        columnDefs: state.overview.columnDefs.map((columnDef: ColDef) => {
          if (columnDef.colId === RecordingColumnIds.FORMAT) {
            return {
              ...columnDef,
              rowGroup: !state.overview.grouped,
              hide: !state.overview.grouped,
            };
          } else {
            return columnDef;
          }
        }),
      },
    };
  })
);
