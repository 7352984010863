import { Store } from '@ngrx/store';
import { BaseResolver } from '../core/base/base.resolver';
import { Actions } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import * as CustomersActions from './store/customer.actions';

@Injectable({
  providedIn: 'root',
})
export class CurrentCustomerResolver extends BaseResolver {
  requestAction = CustomersActions.entities.loadCurrentCustomer;
  successAction = CustomersActions.entities.loadCustomerSuccess;
  failureAction = CustomersActions.entities.loadCustomerFailure;
  constructor(protected store: Store, protected actions$: Actions) {
    super();
  }
}
