<!--<h1>{{ "RECORDINGS.RECORDING_PAGE.TITLE" | translate }}</h1>-->

<app-recording-header [recording]="facade.currentRecording$ | async">
</app-recording-header>

<app-loader-wrapper [active]="tabLoaderActive$ | async">
  <div class="o-container u-height-1/1 u-flex u-fd-col u-pt-40 u-pb-40">
    <mat-tab-group
      animationDuration="0ms"
      [mat-stretch-tabs]="false"
      [selectedIndex]="tabIndex"
      (selectedTabChange)="onSelectedTabChange($event)"
    >
      <mat-tab label="Aufzeichnungsdaten"> </mat-tab>
      <mat-tab label="Buchungen"> </mat-tab>
      <mat-tab label="Format Warteliste & Ticketalarm"></mat-tab>
    </mat-tab-group>

    <router-outlet></router-outlet>
  </div>
</app-loader-wrapper>
