import { updateNestedFormElements } from './../../../core/form.helpers';
import { FormControl, FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Injectable } from '@angular/core';
import {
  BookingForm,
  BookingItemForm,
  BookingParticipantForm,
} from './booking-form.model';

@Injectable()
export class BookingFormService {
  form: FormGroup = this.createForm();

  constructor(private fb: FormBuilder) {}

  set booking(booking: BookingForm) {
    if (!booking) {
      return;
    }
    updateNestedFormElements(this.form, 'items', booking.items.length);
    updateNestedFormElements(this.form, 'participants', booking.items.length);
    // update form values @TODO
    // this.form.patchValue(booking);
  }

  get booking(): BookingForm {
    return this.form.value;
  }

  private createForm(): FormGroup {
    return this.fb.group({
      id: [''], // hidden
      createSelectRecording: [''],
      items: this.fb.array([]),
      participants: this.fb.array([]),
      addressPayment: [''],
      notes: [''],
    });
    // @TODO check required and validators
  }

  public addBookingItem(bookingItem: BookingItemForm): void {
    const productItemFormArray = this.form.get('items') as FormArray;
    productItemFormArray.push(new FormControl(bookingItem));
  }

  public addBookingParticipant(
    bookingParticipant: BookingParticipantForm
  ): void {
    const BookingParticipantFormArray = this.form.get(
      'participants'
    ) as FormArray;
    BookingParticipantFormArray.push(new FormControl(bookingParticipant));
  }
}
