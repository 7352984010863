import { TagsPageComponent } from './pages/tags-page/tags-page.component';
import { NotFoundPageComponent } from '../core/errors/not-found-page/not-found-page.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TagsResolver } from './tags.resolver';
import { RoutesNames } from './tags-routing-helper';

const routes: Routes = [
  // permission is checked on parent page already
  {
    // ADMIN
    path: RoutesNames.ADMIN,
    component: TagsPageComponent,
    pathMatch: 'full',
    resolve: { ready: TagsResolver },
  },
  {
    path: '**',
    component: NotFoundPageComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TagsRoutingModule {}
