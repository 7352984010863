import {
  NotificationType,
  NotifyService,
} from '../../../core/notify/notify.service';
import { FormatsService } from '../../formats.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { of } from 'rxjs';
import * as FormatEntitiesActions from './format-entities.actions';
import * as FormatSelectors from '../format.selectors';
import * as fromFormat from '../format.reducer';
import { Store } from '@ngrx/store';
import { Format } from 'src/app/core/graphql.model';
import { loaderStart, loaderStop } from 'src/app/core/store/app.actions';
import { LOADER_ID_ACTION } from 'src/app/app.component';
import {
  handleMutationError,
  handleQueryError,
} from 'src/app/core/graphql.helpers';
import { Router } from '@angular/router';
import { formatsListLink } from '../../formats-routing-helper';

@Injectable()
export class FormatEntitiesEffects {
  constructor(
    private actions$: Actions,
    private store: Store<fromFormat.State>,
    private service: FormatsService,
    private notifyService: NotifyService,
    private router: Router,
  ) {}

  // CREATE

  createFormat$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FormatEntitiesActions.createFormat),
      map((action) => action.data),
      mergeMap((data) =>
        this.service.createFormat(data).pipe(
          map((format: Format) =>
            FormatEntitiesActions.createFormatSuccess({ data: format })
          ),
          catchError((error) =>
            of(
              FormatEntitiesActions.createFormatFailure({
                error: error.message,
              })
            )
          )
        )
      )
    );
  });
  createFormatSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FormatEntitiesActions.createFormatSuccess),
        tap(() =>
          this.notifyService.notify({
            type: NotificationType.Toast,
            message: 'Successfully created', // @TODO i18n
          })
        )
      ),
    { dispatch: false }
  );

  // READ

  loadFormats$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FormatEntitiesActions.loadFormats),
      switchMap(() => {
        return this.service.loadFormats().pipe(
          map((formats) =>
            FormatEntitiesActions.loadFormatsSuccess({ data: formats })
          ),
          catchError((error) => {
            return of(
              FormatEntitiesActions.loadFormatsFailure({ error: error.message })
            );
          })
        );
      })
    );
  });

  loadCurrentFormat$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FormatEntitiesActions.loadCurrentFormat),
      withLatestFrom(
        this.store.select(FormatSelectors.getEntitiesCurrentFormatId)
      ),
      switchMap(([, id]) => {
        if (!id) {
          return of(FormatEntitiesActions.loadFormatFailure({ error: {} }));
        }
        return this.service.loadFormat(id).pipe(
          map((format) =>
            FormatEntitiesActions.loadFormatSuccess({ data: format })
          ),
          catchError((error) => {
            return of(
              FormatEntitiesActions.loadFormatFailure({ error: error.message })
            );
          })
        );
      })
    );
  });

  // UPDATE

  updateFormat$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FormatEntitiesActions.updateFormat),
      map((action) => action.data),
      mergeMap((data) =>
        of(data).pipe(
          // TODO can this be improved
          withLatestFrom(
            this.store.select(FormatSelectors.getEntitiesFormat(data.id))
          ),
          switchMap(([action, oldFormat]) => {
            return this.service.updateFormat(data, oldFormat).pipe(
              map((format: Format) =>
                FormatEntitiesActions.updateFormatSuccess({ data: format })
              ),
              catchError((error) =>
                of(
                  FormatEntitiesActions.updateFormatFailure({
                    error: error.message,
                  })
                )
              )
            );
          })
        )
      )
    );
  });
  updateFormatSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FormatEntitiesActions.updateFormatSuccess),
        tap(() =>
          this.notifyService.notify({
            type: NotificationType.Toast,
            message: 'Successfully updated', // @TODO i18n
          })
        )
      ),
    { dispatch: false }
  );

  // DELETE
  
  deleteFormat$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FormatEntitiesActions.deleteFormat),
      map((action) => action.id),
      mergeMap((id) =>
        this.service.deleteFormat(id).pipe(
          map(() =>
            FormatEntitiesActions.deleteFormatSuccess({ id })
          ),
          catchError((error) =>
            of(
              FormatEntitiesActions.deleteFormatFailure({
                error: error.message,
              })
            )
          )
        )
      )
    );
  });
  deleteFormatSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FormatEntitiesActions.deleteFormatSuccess),
        tap(() => {
          this.notifyService.notify({
            type: NotificationType.Toast,
            message: 'Successfully deleted',
          });
          this.router.navigate(formatsListLink());
        })
      ),
    { dispatch: false }
  );

  // FAILURES

  queryFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          FormatEntitiesActions.loadFormatsFailure,
          FormatEntitiesActions.loadFormatFailure
        ),
        map((action) => action.error),
        tap((error) => handleQueryError(error, this.notifyService, this.store))
      ),
    { dispatch: false }
  );

  mutationFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          FormatEntitiesActions.createFormatFailure,
          FormatEntitiesActions.updateFormatFailure
        ),
        map((action) => action.error),
        tap((error) =>
          handleMutationError(error, this.notifyService, this.store)
        )
      ),
    { dispatch: false }
  );

  // LOADERS

  loaderStart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        FormatEntitiesActions.createFormat,
        FormatEntitiesActions.updateFormat
      ),
      mergeMap(() => of(loaderStart({ id: LOADER_ID_ACTION })))
    )
  );

  loaderStop$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        FormatEntitiesActions.createFormatSuccess,
        FormatEntitiesActions.createFormatFailure,
        FormatEntitiesActions.updateFormatSuccess,
        FormatEntitiesActions.updateFormatFailure
      ),
      mergeMap(() => of(loaderStop({ id: LOADER_ID_ACTION })))
    )
  );
}
