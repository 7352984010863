import { customersListLink } from '../../customers-routing-helper';
import { Link } from '../../../core/model';

export enum RouteNames {
  CUSTOMERS_OVERVIEW = '',
  LIST_CONFIG = 'listconfig',
}

export const customersListOverviewLink = (): Link =>
  customersListLink(); /*.concat(RouteNames.CUSTOMERS_OVERVIEW)*/
export const customersListConfigLink = (): Link =>
  customersListLink().concat(RouteNames.LIST_CONFIG);
