import { createReducer, on } from '@ngrx/store';
import * as ListConfigListsActions from './list-config-lists.actions';
import { ColDef } from 'ag-grid-community';

export const listConfigListsFeatureKey = 'lists';

export interface State {
  general: {
    columnDefs: ColDef[];
  };
}

export const initialState: State = {
  general: {
    columnDefs: [],
  },
};

export const reducer = createReducer(
  initialState,
  on(ListConfigListsActions.init, (state, action) => ({
    ...state,
    general: {
      ...state.general,
      columnDefs: action.columnDefs,
    },
  }))
);
