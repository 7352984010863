import {
  ROUTE_QUERY_PARAM_LIST_CONFIG_CONFIG,
  ROUTE_QUERY_PARAM_LIST_CONFIG_ID,
  ROUTE_QUERY_PARAM_LIST_CONFIG_TITLE,
} from './../../../core/route-params';
import {
  ListConfigConfig,
  getEmptyListConfigConfig,
} from '../../../core/list/list.model';
import { customersListLink } from '../../customers-routing-helper';
import { CustomerFilter } from 'src/app/core/graphql.model';
import { handleQueryError } from '../../../core/graphql.helpers';
import { NotifyService } from '../../../core/notify/notify.service';
import { CustomersService } from '../../customers.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  delay,
  first,
  map,
  mergeMap,
  startWith,
  take,
  filter,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { of } from 'rxjs';
import * as CustomerListsActions from './customer-lists.actions';
import * as CustomerSelectors from '../customer.selectors';
import * as fromCustomerLists from './customer-lists.reducer';
import { Store } from '@ngrx/store';
import { IServerSideGetRowsRequest } from 'ag-grid-community';
import { requestToFilter } from 'src/app/core/list/connection-filter-helper';
import { routerNavigatedAction } from '@ngrx/router-store';
import { MergedRoute } from 'src/app/core/store/router/merged-route';
import { Location } from '@angular/common';
import { Params, Router } from '@angular/router';
import { CustomerListColumnDefsService } from './customer-list-column-defs.service';
import { getMergedRoute } from 'src/app/core/store/router/router-state.selectors';

@Injectable()
export class CustomerListsEffects {
  constructor(
    private actions$: Actions,
    private store: Store<fromCustomerLists.State>,
    private service: CustomersService,
    private notifyService: NotifyService,
    private location: Location,
    private router: Router,
    private baseColumnDefsService: CustomerListColumnDefsService
  ) {}

  init$ = createEffect(() =>
    this.actions$.pipe(
      startWith({ type: 'INIT_TRIGGER' }),
      first(),
      delay(100), // see https://github.com/ngrx/platform/issues/683#issuecomment-517888082
      mergeMap((action) =>
        of(
          CustomerListsActions.init({
            columnDefs: this.baseColumnDefsService.columnDefs,
          })
        )
      )
    )
  );

  enter$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(routerNavigatedAction),
        map((action) => action.payload.routerState as any as MergedRoute), // @TODO fix routerState typing
        tap((routerState) => {
          const url = this.router.createUrlTree(customersListLink()).toString();
          if (
            routerState.url === url ||
            routerState.url.startsWith(`${url}?`)
          ) {
            // @TODO add exception for invalid json
            const queryParam =
              routerState.queryParams[ROUTE_QUERY_PARAM_LIST_CONFIG_CONFIG];
            const config = queryParam
              ? (JSON.parse(queryParam) as ListConfigConfig)
              : getEmptyListConfigConfig();
            this.store.dispatch(CustomerListsActions.pullConfig({ config }));
          }
        })
      ),
    { dispatch: false }
  );

  pushConfig$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CustomerListsActions.pushConfig),
        withLatestFrom(
          this.store.select(CustomerSelectors.getListOverview),
          this.store.select(getMergedRoute)
        ),
        // @ts-ignore
        map(([, overviewList, mergedRoute]) => [
          overviewList.config,
          mergedRoute,
        ]),
        tap(([config, route]: [ListConfigConfig, MergedRoute]) => {
          const queryParams: Params = {
            [ROUTE_QUERY_PARAM_LIST_CONFIG_CONFIG]: JSON.stringify(config),
          };
          if (
            route.queryParams.hasOwnProperty(ROUTE_QUERY_PARAM_LIST_CONFIG_ID)
          ) {
            queryParams[ROUTE_QUERY_PARAM_LIST_CONFIG_ID] =
              route.queryParams[ROUTE_QUERY_PARAM_LIST_CONFIG_ID];
          }
          if (
            route.queryParams.hasOwnProperty(
              ROUTE_QUERY_PARAM_LIST_CONFIG_TITLE
            )
          ) {
            queryParams[ROUTE_QUERY_PARAM_LIST_CONFIG_TITLE] =
              route.queryParams[ROUTE_QUERY_PARAM_LIST_CONFIG_TITLE];
          }
          const url = this.router
            .createUrlTree(customersListLink(), {
              queryParams,
            })
            .toString();
          this.router.navigateByUrl(url);
        })
      ),
    { dispatch: false }
  );

  loadCustomerConnection$ = createEffect(
    // see list.model.ts for more infos on loading connections in ssrm
    () => {
      return this.actions$.pipe(
        ofType(CustomerListsActions.loadCustomerConnection),
        // @ts-ignore
        map((action) => [action.request, action.uuid]),
        tap(([request, uuid]: [IServerSideGetRowsRequest, string]) => {
          // wait for the previous block to finish
          this.store
            .select(CustomerSelectors.getListOverview)
            .pipe(
              filter((listOverview) => {
                if (request.startRow === 0) {
                  return true;
                }
                const blocks = listOverview.ssrm.blocks;
                const blockSize = request.endRow - request.startRow;
                const blockKey = request.startRow - blockSize;
                return Object.keys(blocks).includes(blockKey.toString());
              }),
              take(1)
            )
            .subscribe((listOverview) => {
              const blocks = listOverview.ssrm.blocks;
              const blockSize = request.endRow - request.startRow;

              let last = '';
              if (request.startRow !== 0) {
                const blockKey = request.startRow - blockSize;
                if (Object.keys(blocks).includes(blockKey.toString())) {
                  last = blocks[blockKey].endCursor;
                } else {
                  this.store.dispatch(
                    CustomerListsActions.loadCustomerConnectionFailure({
                      uuid,
                      request,
                      error: 'Block Key not found',
                    })
                  );
                }
              }

              const filter2: CustomerFilter = requestToFilter(
                request,
                this.baseColumnDefsService.customFilterMappers
              );

              this.service
                .loadCustomerConnection(filter2, blockSize, last)
                .subscribe(
                  (customerConnection) => {
                    this.store.dispatch(
                      CustomerListsActions.loadCustomerConnectionSuccess({
                        uuid,
                        request,
                        result: customerConnection,
                      })
                    );
                  },
                  (error) => {
                    this.store.dispatch(
                      CustomerListsActions.loadCustomerConnectionFailure({
                        uuid,
                        request,
                        error: error.message,
                      })
                    );
                  }
                );
            });
        })
      );
    },
    { dispatch: false }
  );

  // FAILURES

  queryFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CustomerListsActions.loadCustomerConnectionFailure),
        map((action) => action.error),
        tap((error) => handleQueryError(error, this.notifyService, this.store))
      ),
    { dispatch: false }
  );
}
