import { Component } from '@angular/core';
import { Item } from '../../navigation-bar.model';
import navigationItems from './navigation-items.json';
import { CaslService } from '../../../../core/casl/casl.service';
import { UsersFacadeService } from 'src/app/users/users-facade.service';
import { tap, take, filter } from 'rxjs';

// @TODO icon hover via css
// @TODO change icon into svg's / are jpgs now
// @TODO move config to separate file

// @TODO LINK

@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.scss'],
})
export class NavigationBarComponent {
  navItemsArr: Item[];

  constructor(
    private userFacade: UsersFacadeService,
    private caslService: CaslService
  ) {
    this.userFacade.rules$
      .pipe(
        filter((rules) => rules != undefined),
        tap(() => {
          this.updateNavigation();
        })
      )
      .subscribe(); // @TODO unsubscribe
  }

  updateNavigation(): void {
    this.navItemsArr = (navigationItems as Item[]).filter((item: Item) =>
      this.caslService.can(item.auth)
    );
  }
}
