import { FilterModel } from './../../../core/list/list.model';
import { ListService } from 'src/app/core/list/list.service';
import {
  columnTypes,
  defaultColDef,
  sideBar,
  statusBar,
} from '../../../core/list/list.definitions';
import {
  ColDef,
  FilterChangedEvent,
  GridReadyEvent,
  SortChangedEvent,
  ColumnState,
  FirstDataRenderedEvent,
} from 'ag-grid-community';
import { Component, Input, EventEmitter, Output } from '@angular/core';
import { FormatActionsCellRendererComponent } from './cell-renderers/format-actions-cell-renderer.component';
import { Format } from 'src/app/core/graphql.model';

@Component({
  selector: 'app-formats-list',
  templateUrl: './formats-list.component.html',
  styleUrls: ['./formats-list.component.scss'],
  providers: [ListService],
})
export class FormatsListComponent {
  @Input() data: Format[] = [];
  @Input() columnDefs: ColDef[];
  @Input() set filter(filter: FilterModel) {
    this.service.setFilter(filter);
  }
  @Input() set columnsState(columnsState: ColumnState[]) {
    this.service.setColumnsState(columnsState);
  }

  @Output() filterChanged: EventEmitter<FilterModel> = new EventEmitter();
  @Output() sortChanged: EventEmitter<ColumnState[]> = new EventEmitter();

  components = {
    actionsCellRenderer: FormatActionsCellRendererComponent,
  };

  columnTypes = columnTypes;
  defaultColDef = defaultColDef;
  sideBar = sideBar;
  statusBar = statusBar;

  constructor(public service: ListService) {}

  onGridReady = (params: GridReadyEvent): void => {
    this.service.setApis(params.api, params.columnApi);
  };

  onFirstDataRendered = (event: FirstDataRenderedEvent) => {
    event.columnApi.autoSizeAllColumns();
  }

  onFilterChanged = (event: FilterChangedEvent) => {
    // @TODO check and simplify condition
    if (
      event.hasOwnProperty('afterDataChange') &&
      event.hasOwnProperty('afterFloatingFilter') &&
      !event.afterDataChange
    ) {
      // only trigger when changed via cell edit and not via setFilterModel
      const filter = this.service.gridApi.getFilterModel();
      this.filterChanged.emit(filter);
      // } else {
      // triggered when the filter model is changed (occurs always after first condition)
      //     this.filteredDataChanged.emit((this.listApi.getModel() as any).rootNode.childrenAfterFilter );
    }
  };

  onSortChanged = (event: SortChangedEvent) => {
    this.sortChanged.emit(this.service.columnApi.getColumnState());
  };
}
