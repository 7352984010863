import { removeGraphQlFields } from './waiting-lists.helpers';
import { Id } from '../core/model';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { ApolloQueryResult, FetchResult } from '@apollo/client/core';
import waitingListCreateEntryMutation from './graphql/waiting-list-create-entry-mutation.graphql';
import waitingListDeleteEntryMutation from './graphql/waiting-list-delete-entry-mutation.graphql';
import waitingListQuery from './graphql/waiting-list-query.graphql';
import {
  CreateWaitingListEntryInput,
  WaitingListEntry,
  WaitingListEntryFilterInput,
  WaitingListEntryType,
} from '../core/graphql.model';

@Injectable({
  providedIn: 'root',
})
export class WaitingListsService {
  constructor(private apollo: Apollo) {}

  // CREATE

  createWaitingListEntry = (
    input: CreateWaitingListEntryInput
  ): Observable<WaitingListEntry> => {
    return this.apollo
      .mutate({
        mutation: waitingListCreateEntryMutation ,
        variables: {
          input,
        },
      })
      .pipe(
        map((result: FetchResult<any>) => {
          return result.data?.createWaitingList?.waitingList as WaitingListEntry;
        })
      );
  };

  // READ

  loadWaitingList = (
    customerId: Id,
    formatId: Id,
    recordingId: Id
  ): Observable<WaitingListEntry[]> => {
    const filter: WaitingListEntryFilterInput = {};
    if (customerId) {
      filter.customerId = { equals: customerId };
    } else if (formatId) {
      filter.formatId = { equals: formatId };
      filter.type = { equals: WaitingListEntryType.WAITINGLIST };
    } else if (recordingId) {
      filter.recordingId = { equals: recordingId };
      filter.type = { equals: WaitingListEntryType.TICKETALARM };
    }
    return this.apollo
      .watchQuery<WaitingListEntry[]>({
        query: waitingListQuery ,
        variables: {
          filter,
        },
      })
      .valueChanges.pipe(
        map((result: ApolloQueryResult<any>) =>
          result.data?.waitingList.map((waitingListEntry: WaitingListEntry) =>
            removeGraphQlFields(waitingListEntry)
          )
        )
      );
  };

  // DELETE

  deleteWaitingListEntry = (id: Id): Observable<boolean> => {
    return this.apollo
      .mutate({
        mutation: waitingListDeleteEntryMutation ,
        variables: {
          input: {
            id,
          },
        },
      })
      .pipe(map((result: FetchResult<any>) => true));
  };
}
