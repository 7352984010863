import { Injectable } from '@angular/core';
import { ColDef } from 'ag-grid-community';
import { TranslateService } from '@ngx-translate/core';
import {
  BaseColumnIds,
  BaseColumnDefsService,
} from 'src/app/core/list/base-column-defs-service';

export const initialGrouped = false;

export enum RecordingColumnIds {
  FORMAT = 'format',
  TITLE = 'title',
  TYPE = 'type',
  PRODUCTION_START = 'productionStart',
  CHECK_IN_BEGIN = 'checkInBegin',
  CHECK_IN_END = 'checkInEnd',
  EVENT_START_TIME = 'eventStartTime',
  PRODUCTION_START_TIME = 'productionStartTime',
  EVENT_END_TIME = 'eventEndTime',
  OVERVIEW_ACTIONS = 'overviewActions',
  CLONE_ACTIONS = 'cloneActions',
}

@Injectable({
  providedIn: 'root',
})
export class RecordingColumnDefsService extends BaseColumnDefsService {
  // @TODO maybe replace this with https://www.ag-grid.com/javascript-grid/group-cell-renderer/

  // @TODO also check https://www.ag-grid.com/angular-grid/grouping/#providing-cell-renderer
  // https://www.ag-grid.com/angular-data-grid/grouping-group-rows/#example-full-width-groups-rendering

  columnDefsMap = new Map<string, ColDef>([
    [
      RecordingColumnIds.FORMAT,
      {
        // @TODO explain special functions
        headerName: this.translate.instant('RECORDINGS.COLUMNS.FORMAT'),
        field: 'format.id', // this is where we group by
        rowGroup: initialGrouped,
        hide: initialGrouped,
        valueFormatter: (params) => {
          if (params.node.group) {
            return params.node.allLeafChildren[0].data.format.title;
          } else {
            // called if rowGroup: false
            return params.data.format.title;
          }
        },
        // @TODO sort by format.title not format.id
        // https://www.ag-grid.com/javascript-data-grid/grouping-sorting/
        // comparator?: (valueA: any, valueB: any, nodeA: RowNode, nodeB: RowNode, isInverted: boolean) => number;
        // @TODO filter by format.title not format.id
        // filterValueGetter?: ((params: ValueGetterParams) => any) | string;
      },
    ],
    [
      BaseColumnIds.ID,
      this.baseColumnDefsMap.get(BaseColumnIds.ID),
    ],
    [
      RecordingColumnIds.TITLE,
      {
        headerName: this.translate.instant('RECORDINGS.COLUMNS.TITLE'),
        field: 'title',
      },
    ],
    [
      RecordingColumnIds.TYPE,
      {
        headerName: this.translate.instant('RECORDINGS.COLUMNS.TYPE'),
        field: 'type',
        filter: 'agSetColumnFilter',
      },
    ],
    // [
    //   RecordingColumnIds.,
    // {
    //   headerName: 'season.name',
    //   field: 'season.name',
    // }
    // ],
    // [
    //   RecordingColumnIds.,
    // {
    //   headerName: 'studio.name',
    //   field: 'studio.name',
    // }
    // ],
    [
      RecordingColumnIds.PRODUCTION_START,
      {
        headerName: this.translate.instant('RECORDINGS.COLUMNS.PRODUCTION_START'),
        field: 'productionStart',
        type: 'dateColumn',
      },
    ],
    [
      RecordingColumnIds.CHECK_IN_END,
      {
        headerName: this.translate.instant('RECORDINGS.COLUMNS.CHECK_IN_END'),
        field: 'checkInEnd',
        type: 'dateColumn',
      },
    ],
    [
      RecordingColumnIds.CHECK_IN_BEGIN,
      {
        headerName: this.translate.instant('RECORDINGS.COLUMNS.CHECK_IN_BEGIN'),
        field: 'checkInBegin',
        type: 'dateColumn',
      },
    ],
    [
      RecordingColumnIds.EVENT_END_TIME,
      {
        headerName: this.translate.instant('RECORDINGS.COLUMNS.EVENT_END_TIME'),
        field: 'eventEnd',
        type: 'dateColumn',
      },
    ],
    [
      RecordingColumnIds.EVENT_START_TIME,
      {
        headerName: this.translate.instant('RECORDINGS.COLUMNS.EVENT_START_TIME'),
        field: 'eventStart',
        type: 'timeColumn',
      },
    ],
    [
      RecordingColumnIds.PRODUCTION_START_TIME,
      {
        headerName: this.translate.instant('RECORDINGS.COLUMNS.PRODUCTION_START_TIME'),
        field: 'productionStart',
        type: 'timeColumn',
      },
    ],
    [
      RecordingColumnIds.EVENT_END_TIME,
      {
        headerName: this.translate.instant('RECORDINGS.COLUMNS.EVENT_END_TIME'),
        field: 'eventEnd',
        type: 'timeColumn',
      },
    ],
    // [
    //   RecordingColumnIds.,
    // {
    //   headerName: 'audienceCapacity',
    //   field: 'audienceCapacity',
    // }
    // ],
    // [
    //   RecordingColumnIds.,
    // {
    //   headerName: 'wheelchairCapacity',
    //   field: 'wheelchairCapacity',
    // }
    // ],
    // [
    //   RecordingColumnIds.,
    // {
    //   headerName: 'additionalCapacity',
    //   field: 'additionalCapacity',
    // }
    // ],
    // [
    //   RecordingColumnIds.,
    // {
    //   headerName: 'releaseState',
    //   field: 'releaseState',
    // }
    // ],
    // [
    //   RecordingColumnIds.,
    // {
    //   headerName: 'releaseStateChangedAt',
    //   field: 'releaseStateChangedAt',
    // }
    // ],
    // [
    //   RecordingColumnIds.,
    // {
    //   headerName: 'processState',
    //   field: 'processState',
    // }
    // ],
    // [
    //   RecordingColumnIds.,
    // {
    //   headerName: 'processStateChangedAt',
    //   field: 'processStateChangedAt',
    // }
    // ],
    [
      RecordingColumnIds.OVERVIEW_ACTIONS,
      {
        ...this.baseColumnDefsMap.get(BaseColumnIds.ACTIONS),
        cellRenderer: 'actionsCellRenderer',
      },
    ],
    [
      RecordingColumnIds.CLONE_ACTIONS,
      {
        ...this.baseColumnDefsMap.get(BaseColumnIds.ACTIONS),
        cellRenderer: 'cloneActionsCellRenderer',
      },
    ],
  ]);

  columnDefs: ColDef[] = Array.from(this.columnDefsMap.keys()).map((key) => ({
    colId: key,
    ...this.columnDefsMap.get(key),
  }));

  constructor(protected translate: TranslateService) {
    super(translate);
  }
}
