import { MatSnackBar } from '@angular/material/snack-bar';
import { Notification, NotifyServiceInterface } from './notify.service';
import { Injectable } from '@angular/core';

const DEFAULT_DURATION = 5000;

@Injectable({
  providedIn: 'root',
})
export class NotifyToastService implements NotifyServiceInterface {
  constructor(private snackBarService: MatSnackBar) {}

  notify(notification: Notification): void {
    this.snackBarService.open(notification.message, null, {
      duration: notification.duration || DEFAULT_DURATION,
    });
  }
}
