import { createAction, props } from '@ngrx/store';
import { User } from 'src/app/core/graphql.model';

// READ

export const loadUsers = createAction('[User] Load All');
export const loadUsersSuccess = createAction(
  '[User] Load All Success',
  props<{ data: User[] }>()
);
export const loadUsersFailure = createAction(
  '[User] Load All Failure',
  props<{ error: any }>()
);

export const loadCurrentUser = createAction('[User] Load Current');
export const loadUserSuccess = createAction(
  '[User] Load Single Success',
  props<{ data: User }>()
);
export const loadUserFailure = createAction(
  '[User] Load Single Failure',
  props<{ error: any }>()
);

// UPDATE

export const updateUser = createAction(
  '[User] Update',
  props<{ data: Partial<User> }>()
);
export const updateUserSuccess = createAction(
  '[User] Update Success',
  props<{ data: User }>()
);
export const updateUserFailure = createAction(
  '[User] Update Failure',
  props<{ error: any }>()
);

// DELETE
