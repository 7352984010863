import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { Recording } from 'src/app/core/graphql.model';
import * as RecordingEntitiesActions from './recording-entities.actions';

export const recordingEntitiesFeatureKey = 'entities';

export interface State extends EntityState<Recording> {}

export const adapter: EntityAdapter<Recording> =
  createEntityAdapter<Recording>();

export const initialState: State = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,

  // CREATE

  on(RecordingEntitiesActions.createRecordingSuccess, (state, action) =>
    adapter.upsertOne(action.data, state)
  ),

  // READ

  on(RecordingEntitiesActions.loadRecordingsSuccess, (state, action) =>
    adapter.setAll(action.data, state)
  ),

  on(RecordingEntitiesActions.loadRecordingsByFormatSuccess, (state, action) =>
    adapter.setAll(action.data, state)
  ),

  on(RecordingEntitiesActions.loadRecordingsByFilterSuccess, (state, action) =>
    adapter.setAll(action.data, state)
  ),

  on(RecordingEntitiesActions.loadRecordingSuccess, (state, action) =>
    adapter.upsertOne(action.data, state)
  ),

  // UPDATE

  on(RecordingEntitiesActions.updateRecordingSuccess, (state, action) =>
    adapter.upsertOne(action.data, state)
    ),

    // DELETE
  
    on(RecordingEntitiesActions.deleteRecordingSuccess, (state, action) =>
      adapter.removeOne(action.id, state)
    )
);
