import { CustomerWaitingListsTabComponent } from './pages/customer-page/customer-waiting-lists-tab/customer-waiting-lists-tab.component';
import { CustomerCreatePageComponent } from './pages/customer-create-page/customer-create-page.component';
import { AddressFormComponent } from './components/address-form/address-form.component';
import { ContactOptionFormComponent } from './components/contact-option-form/contact-option-form.component';
import { CustomerActionsCellRendererComponent } from './components/customers-list/cell-renderers/customer-actions-cell-renderer.component';
import { CustomersPageComponent } from './pages/customers-page/customers-page.component';
import { CustomersRoutingModule } from './customers-routing.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomerPageComponent } from './pages/customer-page/customer-page.component';
import { StoreModule } from '@ngrx/store';
import * as fromCustomer from './store/customer.reducer';
import { EffectsModule } from '@ngrx/effects';
import { CustomerEffects } from './store/customer.effects';
import { ListModule } from '../core/list/list.module';
import { TranslateModule } from '@ngx-translate/core';
import { CustomersListComponent } from './components/customers-list/customers-list.component';
import { CustomerFormComponent } from './components/customer-form/customer-form.component';
import { CustomerHeaderComponent } from './components/customer-header/customer-header.component';
import { MaterialModule } from '../core/material.module';
import { CustomerRolesComponent } from './components/customer-roles/customer-roles.component';
import { BookingsModule } from './../bookings/bookings.module';
import { CustomerCreateBookingPageComponent } from './pages/customer-create-booking-page/customer-create-booking-page.component';
import { AddressCreateDialogFormComponent } from './components/address-create-dialog-form/address-create-dialog-form.component';
import { CustomerProfilTabComponent } from './pages/customer-page/customer-profil-tab/customer-profil-tab.component';
import { CustomerBookingsTabComponent } from './pages/customer-page/customer-bookings-tab/customer-bookings-tab.component';
import { WaitingListsModule } from '../waiting-lists/waiting-lists.module';
import { ListConfigsModule } from '../list-configs/list-configs.module';
import { CustomersOverviewTabComponent } from './pages/customers-page/customers-overview-tab/customers-overview-tab.component';
import { CustomersListsOverviewTabComponent } from './pages/customers-page/customers-lists-overview-tab/customers-lists-overview-tab.component';
import { RecordingsModule } from '../recordings/recordings.module';
import { LayoutModule } from '../layout/layout.module';
import { CaslModule } from '../core/casl/casl.module';
import { TagsModule } from '../tags/tags.module';

@NgModule({
  declarations: [
    CustomerPageComponent,
    CustomersPageComponent,
    CustomersListComponent,
    CustomerFormComponent,
    CustomerHeaderComponent,
    CustomerProfilTabComponent,
    CustomerBookingsTabComponent,
    CustomerWaitingListsTabComponent,
    CustomerCreatePageComponent,
    CustomerRolesComponent,
    CustomerActionsCellRendererComponent,
    AddressFormComponent,
    ContactOptionFormComponent,
    CustomerCreateBookingPageComponent,
    AddressCreateDialogFormComponent,
    CustomersOverviewTabComponent,
    CustomersListsOverviewTabComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CustomersRoutingModule,
    StoreModule.forFeature(
      fromCustomer.customerFeatureKey,
      fromCustomer.reducers
    ),
    EffectsModule.forFeature(CustomerEffects),
    TranslateModule.forChild({
      extend: true,
    }),
    LayoutModule,
    MaterialModule,
    CaslModule,
    ListModule,
    BookingsModule,
    WaitingListsModule,
    TagsModule,
    ListConfigsModule,
    RecordingsModule, // @TODO only for store
  ],
  exports: [ContactOptionFormComponent],
})
export class CustomersModule {}
