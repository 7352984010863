import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { Tag } from 'src/app/core/graphql.model';
import * as TagEntitiesActions from './tag-entities.actions';

export const tagEntitiesFeatureKey = 'entities';

export interface State extends EntityState<Tag> {}

export const adapter: EntityAdapter<Tag> = createEntityAdapter<Tag>();

export const initialState: State = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,

  // CREATE

  on(TagEntitiesActions.createTagSuccess, (state, action) =>
    adapter.upsertOne(action.data, state)
  ),

  // READ

  on(TagEntitiesActions.loadTagsSuccess, (state, action) =>
    adapter.setAll(action.data, state)
  ),

  // UPDATE

  // DELETE

  on(
    TagEntitiesActions.deleteTagSuccess,
    (state, action) => adapter.removeOne(action.id, state)
  ),

  on(
    TagEntitiesActions.deleteTagFailure,
    (state, action) => {
      return {
        ...state,
        error: action.error,
      };
    }
  )

);
