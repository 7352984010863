<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <mat-accordion>
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title> Formatbasis</mat-panel-title>
      </mat-expansion-panel-header>

      <!-- Panel Content-->

      <div class="u-ph-16">

        <div class="o-grid">
          <div class="o-grid__col u-10/36">
            <mat-form-field class="u-1/1">
              <input type="hidden" id="id" formControlName="id" />
              <mat-label for="title">
                {{ "FORMATS.FORMAT_FORM.LABEL" | translate }}
              </mat-label>
              <input
                matInput
                type="text"
                placeholder="Formats Titel"
                id="title"
                formControlName="title"
              />
            </mat-form-field>
          </div>
        </div>

        <div *ngIf="!isCreateMode" class="o-grid">
          <div class="o-grid__col u-6/36">
            <mat-form-field class="u-1/1">
              <mat-label for="seasonNr">
                Staffel-nr.
              </mat-label>
              <input
                matInput
                type="number"
                placeholder="Staffel-nr"
                [value]="_format?.cmsFormat?.data?.currentSeason?.iv 
                  ? _format?.cmsFormat?.data?.currentSeason?.iv[0]?.data?.number?.iv 
                  : ''"
                readonly
              />
            </mat-form-field>
          </div>

          <div class="o-grid__col u-6/36">
            <mat-form-field class="u-1/1">
              <mat-label for="seasonName">
                Staffelname
              </mat-label>
              <input
                matInput
                type="text"
                placeholder="Staffelname"
                [value]="_format?.cmsFormat?.data?.currentSeason?.iv
                  ? _format?.cmsFormat?.data?.currentSeason?.iv[0]?.data?.name?.iv
                  : ''"
                readonly
              />
            </mat-form-field>
          </div>

          <div class="o-grid__col u-6/36">
            <mat-form-field class="u-1/1">
              <mat-label for="seasonStart">
                Staffelbeginn
              </mat-label>
              <input
                matInput
                type="text"
                placeholder="Staffelbeginn"
                [value]="_format?.cmsFormat?.data?.currentSeason?.iv
                  ? (_format?.cmsFormat?.data?.currentSeason?.iv[0]?.data?.start?.iv | date)
                  : '' "
                readonly
              />
            </mat-form-field>
          </div>

          <div class="o-grid__col u-6/36">
            <mat-form-field class="u-1/1">
              <mat-label for="seasonEnd">
                Staffelende
              </mat-label>
              <input
                matInput
                type="text"
                placeholder="Staffelende"
                [value]="_format?.cmsFormat?.data?.currentSeason?.iv
                  ? (_format?.cmsFormat?.data?.currentSeason?.iv[0]?.data?.end?.iv | date)
                  : ''"
                readonly
              />
            </mat-form-field>
          </div>
        </div>

        <div class="o-grid">
          <div class="o-grid__col u-6/36">
            <mat-form-field class="u-1/1">
              <mat-label>Mindestalter</mat-label>
              <mat-select formControlName="minimumAge">
                <mat-option *ngFor="let age of minimumAges" [value]="age">
                  {{ age }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <hr />

        <div class="o-grid u-mt-24">
          <div *ngIf="!isCreateMode" class="o-grid__col u-6/36">
            <mat-form-field class="u-1/1">
              <mat-label for="studio">
                Studioname
              </mat-label>
              <input
                matInput
                type="text"
                placeholder="Studioname"
                [value]="_format?.cmsFormat?.data?.currentSeason?.iv && _format?.cmsFormat?.data?.currentSeason?.iv[0]?.data?.studio?.iv
                  ? _format?.cmsFormat?.data?.currentSeason?.iv[0]?.data?.studio?.iv[0]?.data?.name?.iv
                  : ''"
                readonly/>
            </mat-form-field>
          </div>

          <div class="o-grid__col u-6/36">
            <mat-form-field class="u-1/1">
              <mat-label for="audienceCapacity">
                Studiokapazität
                <!-- {{ "FORMATS.FORMAT_FORM.CREATED_AT.LABEL" | translate }} -->
              </mat-label>
              <input
                matInput
                type="number"
                placeholder="Studiokapazität"
                id="audienceCapacity"
                formControlName="audienceCapacity"
              />
            </mat-form-field>
          </div>
        </div>
      </div>


    </mat-expansion-panel>
  </mat-accordion>

  <mat-accordion class="u-block u-mt-32">
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title> Ansprechpartner </mat-panel-title>
      </mat-expansion-panel-header>

      <div class="u-ph-16">
        <div
          class="u-mt-24"
          formArrayName="contacts"
          *ngFor="let x of contactsFormItems.controls; index as i"
        >
          <app-format-contact-person-form
            [formControlName]="i"
            [disabled]="disabled"
            (remove)="onRemoveContact(i)"
          >
          </app-format-contact-person-form>
        </div>


        <div class="c-formfield-placeholder u-inline-flex u-ml-auto u-mt-16">
          <button
            mat-mini-fab
            color="accent"
            type="button"
            *ngIf="!disabled"
            (click)="onAddContact()"
          >
            <mat-icon>add</mat-icon>
          </button>
        </div>
<!--        <button
          *ngIf="!disabled"
          (click)="onAddContacts()"
          class="btn__size u-mt-16"
          mat-flat-button
          color="accent"
          type="button"
        >
          +
        </button>-->
      </div>

    </mat-expansion-panel>
  </mat-accordion>

  <mat-accordion class="u-block u-mt-32">
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title> Verfügbare Produkte </mat-panel-title>
      </mat-expansion-panel-header>

      <div class="u-ph-16">
        <div
          formArrayName="products"
          *ngFor="let x of productsFormItems.controls; index as i"
        >
          <app-product-form
            [formControlName]="i"
            [disabled]="disabled"
            (remove)="onRemoveProduct(i)"
          >
          </app-product-form>
        </div>

        <div class="c-formfield-placeholder u-inline-flex u-ml-auto">
          <button
            mat-mini-fab
            color="accent"
            type="button"
            (click)="onAddProduct()"
          >
            <mat-icon>add</mat-icon>
          </button>
        </div>
        &nbsp;
        <!--      <button
                class="btn__size"
                mat-flat-button
                color="accent"
                type="button"
                (click)="onAddProduct()"
              >
                +
              </button>-->
      </div>
    </mat-expansion-panel>
  </mat-accordion>


  <mat-accordion class="u-block u-mt-32">
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title> Interessen </mat-panel-title>
      </mat-expansion-panel-header>

      <div class="u-ph-16">
        <app-tags-select
          formControlName="tags"
          [tags]="tags"
          [enableAdd]="!disabled"
          [enableDelete]="!disabled"
        ></app-tags-select>
        <!-- @TODO permissions -->
      </div>
    </mat-expansion-panel>
  </mat-accordion>

<!--  <div class="u-mt-36">-->
<!--      <mat-form-field class="u-1/1">-->
<!--        <mat-label>Buchbar für</mat-label>-->
<!--        <mat-select>-->
<!--          <mat-option-->
<!--            *ngFor="let bookingOption of bookingOptions"-->
<!--            [value]="bookingOption.value"-->
<!--          >-->
<!--            {{ bookingOption.viewValue }}-->
<!--          </mat-option>-->
<!--        </mat-select>-->
<!--      </mat-form-field>-->
<!--  </div>-->

  <div class="u-flex u-ai-center u-jc-between u-mt-36">
    <input type="hidden" id="isDeletable" formControlName="isDeletable" />
    <button
      mat-flat-button
      color="warn"
      type="button"
      [disabled]="!form.value.isDeletable || disabled"
      (click)="onDelete()"
    >
      löschen
      <!-- @TODO auth - editmode (edit vs. create) -->
      <!-- @TODO disable on create-->
    </button>
    &nbsp;
    <span>
      <a
        *ngIf="!isCreateMode"
        mat-flat-button
        type="button"
        [href]="formatCmsHref(_format?.cmsId)"
        target="_blank"
        class="u-mr-32"
      >
        im CMS bearbeiten
      </a>
      <button
        mat-fab
        extended
        color="primary"
        type="submit"
        [disabled]="form.invalid || disabled"
      >
        Speichern
        <!-- @TODO auth - editmode (edit vs. create) -->
      </button>
    </span>
  </div>
</form>








<!-- back up -->
<!--
<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <mat-accordion>
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title> Formatbasis</mat-panel-title>
      </mat-expansion-panel-header>
    &lt;!&ndash; Panel Content&ndash;&gt;
<input type="hidden" id="id" formControlName="id" />
<mat-label for="title">
  {{ "FORMATS.FORMAT_FORM.LABEL" | translate }}
</mat-label>
&nbsp;
<mat-form-field appearance="outline">
  <input
    matInput
    type="text"
    placeholder="Formats Titel"
    id="title"
    formControlName="title"
  />
</mat-form-field>
&nbsp;

<div>
  <mat-label for="seasonNr">
    Staffel-nr.
  </mat-label>
  &nbsp;
  <mat-form-field appearance="outline">
    <input
      matInput
      type="number"
      placeholder="Staffel-nr"
      [value]="
              _format?.cmsFormat?.data?.currentSeason?.iv[0]?.data?.number?.iv
            "
      readonly
    />
  </mat-form-field>
  &nbsp;
  <mat-label for="seasonName">
    Staffelname
  </mat-label>
  &nbsp;
  <mat-form-field appearance="outline">
    <input
      matInput
      type="text"
      placeholder="Staffelname"
      [value]="
              _format?.cmsFormat?.data?.currentSeason?.iv[0]?.data?.name?.iv
            "
      readonly
    />
  </mat-form-field>
  &nbsp;
  <mat-label for="seasonStart">
    Staffelbeginn
  </mat-label>
  &nbsp;
  <mat-form-field appearance="outline">
    <input
      matInput
      type="text"
      placeholder="Staffelbeginn"
      [value]="
              _format?.cmsFormat?.data?.currentSeason?.iv[0]?.data?.start?.iv | date
            "
      readonly
    />
  </mat-form-field>
  &nbsp;
  <mat-label for="seasonEnd">
    Staffelende
  </mat-label>
  &nbsp;
  <mat-form-field appearance="outline">
    <input
      matInput
      type="text"
      placeholder="Staffelende"
      [value]="
              _format?.cmsFormat?.data?.currentSeason?.iv[0]?.data?.end?.iv | date
            "
      readonly
    />
  </mat-form-field>
  &nbsp;
</div>
&nbsp;
<mat-label>Mindestalter</mat-label>
&nbsp;
<mat-form-field appearance="fill">
  <mat-label>Mindestalter</mat-label>
  <mat-select formControlName="minimumAge">
    <mat-option *ngFor="let age of minimumAges" [value]="age">
      {{ age }}
    </mat-option>
  </mat-select>
</mat-form-field>
<hr />

<div>
  <mat-label for="studio">
    Studioname
  </mat-label>
  &nbsp;
  <mat-form-field appearance="outline">
    <input
      matInput
      type="text"
      placeholder="Studioname"
      [value]="
              _format?.cmsFormat?.data?.currentSeason?.iv[0]?.data?.studio?.iv[0]?.data?.name?.iv
            "
      readonly/>
  </mat-form-field>

  <mat-label for="audienceCapacity">
    Studiokapazität-->
    <!-- {{ "FORMATS.FORMAT_FORM.CREATED_AT.LABEL" | translate }} -->
<!--  </mat-label>
  &nbsp;
  <mat-form-field appearance="outline">
    <input
      matInput
      type="number"
      placeholder="Studiokapazität"
      id="audienceCapacity"
      formControlName="audienceCapacity"
    />
  </mat-form-field>
</div>
</mat-expansion-panel>
</mat-accordion>
<hr />

<mat-accordion>
  <mat-expansion-panel [expanded]="true">
    <mat-expansion-panel-header>
      <mat-panel-title> Ansprechpartner </mat-panel-title>
    </mat-expansion-panel-header>
    <div
      formArrayName="contacts"
      *ngFor="let x of contactsFormItems.controls; index as i"
    >
      <app-format-contact-person-form
        [formControlName]="i"
        [disabled]="disabled"
      >
      </app-format-contact-person-form>
    </div>
    &nbsp;
    <button
      *ngIf="!disabled"
      (click)="onAddContacts()"
      class="btn__size"
      mat-flat-button
      color="accent"
      type="button"
    >
      +
    </button>
  </mat-expansion-panel>
</mat-accordion>
<hr />
<mat-accordion>
  <mat-expansion-panel [expanded]="true">
    <mat-expansion-panel-header>
      <mat-panel-title> Verfügbare Produkte </mat-panel-title>
    </mat-expansion-panel-header>

    <div
      formArrayName="products"
      *ngFor="let x of productsFormItems.controls; index as i"
    >
      <app-product-form
        [formControlName]="i"
        [disabled]="disabled"
        (remove)="onRemoveProduct(i)"
      >
      </app-product-form>
    </div>
    &nbsp;
    <button
      class="btn__size"
      mat-flat-button
      color="accent"
      type="button"
      (click)="onAddProduct()"
    >
      +
    </button>
  </mat-expansion-panel>
</mat-accordion>
&nbsp;
<div class="container__footer-btns">
  &nbsp;
  <span>
      <mat-label>Buchbar für </mat-label>
      &nbsp;
      <mat-form-field appearance="fill">
        <mat-label>Buchbar für</mat-label>
        <mat-select>
          <mat-option
            *ngFor="let bookingOption of bookingOptions"
            [value]="bookingOption.value"
          >
            {{ bookingOption.viewValue }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </span>
</div>-->

<!-- @TODO tags
<mat-card>
  <b>Interessen</b>
  <app-tags-select
    formControlName="tags"
    [tags]="tags"
    [enableAdd]="!disabled"
    [enableRemove]="!disabled"
  ></app-tags-select>
  < !-- @TODO permissions -- >
</mat-card>
<br />
-->

<!--&nbsp;
<div class="container__footer-btns">
  <button class="btn_draft" mat-flat-button color="accent" type="button">
    Entwurf löschen
    &lt;!&ndash; @TODO auth - editmode (edit vs. create) &ndash;&gt;
    &lt;!&ndash; @TODO what is the functionality? &ndash;&gt;
  </button>
  &nbsp;
  <span>
      <a
        class="btn_cms"
        mat-flat-button
        type="button"
        [href]="formatCmsHref(_format?.cmsId)"
        target="_blank"
      >&lt;!&ndash; @TODO LINK &ndash;&gt;
        im CMS bearbeiten
      </a>
      &nbsp;
      <button
        class="btn_save"
        mat-flat-button
        color="accent"
        type="submit"
        [disabled]="form.invalid || disabled"
      >
        Speichern
        &lt;!&ndash; @TODO auth - editmode (edit vs. create) &ndash;&gt;
      </button>
    </span>
</div>
</form>-->
