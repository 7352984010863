import { enforceDateString } from './../../../core/i18n-helpers';
import {
  getEmptyContactOptionPhone,
  getEmptyContactOptionEmail,
  getEmptyDefaultContactOptionEmail,
  getEmptyDefaultContactOptionPhone,
} from './../../contact-options.factory';
import { getEmptyAddress } from './../../addresses.factory';
import {
  Address,
  ContactOption,
  ContactOptionType,
  Customer,
  Salutation,
} from 'src/app/core/graphql.model';
import { updateNestedFormElements } from './../../../core/form.helpers';
import { Injectable } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { getEmptyPrimaryAddress } from '../../addresses.factory';

export interface FormCustomer
  extends Omit<Customer, 'addresses' | 'contactOptions'> {
  primaryAddress: Address;
  additionalAddresses: Address[];
  emailOptions: ContactOption[];
  phoneOptions: ContactOption[];
}

@Injectable()
export class CustomerFormService {
  form: FormGroup = this.createForm();
  formCustomer: FormCustomer;

  set customer(customer: Customer) {
    if (!customer) {
      return;
    }
    // map object data to form data
    const { addresses, contactOptions, ...rest } = customer;
    this.formCustomer = {
      ...rest,
      primaryAddress:
        addresses?.find((address: Address) => address.prio === 0) || null, // optional
      additionalAddresses: addresses?.filter(
        (address: Address) => address.prio !== 0
      ),
      emailOptions: contactOptions?.filter(
        (contactOption: ContactOption) =>
          contactOption.type === ContactOptionType.EMAIL
      ),
      phoneOptions: contactOptions?.filter(
        (contactOption: ContactOption) =>
          contactOption.type === ContactOptionType.PHONE
      ),
    };

    // update nested form elements
    updateNestedFormElements(
      this.form,
      'additionalAddresses',
      this.formCustomer.additionalAddresses.length
    );
    updateNestedFormElements(
      this.form,
      'phoneOptions',
      this.formCustomer.phoneOptions.length
    );
    updateNestedFormElements(
      this.form,
      'emailOptions',
      this.formCustomer.emailOptions.length
    );

    // update form values
    this.form.patchValue(this.formCustomer);
  }

  get customer(): Customer {
    // map form data to object data
    const {
      primaryAddress,
      additionalAddresses,
      phoneOptions,
      emailOptions,
      ...rest
    } = this.form.value;
    return {
      ...rest,
      salutation: this.form.value.salutation || Salutation.NOT_SPECIFIED,
      birthDate: enforceDateString(this.form.value.birthDate),
      addresses: [primaryAddress, ...additionalAddresses],
      contactOptions: [...phoneOptions, ...emailOptions],
    };
  }

  constructor(private fb: FormBuilder) {}

  initContactOptions(): void {
    // add initial first elements of type default
    const additionalEmailFormArray = this.form.get('emailOptions') as FormArray;
    additionalEmailFormArray.push(
      new FormControl(getEmptyDefaultContactOptionEmail())
    );
  }

  private createForm(): FormGroup {
    return this.fb.group({
      id: [''], // hidden
      version: [''], // hidden
      salutation: [''],
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      primaryAddress: [null],
      birthDate: [''],
      additionalAddresses: this.fb.array([]),
      emailOptions: this.fb.array([]),
      phoneOptions: this.fb.array([]),
      tags: [''],
    });
    // @TODO check required and validators
  }

  public addPrimaryAddress(): void {
    const primaryAddresseForm = this.form.get(
      'primaryAddress'
    );
    primaryAddresseForm.setValue(getEmptyPrimaryAddress());
  }

  public removePrimaryAddress(): void {
    const primaryAddresseForm = this.form.get(
      'primaryAddress'
    );
    primaryAddresseForm.reset();
    primaryAddresseForm.setErrors(null);
  }
  
  public addAdditionalAddress(): void {
    const additionalAddressesFormArray = this.form.get(
      'additionalAddresses'
    ) as FormArray;
    additionalAddressesFormArray.push(new FormControl(getEmptyAddress()));
  }

  public removeAdditionalAddress(i: number): void {
    const additionalAddressesFormArray = this.form.get(
      'additionalAddresses'
    ) as FormArray;
    additionalAddressesFormArray.removeAt(i);
  }

  public addAdditionalPhone(): void {
    const additionalPhoneFormArray = this.form.get('phoneOptions') as FormArray;
    if (additionalPhoneFormArray.length === 0) {
      additionalPhoneFormArray.push(
        new FormControl(getEmptyDefaultContactOptionPhone())
      );
    } else {
      additionalPhoneFormArray.push(
        new FormControl(getEmptyContactOptionPhone())
      );
    }
  }

  public removeAdditionalPhone(i: number): void {
    const additionalPhoneFormArray = this.form.get('phoneOptions') as FormArray;
    additionalPhoneFormArray.removeAt(i);
  }

  public addAdditionalEmail(): void {
    const additionalEmailFormArray = this.form.get('emailOptions') as FormArray;
    additionalEmailFormArray.push(
      new FormControl(getEmptyContactOptionEmail())
    );
  }

  public removeAdditionalEmail(i: number): void {
    const additionalEmailFormArray = this.form.get('emailOptions') as FormArray;
    additionalEmailFormArray.removeAt(i);
  }
}
