import { createReducer, on } from '@ngrx/store';
import * as UserListsActions from './user-lists.actions';
import { ColDef } from 'ag-grid-community';

export const userListsFeatureKey = 'lists';

export interface State {
  overview: {
    columnDefs: ColDef[];
  };
}

export const initialState: State = {
  overview: {
    columnDefs: [],
  },
};

export const reducer = createReducer(
  initialState,
  on(UserListsActions.init, (state, action) => ({
    ...state,
    overview: {
      ...state.overview,
      columnDefs: action.columnDefs,
    },
  }))
);
