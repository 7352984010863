import { removeGraphQlFields } from './formats.helpers';
import { Id } from '../core/model';
import { map, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { ApolloQueryResult, FetchResult, WatchQueryFetchPolicy } from '@apollo/client/core';
import formatsQuery from './graphql/formats-query.graphql';
import formatQuery from './graphql/format-query.graphql';
import formatUpdateMutation from './graphql/format-update-mutation.graphql';
import formatCreatetMutation from './graphql/format-create-mutation.graphql';
import formatDeletetMutation from './graphql/format-delete-mutation.graphql';
import updateContactMutation from './graphql/contact-update-mutation.graphql';
import updateProductMutation from './graphql/product-update-mutation.graphql';
import removeContactMutation from './graphql/format-remove-contact-mutation.graphql';
import removeProductMutation from './graphql/format-remove-product-mutation.graphql';
import formatAddContactMutation from './graphql/format-add-contact-mutation.graphql';
import formatAddProductMutation from './graphql/format-add-product-mutation.graphql';
import formatAddTagMutation from './graphql/format-add-tag-mutation.graphql';
import formatRemoveTagMutation from './graphql/format-remove-tag-mutation.graphql';
import combinedQuery from 'ts-blink-graphql-combine-query';
import {
  appendArrayMutations,
  removeDefaultFields,
  removeStatisticFields,
} from '../core/graphql.helpers';
import { Contact, Format, Product, Tag } from '../core/graphql.model';

@Injectable({
  providedIn: 'root',
})
export class FormatsService {
  constructor(private apollo: Apollo) {}

  // CREATE

  createFormat = (format: Format): Observable<Format> => {
    const input = removeDefaultFields(format);
    return this.apollo
      .mutate({
        mutation: formatCreatetMutation ,
        variables: {
          input: {
            ...input,
            products: input.products.map((product: Product) => {
              const { id, ...rest } = product;
              return rest;
            }),
            contacts: input.contacts.map((contact: Contact) => {
              const { id, ...rest } = contact;
              return rest;
            }),
          },
        },
      })
      .pipe(
        map((result: FetchResult<any>) => {
          return result.data?.createFormat?.format as Format;
        })
      );
  };

  // READ

  loadFormats = (): Observable<Format[]> => {
    return this.apollo
      .watchQuery<Format[]>({
        query: formatsQuery ,
      })
      .valueChanges.pipe(
        map((result: ApolloQueryResult<any>) =>
          result.data?.formats.map((format: Format) =>
            removeGraphQlFields(format)
          )
        )
      );
  };

  loadFormat = (id: Id, force: boolean = false): Observable<Format> => {
    const forcedOptions = force ? { fetchPolicy: 'no-cache' as WatchQueryFetchPolicy } : {}
    return this.apollo
      .watchQuery<Format>({
        query: formatQuery ,
        variables: {
          id,
        },
        ...forcedOptions,
      })
      .valueChanges.pipe(
        map((result: ApolloQueryResult<any>) =>
          removeGraphQlFields(result.data?.format)
        )
      );
  };

  // UPDATE

  updateFormat = (format: Format, oldFormat?: Format): Observable<Format> => {
    const { 
      contacts: _1,
      products: _2,
      tags: _3,
      ...input
    } = format;
    // create combined mutation
    const { document, variables } = ((): any => {
      let cq = combinedQuery('CombinedFormatUpdate').add(formatUpdateMutation, {
        updateFormatInput: input,
      });
      cq = appendArrayMutations(
        cq,
        'contacts',
        format.contacts,
        oldFormat?.contacts || [],
        // add
        formatAddContactMutation,
        (contact: Contact) => ({
          input_contacts_add: {
            type: contact.type,
            name: contact.name,
            phone: contact.phone,
            email: contact.email,
            formatId: format.id,
          },
        }),
        // remove
        removeContactMutation,
        (id: Id) => ({
          input_contact_remove: {
            formatId: format.id,
            contactId: id,
          }
        }),
        // update
        updateContactMutation,
        (contact: Contact) => {
          return { updateContactInput: removeStatisticFields(contact) };
        }
      );
      cq = appendArrayMutations(
        cq,
        'product',
        format.products,
        oldFormat?.products || [],
        // add
        formatAddProductMutation,
        (product: Product) => ({
          input_product_add: {
            name: product.name,
            type: product.type,
            formatId: format.id,
          },
          product: removeDefaultFields(product),
        }),
        // remove
        removeProductMutation,
        (id: Id) => ({
          input_product_remove: {
            formatId: format.id,
            productId: id,
          }
        }),
        // update
        updateProductMutation,
        (product: Product) => ({
          updateProductInput: removeStatisticFields(product),
        })
      );
      cq = appendArrayMutations(
        cq,
        'tags',
        format.tags,
        oldFormat?.tags || [],
        // add
        formatAddTagMutation,
        (tag: Tag) => ({
          input_tag_add: {
            formatId: format.id,
            tagId: tag.id,
          }
        }),
        // remove
        formatRemoveTagMutation,
        (id: Id) => ({
          formatId: format.id,
          tagId: id,
        }),
      );
      return cq;
    })();
    // execute mutation
    return this.apollo
      .mutate({
        mutation: document as any,
        variables,
      })
      .pipe(
        switchMap(() => {
          return this.loadFormat(format.id, true)
        })
      );
  };

    // DELETE

    deleteFormat = (id: Id): Observable<null> => {
      return this.apollo
        .mutate({
          mutation: formatDeletetMutation,
          variables: {
            input: {
              id,
            }
          },
        })
        .pipe(
          map((result: FetchResult<any>) => {
            return null;
          })
        );
    };

}
