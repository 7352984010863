import { createReducer, on } from '@ngrx/store';
import * as BookingListsActions from './booking-lists.actions';
import { ColDef } from 'ag-grid-community';

export const bookingListsFeatureKey = 'lists';

export interface State {
  // overview: {
  //   columnDefs: ColDef[];
  // };
  customer: {
    columnDefs: ColDef[];
  };
  recording: {
    columnDefs: ColDef[];
  };
  recordingParticipant: {
    columnDefs: ColDef[];
  };
}

export const initialState: State = {
  // overview: {
  //   columnDefs: [],
  // },
  customer: {
    columnDefs: [],
  },
  recording: {
    columnDefs: [],
  },
  recordingParticipant: {
    columnDefs: [],
  },
};

export const reducer = createReducer(
  initialState,
  on(BookingListsActions.init, (state, action) => ({
    ...state,
    // overview: {
    //   ...state.overview,
    //   columnDefs: action.columnDefs,
    // },
    customer: {
      ...state.customer,
      columnDefs: action.customerColumnDefs,
    },
    recording: {
      ...state.customer,
      columnDefs: action.recordingColumnDefs,
    },
    recordingParticipant: {
      ...state.customer,
      columnDefs: action.recordingParticipantColumnDefs,
    },
  }))
);
