import { Address, Salutation } from '../core/graphql.model';

export const getEmptyAddress = (): Address => ({
  id: null,
  version: null,
  prio: 1,
  salutation: Salutation.NOT_SPECIFIED,
  firstName: '',
  lastName: '',
  company: '',
  address: '',
  additionalAddressInformation: '',
  postCode: '',
  city: '',
  country: '',
  state: '',
});

export const getEmptyPrimaryAddress = (): Address => ({
  ...getEmptyAddress(),
  prio: 0,
});
