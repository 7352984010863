import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  COUNTRIES_DB_DE,
  Country,
} from '@angular-material-extensions/select-country';
import { Address } from 'src/app/core/graphql.model';

export interface FormAddress extends Omit<Address, 'country'> {
  country: Country;
}

@Injectable()
export class AddressFormService {
  form: FormGroup = this.createForm();
  formAddress: FormAddress;

  set address(address: Address) {
    if (!address) {
      return;
    }
    const { country, ...rest } = address;
    this.formAddress = {
      ...rest,
      country:
        COUNTRIES_DB_DE.find(
          (country2: Country) => country2.alpha2Code === country
        ) || null,
    };
    this.form.patchValue(this.formAddress);
  }
  get address(): Address {
    return {
      ...this.form.value,
      country: this.form.value.country?.alpha2Code,
    };
  }

  constructor(private fb: FormBuilder) {}

  private createForm(): FormGroup {
    return this.fb.group({
      id: [''], // hidden
      version: [''], // hidden
      prio: [''], // hidden
      salutation: [''],
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      company: [''],
      address: ['', [Validators.required]],
      additionalAddressInformation: [''],
      postCode: ['', [Validators.required]],
      city: ['', [Validators.required]],
      state: ['', []],
      country: ['', [Validators.required]],
    });
    // @TODO check required and validators
  }
}
