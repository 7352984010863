import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ListConfig } from '../../list-configs.model';

@Injectable()
export class ListConfigsCreateDialogFormService {
  form: FormGroup = this.createForm();

  get listConfig(): ListConfig {
    return this.form.value.title;
  }
  constructor(private fb: FormBuilder) {}

  private createForm(): FormGroup {
    return this.fb.group({
      title: [''],
    });
  }
}
