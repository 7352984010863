<div class="o-container u-height-1/1 u-flex u-fd-col u-pb-24 u-pt-12">
  <h1 class="o-type-h4 u-mb-24">
    {{ "RECORDINGS.RECORDING_CREATE_PAGE.TITLE" | translate }}
  </h1>

  <div class="u-mb-48">
    <mat-form-field>
      <mat-label>Format wählen</mat-label>
      <mat-select
        [(ngModel)]="selectedFormatId"
        name="Options"
        (selectionChange)="onFormatChange()"
      >
        <mat-option
          *ngFor="let format of (formatsFacade.formats$ | async) || []"
          [value]="format.id"
        >
          {{ format.title }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div>
    <a
      mat-fab
      extended
      *ngIf="selectedFormatId"
      [routerLink]="recordingCreateByFormatLink(selectedFormatId)"
    >
      Mit leerer Aufzeichnung beginnen
      <mat-icon>arrow_forward</mat-icon>
    </a>
  </div>

  <h2 class="o-type-h5 u-mv-48" *ngIf="selectedFormatId">
    ... oder bestehende Aufzeichnung als Vorlage wählen:
  </h2>

  <app-recordings-list
    *ngIf="selectedFormatId"
    [columnDefs]="(recordingsFacade.listClone$ | async)?.columnDefs"
    [data]="recordingsFacade.recordingsByFormat$(selectedFormatId) | async"
  >
  </app-recordings-list>
</div>
