import { Action, combineReducers, ActionReducer } from '@ngrx/store';
import * as fromWaitingListEntities from './entities/waiting-list-entities.reducer';
import * as fromWaitingListLists from './lists/waiting-list-lists.reducer';

export const waitingListfeatureKey = 'waitingList';

export interface State {
  [fromWaitingListEntities.waitingListEntitiesFeatureKey]: fromWaitingListEntities.State;
  [fromWaitingListLists.waitingListListsFeatureKey]: fromWaitingListLists.State;
}

export function reducers(
  state: State | undefined,
  action: Action
): ActionReducer<State, any> {
  // @ts-ignore
  return combineReducers({
    [fromWaitingListEntities.waitingListEntitiesFeatureKey]:
      fromWaitingListEntities.reducer,
    [fromWaitingListLists.waitingListListsFeatureKey]:
      fromWaitingListLists.reducer,
  })(state, action);
}
