import { formatCmsHref } from './../../formats-routing-helper';
import { Component, Input } from '@angular/core';
import { FormatFormService } from '../format-form/format-form.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Format } from 'src/app/core/graphql.model';

@Component({
  selector: 'app-format-header',
  templateUrl: './format-header.component.html',
  styleUrls: ['./format-header.component.scss'],
  providers: [FormatFormService],
})
export class FormatHeaderComponent {
  checked = false;

  formatCmsHref = formatCmsHref;

  @Input() format: Format;

  constructor() {}

  onToggleArchived(event: MatSlideToggleChange): void {
    this.checked = event.checked;
    // @TODO functionality is missing
  }
}
