import { environment } from 'src/environments/environment';
import { mailsLink } from '../app-routing.module';
import { Id, Link } from '../core/model';

export enum RoutesNames {
  LIST = '',
  CREATE = 'create',
  SEND = 'send',
  DETAIL = 'detail',
}

export const mailsListLink = (): Link => mailsLink(); /*.concat(RoutesNames.LIST)*/
export const mailCreateLink = (): Link => mailsLink().concat(RoutesNames.CREATE);
export const mailSendLink = (id: Id): Link => mailsLink().concat(id, RoutesNames.SEND);
export const mailExtInfoHref = (id: Id): string => `${environment.mail_info_base}/${id}`;
export const mailExtRecipientsHref = (listId: Id): string => `${environment.mail_recipients_base}/${listId}`;
