import { Injectable } from '@angular/core';
import {
  NotificationType,
  NotifyService,
} from '../../../core/notify/notify.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  switchMap,
  map,
  mergeMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { of } from 'rxjs';
import * as fromBooking from '../booking.reducer';
import * as BookingEntitiesActions from './booking-entities.actions';
import * as BookingSelectors from '../booking.selectors';
import * as CustomerSelectors from '../../../customers/store/customer.selectors';
import { BookingsService } from '../../bookings.service';
import {
  AdminBooking,
  CreateBillingAddressInput,
} from 'src/app/core/graphql.model';
import { Store } from '@ngrx/store';
import {
  ROUTE_PARAM_CUSTOMER_ID,
  ROUTE_PARAM_RECORDING_ID,
} from 'src/app/core/route-params';
import { getMergedRoute } from 'src/app/core/store/router/router-state.selectors';
import { loaderStart, loaderStop } from 'src/app/core/store/app.actions';
import { LOADER_ID_ACTION } from 'src/app/app.component';
import {
  handleMutationError,
  handleQueryError,
} from 'src/app/core/graphql.helpers';

@Injectable()
export class BookingEntitiesEffects {
  constructor(
    private actions$: Actions,
    private store: Store<fromBooking.State>,
    private service: BookingsService,
    private notifyService: NotifyService
  ) {}

  // CREATE

  createBooking$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BookingEntitiesActions.createBooking),
      withLatestFrom(
        this.store.select(CustomerSelectors.getEntitiesCurrentCustomer)
      ),
      map(([action, customer]) => [
        { ...action.booking, customer } as AdminBooking,
        action.address,
      ]),
      mergeMap(
        ([booking, address]: [AdminBooking, CreateBillingAddressInput]) => 
          this.service.createBooking(booking, address).pipe(
            map((result: AdminBooking) =>
              BookingEntitiesActions.createBookingSuccess({ data: result })
            ),
            catchError((error) =>
              of(
                BookingEntitiesActions.createBookingFailure({
                  error: error.message,
                })
              )
            )
          )
      )
    );
  });
  
  createBookingSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(BookingEntitiesActions.createBookingSuccess),
        tap(() =>
          this.notifyService.notify({
            type: NotificationType.Toast,
            message: 'Successfully created', // @TODO i18n
          })
        )
      ),
    { dispatch: false }
  );

  // READ

  loadBookings$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BookingEntitiesActions.loadBookings),
      withLatestFrom(this.store.select(getMergedRoute)),
      switchMap(([, route]) => {
        return this.service
          .loadBookings(
            +route?.params[ROUTE_PARAM_CUSTOMER_ID],
            +route?.params[ROUTE_PARAM_RECORDING_ID]
          )
          .pipe(
            map((bookings) =>
              BookingEntitiesActions.loadBookingsSuccess({
                data: bookings,
              })
            )
          );
      })
    );
  });

  // UPDATE

  addBookingCouponCode$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BookingEntitiesActions.addBookingCouponCode),
      withLatestFrom(
        this.store.select(BookingSelectors.getEntitiesCurrentDraftOrder)
      ),
      map(([action, draftOrder]) => [action.couponCode, draftOrder]),
      mergeMap(
        ([couponCode, draftOrder]: [string, AdminBooking]) => 
          this.service.addBookingCouponCode(draftOrder.id, couponCode).pipe(
            map((result: AdminBooking) =>
              BookingEntitiesActions.addBookingCouponCodeSuccess({ data: result })
            ),
            catchError((error) =>
              of(
                BookingEntitiesActions.addBookingCouponCodeFailure({
                  error: error.message,
                })
              )
            )
          )
      )
    );
  });

  completeBookingDraft$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BookingEntitiesActions.completeBookingDraft),
      withLatestFrom(
        this.store.select(BookingSelectors.getEntitiesCurrentDraftOrder)
      ),
      map(([action, draftOrder]) => draftOrder),
      mergeMap(
        (draftOrder: AdminBooking) => 
          this.service.transitionOrderToState(draftOrder.id, 'ArrangingPayment').pipe(
            map((result: AdminBooking) =>
              BookingEntitiesActions.completeBookingDraftSuccess({ data: result })
            ),
            catchError((error) =>
              of(
                BookingEntitiesActions.completeBookingDraftFailure({
                  error: error.message,
                })
              )
            )
          )
      )
    );
  });
  
  addBookingCouponCodeSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(BookingEntitiesActions.addBookingCouponCodeSuccess),
        tap(() =>
          this.notifyService.notify({
            type: NotificationType.Toast,
            message: 'Coupon Code successfully added',
          })
        )
      ),
    { dispatch: false }
  );

  // DELETE

  // FAILURES

  queryFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(BookingEntitiesActions.loadBookingsFailure),
        map((action) => action.error),
        tap((error) => handleQueryError(error, this.notifyService, this.store))
      ),
    { dispatch: false }
  );

  mutationFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          BookingEntitiesActions.createBookingFailure,
          BookingEntitiesActions.addBookingCouponCodeFailure,
          BookingEntitiesActions.completeBookingDraftFailure,
        ),
        map((action) => action.error),
        tap((error) =>
          handleMutationError(error, this.notifyService, this.store)
        )
      ),
    { dispatch: false }
  );

  // LOADERS

  loaderStart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        BookingEntitiesActions.createBooking,
        BookingEntitiesActions.addBookingCouponCode,
        BookingEntitiesActions.completeBookingDraft,
      ),
      mergeMap(() => of(loaderStart({ id: LOADER_ID_ACTION })))
    )
  );

  loaderStop$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        BookingEntitiesActions.createBookingSuccess,
        BookingEntitiesActions.createBookingFailure,
        BookingEntitiesActions.addBookingCouponCodeSuccess,
        BookingEntitiesActions.addBookingCouponCodeFailure,
        BookingEntitiesActions.completeBookingDraftSuccess,
        BookingEntitiesActions.completeBookingDraftFailure,
      ),
      mergeMap(() => of(loaderStop({ id: LOADER_ID_ACTION })))
    )
  );
}
