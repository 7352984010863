import { DateTime, User } from '../core/graphql.model';
import { Id } from '../core/model';

export interface ListConfig {
  id?: Id;
  title?: string;
  createdAt?: DateTime;
  createdBy?: User;
  entity?: Entity;
  config?: string;
}

export enum Entity {
  customer = 'CUSTOMER',
}
