import { administrationLink } from '../app-routing.module';
import { Link } from '../core/model';

export enum RoutesNames {
  OVERVIEW = '',
  USERS = 'users',
  TAGS = 'tags',
}

export const usersLink = (): Link =>
  administrationLink().concat(RoutesNames.USERS);

export const tagsLink = (): Link =>
  administrationLink().concat(RoutesNames.TAGS);
