import { getMergedRoute } from './../../core/store/router/router-state.selectors';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Id } from 'src/app/core/model';
import * as fromRecording from './recording.reducer';
import * as fromRecordingEntities from './entities/recording-entities.reducer';
import * as fromRecordingLists from './lists/recording-lists.reducer';
import * as EntitiesSelectors from './entities/recording-entities.selectors';
import * as ListsSelectors from './lists/recording-lists.selectors';
import { getEntitiesCurrentFormatId } from 'src/app/formats/store/format.selectors';

export const selectRecordingState = createFeatureSelector<fromRecording.State>(
  fromRecording.recordingFeatureKey
);

// ENTITIES

export const selectEntitiesState = createSelector(
  selectRecordingState,
  (state) => state[fromRecordingEntities.recordingEntitiesFeatureKey]
);

export const getEntitiesRecordings = createSelector(
  selectEntitiesState,
  EntitiesSelectors.getRecordings
);

export const getEntitiesRecordingsByFormat = (formatId: Id) =>
  createSelector(selectEntitiesState, (state) =>
    EntitiesSelectors.getRecordingsByFormat(state, formatId)
  );

export const getEntitiesRecordingByCurrentFormat = createSelector(
  selectEntitiesState,
  getEntitiesCurrentFormatId,
  (state, formatId) => EntitiesSelectors.getRecordingsByFormat(state, formatId)
);

export const getEntitiesRecording = (id: Id) =>
  createSelector(selectEntitiesState, (state) =>
    EntitiesSelectors.getRecording(state, id)
  );

export const getEntitiesCurrentRecordingId = createSelector(
  getMergedRoute,
  EntitiesSelectors.getCurrentRecordingId
);

export const getEntitiesCurrentRecording = createSelector(
  selectEntitiesState,
  getEntitiesCurrentRecordingId,
  EntitiesSelectors.getRecording
);

// LISTS

export const selectListState = createSelector(
  selectRecordingState,
  (state) => state[fromRecordingLists.recordingListsFeatureKey]
);

export const getListOverview = createSelector(
  selectListState,
  ListsSelectors.getOverview
);

export const getListClone = createSelector(
  selectListState,
  ListsSelectors.getClone
);

export const getListFormat = createSelector(
  selectListState,
  ListsSelectors.getFormat
);
