import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { User } from 'src/app/core/graphql.model';
import * as UserEntitiesActions from './user-entities.actions';

export const userEntitiesFeatureKey = 'entities';

export interface State extends EntityState<User> {}

export const adapter: EntityAdapter<User> = createEntityAdapter<User>();

export const initialState: State = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,

  // READ

  on(UserEntitiesActions.loadUsersSuccess, (state, action) =>
    adapter.setAll(action.data, state)
  ),

  on(UserEntitiesActions.loadUserSuccess, (state, action) =>
    adapter.upsertOne(action.data, state)
  ),

  // UPDATE

  on(UserEntitiesActions.updateUserSuccess, (state, action) =>
    adapter.upsertOne(action.data, state)
  )

  // DELETE
);
