export const ROUTE_PARAM_CUSTOMER_ID = 'customer-id';
export const ROUTE_PARAM_FORMAT_ID = 'format-id';
export const ROUTE_PARAM_RECORDING_ID = 'recording-id';
export const ROUTE_PARAM_MAIL_CAMPAIGN_ID = 'mail-campaign-id';
export const ROUTE_PARAM_USER_ID = 'user-id';

export const ROUTE_QUERY_PARAM_FORMAT_ID = 'format-id';
export const ROUTE_QUERY_PARAM_LIST_CONFIG_CONFIG = 'list-config';
export const ROUTE_QUERY_PARAM_LIST_CONFIG_TITLE = 'list-title';
export const ROUTE_QUERY_PARAM_LIST_CONFIG_ID = 'list-id';
export const ROUTE_QUERY_PARAM_FILTER = 'filter';
export const ROUTE_QUERY_PARAM_CUSTOMER_IDS = 'customer-ids';
