import {
  Action,
  ActionReducer,
  ActionReducerMap,
  createReducer,
  MetaReducer,
  on,
} from '@ngrx/store';
import { environment } from './../../../environments/environment';
import * as fromRouter from '@ngrx/router-store';
import { InjectionToken } from '@angular/core';
import { localStorageSync as lss } from 'ngrx-store-localstorage';
import * as AppActions from './app.actions';

export interface State {
  router: fromRouter.RouterReducerState<any>;
  app: AppState;
}

export interface AppState {
  appLoaderActive: boolean;
  activeLoaders: string[];
}

const initialAppState: AppState = {
  appLoaderActive: false,
  activeLoaders: [],
};

export const appReducer = createReducer(
  initialAppState,
  on(AppActions.loaderStart, (state, action) => ({
    ...state,
    activeLoaders: [...state.activeLoaders, action.id],
  })),
  on(AppActions.loaderStop, (state, action) => ({
    ...state,
    activeLoaders: state.activeLoaders.filter((id) => id !== action.id),
  }))
);

export const ROOT_REDUCERS = new InjectionToken<
  ActionReducerMap<State, Action>
>('Root reducers token', {
  factory: () => ({
    router: fromRouter.routerReducer,
    app: appReducer,
  }),
});

// console.log all actions
export function logger(reducer: ActionReducer<State>): ActionReducer<State> {
  return (state, action) => {
    const result = reducer(state, action);
    console.groupCollapsed(action.type);
    console.log('prev state', state);
    console.log('action', action);
    console.log('next state', result);
    console.groupEnd();

    return result;
  };
}

// sync parts of the store with local storage
export function localStorageSync(
  reducer: ActionReducer<any>
): ActionReducer<any> {
  return lss({
    keys: [ {user: [ { auth: ['session'] } ] } ],
    rehydrate: true,
    restoreDates: false,
  })(reducer);
}

export const metaReducers: MetaReducer<State>[] = !environment.production
  ? [localStorageSync, logger]
  : [localStorageSync];
