import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromListConfig from './list-config.reducer';
import * as fromListConfigEntities from './entities/list-config-entities.reducer';
import * as fromListConfigLists from './lists/list-config-lists.reducer';
import * as EntitiesSelectors from './entities/list-config-entities.selectors';
import * as ListsSelectors from './lists/list-config-lists.selectors';

export const selectListConfigState =
  createFeatureSelector<fromListConfig.State>(
    fromListConfig.listConfigFeatureKey
  );

// ENTITIES

export const selectEntitiesState = createSelector(
  selectListConfigState,
  (state) => state[fromListConfigEntities.listConfigEntitiesFeatureKey]
);

export const getEntitiesListConfigs = createSelector(
  selectEntitiesState,
  EntitiesSelectors.getListConfigs
);

// LISTS

export const selectListState = createSelector(
  selectListConfigState,
  (state) => state[fromListConfigLists.listConfigListsFeatureKey]
);

export const getListGeneral = createSelector(
  selectListState,
  ListsSelectors.getGeneral
);
