import { Injectable } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { BookingAddressPaymentForm } from '../booking-form/booking-form.model';

@Injectable()
export class BookingAddressPaymentFormService {
  form: FormGroup = this.createForm();

  set addressPayment(addressPayment: BookingAddressPaymentForm) {
    if (!addressPayment) {
      return;
    }
    this.form.patchValue(addressPayment);
  }

  constructor(private fb: FormBuilder) {}

  private createForm(): FormGroup {
    return this.fb.group({
      invoiceAddress: ['', [Validators.required]],
      // @TODO
      // paymentType: [BookingPaymentType.Invoice, [Validators.required]],
    });
    // @TODO check required and validators
  }
}
