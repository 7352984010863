<div>
  <button
    *ngIf="'delete' | able: 'WaitingList'"
    mat-icon-button
    color="warn"
    aria-label="Eintrag löschen"
    matTooltip="Eintrag löschen"
    matTooltipShowDelay="500"
    (click)="onDelete()"
  >
    <mat-icon>delete</mat-icon>
  </button>
</div>
