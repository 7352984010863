import { removeGraphQlFields } from './tags.helpers';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { ApolloQueryResult, FetchResult } from '@apollo/client/core';
import tagsQuery from './graphql/tags-query.graphql';
import tagCreateMutation from './graphql/tag-create-mutation.graphql';
import tagDeleteMutation from './graphql/tag-delete-mutation.graphql';
import {
  removeDefaultFields,
} from '../core/graphql.helpers';
import { Tag } from '../core/graphql.model';
import { Id } from '../core/model';

@Injectable({
  providedIn: 'root',
})
export class TagsService {
  constructor(private apollo: Apollo) {}

  // CREATE

  createTag = (tag: Tag): Observable<Tag> => {
    const input = removeDefaultFields(tag);
    return this.apollo
      .mutate({
        mutation: tagCreateMutation,
        variables: {
          input
        },
      })
      .pipe(
        map((result: FetchResult<any>) => {
          return result.data?.createTag?.tag as Tag;
        })
      );
  };

  // READ

  loadTags = (): Observable<Tag[]> => {
    return this.apollo
      .watchQuery<Tag[]>({
        query: tagsQuery,
      })
      .valueChanges.pipe(
        map((result: ApolloQueryResult<any>) =>
          result.data?.tags.map((tag: Tag) =>
            removeGraphQlFields(tag)
          )
        )
      );
  };

  // UPDATE

  // DELETE

  deleteTag = (id: Id): Observable<boolean> => {
    return this.apollo
      .mutate({
        mutation: tagDeleteMutation,
        variables: {
          input: {
            id,
          },
        },
      })
      .pipe(map((result: FetchResult<any>) => true));
  };

}
