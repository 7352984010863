import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BookingNotesForm } from '../booking-form/booking-form.model';

@Injectable()
export class BookingNotesFormService {
  form: FormGroup = this.createForm();

  set bookingNote(bookingNote: BookingNotesForm) {
    if (!bookingNote) {
      return;
    }
    this.form.patchValue(bookingNote);
  }

  get bookingNote(): BookingNotesForm {
    return this.form.value;
  }

  constructor(private fb: FormBuilder) {}

  private createForm(): FormGroup {
    return this.fb.group({
      note: [''],
    });
    // @TODO check required and validators
  }
}
