<!-- Panel Content-->
<form [formGroup]="form">
  <!-- (ngSubmit)="onSubmit()" -->
  <input type="hidden" id="id" formControlName="id" />

  <div class="o-grid">
    <div class="o-grid__col u-12/36">
      <mat-form-field class="u-1/1">
        <mat-label for="name">
          Ansprechpartner
          <!-- {{ "FORMATS.FORMAT_FORM.LABEL" | translate }} -->
        </mat-label>
        <input
          matInput
          type="text"
          placeholder="Ansprechpartner"
          [id]="'name-' + idx"
          formControlName="name"
        />
      </mat-form-field>
    </div>

    <div class="o-grid__col u-12/36">
      <mat-form-field class="u-1/1">
        <mat-label for="type">
          Art des Ansprechpartner
          <!-- {{ "FORMATS.FORMAT_FORM.CREATED_AT.LABEL" | translate }} -->
        </mat-label>
        <input matInput type="text" [id]="'type-' + idx" formControlName="type" />
      </mat-form-field>
    </div>
    <div class="o-grid__col u-12/36 u-text-right">
      <div class="c-formfield-placeholder u-inline-flex">
        <button
          mat-mini-fab
          color="accent"
          type="button"
          (click)="onRemove()"
        >
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <div class="o-grid">
    <div class="o-grid__col u-12/36">
      <mat-form-field class="u-1/1">
        <mat-label for="email">
          email
          <!-- {{ "FORMATS.FORMAT_FORM.CREATED_AT.LABEL" | translate }} -->
        </mat-label>
        <input
          matInput
          type="text"
          placeholder="Email"
          [id]="'email-' + idx"
          formControlName="email"
        />
      </mat-form-field>
    </div>

    <div class="o-grid__col u-12/36">
      <mat-form-field class="u-1/1">
        <mat-label for="telephone">
          Telefon
          <!-- {{ "FORMATS.FORMAT_FORM.CREATED_AT.LABEL" | translate }} -->
        </mat-label>
        <input
          matInput
          type="text"
          placeholder="Telefon"
          [id]="'telefon-' + idx"
          formControlName="phone"
        />
      </mat-form-field>
      <input type="hidden" id="isDeletable" formControlName="isDeletable" />
<!--      <button-->
<!--        [disabled]="disabled"-->
<!--        (click)="onRemove()"-->
<!--      >-->
<!--        x-->
<!--      </button>-->
    </div>
  </div>

  <hr class="u-mt-24">
</form>

