import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { User } from 'src/app/core/graphql.model';

@Injectable()
export class UserFormService {
  form: FormGroup = this.createForm();

  set user(user: User) {
    if (!user) {
      return;
    }
    // update form values
    this.form.patchValue(user);
  }

  get user(): User {
    // map form data to object data
    return this.form.value;
  }

  constructor(private fb: FormBuilder) {}

  private createForm(): FormGroup {
    return this.fb.group({
      id: [''], // hidden
      roles: [null],
    });
  }
}
