import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Product, ProductVariant } from 'src/app/core/graphql.model';
import { formatTime, mergeDateAndTime } from 'src/app/core/i18n-helpers';
import { VId } from 'src/app/core/model';

interface ProductVariantForm
  extends Omit<
    ProductVariant,
    'name' | 'sku' | 'recording' | 'product' | 'eventStart' | 'eventEnd'
  > {
    productId: VId;
    eventStart: string;
    eventEnd: string;
  }

@Injectable()
export class ProductVariantFormService {
  readonly form: FormGroup = this.createProductVariant();

  private _productVariant: ProductVariant;
  set productVariant(productVariant: ProductVariant) {
    if (!productVariant) {
      return;
    }

    // keep non form data
    this._productVariant = productVariant;

    // map product variant to form
    const { name, sku, recording, product, eventStart, eventEnd, ...rest } = productVariant;
    const productVarianForm: ProductVariantForm = {
      ...rest,
      productId: product.id,
      eventStart: formatTime(eventStart),
      eventEnd: formatTime(eventEnd),
      price: Math.round(rest.price / 100.0)
    };

    // update form
    this.form.patchValue(productVarianForm);
  }
  get productVariant(): ProductVariant {
    const {
      isDeletable: _1,
      productId,
      eventStart,
      eventEnd,
      ...rest
    } = this.form.value as ProductVariantForm;
    const {isDeletable: _2, ...old} = this._productVariant;

    const baseDateDT = new Date(this.baseDate);
    const productVariant: ProductVariant = {
      ...old,
      ...rest,
      eventStart: mergeDateAndTime(baseDateDT, eventStart),
      eventEnd: mergeDateAndTime(baseDateDT, eventEnd),
      product: this.products.find(product => product.id === productId),
      price: rest.price * 100,
    };

    return productVariant;
  }

  public products: Product[];

  public baseDate: Date;

  constructor(private fb: FormBuilder) {}

  private createProductVariant(): FormGroup {
    return this.fb.group({
      id: [''], // hidden
      productId: [''],
      price: ['', [Validators.required]], // bruttopreis
      stockOnHand: ['', [Validators.required]], // Available seats
      maxPerOrder: [''],
      eventStart: [''],
      eventEnd: [''],
      isDeletable: [''], // hidden
    });
    // @TODO check required and validators
  }
}
