import { RecordingBookingTabComponent } from './pages/recording-page/recording-booking-tab/recording-booking-tab.component';
import { RecordingDataTabComponent } from './pages/recording-page/recording-data-tab/recording-data-tab.component';
import { RecordingCloneActionsCellRendererComponent } from './components/recordings-list/cell-renderers/recording-clone-actions-cell-renderer.component';
import { RecordingActionsCellRendererComponent } from './components/recordings-list/cell-renderers/recording-actions-cell-renderer.component';
import { FormatsModule } from './../formats/formats.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '../core/material.module';
import { EffectsModule } from '@ngrx/effects';
import { RecordingsRoutingModule } from './recordings-routing.module';
import { RecordingsPageComponent } from './pages/recordings-page/recordings-page.component';
import { RecordingEffects } from './store/recording.effects';
import { RecordingHeaderComponent } from './components/recording-header/recording-header.component';
import { RecordingPageComponent } from './pages/recording-page/recording-page.component';
import { RecordingCreatePageComponent } from './pages/recording-create-page/recording-create-page.component';
import { RecordingFormComponent } from './components/recording-form/recording-form.component';
import { RecordingFullWidthCellRendererComponent } from './components/recordings-list/cell-renderers/recording-full-width-cell-renderer.component';
import { InternalTicketFormComponent } from './components/internal-ticket-form/internal-ticket-form.component';
import { ProductVariantFormComponent } from './components/product-variant-form/product-variant-form.component';
import { ProductVariantTypeMenuComponent } from './components/product-variant-type-menu/product-variant-type-menu.component';
import { RecordingCreateByFormatPageComponent } from './pages/recording-create-by-format-page/recording-create-by-format-page.component';
import { RecordingCreateByRecordingCloneComponent } from './pages/recording-create-by-recording-clone/recording-create-by-recording-clone.component';
import { WaitingListsModule } from '../waiting-lists/waiting-lists.module';
import { RecordingWaitingListsTabComponent } from './pages/recording-page/recording-waiting-lists-tab/recording-waiting-lists-tab.component';
import { BookingsModule } from '../bookings/bookings.module';
import { RecordingStoreModule } from './store/recording-store.module';
import { RecordingsListModule } from './recordings-list.module';
import { LayoutModule } from '../layout/layout.module';
import { CaslModule } from '../core/casl/casl.module';
import { CURRENCY_MASK_CONFIG, CurrencyMaskConfig, CurrencyMaskModule } from 'ng2-currency-mask';

export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
  align: "right",
  allowNegative: false,
  decimal: ",",
  precision: 2,
  prefix: "",
  suffix: "",
  thousands: "."
};

@NgModule({
  declarations: [
    RecordingsPageComponent,
    RecordingActionsCellRendererComponent,
    RecordingCloneActionsCellRendererComponent,
    RecordingFullWidthCellRendererComponent,
    RecordingHeaderComponent,
    RecordingPageComponent,
    RecordingCreatePageComponent,
    RecordingFormComponent,
    InternalTicketFormComponent,
    ProductVariantFormComponent,
    ProductVariantTypeMenuComponent,
    RecordingDataTabComponent,
    RecordingBookingTabComponent,
    RecordingWaitingListsTabComponent,
    RecordingCreateByFormatPageComponent,
    RecordingCreateByRecordingCloneComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RecordingsRoutingModule,
    EffectsModule.forFeature(RecordingEffects),
    TranslateModule.forChild({
      extend: true,
    }),
    RecordingStoreModule,
    LayoutModule,
    MaterialModule,
    CaslModule,
    WaitingListsModule,
    FormatsModule,
    BookingsModule,
    RecordingsListModule,
    CurrencyMaskModule,
  ],
  exports: [],
  providers: [
    { provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig }
],
})
export class RecordingsModule {}
