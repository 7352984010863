import { formatEditLink } from '../../formats-routing-helper';
import { Id, Link } from '../../../core/model';

export enum RouteNames {
  DATA = '',
  FORMAT_RECORDINGS = 'recordings',
  WAITING_LIST = 'waitinglist',
}

export const formatEditLinkData = (id: Id): Link =>
  formatEditLink(id); /*.concat(RouteNames.DATA)*/
export const formatEditLinkRecordings = (id: Id): Link =>
  formatEditLink(id).concat(RouteNames.FORMAT_RECORDINGS);
export const formatEditLinkWaitingList = (id: Id): Link =>
  formatEditLink(id).concat(RouteNames.WAITING_LIST);
