import { NotifyModule } from './core/notify/notify.module';
import { UsersModule } from './users/users.module';
import { GraphqlModule } from './core/graphql.module';
import { Observable } from 'rxjs/internal/Observable';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './core/material.module';
import { AppStoreModule } from './core/store/app-store.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { of } from 'rxjs';
import { translations } from './translations';
import { FormsModule } from '@angular/forms';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { LayoutModule } from './layout/layout.module';
import { ApolloModule } from 'apollo-angular';
import { Ability, PureAbility } from '@casl/ability';
import { GlobalErrorHandler } from './core/errors/global-error-handler';
import { MsalModule } from "@azure/msal-angular";
import { PublicClientApplication } from "@azure/msal-browser";
import { environment } from 'src/environments/environment';

class StaticLoader implements TranslateLoader {
  getTranslation(lang: string): Observable<any> {
    registerLocaleData(localeDe);
    return of(translations);
  }
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NotifyModule,
    MaterialModule,
    GraphqlModule,
    ApolloModule,
    AppStoreModule,
    UsersModule,
    LayoutModule,
    TranslateModule.forRoot({
      loader: { provide: TranslateLoader, useClass: StaticLoader },
      defaultLanguage: 'de',
    }),
    MsalModule.forRoot(
      new PublicClientApplication({
        auth: {
          clientId: environment.msal_client_id,
          authority: environment.masl_authority,
        },
        cache: {
          cacheLocation: "localStorage",
        },
        system: {
          allowNativeBroker: false,
        }
      }),
      null,
      null
    ),
  ],
  providers: [
    { provide: Ability, useValue: new Ability() },
    { provide: PureAbility, useExisting: Ability },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
