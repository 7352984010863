import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { RecordingsSearchformService } from './recordings-searchform.service';

@Component({
  selector: 'app-recordings-searchform',
  templateUrl: './recordings-searchform.component.html',
  styleUrls: ['./recordings-searchform.component.scss'],
  providers: [RecordingsSearchformService],
})
export class RecordingsSearchformComponent {
  form: FormGroup = this.formService.form;

  constructor(private formService: RecordingsSearchformService) {}
}
