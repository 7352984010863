import { Customer } from 'src/app/core/graphql.model';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CustomersFacadeService } from '../../customers-facade.service';
import { Observable } from 'rxjs';
import { AppFacadeService } from 'src/app/app-facade.service';
import { LOADER_ID_ACTION } from 'src/app/app.component';
import { TagsFacadeService } from 'src/app/tags/tags-facade.service';

@Component({
  selector: 'app-customer-create-page',
  templateUrl: './customer-create-page.component.html',
  styleUrls: ['./customer-create-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomerCreatePageComponent {
  actionLoaderActive$: Observable<boolean>;

  constructor(
    public facade: CustomersFacadeService,
    public tagsFacade: TagsFacadeService,
    public appFacade: AppFacadeService,
  ) {
    this.actionLoaderActive$ = this.appFacade.isLoaderActive$(LOADER_ID_ACTION);
  }

  onSubmitCreate(customer: Customer): void {
    this.facade.createCustomer(customer);
  }
}
