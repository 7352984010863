import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TagsSelectComponent } from './components/tags-select/tags-select.component';
import * as fromTag from './store/tag.reducer';
import { TagEffects } from './store/tag.effects';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { TranslateModule } from '@ngx-translate/core';
import { TagsPageComponent } from './pages/tags-page/tags-page.component';
import { TagsRoutingModule } from './tags-routing.module';
import { MaterialModule } from '../core/material.module';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [TagsSelectComponent, TagsPageComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MaterialModule,
    TagsRoutingModule,
    StoreModule.forFeature(
      fromTag.tagFeatureKey,
      fromTag.reducers
    ),
    EffectsModule.forFeature(TagEffects),
    TranslateModule.forChild({
      extend: true,
    }),
  ],
  exports: [
    TagsSelectComponent,
  ]
})
export class TagsModule { }
