import { ActivatedRoute, Router } from '@angular/router';
import { Link } from 'src/app/core/model';
import { MatTabChangeEvent } from '@angular/material/tabs';

// for handling the loader we pass it in the navigation.
// Furthermore it must be added in the route.
export const LOADER_ID_TAB = 'tab';

export abstract class BaseTabPageComponent {
  abstract tabLinks: Link[];
  tabIndex: number;
  suppressNavigation: boolean;

  constructor(protected router: Router, protected route: ActivatedRoute) {}

  pullTabIndex(): void {
    const newTabIndex = Math.max(
      this.tabLinks
        .map((link: Link) => this.router.createUrlTree(link).toString())
        .findIndex((route) => route === this.router.url),
      0
    );

    // this is executed after navigation, so we don't need to navigate (which would happen through the change of selected tab)
    this.suppressNavigation = false;
    if (this.tabIndex !== undefined && newTabIndex !== this.tabIndex) {
      this.suppressNavigation = true;
    }
    this.tabIndex = newTabIndex;
  }

  onSelectedTabChange(
    event: MatTabChangeEvent,
    loader: string = LOADER_ID_TAB
  ): void {
    this.tabIndex = event.index;
    // navigate only on click
    if (this.suppressNavigation) {
      this.suppressNavigation = false;
    } else {
      this.router.navigate(this.tabLinks[event.index], { state: { loader } });
    }
  }
}
