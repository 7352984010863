import { userEditLink } from './../../../users-routing-helper';
import { Router } from '@angular/router';
import { ICellRendererParams } from 'ag-grid-community';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Component } from '@angular/core';
import { Link } from 'src/app/core/model';

@Component({
  selector: 'app-user-actions-cell-renderer',
  templateUrl: './user-actions-cell-renderer.component.html',
})
export class UserActionsCellRendererComponent
  implements ICellRendererAngularComp
{
  params: ICellRendererParams;
  link: Link;

  constructor(private router: Router) {}

  agInit(params: ICellRendererParams): void {
    this.updateHrefs(params);
  }

  refresh(params: ICellRendererParams): boolean {
    this.updateHrefs(params);
    return true;
  }

  updateHrefs(params: ICellRendererParams): void {
    this.params = params;
    this.link = userEditLink(this.params.data?.id);
  }

  onDetails(e: any): void {
    e.preventDefault();
    this.router.navigate(this.link);
  }
}
