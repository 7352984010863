import { Customer } from '../core/graphql.model';
import { removeGraphQlFields as removeGraphQlFieldsBasic } from '../core/graphql.helpers';

export const removeGraphQlFields = (customer: Customer): Customer => ({
  ...removeGraphQlFieldsBasic(customer),
  addresses: customer.addresses.map((address) =>
    removeGraphQlFieldsBasic(address)
  ),
  contactOptions: customer.contactOptions.map((contactOption) =>
    removeGraphQlFieldsBasic(contactOption)
  ),
  tags: customer.tags?.map((contactOption) =>
    removeGraphQlFieldsBasic(contactOption) 
  ) || [],
});
