import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { AppFacadeService } from 'src/app/app-facade.service';
import { LOADER_ID_ACTION } from 'src/app/app.component';
import { Format } from 'src/app/core/graphql.model';
import { Id } from 'src/app/core/model';
import { FormatsFacadeService } from 'src/app/formats/formats-facade.service';
import { TagsFacadeService } from 'src/app/tags/tags-facade.service';

@Component({
  selector: 'app-format-data-tab',
  templateUrl: './format-data-tab.component.html',
  styleUrls: ['./format-data-tab.component.scss'],
})
export class FormatDataTabComponent {
  actionLoaderActive$: Observable<boolean>;

  constructor(
    public facade: FormatsFacadeService,
    public tagsFacade: TagsFacadeService,
    public appFacade: AppFacadeService
  ) {
    this.actionLoaderActive$ = this.appFacade.isLoaderActive$(LOADER_ID_ACTION);
  }

  onRemove(id: Id): void {
    this.facade.deleteFormat(id);
  }

  onFormatSubmit(format: Format): void {
    this.facade.updateFormat(format);
  }
}
