import {
  Component,
  Input,
  Output,
  EventEmitter,
  forwardRef,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  Validator,
} from '@angular/forms';
import { FormatContactPersonFormService } from './format-contact-person-form.service';
import { Contact } from 'src/app/core/graphql.model';
import { BaseSubFormComponent } from 'src/app/core/base/base-sub-form.component';

@Component({
  selector: 'app-format-contact-person-form',
  templateUrl: './format-contact-person-form.component.html',
  styleUrls: ['./format-contact-person-form.component.scss'],
  providers: [
    FormatContactPersonFormService,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormatContactPersonFormComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => FormatContactPersonFormComponent),
      multi: true,
    },
  ],
})
export class FormatContactPersonFormComponent
  extends BaseSubFormComponent
  implements ControlValueAccessor, Validator
{
  @Input() idx: number | string;

  @Input() set contact(contact: Contact) {
    this.formService.contact = contact;
  }

  @Input() set disabled(disabled: boolean) {
    this.setDisabledState(disabled);
  }

  @Output() remove: EventEmitter<void> = new EventEmitter();

  @Output() formSubmit: EventEmitter<Contact> = new EventEmitter();

  constructor(private formService: FormatContactPersonFormService) {
    super();
    this.form = this.formService.form;
  }

  writeValue(val: any): void {
    if (val) {
      this.form.setValue(val, { emitEvent: false });
    }
  }

  onRemove(): void {
    this.remove.emit();
  }

  onSubmit(): void {
    this.formSubmit.emit(this.formService.contact);
  }
}
