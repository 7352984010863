"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Supervisor = void 0;
const casl_1 = require("../casl");
class Supervisor {
    can(builder) {
        builder.can(casl_1.Action.Create, casl_1.Subject.Customer);
        builder.can(casl_1.Action.Update, casl_1.Subject.Customer);
        builder.can(casl_1.Action.Read, casl_1.Subject.Customer);
        builder.can(casl_1.Action.Create, casl_1.Subject.ListConfig);
        builder.can(casl_1.Action.Update, casl_1.Subject.ListConfig);
        builder.can(casl_1.Action.Delete, casl_1.Subject.ListConfig);
        builder.can(casl_1.Action.Read, casl_1.Subject.ListConfig);
        builder.can(casl_1.Action.Create, casl_1.Subject.WaitingList);
        builder.can(casl_1.Action.Read, casl_1.Subject.WaitingList);
        builder.can(casl_1.Action.Delete, casl_1.Subject.WaitingList);
        builder.can(casl_1.Action.Create, casl_1.Subject.Format);
        builder.can(casl_1.Action.Update, casl_1.Subject.Format);
        builder.can(casl_1.Action.Read, casl_1.Subject.Format);
        builder.can(casl_1.Action.Create, casl_1.Subject.Recording);
        builder.can(casl_1.Action.Update, casl_1.Subject.Recording);
        builder.can(casl_1.Action.Read, casl_1.Subject.Recording);
        builder.can(casl_1.Action.Create, casl_1.Subject.Booking);
        builder.can(casl_1.Action.Update, casl_1.Subject.Booking);
        builder.can(casl_1.Action.Read, casl_1.Subject.Booking);
        builder.can(casl_1.Action.Read, casl_1.Subject.Tag);
        return builder;
    }
}
exports.Supervisor = Supervisor;
