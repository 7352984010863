import { Component, forwardRef } from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  Validator,
} from '@angular/forms';
import { BaseSubFormComponent } from 'src/app/core/base/base-sub-form.component';
import { BookingNotesFormService } from './booking-notes-form.service';

@Component({
  selector: 'app-booking-notes-form',
  templateUrl: './booking-notes-form.component.html',
  styleUrls: ['./booking-notes-form.component.scss'],
  providers: [
    BookingNotesFormService,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BookingNotesFormComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => BookingNotesFormComponent),
      multi: true,
    },
  ],
})
export class BookingNotesFormComponent
  extends BaseSubFormComponent
  implements ControlValueAccessor, Validator
{
  // @TODO activate if the textArea needs to be marked as required
  // @Input() set submitted(submitted: boolean) {
  //   if (submitted === true) {
  //     Object.values(this.form.controls).forEach(control => control.markAsTouched())
  //   }
  // }

  constructor(private formService: BookingNotesFormService) {
    super();
    this.form = this.formService.form;
  }

  writeValue(val: any): void {
    if (val) {
      this.formService.bookingNote = val;
      this.form.setValue(this.formService.form.value, { emitEvent: false });
    }
  }
}
