import { UsersFacadeService } from '../../users-facade.service';
import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginPageComponent {
  constructor(private facade: UsersFacadeService) {}

  login(): void {
    this.facade.login();
  }
}
