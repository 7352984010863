import {
  NotificationType,
  NotifyService,
} from '../../../core/notify/notify.service';
import { RecordingsService } from '../../recordings.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { of } from 'rxjs';
import * as RecordingEntitiesActions from './recording-entities.actions';
import * as RecordingSelectors from '../recording.selectors';
import * as fromRecording from '../recording.reducer';
import { Store } from '@ngrx/store';
import { Recording } from 'src/app/core/graphql.model';
import { getEntitiesCurrentFormatId } from 'src/app/formats/store/format.selectors';
import { loaderStart, loaderStop } from 'src/app/core/store/app.actions';
import { LOADER_ID_ACTION } from 'src/app/app.component';
import {
  handleMutationError,
  handleQueryError,
} from 'src/app/core/graphql.helpers';
import { Router } from '@angular/router';
import { recordingsListLink } from '../../recordings-routing-helper';

@Injectable()
export class RecordingEntitiesEffects {
  constructor(
    private actions$: Actions,
    private store: Store<fromRecording.State>,
    private service: RecordingsService,
    private notifyService: NotifyService,
    private router: Router,
  ) {}

  // CREATE

  createRecording$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RecordingEntitiesActions.createRecording),
      map((action) => action.data),
      mergeMap((data) =>
        this.service.createRecording(data).pipe(
          map((recording: Recording) =>
          RecordingEntitiesActions.createRecordingSuccess({
              data: recording,
            })
          ),
          catchError((error) =>
            of(
              RecordingEntitiesActions.createRecordingFailure({
                error: error.message,
              })
            )
          )
        )
      )
    );
  });
  createRecordingSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RecordingEntitiesActions.createRecordingSuccess),
        tap(() =>
          this.notifyService.notify({
            type: NotificationType.Toast,
            message: 'Successfully created', // @TODO i18n
          })
        )
      ),
    { dispatch: false }
  );

  // READ

  loadRecordings$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RecordingEntitiesActions.loadRecordings),
      switchMap(() => {
        return this.service.loadRecordings().pipe(
          switchMap((data) => [
            RecordingEntitiesActions.loadRecordingsSuccess({ data }),
          ]),
          catchError((error) => {
            return of(
              RecordingEntitiesActions.loadRecordingsFailure({
                error: error.message,
              })
            );
          })
        );
      })
    );
  });

  loadRecordingsByFormat$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RecordingEntitiesActions.loadRecordingsByFormat),
      map((action) => action.formatId),
      switchMap((formatId) => {
        return this.service.loadRecordingsByFormat(formatId).pipe(
          switchMap((data) => [
            RecordingEntitiesActions.loadRecordingsByFormatSuccess({ data }),
          ]),
          catchError((error) => {
            return of(
              RecordingEntitiesActions.loadRecordingsByFormatFailure({
                error: error.message,
              })
            );
          })
        );
      })
    );
  });

  loadRecordingsByCurrentFormat$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RecordingEntitiesActions.loadRecordingsByCurrentFormat),
      withLatestFrom(this.store.select(getEntitiesCurrentFormatId)),
      map(([, formatId]) => formatId),
      switchMap((formatId) => {
        return this.service.loadRecordingsByFormat(formatId).pipe(
          switchMap((data) => [
            RecordingEntitiesActions.loadRecordingsByFormatSuccess({ data }),
          ]),
          catchError((error) => {
            return of(
              RecordingEntitiesActions.loadRecordingsByFormatFailure({
                error: error.message,
              })
            );
          })
        );
      })
    );
  });

  loadRecordingsbyFilter$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RecordingEntitiesActions.loadRecordingsByFilter),
      map((action) => action.filter),
      switchMap((filter) => {
        return this.service.loadRecordingsByFilter(filter).pipe(
          switchMap((data) => [
            RecordingEntitiesActions.loadRecordingsByFilterSuccess({ data }),
          ]),
          catchError((error) => {
            return of(
              RecordingEntitiesActions.loadRecordingsByFilterFailure({
                error: error.message,
              })
            );
          })
        );
      })
    );
  });

  loadCurrentRecording$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RecordingEntitiesActions.loadCurrentRecording),
      withLatestFrom(
        this.store.select(RecordingSelectors.getEntitiesCurrentRecordingId)
      ),
      switchMap(([, id]) => {
        if (!id) {
          return of(RecordingEntitiesActions.loadRecordingFailure({ error: {} }));
        }
        return this.service.loadRecording(id).pipe(
          switchMap((data) => [
            RecordingEntitiesActions.loadRecordingSuccess({ data }),
          ]),
          catchError((error) => {
            return of(
              RecordingEntitiesActions.loadRecordingFailure({
                error: error.message,
              })
            );
          })
        );
      })
    );
  });

  // UPDATE

  updateRecording$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RecordingEntitiesActions.updateRecording),
      map((action) => action.data),
      mergeMap((data) =>
        of(data).pipe(
          // TODO can this be improved
          withLatestFrom(
            this.store.select(RecordingSelectors.getEntitiesRecording(data.id))
          ),
          switchMap(([action, oldRecording]) => {
            return this.service.updateRecording(data, oldRecording).pipe(
              map((recording: Recording) =>
              RecordingEntitiesActions.updateRecordingSuccess({
                  data: recording,
                })
              ),
              catchError((error) =>
                of(
                  RecordingEntitiesActions.updateRecordingFailure({
                    error: error.message,
                  })
                )
              )
            );
          })
        )
      )
    );
  });
  updateRecordingSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RecordingEntitiesActions.updateRecordingSuccess),
        tap(() =>
          this.notifyService.notify({
            type: NotificationType.Toast,
            message: 'Successfully updated', // @TODO i18n
          })
        )
      ),
    { dispatch: false }
  );

  // DELETE

  deleteRecording$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RecordingEntitiesActions.deleteRecording),
      map((action) => action.id),
      mergeMap((id) =>
        this.service.deleteRecording(id).pipe(
          map(() => RecordingEntitiesActions.deleteRecordingSuccess({ id })),
          catchError((error) =>
            of(
              RecordingEntitiesActions.deleteRecordingFailure({
                error: error.message,
              })
            )
          )
        )
      )
    );
  });
  deleteRecordingSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RecordingEntitiesActions.deleteRecordingSuccess),
        tap(() => {
          this.notifyService.notify({
            type: NotificationType.Toast,
            message: 'Successfully deleted',
          });
          this.router.navigate(recordingsListLink());
        })
      ),
    { dispatch: false }
  );

  // FAILURES

  queryFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          RecordingEntitiesActions.loadRecordingsFailure,
          RecordingEntitiesActions.loadRecordingsByFormatFailure,
          RecordingEntitiesActions.loadRecordingsByFilterFailure,
          RecordingEntitiesActions.loadRecordingFailure
        ),
        map((action) => action.error),
        tap((error) => handleQueryError(error, this.notifyService, this.store))
      ),
    { dispatch: false }
  );

  mutationFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          RecordingEntitiesActions.createRecordingFailure,
          RecordingEntitiesActions.updateRecordingFailure
        ),
        map((action) => action.error),
        tap((error) =>
          handleMutationError(error, this.notifyService, this.store)
        )
      ),
    { dispatch: false }
  );

  // LOADERS

  loaderStart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        RecordingEntitiesActions.createRecording,
        RecordingEntitiesActions.updateRecording
      ),
      mergeMap(() => of(loaderStart({ id: LOADER_ID_ACTION })))
    )
  );

  loaderStop$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        RecordingEntitiesActions.createRecordingSuccess,
        RecordingEntitiesActions.createRecordingFailure,
        RecordingEntitiesActions.updateRecordingSuccess,
        RecordingEntitiesActions.updateRecordingFailure
      ),
      mergeMap(() => of(loaderStop({ id: LOADER_ID_ACTION })))
    )
  );
}
