<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <input type="hidden" id="id" formControlName="id" />

  <div class="u-bgcolor-white u-pv-48 u-ph-16">
    <mat-form-field appearance="fill" style="width: 50%">
      <mat-label>Roles</mat-label>
      <mat-select formControlName="roles" multiple
        ><mat-option [value]="role" *ngFor="let role of allRoles">{{
          role
        }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <br />

  <app-loader-wrapper [active]="actionLoaderActive" style="height: 100%">
    <div class="u-flex u-jc-end">
      <button
        mat-fab
        extended
        color="primary"
        type="submit"
        [disabled]="form.invalid || disabled"
      >
        Speichern
      </button>
    </div>
  </app-loader-wrapper>
</form>
