import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { WaitingListEntry } from 'src/app/core/graphql.model';
import * as WaitingListEntitiesActions from './waiting-list-entities.actions';

export const waitingListEntitiesFeatureKey = 'entities';

export interface State extends EntityState<WaitingListEntry> {}

export const adapter: EntityAdapter<WaitingListEntry> =
  createEntityAdapter<WaitingListEntry>();

export const initialState: State = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,

  // CREATE

  on(
    WaitingListEntitiesActions.createWaitingListEntrySuccess,
    (state, action) => adapter.upsertOne(action.data, state)
  ),

  // READ

  on(WaitingListEntitiesActions.loadWaitingListSuccess, (state, action) =>
    adapter.setAll(action.data, state)
  ),

  // DELETE

  on(
    WaitingListEntitiesActions.deleteWaitingListEntrySuccess,
    (state, action) => adapter.removeOne(action.id, state)
  ),

  on(
    WaitingListEntitiesActions.deleteWaitingListEntryFailure,
    (state, action) => {
      return {
        ...state,
        error: action.error,
      };
    }
  )
);
