<div class="o-container u-height-1/1 u-flex u-fd-col u-pb-24 u-pt-12">
  <h1 class="o-type-h4 u-mb-24">
    {{ "FORMATS.FORMATS_PAGE.TITLE" | translate }}
  </h1>

  <app-formats-list
    [columnDefs]="(facade.listOverview$ | async)?.columnDefs"
    [data]="(facade.formats$ | async) || []"
    [filter]="(facade.listOverview$ | async)?.config.filter"
    [columnsState]="(facade.listOverview$ | async)?.config.columnsState"
  >
  </app-formats-list>

  <div class="c-floating-button-wrapper">
    <button
      mat-fab
      color="primary"
      matTooltip="Neues Format anlegen"
      matTooltipShowDelay="500"
      *ngIf="'create' | able: 'Format'"
      [routerLink]="formatCreateLink"
    >
      <mat-icon>add</mat-icon>
    </button>
  </div>
</div>
