<div *ngIf="!params.node.group">
<!--  <a-->
<!--    [routerLink]="link"-->
<!--    (click)="onDetails($event)"-->
<!--  >-->
<!--    <mat-icon>info</mat-icon>-->
<!--  </a>-->
  <button
    mat-icon-button
    color="primary"
    aria-label="Kunden Detailansicht anzeigen"
    matTooltip="Kunden Detailansicht anzeigen"
    matTooltipShowDelay="500"
    [routerLink]="link"
    (click)="onDetails($event)"
  >
    <mat-icon>arrow_circle_right</mat-icon>
  </button>
</div>
