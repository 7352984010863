import { IDetailCellRendererParams } from 'ag-grid-community';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Component } from '@angular/core';
import { AdminBooking } from 'src/app/core/graphql.model';

@Component({
  selector: 'app-bookings-detail-cell-renderer',
  templateUrl: './bookings-detail-cell-renderer.component.html',
  styleUrls: ['./bookings-detail-cell-renderer.component.scss'],
})
export class BookingsDetailCellRendererComponent
  implements ICellRendererAngularComp
{
  params: IDetailCellRendererParams;
  bookings: AdminBooking[];

  agInit(params: IDetailCellRendererParams): void {
    this.params = params;
  }

  constructor() {}

  refresh(params: IDetailCellRendererParams): boolean {
    this.params = params;
    return true;
  }
}
