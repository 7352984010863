export function sanatisedCopy<T extends object>(data: any, keys: string[]): T {
  const sanitized = {};
  for (const p of keys) {
    if (p in data) {
      // @ts-ignore
      sanitized[p] = data[p];
    } else {
      // @ts-ignore
      sanitized[p] = undefined;
    }
  }
  return sanitized as T;
}
