import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromApp from './app.reducer';

export const selectAppState = createFeatureSelector<fromApp.AppState>(
  'app' // @TODO
);

export const isLoaderActive = (id: string) =>
  createSelector(selectAppState, (state) => state.activeLoaders.includes(id));

export const getActiveLoaders = createSelector(
  selectAppState,
  (state) => state.activeLoaders
);
