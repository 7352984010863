import { Injectable } from '@angular/core';
import {
  FormControl,
  Validators,
  FormBuilder,
  FormGroup,
} from '@angular/forms';
import { ProductVariant } from 'src/app/core/graphql.model';
import { BookingParticipantForm } from '../booking-form/booking-form.model';

@Injectable()
export class BookingParticipantFormService {
  form: FormGroup = this.createForm();
  options: ProductVariant[];

  set bookingParticipant(bookingParticipant: BookingParticipantForm) {
    if (!bookingParticipant) {
      return;
    }
    this.options = bookingParticipant.options;
    const options = new FormGroup({});

    this.options.forEach((option) => {
      options.addControl(option.id, new FormControl(false));
    });

    this.form.setControl('options', options);

    const { options: _, ...rest } = bookingParticipant;

    this.form.patchValue(rest);
  }

  constructor(private fb: FormBuilder) {}

  private createForm(): FormGroup {
    return this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      birthDate: ['', [Validators.required]],
      options: {},
    });
    // @TODO check required and validators
  }
}
