import { Component } from '@angular/core';

import { WaitingListsFacadeService } from 'src/app/waiting-lists/waiting-lists-facade.service';

@Component({
  selector: 'app-format-waiting-lists-tab',
  templateUrl: './format-waiting-lists-tab.component.html',
  styleUrls: ['./format-waiting-lists-tab.component.scss'],
})
export class FormatWaitingListsTabComponent {
  constructor(public facade: WaitingListsFacadeService) {}
}
