import { UsersPageComponent } from './pages/users-page/users-page.component';
import { NotFoundPageComponent } from './../core/errors/not-found-page/not-found-page.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UsersResolver } from './users.resolver';
import { CurrentUserResolver } from './user-current.resolver';
import { UserPageComponent } from './pages/user-page/user-page.component';
import { ROUTE_PARAM_USER_ID } from '../core/route-params';
import { RoutesNames } from './users-routing-helper';

const routes: Routes = [
  // permission is checked on parent page already
  {
    // LIST
    path: RoutesNames.LIST,
    component: UsersPageComponent,
    pathMatch: 'full',
    resolve: { ready: UsersResolver },
  },
  {
    // EDIT
    path: `:${ROUTE_PARAM_USER_ID}`,
    component: UserPageComponent,
    resolve: {
      user: CurrentUserResolver,
    },
  },
  {
    path: '**',
    component: NotFoundPageComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UsersRoutingModule {}
