import { Store } from '@ngrx/store';
import { BaseResolver } from '../core/base/base.resolver';
import { Actions } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import * as ListConfigsActions from './store/list-config.actions';

@Injectable({
  providedIn: 'root',
})
export class ListConfigsResolver extends BaseResolver {
  requestAction = ListConfigsActions.entities.loadListConfigs;
  successAction = ListConfigsActions.entities.loadListConfigsSuccess;
  failureAction = ListConfigsActions.entities.loadListConfigsFailure;
  constructor(protected store: Store, protected actions$: Actions) {
    super();
  }
}
