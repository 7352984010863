<div class="o-container u-height-1/1 u-flex u-fd-col u-pb-24 u-pt-12">
  <h1 class="o-type-h4 u-mb-24">
    {{ "FORMATS.FORMAT_CREATE_PAGE.TITLE" | translate }}
  </h1>
  <app-loader-wrapper [active]="actionLoaderActive$ | async" style="height: 100%">

    <app-format-form (formSubmit)="onSubmitCreate($event)"> </app-format-form>
    <!-- @TODO -->
    <!-- <button class="btn__add-margin" mat-flat-button color="accent" type="button">
        add another Format
      </button>-->
  </app-loader-wrapper>
</div>
