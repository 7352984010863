import { CustomerConnection } from '../../../core/graphql.model';
import {
  getEmptyListConfigConfig,
  ListConfigConfig,
  SsrmState,
} from '../../../core/list/list.model';
import { createReducer, on } from '@ngrx/store';
import * as CustomerListsActions from './customer-lists.actions';
import { ColDef } from 'ag-grid-community';

export const customerListsFeatureKey = 'lists';

export interface State {
  overview: {
    columnDefs: ColDef[];
    ssrm: SsrmState<CustomerConnection>;
    config: ListConfigConfig;
  };
}

export const initialState: State = {
  overview: {
    columnDefs: [],
    ssrm: {},
    config: getEmptyListConfigConfig(),
  },
};

export const reducer = createReducer(
  initialState,
  on(CustomerListsActions.init, (state, action) => ({
    ...state,
    overview: {
      ...state.overview,
      columnDefs: action.columnDefs,
    },
  })),

  on(CustomerListsActions.pushConfig, (state, action) => ({
    ...state,
    overview: {
      ...state.overview,
      config: {
        ...state.overview.config,
        ...action.config,
      },
      ssrm: action.resetSsrm ? {} : state.overview.ssrm,
    },
  })),

  on(CustomerListsActions.pullConfig, (state, action) => ({
    ...state,
    overview: {
      ...state.overview,
      config: action.config,
    },
  })),

  on(CustomerListsActions.pullConfigNewBlock, (state) => ({
    ...state,
    overview: {
      ...state.overview,
      config: {
        ...state.overview.config,
        selection: [...state.overview.config.selection],
      },
    },
  })),

  on(CustomerListsActions.loadCustomerConnection, (state, action) => ({
    ...state,
    overview: {
      ...state.overview,
      ssrm: {
        blocks: { ...state.overview.ssrm.blocks },
      },
    },
  })),
  on(CustomerListsActions.loadCustomerConnectionSuccess, (state, action) => ({
    ...state,
    overview: {
      ...state.overview,
      ssrm: {
        ...state.overview.ssrm,
        lastRequest: action.request,
        lastResult: action.result,
        blocks: {
          ...state.overview.ssrm.blocks,
          [action.request.startRow]: {
            endCursor: action.result.pageInfo.endCursor,
          },
        },
      },
    },
  })),
  on(CustomerListsActions.loadCustomerConnectionFailure, (state, action) => ({
    ...state,
    overview: {
      ...state.overview,
      ssrm: {
        lastRequest: action.request,
        ...state.overview.ssrm,
        lastError: action.error,
      },
    },
  }))
);
