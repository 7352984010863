<mat-accordion>
  <!-- belongs into expasnion-panel but also works without
    [expanded]="true"-->
  <mat-expansion-panel>
    <mat-expansion-panel-header class="dropdown__container expansion-indicator-notVisible">
      <div class="u-17/36">
        <mat-form-field class="u-1/1">
          <mat-label for="vat"> Produktname </mat-label>
          <mat-select
            (click)="onClickStopPropagation($event)"
            class="ticketOption__bar"
            name="ticketOptions"
            placeholder="wähle Ticketart"
            [(ngModel)]="selectedProductId"
          >
            <mat-option *ngFor="let product of products" [value]="product.id">
              {{ product.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="u-13/36 u-ml-16">
        <mat-form-field class="u-1/1">
          <mat-label for="vat"> Ticket Status </mat-label>
          <mat-select
            (click)="onClickStopPropagation($event)"
            class="bookingOption__bar"
            name="bookingOptions"
            id="bookingType"
            placeholder="Multiselect"
          >
            <mat-option
              *ngFor="let bookingOption of bookingOptions"
              [value]="bookingOption.selectedValue"
            >
              {{ bookingOption.viewValue }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="c-formfield-placeholder u-inline-flex u-ml-auto">
        <button
          mat-mini-fab
          id="addProductVariant"
          color="accent"
          type="button"
          [disabled]="!selectedProductId"
          (click)="onAddProductVariant()"
        >
          <mat-icon>add</mat-icon>
        </button>
      </div>

    </mat-expansion-panel-header>
  </mat-expansion-panel>
</mat-accordion>
