import { createAction, props } from '@ngrx/store';
import { Tag } from 'src/app/core/graphql.model';
import { Id } from 'src/app/core/model';

// CREATE

export const createTag = createAction(
  '[Tag] Create',
  props<{ data: Tag }>()
);
export const createTagSuccess = createAction(
  '[Tag] Create Success',
  props<{ data: Tag }>()
);
export const createTagFailure = createAction(
  '[Tag] Create Failure',
  props<{ error: any }>()
);

// READ

export const loadTags = createAction('[Tag] Load All');

export const loadTagsSuccess = createAction(
  '[Tag] Load All Success',
  props<{ data: Tag[] }>()
);
export const loadTagsFailure = createAction(
  '[Tag] Load All Failure',
  props<{ error: any }>()
);

// UPDATE

// DELETE

export const deleteTag = createAction(
  '[Tag] Delete',
  props<{ id: Id }>()
);
export const deleteTagSuccess = createAction(
  '[Tag] delete Success',
  props<{ id: Id }>()
);
export const deleteTagFailure = createAction(
  '[Tag] Delete Failure',
  props<{ error: any }>()
);
