import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Injectable()
export class GlobalSearchformService {
  form: FormGroup = this.createForm();

  constructor(private fb: FormBuilder) {}

  private createForm(): FormGroup {
    return this.fb.group({
      searchWhat: [''],
      searchWho: [''],
      searchWhere: [''],
      searchWhithout: [''],
      searchDateStart: [''],
      searchDateEnd: [''],
    });
    // @TODO check required and validators
  }
}
