import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';

interface SearchOption {
  searchValue: string;
  viewValue: string;
}

@Component({
  selector: 'app-cascading-searchform-page',
  templateUrl: './cascading-searchform-page.component.html',
  styleUrls: ['./cascading-searchform-page.component.scss'],
})
export class CascadingSearchformPageComponent {
  form: FormGroup;
  selectedValue: string;
  default: string = 'Choose Form';

  searchOptions: SearchOption[] = [
    { searchValue: 'Kunde', viewValue: 'Kunden' },
    { searchValue: 'Formate', viewValue: 'Formate' },
    { searchValue: 'Aufzeichnung', viewValue: 'Aufzeichnungen' },
    { searchValue: 'Global', viewValue: 'Globalsuche' },
  ];

  constructor() {}
}
