import {
  CustomFilterMappers,
  CombinedFilter,
  mapFilter,
} from './../../../core/list/connection-filter-helper';
import { Injectable } from '@angular/core';
import {
  ColDef,
  ValueFormatterParams,
  ValueGetterParams,
} from 'ag-grid-community';
import {
  Address,
  ContactOption,
  ContactOptionType,
  Salutation,
} from 'src/app/core/graphql.model';
import { TranslateService } from '@ngx-translate/core';
import {
  BaseColumnIds,
  BaseColumnDefsService,
} from 'src/app/core/list/base-column-defs-service';
import {
  COUNTRIES_DB_DE,
  Country,
} from '@angular-material-extensions/select-country';

export enum CustomerColumnIds {
  SALUTATION = 'salutation',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  BIRTH_DATE = 'birthDate',
  COMPANY = 'company',
  DEFAULT_EMAIL = 'defaultEmail',
  DEFAULT_PHONE = 'defaultPhone',
  PRIMARY_FULL_ADDRESS = 'primaryFullAddress',
  PRIMARY_ADDRESS = 'primaryAddress',
  PRIMARY_POST_CODE = 'primaryPostCode',
  PRIMARY_CITY = 'primaryCity',
  PRIMARY_COUNTRY = 'primaryCountry',
}

@Injectable({
  providedIn: 'root',
})
export class CustomerListColumnDefsService extends BaseColumnDefsService {
  columnDefsMap = new Map<string, ColDef>([
    [
      BaseColumnIds.SELECT,
      {
        ...this.baseColumnDefsMap.get(BaseColumnIds.SELECT),
        headerCheckboxSelection: false,
      }
    ],
    [
      BaseColumnIds.ID,
      this.baseColumnDefsMap.get(BaseColumnIds.ID),
    ],
    [
      CustomerColumnIds.SALUTATION,
      {
        field: 'salutation',
        headerName: this.translate.instant('CUSTOMERS.COLUMNS.SALUTATION'),
        valueFormatter: (params: ValueFormatterParams) =>
          this.translate.instant(`ENUM.SALUTATION.${params.value}`),
        filter: 'agSetColumnFilter',
        filterParams: {
          values: Object.values(Salutation),
          valueFormatter: (params: ValueFormatterParams) =>
            this.translate.instant(`ENUM.SALUTATION.${params.value}`),
        },
      },
    ],
    [
      CustomerColumnIds.FIRST_NAME,
      {
        field: 'firstName',
        headerName: this.translate.instant('CUSTOMERS.COLUMNS.FIRST_NAME'),
      },
    ],
    [
      CustomerColumnIds.LAST_NAME,
      {
        field: 'lastName',
        headerName: this.translate.instant('CUSTOMERS.COLUMNS.LAST_NAME'),
      },
    ],
    [
      CustomerColumnIds.BIRTH_DATE,
      {
        field: 'birthDate',
        headerName: this.translate.instant('CUSTOMERS.COLUMNS.BIRTH_DATE'),
        type: 'dateColumn',
      },
    ],
    [
      CustomerColumnIds.COMPANY,
      {
        field: 'company',
        headerName: this.translate.instant('CUSTOMERS.COLUMNS.COMPANY'),
      },
    ],
    [
      CustomerColumnIds.DEFAULT_EMAIL,
      {
        headerName: this.translate.instant('CUSTOMERS.COLUMNS.DEFAULT_EMAIL'),
        valueGetter: (params: ValueGetterParams) => {
          return (params.data?.contactOptions as ContactOption[])?.find(
            (contactOption: ContactOption) =>
              contactOption.type === ContactOptionType.EMAIL &&
              contactOption.prio === 0
          )?.value;
        },
      },
    ],
    [
      CustomerColumnIds.DEFAULT_PHONE,
      {
        headerName: this.translate.instant('CUSTOMERS.COLUMNS.DEFAULT_PHONE'),
        valueGetter: (params: ValueGetterParams) => {
          return (params.data?.contactOptions as ContactOption[])?.find(
            (contactOption: ContactOption) =>
              contactOption.type === ContactOptionType.PHONE &&
              contactOption.prio === 0
          )?.value;
        },
      },
    ],
    [
      CustomerColumnIds.PRIMARY_FULL_ADDRESS,
      {
        headerName: this.translate.instant('CUSTOMERS.COLUMNS.PRIMARY_FULL_ADDRESS'),
        filter: false,
        hide: true,
        valueGetter: (params: ValueGetterParams) => {
          const primaryAddress = (params.data.addresses as Address[]).find(
            (address: Address) => address.prio === 0
          );
          if (!primaryAddress) {
            return '';
          }
          return `${primaryAddress.address}, ${primaryAddress.postCode} ${primaryAddress.city}, ${primaryAddress.country}`;
        },
      },
    ],
    [
      CustomerColumnIds.PRIMARY_ADDRESS,
      {
        headerName: this.translate.instant('CUSTOMERS.COLUMNS.PRIMARY_ADDRESS'),
        valueGetter: (params: ValueGetterParams) => {
          const primaryAddress = (params.data.addresses as Address[]).find(
            (address: Address) => address.prio === 0
          );
          if (!primaryAddress) {
            return '';
          }
          return primaryAddress.address;
        },
      },
    ],
    [
      CustomerColumnIds.PRIMARY_POST_CODE,
      {
        headerName: this.translate.instant('CUSTOMERS.COLUMNS.PRIMARY_POST_CODE'),
        valueGetter: (params: ValueGetterParams) => {
          const primaryAddress = (params.data.addresses as Address[]).find(
            (address: Address) => address.prio === 0
          );
          if (!primaryAddress) {
            return '';
          }
          return primaryAddress.postCode;
        },
      },
    ],
    [
      CustomerColumnIds.PRIMARY_CITY,
      {
        headerName: this.translate.instant('CUSTOMERS.COLUMNS.PRIMARY_CITY'),
        valueGetter: (params: ValueGetterParams) => {
          const primaryAddress = (params.data?.addresses as Address[])?.find(
            (address: Address) => address.prio === 0
          );
          if (!primaryAddress) {
            return '';
          }
          return primaryAddress.city;
        },
      },
    ],
    [
      CustomerColumnIds.PRIMARY_COUNTRY,
      {
        headerName: this.translate.instant('CUSTOMERS.COLUMNS.PRIMARY_COUNTRY'),
        filter: 'agSetColumnFilter',
        filterParams: {
          values: COUNTRIES_DB_DE.map((country: Country) => country.alpha2Code),
        },
        valueGetter: (params: ValueGetterParams) => {
          const primaryAddress = (params.data.addresses as Address[]).find(
            (address: Address) => address.prio === 0
          );
          if (!primaryAddress) {
            return '';
          }
          return primaryAddress.country;
        },
      },
    ],
    [
      BaseColumnIds.ACTIONS,
      this.baseColumnDefsMap.get(BaseColumnIds.ACTIONS),
    ],
  ]);

  columnDefs: ColDef[] = Array.from(this.columnDefsMap.keys())
    .map((key) => ({
      colId: key,
      ...this.columnDefsMap.get(key),
    }))
    // sort is not yet implemented for connections
    .map((col) => ({
      ...col,
      sortable: false,
      filterParams: {
        ...col.filterParams,
        suppressAndOrCondition: true,
      },
    }));

  customFilterMappers: CustomFilterMappers = {
    defaultEmail: (filter: any) =>
      ({
        AND: [
          {
            contactOptions: {
              some: {
                type: { equals: ContactOptionType.EMAIL },
                prio: { equals: 0 },
                ...mapFilter('value', filter), // via AND
              },
            },
          },
        ],
      } as CombinedFilter<any>),
    defaultPhone: (filter: any) =>
      ({
        AND: [
          {
            contactOptions: {
              some: {
                type: { equals: ContactOptionType.PHONE },
                prio: { equals: 0 },
                ...mapFilter('value', filter), // via AND
              },
            },
          },
        ],
      } as CombinedFilter<any>),
    primaryAddress: (filter: any) =>
      ({
        AND: [
          {
            addresses: {
              some: {
                prio: { equals: 0 },
                ...mapFilter('address', filter), // via AND
              },
            },
          },
        ],
      } as CombinedFilter<any>),
    primaryPostCode: (filter: any) =>
      ({
        AND: [
          {
            addresses: {
              some: {
                prio: { equals: 0 },
                ...mapFilter('postCode', filter), // via AND
              },
            },
          },
        ],
      } as CombinedFilter<any>),
    primaryCity: (filter: any) =>
      ({
        AND: [
          {
            addresses: {
              some: {
                prio: { equals: 0 },
                ...mapFilter('city', filter), // via AND
              },
            },
          },
        ],
      } as CombinedFilter<any>),
    primaryCountry: (filter: any) =>
      ({
        AND: [
          {
            addresses: {
              some: {
                prio: { equals: 0 },
                ...mapFilter('country', filter), // via AND
              },
            },
          },
        ],
      } as CombinedFilter<any>),
  };

  constructor(protected translate: TranslateService) {
    super(translate);
  }
}
