import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from './../../../environments/environment';
import { AppEffects } from './app.effects';
import { metaReducers, ROOT_REDUCERS } from './app.reducer';
import { NgrxRouterStoreModule } from './router/ngrx-router.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    EffectsModule.forRoot([AppEffects]),
    StoreModule.forRoot(ROOT_REDUCERS, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
        strictStateSerializability: true,
        strictActionSerializability: false,
      },
    }),
    StoreDevtoolsModule.instrument({ logOnly: environment.production }),
    NgrxRouterStoreModule,
  ],
})
export class AppStoreModule {}
