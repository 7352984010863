import { ListConfig } from '../../list-configs.model';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as ListConfigEntitiesActions from './list-config-entities.actions';

export const listConfigEntitiesFeatureKey = 'entities';

export interface State extends EntityState<ListConfig> {}

export const adapter: EntityAdapter<ListConfig> =
  createEntityAdapter<ListConfig>();

export const initialState: State = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,

  // CREATE

  on(ListConfigEntitiesActions.createListConfigSuccess, (state, action) =>
    adapter.upsertOne(action.data, state)
  ),

  // READ

  on(ListConfigEntitiesActions.loadListConfigsSuccess, (state, action) =>
    adapter.setAll(action.data, state)
  ),

  // UPDATE

  on(ListConfigEntitiesActions.updateListConfigSuccess, (state, action) =>
    adapter.upsertOne(action.data, state)
  ),

  // DELETE

  on(ListConfigEntitiesActions.deleteListConfigSuccess, (state, action) =>
    adapter.removeOne(action.id, state)
  ),

  on(ListConfigEntitiesActions.deleteListConfigFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  })
);
