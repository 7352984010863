import {
  ChangeDetectionStrategy,
  Component,
} from '@angular/core';
import { BookingsFacadeService } from 'src/app/bookings/bookings-facade.service';
import { CustomersFacadeService } from '../../customers-facade.service';
import {
  Address,
  AdminBooking,
  CreateBillingAddressInput,
} from 'src/app/core/graphql.model';
import { DeepPartial, DraftOrderState } from 'src/app/core/model';
import { Observable } from 'rxjs';
import { AppFacadeService } from 'src/app/app-facade.service';
import { LOADER_ID_ACTION } from 'src/app/app.component';
import { Location } from '@angular/common';

@Component({
  selector: 'app-customer-create-booking-page',
  templateUrl: './customer-create-booking-page.component.html',
  styleUrls: ['./customer-create-booking-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomerCreateBookingPageComponent {
  actionLoaderActive$: Observable<boolean>;

  DraftOrderState = DraftOrderState;

  constructor(
    public customersFacade: CustomersFacadeService,
    public bookingsFacade: BookingsFacadeService,
    public appFacade: AppFacadeService,
    private location: Location,
  ) {
    this.actionLoaderActive$ = this.appFacade.isLoaderActive$(LOADER_ID_ACTION);
  }

  onNewAddress(address: Address): void {
    this.customersFacade.currentCustomer$.subscribe((customer) => {
      this.customersFacade.updateCustomer({
        ...customer,
        addresses: [...customer.addresses, address],
      });
    });
  }

  onSubmitCreateBooking(data: {
    booking: DeepPartial<AdminBooking>;
    address: CreateBillingAddressInput;
  }): void {
    this.bookingsFacade.createBooking(data.booking, data.address);
  }

  onAddCouponCode(couponCode: string) {
    this.bookingsFacade.addCouponCode(couponCode)
  }

  onCancel() {
    this.location.back()
  }

  onComplete() {
    this.bookingsFacade.completeDraftBooking()
  }

}
