import { Component } from '@angular/core';
import {
  CreateWaitingListEntryInput,
  Recording,
} from 'src/app/core/graphql.model';
import { Id } from 'src/app/core/model';
import { FormatsFacadeService } from 'src/app/formats/formats-facade.service';
import { RecordingsFacadeService } from 'src/app/recordings/recordings-facade.service';
import { WaitingListsFacadeService } from 'src/app/waiting-lists/waiting-lists-facade.service';

@Component({
  selector: 'app-customer-waiting-lists-tab',
  templateUrl: './customer-waiting-lists-tab.component.html',
  styleUrls: ['./customer-waiting-lists-tab.component.scss'],
})
export class CustomerWaitingListsTabComponent {
  selectedFormatId: Id;
  selectedRecording: Recording;

  constructor(
    public waitingListsFacade: WaitingListsFacadeService,
    public recordingsFacade: RecordingsFacadeService,
    public formatsFacade: FormatsFacadeService
  ) {}

  onFormatChange(id: Id): void {
    this.selectedFormatId = id;
    this.recordingsFacade.loadRecordingsByFormat(this.selectedFormatId);
  }

  onCreateWaitingListEntry(input: CreateWaitingListEntryInput): void {
    this.waitingListsFacade.createWaitingListEntry(input);
  }
}
