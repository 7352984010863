import { Format } from 'src/app/core/graphql.model';
import { Id } from 'src/app/core/model';
import { ROUTE_PARAM_FORMAT_ID } from 'src/app/core/route-params';
import { MergedRoute } from 'src/app/core/store/router/merged-route';
import { State } from './format-entities.reducer';

export const getFormats = (state: State) =>
  Object.values(state.entities).filter((format): format is Format => !!format);

export const getCurrentFormatId = (mergedRoute: MergedRoute) =>
  +mergedRoute?.params[ROUTE_PARAM_FORMAT_ID];

export const getFormat = (state: State, id: Id) => state.entities[id];
