
  <ng-container [formGroup]="form">
    <input type="hidden" [id]="'id-' + idx" formControlName="id" />
    <input type="hidden" [id]="'version-' + idx" formControlName="version" />
    <input type="hidden" [id]="'prio-' + idx" formControlName="prio" />

      <div class="o-grid">
        <div class="o-grid__col u-6/32">
          <div class="c-formfield-placeholder u-jc-start">
            <mat-slide-toggle
              (change)="onToggleCompanyVisibility($event)"
              [checked]="companyVisible"
              [disabled]="disabled"
            >
              {{ "ADDRESS.ADDRESS_FORM.TOGGLE_COMPANY_VISIBILITY.LABEL" | translate }}
            </mat-slide-toggle>
          </div>
        </div>
        <div class="o-grid__col u-13/32">
            <ng-container *ngIf="companyVisible">
              <mat-form-field class="u-1/1">
                <mat-label [attr.for]="'company-' + idx">
                  {{ "ADDRESS.ADDRESS_FORM.COMPANY.LABEL" | translate }}
                </mat-label>
                <input
                  matInput
                  type="text"
                  [placeholder]="'ADDRESS.ADDRESS_FORM.COMPANY.PLACEHOLDER' | translate "
                  id="company"
                  formControlName="company"
                />
              </mat-form-field>
            </ng-container>
        </div>
      </div>

      <div class="o-grid">
        <div class="o-grid__col u-6/32">
          <!--Salutation -->
          <mat-button-toggle-group
            formControlName="salutation"
            aria-label="Salutation"
            class="u-1/1"
          >
            <mat-button-toggle class="u-1/1" [value]="Salutation.MRS">Frau</mat-button-toggle>
            <mat-button-toggle class="u-1/1" [value]="Salutation.MR">Herr</mat-button-toggle>
            <mat-button-toggle class="u-1/1" [value]="Salutation.THEY">Divers</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
        <!--First Name -->
        <div class="o-grid__col u-13/32">
          <mat-form-field class="u-1/1">
            <mat-label [attr.for]="'firstName-' + idx">
              {{ "ADDRESS.ADDRESS_FORM.FIRST_NAME.LABEL" | translate }}
            </mat-label>
            <input
              matInput
              type="text"
              [placeholder]="'ADDRESS.ADDRESS_FORM.FIRST_NAME.PLACEHOLDER' | translate"
              [id]="'firstName-' + idx"
              formControlName="firstName"
            />
          </mat-form-field>
        </div>
        <!--Last Name -->
        <div class="o-grid__col u-13/32">
          <mat-form-field class="u-1/1">
            <mat-label [attr.for]="'lastName-' + idx">
              {{ "ADDRESS.ADDRESS_FORM.LAST_NAME.LABEL" | translate }}
            </mat-label>
            <input
              matInput
              type="text"
              placeholder="Nachname"
              [id]="'lastName-' + idx"
              formControlName="lastName"
            />
          </mat-form-field>
        </div>
      </div>

    <!--Address Inputs -->
      <div class="o-grid u-fd-col">
        <div class="o-grid__col u-13/32">
          <mat-form-field class="u-1/1">
            <mat-label [attr.for]="'address-' + idx">
              {{ "ADDRESS.ADDRESS_FORM.ADDRESS.LABEL" | translate }}</mat-label>
            <input
              matInput
              type="text"
              [id]="'address-' + idx"
              formControlName="address"
            />
          </mat-form-field>
        </div>
        <div class="o-grid__col u-13/32">
          <mat-form-field class="u-1/1">
            <mat-label>
              {{
              "ADDRESS.ADDRESS_FORM.ADDITIONAL_ADDRESS_INFORMATION.LABEL"
                | translate
              }}
            </mat-label>
            <input
              matInput
              #message
              placeholder="Adresszusatz"
              formControlName="additionalAddressInformation"
            />
          </mat-form-field>
        </div>
      </div>

      <div class="o-grid">
        <div class="o-grid__col u-6/32">
          <mat-form-field class="u-1/1">
            <mat-label [attr.for]="'postCode-' + idx">
              {{ "ADDRESS.ADDRESS_FORM.POST_CODE.LABEL" | translate }}
            </mat-label>
            <input
              matInput
              type="text"
              [id]="'postCode-' + idx"
              formControlName="postCode"
            />
          </mat-form-field>
        </div>
        <div class="o-grid__col u-9/32">
          <mat-form-field class="u-1/1">
            <mat-label [attr.for]="'city-' + idx">
              {{ "ADDRESS.ADDRESS_FORM.CITY.LABEL" | translate }}
            </mat-label>
            <input
              matInput
              type="text"
              [id]="'city-' + idx"
              formControlName="city"
            />
          </mat-form-field>
        </div>
        <div class="o-grid__col u-9/32">
          <mat-form-field
            class="u-1/1">
            <mat-label [attr.for]="'state-' + idx">
              {{ "ADDRESS.ADDRESS_FORM.STATE.LABEL" | translate }}
            </mat-label>
            <input
              matInput
              type="text"
              [id]="'state-' + idx"
              formControlName="state"
            />
          </mat-form-field>
        </div>
        <div class="o-grid__col u-8/32">
          <mat-select-country
            class="u-1/1"
            appearance="fill"
            label="Land"
            formControlName="country"
            [id]="'country-' + idx"
          >
          </mat-select-country>
        </div>
      </div>

  </ng-container>
