import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RecordingsFacadeService } from '../../recordings-facade.service';
import { ROUTE_PARAM_RECORDING_ID } from 'src/app/core/route-params';
import {
  BaseTabPageComponent,
  LOADER_ID_TAB,
} from 'src/app/core/base/base-tab-page.component';
import { Link } from 'src/app/core/model';
import {
  recordingEditLinkBookings,
  recordingEditLinkData,
  recordingEditLinkWaitingList,
} from './recording-routing-helper';
import { Observable } from 'rxjs';
import { AppFacadeService } from 'src/app/app-facade.service';

@Component({
  selector: 'app-recording-page',
  templateUrl: './recording-page.component.html',
  styleUrls: ['./recording-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecordingPageComponent
  extends BaseTabPageComponent
  implements OnInit
{
  tabLinks = [] as Link[];
  tabLoaderActive$: Observable<boolean>;

  constructor(
    public facade: RecordingsFacadeService,
    public appFacade: AppFacadeService,
    protected router: Router,
    protected route: ActivatedRoute
  ) {
    super(router, route);
    this.tabLoaderActive$ = this.appFacade.isLoaderActive$(LOADER_ID_TAB);
  }

  ngOnInit(): void {
    const id = this.route.snapshot.params[ROUTE_PARAM_RECORDING_ID];
    this.tabLinks = [
      recordingEditLinkData(id),
      recordingEditLinkBookings(id),
      recordingEditLinkWaitingList(id),
    ];
    this.route.parent.url.subscribe(() => {
      this.pullTabIndex();
    });
  }
}
