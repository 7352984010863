import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as ListConfigsSelectors from './store/list-config.selectors';
import * as ListConfigActions from './store/list-config.actions';
import { ListConfig } from './list-configs.model';
import { Id } from '../core/model';

@Injectable({
  providedIn: 'root',
})
export class ListConfigsFacadeService {
  constructor(private store: Store) {}

  // SELECTORS

  ListConfigs$ = this.store.select(ListConfigsSelectors.getEntitiesListConfigs);
  listGeneral$ = this.store.select(ListConfigsSelectors.getListGeneral);

  // ENTITIES CREATE

  createListConfig = (listConfig: ListConfig) => {
    this.store.dispatch(
      ListConfigActions.entities.createListConfig({ data: listConfig })
    );
  };

  // ENTITIES READ

  loadListConfigs = () => {
    this.store.dispatch(ListConfigActions.entities.loadListConfigs());
  };

  // ENTITIES UPDATE

  updateListConfig = (listConfig: ListConfig) => {
    this.store.dispatch(
      ListConfigActions.entities.updateListConfig({ data: listConfig })
    );
  };

  updateListConfigByRoute = () => {
    this.store.dispatch(ListConfigActions.entities.updateListConfigByRoute());
  };

  // ENTITIES DELETE

  deleteListConfig = (id: Id) => {
    this.store.dispatch(ListConfigActions.entities.deleteListConfig({ id }));
  };
}
