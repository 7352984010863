import { User } from '@blab/blab-graphql';
import { createAction, props } from '@ngrx/store';
import { AuthenticationResult, Link } from 'src/app/core/model';

export const login = createAction('[User] Login');

export const loginToken = createAction('[User] Login Token');

export const loginTokenSuccess = createAction(
  '[User] Login Success Token',
  props<{ data: AuthenticationResult }>()
);
export const loginTokenFailure = createAction(
  '[User] Login Failure Token',
  props<{ error: any }>()
);

export const loginSuccess = createAction(
  '[User] Login Success',
  props<{ user: User }>()
);

export const updateAbilities = createAction(
  '[User] Update Abilities',
  props<{ rules: any, redirect?: Link }>() // @TODO get type of rules
);

export const updateAbilitiesSuccess = createAction(
  '[User] Update Abilities Success',
  props<{ rules: any, redirect?: Link }>() // @TODO get type of rules
);

export const getUser = createAction(
  '[User] Get User',
  props<{ isLogin: boolean }>()
);

export const getUserSuccess = createAction(
  '[User] Get User Success',
  props<{ data: User, isLogin: boolean }>()
);
export const getUserFailure = createAction(
  '[User] Get User Failure',
  props<{ error: any }>()
);

export const loginRedirect = createAction('[User] Login Redirect');

export const logout = createAction(
  '[User] Logout',
  props<{ soft: boolean }>() // soft means only in app, not azure
);
