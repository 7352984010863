import { Store } from '@ngrx/store';
import { BaseResolver } from './../core/base/base.resolver';
import { Actions } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import * as UsersActions from './store/user.actions';

@Injectable({
  providedIn: 'root',
})
export class UsersResolver extends BaseResolver {
  requestAction = UsersActions.entities.loadUsers;
  successAction = UsersActions.entities.loadUsersSuccess;
  failureAction = UsersActions.entities.loadUsersFailure;
  constructor(protected store: Store, protected actions$: Actions) {
    super();
  }
}
