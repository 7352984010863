"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Shopuser = void 0;
const casl_1 = require("../casl");
class Shopuser {
    can(builder) {
        builder.can(casl_1.Action.Create, casl_1.Subject.WaitingList);
        builder.can(casl_1.Action.Delete, casl_1.Subject.WaitingList);
        builder.can(casl_1.Action.Read, casl_1.Subject.WaitingList);
        return builder;
    }
}
exports.Shopuser = Shopuser;
