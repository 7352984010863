<!--<mat-accordion>
  <mat-expansion-panel [expanded]="true">
    <mat-expansion-panel-header>
      <mat-panel-title>Produkte auswählen: Schritt 2</mat-panel-title>
    </mat-expansion-panel-header>
  </mat-expansion-panel>
</mat-accordion> -->

<!-- @TODO either relocate to the accordion above or in the page-->
<!--
-->
<form [formGroup]="form">
  <div class="customer-container-border u-bgcolor-ocean-50 u-pt-20 u-pb-24 u-ph-16">
    <div class="o-type-overline u-uppercase u-mb-24">
      <mat-label>Produkt: </mat-label>
        {{_bookingParticipant.productVariant.product.name }}
    </div>
    <!-- @TODO add u-mb-48 only if ng:deep is not added -->
    <div class="u-mb-48">
      <!--First Name -->
      <mat-form-field>
        <mat-label for="firstName">
          {{ "CUSTOMERS.CUSTOMER_FORM.FIRST_NAME.LABEL" | translate }}
        </mat-label>
        <input
        matInput
        type="text"
        placeholder="Vorname"
        id="firstName"
        formControlName="firstName"
      />
        <mat-error>
          <ng-container [ngSwitch]="true">
            <div *ngSwitchCase="form.get('firstName').errors?.required">
              {{
                "CUSTOMERS.CUSTOMER_FORM.FIRST_NAME.ERRORS.REQUIRED"
                  | translate
              }}
            </div>
          </ng-container>
        </mat-error>
      </mat-form-field>
      &nbsp;
      <!--Last Name -->
      <mat-form-field>
        <mat-label for="lastName">
          {{ "CUSTOMERS.CUSTOMER_FORM.LAST_NAME.LABEL" | translate }}
        </mat-label>
        <input
          matInput
          type="text"
          placeholder="Nachname"
          id="lastName"
          formControlName="lastName"
        />
        <mat-error>
          <ng-container [ngSwitch]="true">
            <div *ngSwitchCase="form.get('lastName').errors?.required">
              {{
                "CUSTOMERS.CUSTOMER_FORM.LAST_NAME.ERRORS.REQUIRED"
                  | translate
              }}
            </div>
          </ng-container>
        </mat-error>
      </mat-form-field>
      &nbsp;
      <!--Birthdate-->
      <mat-form-field>
        <mat-label for="birthDate">
          {{ "CUSTOMERS.CUSTOMER_FORM.BIRTH_DATE.LABEL" | translate }}
        </mat-label>
        <input
          id="birthDate"
          matInput
          [matDatepicker]="picker"
          formControlName="birthDate"
        />
        <mat-datepicker-toggle matSuffix [for]="picker">
        </mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-error>
          <ng-container [ngSwitch]="true">
            <div *ngSwitchCase="form.get('birthDate').errors?.required">
              {{
                "CUSTOMERS.CUSTOMER_FORM.BIRTH_DATE.ERRORS.REQUIRED"
                  | translate
              }}
            </div>
          </ng-container>
        </mat-error>
      </mat-form-field>
      <!--@TODO delete later:  {{ form.get('birthDate').errors | json }}-->
    </div>
    <div class="checkbox__container-radius u-bgcolor-white-02 u-mb-24 u-pl-12 u-pv-4">
      <p class="o-type-body-small dummy-data">
        Zusatzoptionen
      </p>
<!--        <mat-checkbox class="u-mr-10">-->
<!--          Studioführung-->
<!--        </mat-checkbox>-->
<!--        <mat-checkbox class="checkbox2-margin">-->
<!--          Rollstuhlfahrerplatz-->
<!--        </mat-checkbox>-->
      <div formGroupName="options">
        <div *ngFor="let option of formService.options">
            <input type="checkbox" [formControlName]="option.id">
            <span>{{option.name}}</span>
        </div>
      </div>

    </div>

<!--      <div class="u-flex u-mt-10 u-p-10">-->
<!--        <mat-label for="conditions"> Bedingungen: </mat-label>-->
<!--        &nbsp;-->
<!--        <mat-chip-list>-->
<!--          <mat-chip selected color="accent"> Corona Impfung </mat-chip>-->
<!--        </mat-chip-list>-->
<!--        &nbsp;-->
<!--        <mat-chip-list>-->
<!--          <mat-chip selected color="accent"> Lorem Ipsum </mat-chip>-->
<!--        </mat-chip-list>-->
<!--        &nbsp;-->
<!--        <mat-chip-list>-->
<!--          <mat-chip selected color="accent"> Lorem Ipsum </mat-chip>-->
<!--        </mat-chip-list>-->
<!--      </div>-->

    <button
      mat-mini-fab
      color="accent"
      type="button"
    >
      <mat-icon>delete</mat-icon>
    </button>
  </div>

</form>
