import { customersLink } from '../app-routing.module';
import { Id, Link } from '../core/model';

export enum RoutesNames {
  LIST = '',
  CREATE = 'create',
  BOOKINGS = 'bookings',
  BOOKINGS_CREATE = 'create',
}

export const customersListLink = (): Link =>
  customersLink(); /*.concat(RoutesNames.LIST)*/
export const customerCreateLink = (): Link =>
  customersLink().concat(RoutesNames.CREATE);
export const customerEditLink = (id: Id): Link => customersLink().concat(id);
export const customerBookingsCreateLink = (id: Id): Link =>
  customersLink().concat(id, RoutesNames.BOOKINGS, RoutesNames.BOOKINGS_CREATE);
