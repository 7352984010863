import { ICellRendererParams } from 'ag-grid-community';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Component } from '@angular/core';
import { Permission } from '../../model';
import { CaslService } from '../../casl/casl.service';

@Component({
  selector: 'app-edit-cell',
  templateUrl: './edit-cell-renderer.component.html',
})
export class EditCellRendererComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;
  canEdit = false;

  constructor(private caslService: CaslService) {}

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.updatePermission();
  }

  refresh(params: ICellRendererParams): boolean {
    this.params = params;
    this.updatePermission();
    return true;
  }

  updatePermission(): void {
    const permissions = (this.params as undefined as any).auth as Permission[];
    this.canEdit = this.caslService.can(permissions);
  }

  onButtonClicked(): void {
    // start editing this cell. see the docs on the params that this method takes
    const startEditingParams = {
      rowIndex: this.params.rowIndex,
      colKey: this.params.column.getId(),
    };
    this.params.api.startEditingCell(startEditingParams);
  }
}
