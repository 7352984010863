<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <!-- STEP ONE -->
  <div class="o-grid">
    <div class="o-grid__col u-1/1">
      <div [ngClass]="{ formHidden: !state[1].formVisible }">
        <div class="u-flex u-ai-center u-mb-16">
          <div class="o-circle o-type-body-small u-bgcolor-primary-pink-50 u-color-white u-mr-8">
            1.
          </div>
          <div class="o-type-h5">
            Aufzeichnungstermin Wählen
          </div>
        </div>

        <app-booking-create-select-recording-form
          [submitted]="state[1].submitted"
          formControlName="createSelectRecording"
          (recordingChange)="onChangedRecording($event)"
        >
        </app-booking-create-select-recording-form>

        <button
          class="u-mt-48 u-mb-24"
          id="next-1"
          mat-flat-button
          color="accent"
          type="button"
          (click)="onNextStepComplete(1, 'createSelectRecording')"
        >
          weiter 1
        </button>
      </div>
    </div>

    <div class="o-grid__col u-1/1">
      <div *ngIf="state[1].overviewVisible">
        <div *ngIf="!_booking" class="u-mb-16">
          <button (click)="onEdit(1)">
            edit 1
          </button>
        </div>
        <app-booking-create-select-recording-overview
          [createSelectRecording]="form.value.createSelectRecording"
        >
        </app-booking-create-select-recording-overview>
      </div>
    </div>
  </div>


  <!-- STEP TWO -->
  <div class="o-grid">
    <div class="o-grid__col u-1/1">
      <div [ngClass]="{ formHidden: !state[2].formVisible }">
        <div class="u-flex u-ai-center u-mb-16">
          <div class="o-circle o-type-body-small u-bgcolor-primary-pink-50 u-color-white u-mr-8">
            2.
          </div>
          <div class="o-type-h5">
            Produkte auswählen
          </div>
        </div>
        <div class="o-grid">
          <div class="o-grid__col u-9/36"
              formArrayName="items"
              *ngFor="let x of BookingItemForms.controls; index as i"
          >
            <app-booking-item-form
              [submitted]="state[2].submitted"
              [formControlName]="i"
              (amountSelected)="onChangedAmount($event)"
            >
            </app-booking-item-form>
          </div>
        </div>

        <div *ngIf="BookingParticipantForms.controls.length > 0" class="o-type-body-large u-mt-32">
          Tickets Personalisieren
        </div>

        <div
          class="u-mt-8"
          formArrayName="participants"
          *ngFor="let x of BookingParticipantForms.controls; index as i"
        >
          <app-booking-participant-form
            [submitted]="state[2].submitted"
            [formControlName]="i"
            [productVariants]="_recording.productVariants"
          >
          </app-booking-participant-form>
        </div>
        <div class="u-mt-48 u-mb-24">
          <button
            id="next-2"
            mat-flat-button
            color="accent"
            type="button"
            (click)="onNextStepComplete(2, 'participants')"
          >
            weiter 2
          </button>
        </div>
      </div>
    </div>

    <div class="o-grid__col u-1/1">
      <div *ngIf="state[2].overviewVisible">
        <div *ngIf="!_booking" class="u-mb-16">
          <button (click)="onEdit(2)">
            edit 2
          </button>
        </div>
        <app-booking-item-participant-overview
          *ngFor="let bookingParticipant of form.value.participants"
          [bookingParticipant]="bookingParticipant"
        >
        </app-booking-item-participant-overview>
      </div>
    </div>
  </div>


  <!-- STEP THREE -->
  <div [ngClass]="{ formHidden: !state[3].formVisible }">
    <div class="u-flex u-ai-center u-mb-16 u-mt-24">
      <div class="o-circle o-type-body-small u-bgcolor-primary-pink-50 u-color-white u-mr-8">
        3.
      </div>
      <div class="o-type-h5">
        Rechnung
      </div>
    </div>
    <app-booking-address-payment-form
      formControlName="addressPayment"
      (newAddress)="onNewAddress($event)"
      [addresses]="customer.addresses"
      [submitted]="state[3].submitted"
    >
    </app-booking-address-payment-form>
    <div class="button__container">
      <button
        class="u-mb-16"
        id="next-3"
        mat-flat-button
        color="accent"
        type="button"
        (click)="onNextStepComplete(3, 'addressPayment')"
      >
        weiter 3
      </button>
    </div>
  </div>

  <div class="u-mb-16" *ngIf="state[3].overviewVisible">
    <button *ngIf="!_booking"  (click)="onEdit(3)">edit 3</button>
    <app-booking-address-payment-overview
      [addressPayment]="form.value.addressPayment"
    >
    </app-booking-address-payment-overview>
  </div>

  <!-- STEP FOUR -->
  <div [ngClass]="{ formHidden: !state[4].formVisible }">
    <app-booking-notes-form formControlName="notes"> </app-booking-notes-form>
    <button
      class="u-mt-24"
      id="next-4"
      mat-flat-button
      color="accent"
      type="button"
      (click)="onSubmit()"
    >
      Buchung anlegen (weiter 4)
    </button>
    Lorem ipsum danach keine Änderungen mehr möglich...
  </div>
  <div *ngIf="state[4].overviewVisible">
    <button
      *ngIf="!_booking" 
      class="u-mt-16 u-mb-16"
      (click)="onEdit(4)">edit 4
    </button>
    <app-booking-notes-overview [bookingNote]="form.value.notes">
    </app-booking-notes-overview>
  </div>

</form>

<button class="u-mt-48 dummy-data"
  (click)="log()"
>
  log
</button>

