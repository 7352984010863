import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AgGridModule } from 'ag-grid-angular';
import { EditCellRendererComponent } from './cell-renderers/edit-cell-renderer.component';
import { MaterialModule } from '../material.module';

@NgModule({
  declarations: [EditCellRendererComponent],
  imports: [CommonModule, MaterialModule, AgGridModule],
  exports: [AgGridModule],
})
export class ListModule {}
