import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { filter, mergeMap, Observable, of, take } from 'rxjs';
import { AppFacadeService } from 'src/app/app-facade.service';
import { Permission } from 'src/app/core/model';
import { UsersFacadeService } from 'src/app/users/users-facade.service';
import { CaslService } from './casl.service';

@Injectable({
  providedIn: 'root',
})
export class CaslAuthGuard implements CanActivate {
  constructor(
    private appFacade: AppFacadeService,
    private userFacade: UsersFacadeService,
    private caslService: CaslService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    // wait for the user store (and casl ability instance) to be ready
    return this.userFacade.rules$.pipe(
      filter((rules) => rules != undefined),
      take(1),
      mergeMap(() => {
        const permissions =
          route.routeConfig.data?.auth || ([] as Permission[]);
        const allowed = this.caslService.can(permissions);
        if (!allowed) {
          this.appFacade.unauthorizedRedirect(state.url);
        }
        return of(allowed);
      })
    );
  }
}
