<div>
  <div class="detail__container" *ngFor="let line of params.data.lines">
    <div style="background-color: rgb(241, 241, 241)">
      <h2 style="padding: 10px">
        Aufzeichnung:
        {{ line.productVariant.recording.format.cmsFormat | json }}
      </h2>

      <div class="header__container">
        <p>
          {{ line.productVariant.recording.eventStart }}
        </p>
        <p>
          {{ line.productVariant.recording.title }}
        </p>
        <p>
          <!-- which exact price shall be listed? -->
          {{ line.productVariant.recording.title.type }}
        </p>
        <p>
          <!-- don't have any 'Aktion' yet-->
          Aktion: nicht ausgewählt
        </p>
      </div>

      <div class="product__container">
        <h3>
          Anzahl der Tickets:
          {{ line.quantity | json }}
        </h3>
        <div class="product__content">
          <p>Produkt: {{ line.productVariant.name | json }}</p>

          <div class="product__customer">
            <p>Name: {{ params.data.customer?.lastName | json }}</p>
            <p>Vorname: {{ params.data.customer?.firstName | json }}</p>
            <p>Geb Dat:{{ params.data.customer?.birthDate | json }}</p>
          </div>
          <div class="product__item">
            <p>
              Produkt:{{ line.productVariant.name | json }} -
              {{ line.productVariant.price | json }}€
            </p>
            <p>Studioführung: ---</p>
            <p>Rollstuhl: ---</p>
          </div>
          <p>Bedingungen: Lorem ipsum Lorem ipsum Lorem ipsum Lorem</p>
        </div>
      </div>
    </div>

    <div class="payment__container">
      <h3>Zahlung</h3>
      <p>Rechnungsadresse: Standard</p>
      <p>
        {{ params.data.billingAddress.fullName }}
      </p>
      <p>
        {{ params.data.billingAddress.streetLine1 }}
      </p>
      <p>
        {{ params.data.billingAddress.postalCode }}
        {{ params.data.billingAddress.city }}
      </p>
      <!-- haven't chosen payment method yet-->
      <p>Zahlungsmethode:</p>
    </div>

    <div class="totalSumm__container">
      <h3>Summe</h3>
      <div>
        <p style="font-size: large">
          {{ params.data.total | price }} &nbsp; {{ params.data.totalWithTax | price }}
          inkl.MwSt
        </p>
      </div>
    </div>
  </div>
</div>