<form [formGroup]="form">
    <div class="o-grid u-fd-col">

<!--      <div>-->
<!--        <mat-form-field appearance="outline" class="input__container">-->
<!--          <mat-label> Aktion wählen </mat-label>-->
<!--          <mat-select formControlName="campaign">-->
<!--            <mat-option *ngFor="let action of actions" [value]="action.value">-->
<!--              {{ action.viewValue }}-->
<!--            </mat-option>-->
<!--          </mat-select>-->
<!--        </mat-form-field>-->
<!--      </div>-->

      <div class="o-grid__col u-12/36">
        &nbsp;<mat-form-field class="u-1/1">
          <mat-label for="title">
            {{ "FORMATS.FORMAT_FORM.LABEL" | translate }}
          </mat-label>
          <mat-select
            formControlName="format"
            name="Options"
            (selectionChange)="onFormatChange()"
          >
            <!-- [(ngModel)]="formatsListValue"-->
            <mat-option
              *ngFor="let format of (formatsFacade.formats$ | async) || []"
              [value]="format"
            >
              {{ format.title }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!--
      <div class="u-flex">
        <div>
          <mat-label for="date"> Date </mat-label>
          &nbsp;
          <mat-form-field appearance="outline">
            <input
              matInput
              formControlName="date"
              [matDatepicker]="picker"
              (dateChange)="onDateChange($event)"
            />
            <! -- [(ngModel)]="dateValue"-- >
            <mat-datepicker-toggle matSuffix [for]="picker">
            </mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>

        <div>
          &nbsp;
          <mat-label for="persons"> Personen </mat-label>
          &nbsp;
          <mat-form-field appearance="outline">
            <input
              matInput
              type="number"
              formControlName="amount"
            />
          </mat-form-field>
        </div>
      </div>
      -->
      <!--
      <div class="checkbox__container u-flex u-jc-between">
        <mat-checkbox class="example-margin1"> Studioführung </mat-checkbox>
        <mat-checkbox class="example-margin2">
          Rollstuhlfahrerplatz
        </mat-checkbox>
      </div>
      -->
      <!-- @TODO delete ID later
          <p>{{ formatsListValue }}</p> -->

      <!-- @TODO have a loader here? -->
      <div *ngIf="selectedFormatId" class="o-grid__col u-30/36 u-mt-24">
        <mat-radio-group
          *ngIf="(recordingsFacade.recordings$ | async).length > 0"
          formControlName="recording"
          aria-label="Select an option"
          (change)="onRecordingChanged($event)"
        >
          <mat-radio-button
            [value]="recording"
            *ngFor="let recording of recordingsFacade.recordings$ | async"
          >
            {{ recording.title }} | Datum:
            {{ recording?.eventStart | date:'fullDate' }}, {{ recording?.eventStart | date:'shortTime' }} Uhr
          </mat-radio-button>
        </mat-radio-group>
        <span *ngIf="(recordingsFacade.recordings$ | async).length === 0">Keine Aufzeichnungen zum Format vorhanden</span>
        <!-- @TODO i18n -->
      </div>
    </div>

</form>
