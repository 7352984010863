import { CustomersPageComponent } from './pages/customers-page/customers-page.component';
import { CurrentCustomerResolver } from './customer-current.resolver';
import { NotFoundPageComponent } from './../core/errors/not-found-page/not-found-page.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes, UrlSegment } from '@angular/router';
import { CustomerPageComponent } from './pages/customer-page/customer-page.component';
import { CustomerCreatePageComponent } from './pages/customer-create-page/customer-create-page.component';
import { FormatsResolver } from '../formats/formats.resolver';
import { CustomerCreateBookingPageComponent } from './pages/customer-create-booking-page/customer-create-booking-page.component';
import { ROUTE_PARAM_CUSTOMER_ID } from '../core/route-params';
import { RoutesNames } from './customers-routing-helper';
import { routes as editRoutes } from './pages/customer-page/customer-routing';
import { routes as listRoutes } from './pages/customers-page/customers-routing';
import { CaslAuthGuard } from '../core/casl/casl-auth.guard';
import { Permission } from '../core/model';
import { TagsResolver } from '../tags/tags.resolver';
import { Action, Subject } from '@blab/blab-authorization';

const routes: Routes = [
  {
    // LIST
    path: RoutesNames.LIST,
    component: CustomersPageComponent,
    matcher: urlMatcher,
    children: listRoutes,
    canActivate: [CaslAuthGuard],
    data: {
      auth: [
        {
          subject: Subject.Customer,
          action: Action.Read,
        },
      ] as Permission[],
    },
  },
  {
    // CREATE
    path: RoutesNames.CREATE,
    component: CustomerCreatePageComponent,
    canActivate: [CaslAuthGuard],
    resolve: {
      tags: TagsResolver,
    },
    data: {
      auth: [
        {
          subject: Subject.Customer,
          action: Action.Create,
        },
        {
          subject: Subject.Tag,
          action: Action.Read,
        },
      ] as Permission[],
    },
  },
  {
    // EDIT
    path: `:${ROUTE_PARAM_CUSTOMER_ID}`,
    component: CustomerPageComponent,
    children: editRoutes,
    canActivate: [CaslAuthGuard],
    resolve: {
      customer: CurrentCustomerResolver,
    },
    data: {
      auth: [
        {
          subject: Subject.Customer,
          action: Action.Read,
        },
      ] as Permission[],
    },
  },
  {
    // BOOKINGS_CREATE
    path: `:${ROUTE_PARAM_CUSTOMER_ID}/${RoutesNames.BOOKINGS}/${RoutesNames.BOOKINGS_CREATE}`,
    component: CustomerCreateBookingPageComponent,
    canActivate: [CaslAuthGuard],
    resolve: {
      customer: CurrentCustomerResolver,
      formats: FormatsResolver,
    },
    data: {
      auth: [
        {
          subject: Subject.Customer,
          action: Action.Read,
        },
        {
          subject: Subject.Format,
          action: Action.Read,
        },
        {
          subject: Subject.Booking,
          action: Action.Create,
        },
      ] as Permission[],
    },
  },
  {
    path: '**',
    component: NotFoundPageComponent,
  },
];

// Function for staying on given path
//@TODO refactor or centralize when final
export function urlMatcher(url: UrlSegment[]) {
  if (url.length == 1) {
    const path = url[0].path;
    if (path.startsWith(RoutesNames.LIST)) {
      return { consumed: url };
    }
  }
  return null;
}

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CustomersRoutingModule {}
