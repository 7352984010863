import { ListConfigsFacadeService } from 'src/app/list-configs/list-configs-facade.service';
import { Injectable } from '@angular/core';
import { ColDef, ValueSetterParams } from 'ag-grid-community';
import { TranslateService } from '@ngx-translate/core';
import {
  BaseColumnIds,
  BaseColumnDefsService,
} from 'src/app/core/list/base-column-defs-service';
import { Permission } from 'src/app/core/model';
import { Action, Subject } from '@blab/blab-authorization';

export enum ListConfigColumnIds {
  TITLE = 'title',
  CREATED_BY = 'createdBy',
}

@Injectable({
  providedIn: 'root',
})
export class ListConfigListColumnDefsService extends BaseColumnDefsService {
  columnDefsMap = new Map<string, ColDef>([
    [
      BaseColumnIds.SELECT,
      this.baseColumnDefsMap.get(BaseColumnIds.SELECT),
    ],
    [
      BaseColumnIds.ID,
      this.baseColumnDefsMap.get(BaseColumnIds.ID),
    ],
    [
      ListConfigColumnIds.TITLE,
      {
        headerName: this.translate.instant('LIST_CONFIGS.COLUMNS.TITLE'),
        field: 'title',
        editable: true,
        cellRenderer: 'editCellRenderer',
        cellRendererParams: {
          auth: [
            {
              subject: Subject.ListConfig,
              action: Action.Update,
            },
          ] as Permission[],
        },
        valueSetter: (params: ValueSetterParams): boolean => {
          this.listConfigFacade.updateListConfig({
            id: params.data.id,
            title: params.newValue,
          });
          return true;
        },
      },
    ],
    [
      BaseColumnIds.CREATED_AT,
      this.baseColumnDefsMap.get(BaseColumnIds.CREATED_AT),
    ],
    [
      ListConfigColumnIds.CREATED_BY,
      {
        headerName: this.translate.instant('LIST_CONFIGS.COLUMNS.CREATED_BY'),
        field: 'createdBy',
      },
    ],
    [
      BaseColumnIds.ACTIONS,
      this.baseColumnDefsMap.get(BaseColumnIds.ACTIONS),
    ],
  ]);

  columnDefs: ColDef[] = Array.from(this.columnDefsMap.keys()).map((key) => ({
    colId: key,
    ...this.columnDefsMap.get(key),
  }));

  constructor(
    protected translate: TranslateService,
    private listConfigFacade: ListConfigsFacadeService
  ) {
    super(translate);
  }
}
