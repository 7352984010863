<mat-accordion>
  <mat-expansion-panel [expanded]="true">
    <mat-expansion-panel-header>
      <mat-panel-title>+ Eintrag hinzufügen</mat-panel-title>
    </mat-expansion-panel-header>

    <div
      *ngIf="'create' | able: 'WaitingList'"
      class="u-mb-48 u-mt-8"
    >
      <app-waiting-list-create-form
        (createWaitingListEntry)="onCreateWaitingListEntry($event)"
        [formats]="formatsFacade.formats$ | async"
      >
      </app-waiting-list-create-form>

      <app-ticket-list-create-form
        [formats]="formatsFacade.formats$ | async"
        [recordings]="
          recordingsFacade.recordingsByFormat$(selectedFormatId) | async
        "
        (createTicketListEntry)="onCreateWaitingListEntry($event)"
        (formatSelected)="onFormatChange($event)"
      >
      </app-ticket-list-create-form>
    </div>

  </mat-expansion-panel>
</mat-accordion>

<div class="u-fg-1 u-mt-40">
  <app-waiting-lists-list
    [columnDefs]="(waitingListsFacade.listCustomer$ | async)?.columnDefs"
    [data]="(waitingListsFacade.waitingList$ | async) || []"
  >
  </app-waiting-lists-list>
</div>
