import { Injectable } from '@angular/core';
import { Ability } from '@casl/ability';
import { Permission } from '../model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CaslService {
  constructor(private ability: Ability) {}

  can = (permissions: Permission[]): boolean => {
    let allowed = true;
    permissions.forEach((permission: Permission) => {
      if (this.ability.cannot(permission.action, permission.subject)) {
        if (!environment.production) {
          console.log(`missing auth ${permission.action} / ${permission.subject}`);
        }
        allowed = false;
      }
    });
    return allowed;
  };
}
