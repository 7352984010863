import { FormatsPageComponent } from './pages/formats-page/formats-page.component';
import { FormatPageComponent } from './pages/format-page/format-page.component';
import { FormatsResolver } from './formats.resolver';
import { CurrentFormatResolver } from './format-current.resolver';
import { NotFoundPageComponent } from './../core/errors/not-found-page/not-found-page.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FormatCreatePageComponent } from './pages/format-create-page/format-create-page.component';
import { RoutesNames } from './formats-routing-helper';
import { ROUTE_PARAM_FORMAT_ID } from '../core/route-params';
import { routes as editRoutes } from './pages/format-page/format-routing';
import { Permission } from '../core/model';
import { CaslAuthGuard } from '../core/casl/casl-auth.guard';
import { Action, Subject } from '@blab/blab-authorization';

const routes: Routes = [
  {
    // LIST
    path: RoutesNames.LIST,
    component: FormatsPageComponent,
    pathMatch: 'full',
    canActivate: [CaslAuthGuard],
    resolve: {
      formats: FormatsResolver,
    },
    data: {
      auth: [
        {
          subject: Subject.Format,
          action: Action.Read,
        },
      ] as Permission[],
    },
  },
  {
    // CREATE
    path: RoutesNames.CREATE,
    component: FormatCreatePageComponent,
    canActivate: [CaslAuthGuard],
    data: {
      auth: [
        {
          subject: Subject.Format,
          action: Action.Create,
        },
      ] as Permission[],
    },
  },
  {
    // EDIT
    path: `:${ROUTE_PARAM_FORMAT_ID}`,
    component: FormatPageComponent,
    canActivate: [CaslAuthGuard],
    resolve: {
      format: CurrentFormatResolver,
    },
    data: {
      auth: [
        {
          subject: Subject.Format,
          action: Action.Read,
        },
      ] as Permission[],
    },
    children: editRoutes,
  },
  {
    path: '**',
    component: NotFoundPageComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class FormatsRoutingModule {}
