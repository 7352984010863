// @TODO remove old/commented code when sure that they are not needed anymore

export const translations = {
  CUSTOMERS: {
    COLUMNS: {
      SALUTATION: 'Anrede',
      FIRST_NAME: 'Vorname',
      LAST_NAME: 'Nachname',
      BIRTH_DATE: 'Geburtsdatum',
      COMPANY: 'Firma',
      DEFAULT_EMAIL: 'Standard-E-Mail',
      DEFAULT_PHONE: 'Standard-Telefonnummer',
      PRIMARY_FULL_ADDRESS: 'Adresse vollständig (primär)',
      PRIMARY_ADDRESS: 'Adresse (primär)',
      PRIMARY_POST_CODE: 'Postleitzahl (primär)',
      PRIMARY_CITY: 'Stadt (primär)',
      PRIMARY_COUNTRY: 'Land (primär)',
    },
    CUSTOMERS_PAGE: {
      TITLE: 'Kunden',
    },
    CUSTOMERS_CREATE_PAGE: {
      TITLE: 'Neuen Kunden anlegen',
    },
    CUSTOMER_FORM: {
      FIRST_NAME: {
        LABEL: 'Vorname',
        PLACEHOLDER: '',
        ERRORS: {
          REQUIRED: 'Bitte geben Sie den Vornamen ein.',
        },
      },
      LAST_NAME: {
        LABEL: 'Nachname',
        PLACEHOLDER: '',
        ERRORS: {
          REQUIRED: 'Bitte geben Sie den Nachnamen ein.',
        },
      },
      BIRTH_DATE: {
        LABEL: 'Geburtsdatum',
        PLACEHOLDER: 'xyz',
        ERRORS: {
          REQUIRED: 'Bitte geben Sie das Geburtsdatum ein.',
        },
      },
      EMAIL: {
        LABEL: 'E-Mail',
        PLACEHOLDER: '',
        ERRORS: {
          REQUIRED: 'Bitte geben Sie die E-Mail Adresse ein.',
        },
      },
      PHONE: {
        LABEL: 'Telefon',
        PLACEHOLDER: '',
        ERRORS: {
          REQUIRED: 'Bitte geben Sie die Telefonnummer ein.',
        },
      },
      PRIMARY_ADDRESS: {
        HEADLINE: 'Primäradresse'
      },
      ADDITIONAL_ADDRESS: {
        HEADLINE: '+ Adresse'
      }
    },
  },
  ADDRESS: {
    ADDRESS_FORM: {
      TOGGLE_COMPANY_VISIBILITY: {
        LABEL: 'Firmenadresse',
      },
      SALUTAION: {
        LABEL: 'Anrede',
      },
      FIRST_NAME: {
        LABEL: 'Vorname',
        PLACEHOLDER: '',
        ERRORS: {
          REQUIRED: 'Bitte geben Sie den Vornamen ein.',
        },
      },
      LAST_NAME: {
        LABEL: 'Nachname',
        PLACEHOLDER: '',
        ERRORS: {
          REQUIRED: 'Bitte geben Sie den Nachnamen ein.',
        },
      },
      COMPANY: {
        LABEL: 'Firma',
        PLACEHOLDER: '',
        ERRORS: {
          REQUIRED: 'Bitte geben Sie den Firmennamen an.',
        },
      },
      ADDRESS: {
        LABEL: 'Adresse',
        PLACEHOLDER: '',
        ERRORS: {
          REQUIRED: 'Bitte geben Sie die Adresse ein.',
        },
      },
      ADDITIONAL_ADDRESS_INFORMATION: {
        LABEL: 'Adresszusatz',
        PLACEHOLDER: '',
        // ERRORS: {
        //   REQUIRED: 'Bitte geben Sie eine Adresse an.'
        // },
      },
      POST_CODE: {
        LABEL: 'Postleitzahl',
        PLACEHOLDER: '',
        ERRORS: {
          REQUIRED: 'Bitte geben Sie die Postleitzahl ein.',
        },
      },
      CITY: {
        LABEL: 'Stadt',
        PLACEHOLDER: '',
        ERRORS: {
          REQUIRED: 'Bitte geben Sie die Stadt ein.',
        },
      },
      STATE: {
        LABEL: 'Bundesland',
        PLACEHOLDER: '',
        ERRORS: {
          REQUIRED: 'Bitte geben Sie das Bundesland ein.',
        },
      },
      COUNTRY: {
        LABEL: 'Land',
        PLACEHOLDER: '',
        ERRORS: {
          REQUIRED: 'Bitte geben Sie das Land ein.',
        },
      }
    },
  },
  CONTACT_OPTION: {
    CATEGORY: {
      LABEL: 'Kategorie',
      // ERRORS: {
      //   REQUIRED: 'Bitte geben Sie Ihr Addresstyp an.'
      // },
      OPTION: {
        PRIVATE: 'Privat',
        BUSINESS: 'Geschäftlich',
      },
    },
  },
  FORMATS: {
    COLUMNS: {
      RELEASE_STATE: 'Status',
      TITLE: 'Titel',
      CURRENT_SEASON_NAME: 'Staffelname',
      CURRENT_SEASON_NUMBER: 'Staffel-Nr.',
      CURRENT_SEASON_START: 'Staffelbegin',
      CURRENT_SEASON_END: 'Staffelende',
      CURRENT_SEASON_STUDIO_NAME: 'Studio',
      ACTIVE_RECORDINGS: 'Aktive Aufzeichnungen',
    },
    FORMATS_PAGE: {
      TITLE: 'Formate',
    },
    FORMAT_PAGE: {
      TITLE: 'Format-Detail',
    },
    FORMAT_CREATE_PAGE: {
      TITLE: 'Neues Format anlegen',
    },
    FORMAT_FORM: {
      LABEL: 'Format',
      ERRORS: {
        REQUIRED: 'Bitte geben Sie einen Titel für das Format an.',
      },
      CREATED_AT: {
        LABEL: 'Erstellt am',
      },
    },
  },
  RECORDINGS: {
    COLUMNS: {
      FORMAT: 'Format',
      TITLE: 'Titel',
      TYPE: 'Typ',
      PRODUCTION_START: 'Aufzeichnungstermin',
      CHECK_IN_BEGIN: 'Check-In-Beginn',
      CHECK_IN_END: 'Check-In-Ende',
      EVENT_START_TIME: 'Ankunftszeit',
      PRODUCTION_START_TIME: 'Start Produktion',
      EVENT_END_TIME: 'Ende',
    },
    RECORDINGS_PAGE: {
      TITLE: 'Aufzeichnungen',
    },
    RECORDING_PAGE: {
      TITLE: 'Aufzeichnung-Detail',
    },
    RECORDING_CREATE_PAGE: {
      TITLE: 'Neue Aufzeichnung anlegen',
    },
    RECORDING_FORM: {
      LABEL: 'Aufzeichnung',
      ERRORS: {
        REQUIRED: 'Bitte geben Sie einen Aufzeichnungstitel an.',
      },
      RECORDING_TYPE: {
        LABEL: 'Aufzeichnungs Typ'
      },
      SEASON: {
        LABEL: 'Staffel'
      },
      CREATED_AT: {
        LABEL: 'Erstellt am',
      },
      PRODUCTION_START: {
        LABEL: 'Aufzeichnungstermin',
      },
      CHECK_IN_BEGIN: {
        LABEL: 'Check-In-Beginn',
      },
      CHECK_IN_END: {
        LABEL: 'Check-In-Ende',
      },
      EVENT_START: {
        LABEL: 'Ankunftszeit',
      },
      PRODUCTION_TIME_START: {
        LABEL: 'Beginn',
      },
      EVENT_TIME_END: {
        LABEL: 'Ende',
      },
      AUDIENCE_CAPACITY: {
        LABEL: 'Studiokapazität',
      },

    },
  },
  BOOKINGS: {
    COLUMNS: {
      DETAILS: 'Details',
      CUSTOMER: 'Kunde',
      TOTAL_RECORDING_TICKETS: 'totalRecordingTickets',
      ORDER_PLACED_AT: 'Datum der Buchung',
      STATE: 'Status',
      CHECK_IN_COMPLETE: 'Check-In erfolgt',
      CHECK_IN_DATE: 'Check-In-Datum',
      TOTAL_QUANTITY: 'Anzahl',
      RECORDING_QUANTITY: 'Anzahl',
      TOTAL_WITH_TAX: 'Gesamtbetrag (inkl. MwSt.)',
      RECORDING: 'Aufzeichnung',
      PARTICIPANT_CUSTOMER_NUMBER: 'Kundennummer',
      PARTICIPANT_SHOW: 'Show',
      PARTICIPANT_PRODUCT_NAME: 'Ticketname',
      PARTICIPANT_SALUTATION: 'Anrede',
      PARTICIPANT_FIRST_NAME: 'Vorname',
      PARTICIPANT_LAST_NAME: 'Nachname',
      PARTICIPANT_BIRTH_DATE: 'Geburtsdatum',
    },
  },
  WAITING_LISTS: {
    COLUMNS: {
      FORMAT: 'Format',
      RECORDING: 'Aufzeichnung',
      PRODUCTION_START: 'Aufzeichnungstermin',
      TYPE: 'Typ',
    },
  },
  USERS: {
    COLUMNS: {
      FIRST_NAME: 'Vorname',
      LAST_NAME: 'Nachname',
      EMAIL: 'E-Mail',
      PHONE_NUMBER: 'Telefon',
      ACTIVE: 'aktiv',
      ROLES: 'Rollen',
      DEFAULT_PHONE: 'Telefon-Nummer (primär)',
    },
  },
  LIST_CONFIGS: {
    COLUMNS: {
      TITLE: 'Titel',
      CREATED_BY: 'Erstellt am',
    },
  },
  LIST: {
    loadingError: 'Es ist leider ein Fehler aufgetreten',
    // Set Filter
    selectAll: '(Alle auswählen)',
    selectAllSearchResults: '(Select All Search Results)',
    searchOoo: 'Suche...',
    blanks: '(Blanks)',
    noMatches: 'Keine Übereinstimmungen',

    // Number Filter & Text Filter
    filterOoo: 'Filter...',
    equals: 'ist gleich',
    notEqual: 'ist ungleich',
    empty: 'Choose One',

    // Number Filter
    lessThan: 'kleiner als',
    greaterThan: 'größer als',
    lessThanOrEqual: 'kleiner gleich',
    greaterThanOrEqual: 'größer gleich',
    inRange: 'zwischen',
    inRangeStart: 'von',
    inRangeEnd: 'bis',

    // Text Filter
    contains: 'enthält',
    notContains: 'enthält nicht',
    startsWith: 'beginnt mit',
    endsWith: 'endet mit',

    // Date Filter
    dateFormatOoo: 'yyyy-mm-dd',

    // Filter Conditions
    andCondition: 'UND',
    orCondition: 'ODER',

    // Filter Buttons
    applyFilter: 'Apply',
    resetFilter: 'Reset',
    clearFilter: 'Clear',
    cancelFilter: 'Cancel',

    // Filter Titles
    textFilter: 'Text Filter',
    numberFilter: 'Number Filter',
    dateFilter: 'Date Filter',
    setFilter: 'Set Filter',

    // Side Bar
    columns: 'Spalten',
    filters: 'Filter',

    // columns tool panel
    pivotMode: 'Pivot Mode',
    groups: 'Row Groups',
    rowGroupColumnsEmptyMessage: 'Drag here to set row groups',
    values: 'Values',
    valueColumnsEmptyMessage: 'Drag here to aggregate',
    pivots: 'Column Labels',
    pivotColumnsEmptyMessage: 'Drag here to set column labels',

    // Header of the Default Group Column
    group: 'Group',

    // Other
    loadingOoo: 'Loading...',
    noRowsToShow: 'Keine Daten vorhanden',
    enabled: 'Enabled',

    // Menu
    pinColumn: 'Pin Column',
    pinLeft: 'Pin Left',
    pinRight: 'Pin Right',
    noPin: 'No Pin',
    valueAggregation: 'Value Aggregation',
    autosizeThiscolumn: 'Autosize This Column',
    autosizeAllColumns: 'Autosize All Columns',
    groupBy: 'Group by',
    ungroupBy: 'Un-Group by',
    resetColumns: 'Reset Columns',
    expandAll: 'Expand All',
    collapseAll: 'Close All',
    copy: 'Copy',
    ctrlC: 'Ctrl+C',
    copyWithHeaders: 'Copy With Headers',
    paste: 'Paste',
    ctrlV: 'Ctrl+V',
    export: 'Export',
    csvExport: 'CSV Export',
    excelExport: 'Excel Export',

    // Enterprise Menu Aggregation and Status Bar
    sum: 'Sum',
    min: 'Min',
    max: 'Max',
    none: 'None',
    count: 'Count',
    avg: 'Average',
    filteredRows: 'Filtered',
    selectedRows: 'Selected',
    totalRows: 'Total Rows',
    totalAndFilteredRows: 'Rows',
    more: 'More',
    to: 'to',
    of: 'of',
    page: 'Page',
    nextPage: 'Next Page',
    lastPage: 'Last Page',
    firstPage: 'First Page',
    previousPage: 'Previous Page',

    // Pivoting
    pivotColumnGroupTotals: 'Total',

    // Enterprise Menu (Charts)
    pivotChartAndPivotMode: 'Pivot Chart & Pivot Mode',
    pivotChart: 'Pivot Chart',
    chartRange: 'Chart Range',

    columnChart: 'Column',
    groupedColumn: 'Grouped',
    stackedColumn: 'Stacked',
    normalizedColumn: '100% Stacked',

    barChart: 'Bar',
    groupedBar: 'Grouped',
    stackedBar: 'Stacked',
    normalizedBar: '100% Stacked',

    pieChart: 'Pie',
    pie: 'Pie',
    doughnut: 'Doughnut',

    line: 'Line',

    xyChart: 'X Y (Scatter)',
    scatter: 'Scatter',
    bubble: 'Bubble',

    areaChart: 'Area',
    area: 'Area',
    stackedArea: 'Stacked',
    normalizedArea: '100% Stacked',

    histogramChart: 'Histogram',

    // Charts
    pivotChartTitle: 'Pivot Chart',
    rangeChartTitle: 'Range Chart',
    settings: 'Settings',
    data: 'Data',
    format: 'Format',
    categories: 'Categories',
    defaultCategory: '(None)',
    series: 'Series',
    xyValues: 'X Y Values',
    paired: 'Paired Mode',
    axis: 'Axis',
    navigator: 'Navigator',
    color: 'Color',
    thickness: 'Thickness',
    xType: 'X Type',
    automatic: 'Automatic',
    category: 'Category',
    number: 'Number',
    time: 'Time',
    xRotation: 'X Rotation',
    yRotation: 'Y Rotation',
    ticks: 'Ticks',
    width: 'Width',
    height: 'Height',
    length: 'Length',
    padding: 'Padding',
    spacing: 'Spacing',
    chart: 'Chart',
    title: 'Title',
    titlePlaceholder: 'Chart title - double click to edit',
    background: 'Background',
    font: 'Font',
    top: 'Top',
    right: 'Right',
    bottom: 'Bottom',
    left: 'Left',
    labels: 'Labels',
    size: 'Size',
    minSize: 'Minimum Size',
    maxSize: 'Maximum Size',
    legend: 'Legend',
    position: 'Position',
    markerSize: 'Marker Size',
    markerStroke: 'Marker Stroke',
    markerPadding: 'Marker Padding',
    itemSpacing: 'Item Spacing',
    itemPaddingX: 'Item Padding X',
    itemPaddingY: 'Item Padding Y',
    layoutHorizontalSpacing: 'Horizontal Spacing',
    layoutVerticalSpacing: 'Vertical Spacing',
    strokeWidth: 'Stroke Width',
    offset: 'Offset',
    offsets: 'Offsets',
    tooltips: 'Tooltips',
    callout: 'Callout',
    markers: 'Markers',
    shadow: 'Shadow',
    blur: 'Blur',
    xOffset: 'X Offset',
    yOffset: 'Y Offset',
    lineWidth: 'Line Width',
    normal: 'Normal',
    bold: 'Bold',
    italic: 'Italic',
    boldItalic: 'Bold Italic',
    predefined: 'Predefined',
    fillOpacity: 'Fill Opacity',
    strokeOpacity: 'Line Opacity',
    histogramBinCount: 'Bin count',
    columnGroup: 'Column',
    barGroup: 'Bar',
    pieGroup: 'Pie',
    lineGroup: 'Line',
    scatterGroup: 'X Y (Scatter)',
    areaGroup: 'Area',
    histogramGroup: 'Histogram',
    groupedColumnTooltip: 'Grouped',
    stackedColumnTooltip: 'Stacked',
    normalizedColumnTooltip: '100% Stacked',
    groupedBarTooltip: 'Grouped',
    stackedBarTooltip: 'Stacked',
    normalizedBarTooltip: '100% Stacked',
    pieTooltip: 'Pie',
    doughnutTooltip: 'Doughnut',
    lineTooltip: 'Line',
    groupedAreaTooltip: 'Area',
    stackedAreaTooltip: 'Stacked',
    normalizedAreaTooltip: '100% Stacked',
    scatterTooltip: 'Scatter',
    bubbleTooltip: 'Bubble',
    histogramTooltip: 'Histogram',
    noDataToChart: 'No data available to be charted.',
    pivotChartRequiresPivotMode: 'Pivot Chart requires Pivot Mode enabled.',
    chartSettingsToolbarTooltip: 'Menu',
    chartLinkToolbarTooltip: 'Linked to Grid',
    chartUnlinkToolbarTooltip: 'Unlinked from Grid',
    chartDownloadToolbarTooltip: 'Download Chart',

    // ARIA
    ariaHidden: 'hidden',
    ariaVisible: 'visible',
    ariaChecked: 'checked',
    ariaUnchecked: 'unchecked',
    ariaIndeterminate: 'indeterminate',
    ariaDefaultListName: 'List',
    ariaColumnSelectAll: 'Toggle Select All Columns',
    ariaInputEditor: 'Input Editor',
    ariaDateFilterInput: 'Date Filter Input',
    ariaFilterList: 'Filter List',
    ariaFilterInput: 'Filter Input',
    ariaFilterColumnsInput: 'Filter Columns Input',
    ariaFilterValue: 'Filter Value',
    ariaFilterFromValue: 'Filter from value',
    ariaFilterToValue: 'Filter to value',
    ariaFilteringOperator: 'Filtering Operator',
    ariaColumn: 'Column',
    ariaColumnList: 'Column List',
    ariaColumnGroup: 'Column Group',
    ariaRowSelect: 'Press SPACE to select this row',
    ariaRowDeselect: 'Press SPACE to deselect this row',
    ariaRowToggleSelection: 'Press Space to toggle row selection',
    ariaRowSelectAll: 'Press Space to toggle all rows selection',
    ariaToggleVisibility: 'Press SPACE to toggle visibility',
    ariaSearch: 'Search',
    ariaSearchFilterValues: 'Search filter values',

    // ARIA LABEL FOR DIALOGS
    ariaLabelCellEditor: 'Cell Editor',
    ariaLabelDialog: 'Dialog',
    ariaLabelSelectField: 'Select Field',
    ariaLabelTooltip: 'Tooltip',
    ariaLabelContextMenu: 'Context Menu',
    ariaLabelColumnMenu: 'Column Menu',
    ariaLabelSubMenu: 'SubMenu',
    ariaLabelAggregationFunction: 'Aggregation Function',
  },
  ENUM: {
    BOOKING_PAYMENT_TYPE: {
      INVOICE: 'Rechnung',
    },
    SALUTATION: {
      MRS: 'Frau',
      MR: 'Herr',
      THEY: 'divers',
      NOT_SPECIFIED: 'nicht angegeben',
    },
    PRODUCT_TYPE: {
      TICKET: 'Ticket',
      STUDIO_TOUR: 'Studioführung',
    },
    FORMAT_RELEASE_STATE: {
      ACTIVE: 'aktiv',
      ARCHIVED: 'archiviert',
    },
    RECORDING_TYPE: {
      LIVE: 'Live',
      SINGLE: 'Einzel-AZ',
      DUAL: 'Doppel-AZ',
    },
    WAITING_LIST_ENTRY_TYPE: {
      WAITINGLIST: 'Warteliste',
      TICKETALARM: 'Ticketalarm',
    },
    MAIL_CAMPAIGN_STATUS: {
      draft: 'Entwurf (noch nicht versendet)',
      sent: 'versendet',
      queued: 'in Warteschlange',
      in_process: 'Versendeprozess läuft',
      archive: 'archiviert',
      suspended: 'ausgesetzt'
    }
  },
  COLUMNS: {
    SELECT: 'Auswahl',
    ID: 'ID',
    CREATED_AT: 'Erstellt am',
    UPDATED_AT: 'Geändert am',
  },
  MAILS: {
    COLUMNS: {
      NAME: 'Name',
      STATUS: 'Status',
      SUBJECT: 'Betreff',
      SENT: 'versendet',
      DELIVERED: 'zugestellt',
      UNIQUE_VIEWS: 'unique views',
      SOFT_BOUNCES: 'Soft Bounces',
      HARD_BOUNCES: 'Hard Bounces',
      COMPLAINTS: 'Beschwerden',
      UNSUBSCRIPTIONS: 'Abmeldungen',
    },
    MAILS_PAGE: {
      TITLE: 'Mail Historie'
    },
    MAIL_SEND_PAGE: {
      TITLE: 'Mail versenden',
      RECIPIENT_LIST: 'Empfängerliste bei Brevo einsehen',
      MAIL: {
        NAME: 'Name der Mail',
        SUBJECT: 'Betreff'
      },
      RECIPIENT_COUNT: 'Anzahl Empfänger',
      SEND_IMMEDIATELY: 'Sofort versenden',
      SEND_LATER: 'Später versenden',
      SEND_TEST: 'Testmail versenden',
      SEND_TEST_FIELD: {
        LABEL: 'Testmail an diese Adresse senden'
      }
    },
    MAIL_CREATE_PAGE: {
      TITLE: 'Mail anlegen'
    },
    MAIL_FORM: {
      NAME: {
        LABEL: 'Name',
        ERRORS: {
          REQUIRED: 'Bitte geben Sie den Namen der Mail ein.',
        }
      },
      SUBJECT: {
        LABEL: 'Betreff',
        ERRORS: {
          REQUIRED: 'Bitte geben Sie den Betreff der Mail ein.',
        }
      },
      TEXT: {
        LABEL: 'TEXT'
      }
    }
  }

};
