<form [formGroup]="form">
  <div class="notes__container-border u-bgcolor-white u-mt-32">
    <p class="o-type-body-small u-mt-32 u-ml-32">
      Anmerkungen
    </p>
    <div class="input__container u-flex u-ai-center u-jc-center u-pt-10 u-pb-20 u-ph-20">
      <textarea
        formControlName="note"
        class="textArea__box u-1/1"
        name=""
        id=""
        rows="5"
      >
      </textarea>
    </div>
  </div>
</form>
