import { ColDef } from 'ag-grid-community';
import { createAction, props } from '@ngrx/store';

export const init = createAction(
  '[WaitingList] Init',
  props<{
    customerColumnDefs: ColDef[];
    formatColumnDefs: ColDef[];
    recordingColumnDefs: ColDef[];
  }>()
);
