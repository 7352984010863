<div *ngIf="!params.node.group">
<!--  <a-->
<!--    (click)="onShow($event)"-->
<!--    [routerLink]="linkShow"-->
<!--    [queryParams]="queryParamsShow"-->
<!--  >-->
<!--    <mat-icon>double_arrow</mat-icon>-->
<!--  </a>-->
  <button
    mat-icon-button
    color="primary"
    aria-label="Listen-Konfiguration übernehmen"
    matTooltip="Listen-Konfiguration übernehmen"
    matTooltipShowDelay="500"
    (click)="onShow($event)"
    [routerLink]="linkShow"
    [queryParams]="queryParamsShow"
  >
    <mat-icon>outbound</mat-icon>
  </button>
<!--  <a-->
<!--    *ngIf="'delete' | able: 'ListConfig'"-->
<!--    href="#"-->
<!--    (click)="onDelete($event)"-->
<!--  >-->
<!--    <mat-icon>delete</mat-icon>-->
<!--  </a>-->
  <button
    *ngIf="'delete' | able: 'ListConfig'"
    class="u-ml-8"
    mat-icon-button
    color="warn"
    aria-label="Eintrag löschen"
    matTooltip="Eintrag löschen"
    matTooltipShowDelay="500"
    (click)="onDelete($event)"
  >
    <mat-icon>delete</mat-icon>
  </button>
</div>
