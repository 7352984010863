import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { User } from 'src/app/core/graphql.model';

@Component({
  selector: 'app-user-header',
  templateUrl: './user-header.component.html',
  styleUrls: ['./user-header.component.scss'],
})
export class UserHeaderComponent {
  @Input() user: User;
  @Input() actionLoaderActive: boolean;

  @Output() formSubmit: EventEmitter<Partial<User>> = new EventEmitter();

  constructor() {}

  onToggleActive(event: MatSlideToggleChange): void {
    this.formSubmit.emit({ id: this.user.id, active: event.checked });
  }
}
