import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AbilityModule } from '@casl/angular';

@NgModule({
  declarations: [],
  imports: [CommonModule, AbilityModule],
  exports: [AbilityModule],
})
export class CaslModule {}
