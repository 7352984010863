import { NotifyToastService } from './notify-toast.service';
import { NotifyConsoleService } from './notify-console.service';
import { Injectable } from '@angular/core';

export enum NotificationType {
  Toast,
  Console,
}

export interface Notification {
  type: NotificationType;
  message?: string;
  duration?: number;
}

export interface NotifyServiceInterface {
  notify(notification: Notification): void;
}

@Injectable({
  providedIn: 'root',
})
export class NotifyService {
  constructor(
    private notifyToastService: NotifyToastService,
    private notifyConsoleService: NotifyConsoleService
  ) {}

  notify(notification: Notification): void {
    switch (notification.type) {
      case NotificationType.Toast: {
        this.notifyToastService.notify(notification);
        break;
      }
      case NotificationType.Console: {
        this.notifyConsoleService.notify(notification);
        break;
      }
      default: {
        console.error('Undefined Notification Type');
      }
    }
  }
}
