import { getMergedRoute } from './../../core/store/router/router-state.selectors';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Id } from 'src/app/core/model';
import * as fromBookingEntities from './entities/booking-entities.reducer';
import * as fromBookingLists from './lists/booking-lists.reducer';
import * as fromBooking from './booking.reducer';
import * as EntitiesSelectors from './entities/booking-entities.selectors';
import * as ListSelectors from './lists/booking-lists.selectors';

export const selectBookingState = createFeatureSelector<fromBooking.State>(
  fromBooking.bookingFeatureKey
);

// ENTITIES

export const selectEntitiesState = createSelector(
  selectBookingState,
  (state) => state[fromBookingEntities.bookingEntitiesFeatureKey]
);

export const getEntitiesBookings = createSelector(
  selectEntitiesState,
  EntitiesSelectors.getBookings
);

export const getEntitiesCurrentBookingId = createSelector(
  getMergedRoute,
  EntitiesSelectors.getCurrentBookingId
);

export const getEntitiesCurrentBooking = createSelector(
  selectEntitiesState,
  getEntitiesCurrentBookingId,
  (state, id) => state.entities[id]
);

export const getEntitiesCurrentDraftOrder = createSelector(
  selectEntitiesState,
  (state) => state.currentDraftOrderId ? state.entities[state.currentDraftOrderId] : null
);

export const getEntitiesBooking = (id: Id) =>
  createSelector(selectEntitiesState, (state) => state.entities[id]);

// LISTS

export const selectListState = createSelector(
  selectBookingState,
  (state) => state[fromBookingLists.bookingListsFeatureKey]
);

export const getListCustomer = createSelector(
  selectListState,
  ListSelectors.getCustomerList
);

export const getListRecording = createSelector(
  selectListState,
  ListSelectors.getRecordingList
);

export const getListRecordingParticipant = createSelector(
  selectListState,
  ListSelectors.getRecordingParticipantList
);
