import { Id } from './../model';
import { Injectable } from '@angular/core';
import { FilterModel } from './list.model';
import {
  ColumnApi,
  GridApi,
  ColumnState,
  GetLocaleTextParams,
} from 'ag-grid-community';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ListService {
  public gridApi: GridApi;
  public columnApi: ColumnApi;
  private initialFilter: FilterModel;
  private initialColumnsState: ColumnState[];
  private initialSelection: Id[];

  constructor(private _translate: TranslateService) {}

  setApis = (gridApi: GridApi, columnApi: ColumnApi) => {
    this.gridApi = gridApi;
    this.columnApi = columnApi;
    if (this.initialFilter) {
      this.setFilter(this.initialFilter);
      this.initialFilter = undefined;
    }
    if (this.initialColumnsState) {
      this.setColumnsState(this.initialColumnsState);
      this.initialColumnsState = undefined;
    }
    if (this.initialSelection) {
      this.setSelection(this.initialSelection);
      this.initialSelection = undefined;
    }
  };

  setFilter = (filter: FilterModel) => {
    if (!this.gridApi) {
      this.initialFilter = filter;
      return;
    }
    this.gridApi.setFilterModel(filter);
  };

  setColumnsState = (columnsState: ColumnState[]) => {
    if (!this.columnApi) {
      this.initialColumnsState = columnsState;
      return;
    }
    this.columnApi.applyColumnState({
      state: columnsState,
    });
  };

  // as we can't select ssrm entries before they are loaded, this is called each time new data arrives via pullConfigNewBlock action
  setSelection = (selection: Id[]) => {
    if (!this.gridApi) {
      this.initialSelection = selection;
      return;
    }
    this.gridApi.forEachNode((node) => {
      if (node.data) {
        node.setSelected(selection.includes(node.data.id), false, true);
      }
    });
  };

  getLocaleText = (params: GetLocaleTextParams) => {
    return this._translate.instant(`LIST.${params.key}`);
  };
}
