import { Component, Input } from '@angular/core';
import { BookingParticipantForm } from '../booking-form/booking-form.model';

@Component({
  selector: 'app-booking-item-participant-overview',
  templateUrl: './booking-item-participant-overview.component.html',
  styleUrls: ['./booking-item-participant-overview.component.scss'],
})
export class BookingItemParticipantOverviewComponent {
  @Input() bookingParticipant: BookingParticipantForm;

  constructor() {}
}
