import { formatsLink } from '../app-routing.module';
import { Id, CId, Link } from '../core/model';
import { environment } from 'src/environments/environment';

export enum RoutesNames {
  LIST = '',
  CREATE = 'create',
}

export const formatsListLink = (): Link =>
  formatsLink(); /*.concat(RoutesNames.LIST)*/
export const formatCreateLink = (): Link =>
  formatsLink().concat(RoutesNames.CREATE);
export const formatEditLink = (id: Id): Link => formatsLink().concat(id);

export const formatCmsHref = (cmsId: CId): string =>
  `${environment.cms_fe_base}/format/${cmsId}`;
