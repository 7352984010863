import { CustomerConnection, Tag } from './../core/graphql.model';
import {
  Address,
  ContactOption,
  ContactOptionType,
  CreateAddressInput,
  CreateContactOptionInput,
  Customer,
  CustomerFilter,
} from '../core/graphql.model';
import { removeGraphQlFields } from './customers.helpers';
import {
  appendArrayMutations,
  removeDefaultFields,
  removeStatisticFields,
} from './../core/graphql.helpers';
import { Id } from './../core/model';
import { map, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { ApolloQueryResult, FetchResult, WatchQueryFetchPolicy } from '@apollo/client/core';
import customersQuery from './graphql/customers-query.graphql';
import customerQuery from './graphql/customer-query.graphql';
import customerConnectionQuery from './graphql/customer-connection-query.graphql';
import customerUpdateMutation from './graphql/customer-update-mutation.graphql';
import customerCreateMutation from './graphql/customer-create-mutation.graphql';
import addressUpdateMutation from './graphql/address-update-mutation.graphql';
import contactOptionUpdateMutation from './graphql/contact-option-update-mutation.graphql';
import customerAddAddressMutation from './graphql/customer-add-address-mutation.graphql';
import customerAddContactOptionMutation from './graphql/customer-add-contact-option-mutation.graphql';
import customerAddTagMutation from './graphql/customer-add-tag-mutation.graphql';
import customerRemoveAddressMutation from './graphql/customer-remove-address-mutation.graphql';
import customerRemoveContactOptionMutation from './graphql/customer-remove-contact-option-mutation.graphql';
import customerRemoveTagMutation from './graphql/customer-remove-tag-mutation.graphql';
import combinedQuery from 'ts-blink-graphql-combine-query';

@Injectable({
  providedIn: 'root',
})
export class CustomersService {
  constructor(private apollo: Apollo) {}

  // CREATE

  createCustomer = (customer: Customer): Observable<Customer> => {
    const {
      addresses: _1,
      contactOptions: _2,
      tags: _3,
      birthDate,
      ...input
    } = removeDefaultFields(customer);
    const addresses: Address[] = customer.addresses.filter(address => !!address).map((address: Address) =>
      removeDefaultFields(address)
    );
    const additionalContactOptions: CreateContactOptionInput[] =
      customer.contactOptions.map((contactOption: ContactOption) =>
        removeDefaultFields(contactOption as CreateContactOptionInput)
      );
    const primaryContactOption: CreateContactOptionInput =
      additionalContactOptions.shift();
    const additionalAddresses: CreateAddressInput[] = addresses
      .filter((address) => address.prio !== 0)
      .map((address: Address) => {
        const { id, version, createdAt, updatedAt, ...rest } = address;
        return {
          ...rest,
        } as CreateAddressInput;
      });
    const primaryAddress = addresses.find(
      (address) => address.prio === 0
    ) as CreateAddressInput;

    const optional: any = {};
    if (!!birthDate) {
      optional.birthDate = birthDate;
    }
    if (!!primaryAddress) {
      optional.primaryAddress = primaryAddress;
    }
    if (additionalAddresses.length > 0) {
      optional.additionalAddresses = additionalAddresses;
    }
    if (additionalContactOptions.length > 0) {
      optional.additionalContactOptions = additionalContactOptions;
    }

    return this.apollo
      .mutate({
        mutation: customerCreateMutation ,
        variables: {
          input: {
            ...input,
            primaryContactOption,
            ...optional,
          },
        },
      })
      .pipe(
        // @TODO read success of each mutation
        map((result: FetchResult<any>) => {
          return result.data?.createCustomer?.customer as Customer;
        })
      );
  };

  // READ

  loadCustomers = (): Observable<Customer[]> => {
    return this.apollo
      .watchQuery<Customer[]>({
        query: customersQuery ,
      })
      .valueChanges.pipe(
        map((result: ApolloQueryResult<any>) =>
          result.data?.customers.map((customer: Customer) =>
            removeGraphQlFields(customer)
          )
        )
      );
  };

  loadCustomer = (id: Id, force: boolean = false): Observable<Customer> => {
    const forcedOptions = force ? { fetchPolicy: 'no-cache' as WatchQueryFetchPolicy } : {}
    return this.apollo
      .watchQuery<Customer>({
        query: customerQuery ,
        variables: {
          id,
        },
        ...forcedOptions,
      })
      .valueChanges.pipe(
        map((result: ApolloQueryResult<any>) =>
          removeGraphQlFields(result.data?.customer)
        )
      );
  };

  loadCustomerConnection = (
    filter: CustomerFilter,
    blockSize: number,
    after: string = ''
  ): Observable<CustomerConnection> => {
    return this.apollo
      .watchQuery<CustomerConnection>({
        query: customerConnectionQuery ,
        variables: {
          first: blockSize,
          after,
          filter,
        },
      })
      .valueChanges.pipe(
        map((result: ApolloQueryResult<any>) => result.data?.customerConnection)
      );
  };
  // UPDATE

  updateCustomer = (
    customer: Customer,
    oldCustomer?: Customer
  ): Observable<Customer> => {
    // prepare base diff
    const {
      addresses: _1,
      contactOptions: _2,
      tags: _3,
      createdAt: _4,
      updatedAt: _5,
      ...input
    } = customer;

    // create combined mutation
    const { document, variables } = ((): any => {
      let cq = combinedQuery('CombinedCustomerUpdate').add(
        customerUpdateMutation,
        {
          updateCustomerInput: input,
        }
      );
      cq = appendArrayMutations(
        cq,
        'address',
        customer.addresses.filter(address => !!address),
        oldCustomer?.addresses || [],
        customerAddAddressMutation,
        (address: Address) => ({
          customerIdAddAddress: customer.id,
          address: removeDefaultFields(address),
        }),
        customerRemoveAddressMutation,
        (id: Id) => ({
          customerIdRemoveAddress: customer.id,
          addressId: id,
        }),
        addressUpdateMutation,
        (address: Address) => {
          const updateAddressInput = removeStatisticFields(address);
          if (address.prio === 0) {
            delete updateAddressInput.type;
          }
          return { updateAddressInput };
        }
      );
      cq = appendArrayMutations(
        cq,
        'contact_options',
        customer.contactOptions,
        oldCustomer?.contactOptions.filter((contactOption) =>
          [ContactOptionType.EMAIL, ContactOptionType.PHONE].includes(
            contactOption.type
          )
        ) || [],
        customerAddContactOptionMutation,
        (contactOption: ContactOption) => ({
          customerIdAddContactOption: customer.id,
          contactOption: removeDefaultFields(contactOption),
        }),
        customerRemoveContactOptionMutation,
        (id: Id) => ({
          customerIdRemoveContactOption: customer.id,
          contactOptionId: id,
        }),
        contactOptionUpdateMutation,
        (contactOption: ContactOption) => ({
          updateContactOptionInput: removeStatisticFields(contactOption),
        })
      );
      cq = appendArrayMutations(
        cq,
        'tags',
        customer.tags,
        oldCustomer?.tags || [],
        // add
        customerAddTagMutation,
        (tag: Tag) => ({
          input_tag_add: {
            customerId: customer.id,
            tagId: tag.id,
          }
        }),
        // remove
        customerRemoveTagMutation,
        (id: Id) => ({
          customerIdRemoveTag: customer.id,
          tagId: id,
        }),
      );
      return cq;
    })();

    // execute mutation
    return this.apollo
      .mutate({
        mutation: document as any,
        variables,
      })
      .pipe(
        switchMap(() => {
          return this.loadCustomer(customer.id, true)
        })
      );
  };

  // DELETE
}
