export const environment = {
  name: 'INTEGRATION',
  indicator_color: 'yellow',
  production: false,
  api_base: 'https://api.blab.integration.diepartments.dev/graphql',
  cms_fe_base: 'https://cloud.squidex.io/app/blab-integration/content',
  mail_info_base: 'https://my.brevo.com/camp/report/id',
  mail_recipients_base: 'https://app.brevo.com/contact/list/id',
  timeoutSec: 30,
  msal_client_id: 'b6b3aeb9-394c-4668-a493-c559ea0abcaf',
  masl_authority: 'https://login.microsoftonline.com/658c56c5-4422-4da6-963b-3f364bd68105',
};
