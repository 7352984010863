<ng-container [formGroup]="form">
  <input type="hidden" [id]="'id-' + idx" formControlName="id" />
  <input type="hidden" [id]="'version-' + idx" formControlName="version" />
  <input type="hidden" [id]="'prio-' + idx" formControlName="prio" />

  <div class="o-grid">
    <!-- contact options-->
    <div class="o-grid__col u-6/19">
      <mat-form-field class="u-1/1">
        <mat-label for="category">
          {{ "CONTACT_OPTION.CATEGORY.LABEL" | translate }}
        </mat-label>
        <mat-select formControlName="category">
          <mat-option [value]="ContactOptionCategory.BUSINESS">
            {{ "CONTACT_OPTION.CATEGORY.OPTION.BUSINESS" | translate }}
          </mat-option>
          <mat-option [value]="ContactOptionCategory.PRIVATE">
            {{ "CONTACT_OPTION.CATEGORY.OPTION.PRIVATE" | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
      <!-- phone input-->
      <div
        class="o-grid__col u-13/19"
        *ngIf="form.get('type').value === ContactOptionType.PHONE"
      >
        <mat-form-field class="u-1/1">
          <mat-label [attr.for]="'phoneOption-' + idx">
            {{ "CUSTOMERS.CUSTOMER_FORM.PHONE.LABEL" | translate }}
          </mat-label>
          <input
            matInput
            type="tel"
            placeholder="163-2839561"
            [id]="'phoneOption-' + idx"
            formControlName="value"
          />
          <mat-error>
            <ng-container [ngSwitch]="true">
              <div *ngSwitchCase="form.get('value').errors?.required">
                {{ "CUSTOMERS.CUSTOMER_FORM.PHONE.ERRORS.REQUIRED" | translate }}
              </div>
            </ng-container>
          </mat-error>
        </mat-form-field>
      </div>
      <!-- email container -->
      <div
        class="o-grid__col u-13/19"
        *ngIf="form.get('type').value === ContactOptionType.EMAIL"
      >
        <mat-form-field class="u-1/1">
          <mat-label [attr.for]="'emailOption-' + idx">
            {{ "CUSTOMERS.CUSTOMER_FORM.EMAIL.LABEL" | translate }}
          </mat-label>
          <input
            matInput
            type="email"
            placeholder="Ex.pat@example.com"
            [id]="'emailOption-' + idx"
            formControlName="value"
          />
          <mat-error>
            <ng-container [ngSwitch]="true">
              <div *ngSwitchCase="form.get('value').errors?.required">
                {{ "CUSTOMERS.CUSTOMER_FORM.EMAIL.ERRORS.REQUIRED" | translate }}
              </div>
            </ng-container>
          </mat-error>
        </mat-form-field>
      </div>
    </div>
</ng-container>
