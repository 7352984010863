import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Injectable()
export class CustomerSearchformService {
  form: FormGroup = this.createForm();

  constructor(private fb: FormBuilder) {}

  private createForm(): FormGroup {
    return this.fb.group({
      firstName: [''],
      lastName: [''],
      address: [''],
      // birthDate: [''],
      // address: this.fb.array([]),
    });
    // @TODO check required and validators
  }
}
