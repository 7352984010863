import { ICellRendererParams } from 'ag-grid-community';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Component } from '@angular/core';
import { Link } from 'src/app/core/model';
import { WaitingListsFacadeService } from 'src/app/waiting-lists/waiting-lists-facade.service';

@Component({
  selector: 'app-waiting-lists-list-actions-cell',
  templateUrl: './waiting-lists-list-actions-cell-renderer.component.html',
})
export class WaitingListsListActionsCellRendererComponent
  implements ICellRendererAngularComp
{
  params: ICellRendererParams;
  linkShow: Link;
  queryParamsShow: any;

  constructor(private facade: WaitingListsFacadeService) {}

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  refresh(params: ICellRendererParams): boolean {
    this.params = params;
    return true;
  }

  onDelete(): void {
    this.facade.deleteWaitingListEntry(this.params.data.id);
  }
}
