import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Contact } from 'src/app/core/graphql.model';

@Injectable()
export class FormatContactPersonFormService {
  form: FormGroup = this.createForm();

  set contact(contact: Contact) {
    if (!contact) {
      return;
    }
    this.form.patchValue(contact);
  }

  get contact(): Contact {
    return this.form.value;
  }

  constructor(private fb: FormBuilder) {}

  private createForm(): FormGroup {
    return this.fb.group({
      id: [''], // hidden
      type: [''],
      name: [''],
      phone: [''],
      email: [''],
      isDeletable: [''], // hidden
    });
  }
  // @TODO check required and validators
}
