import {
  ContactOptionCategory,
  ContactOptionType,
} from 'src/app/core/graphql.model';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  Validator,
} from '@angular/forms';
import { Component, forwardRef, Input } from '@angular/core';
import { ContactOptionFormService } from './contact-options-form.service';
import { BaseSubFormComponent } from 'src/app/core/base/base-sub-form.component';

@Component({
  selector: 'app-contact-option-form',
  templateUrl: './contact-option-form.component.html',
  styleUrls: ['./contact-option-form.component.scss'],
  providers: [
    ContactOptionFormService,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ContactOptionFormComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ContactOptionFormComponent),
      multi: true,
    },
  ],
})
export class ContactOptionFormComponent
  extends BaseSubFormComponent
  implements ControlValueAccessor, Validator
{
  ContactOptionType = ContactOptionType;
  ContactOptionCategory = ContactOptionCategory;

  @Input() isPrimary = false;

  @Input() idx: number | string;

  @Input() set disabled(disabled: boolean) {
    this.setDisabledState(disabled);
  }

  constructor(private formService: ContactOptionFormService) {
    super();
    this.form = this.formService.form;
  }

  writeValue(val: any): void {
    if (val) {
      this.formService.contactOption = val;
    }
  }
}
