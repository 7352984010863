import { Action, combineReducers, ActionReducer } from '@ngrx/store';
import * as fromFormatEntities from './entities/format-entities.reducer';
import * as fromFormatLists from './lists/format-lists.reducer';

export const formatFeatureKey = 'format';

export interface State {
  [fromFormatEntities.formatEntitiesFeatureKey]: fromFormatEntities.State;
  [fromFormatLists.formatListsFeatureKey]: fromFormatLists.State;
}

export function reducers(
  state: State | undefined,
  action: Action
): ActionReducer<State, any> {
  // @ts-ignore
  return combineReducers({
    [fromFormatEntities.formatEntitiesFeatureKey]: fromFormatEntities.reducer,
    [fromFormatLists.formatListsFeatureKey]: fromFormatLists.reducer,
  })(state, action);
}
