"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createForUser = exports.Subject = exports.UserRole = exports.Action = void 0;
const superadmin_1 = require("./roles/superadmin");
const admin_1 = require("./roles/admin");
const supervisor_1 = require("./roles/supervisor");
const user_1 = require("./roles/user");
const guest_1 = require("./roles/guest");
const shopuser_1 = require("./roles/shopuser");
var Action;
(function (Action) {
    Action["Create"] = "create";
    Action["Read"] = "read";
    Action["Update"] = "update";
    Action["Delete"] = "delete";
})(Action = exports.Action || (exports.Action = {}));
var UserRole;
(function (UserRole) {
    UserRole["superadmin"] = "superadmin";
    UserRole["admin"] = "admin";
    UserRole["supervisor"] = "supervisor";
    UserRole["user"] = "user";
    UserRole["guest"] = "guest";
    UserRole["shopuser"] = "shopuser";
})(UserRole = exports.UserRole || (exports.UserRole = {}));
var Subject;
(function (Subject) {
    Subject["User"] = "User";
    Subject["Customer"] = "Customer";
    Subject["ListConfig"] = "ListConfig";
    Subject["WaitingList"] = "WaitingList";
    Subject["Format"] = "Format";
    Subject["Recording"] = "Recording";
    Subject["Booking"] = "Booking";
    Subject["Tag"] = "Tag";
    Subject["Mail"] = "Mail";
})(Subject = exports.Subject || (exports.Subject = {}));
function matchRole(p) {
    return (role) => {
        switch (role) {
            case "superadmin":
                return p.superadmin();
            case "admin":
                return p.admin();
            case "supervisor":
                return p.supervisor();
            case "guest":
                return p.guest();
            case "user":
                return p.user();
            case "shopuser":
                return p.shopuser();
        }
    };
}
function createForUser(user, builder) {
    const match = matchRole({
        superadmin: () => new superadmin_1.Superadmin(),
        admin: () => new admin_1.Admin(),
        supervisor: () => new supervisor_1.Supervisor(),
        user: () => new user_1.User(),
        guest: () => new guest_1.Guest(),
        shopuser: () => new shopuser_1.Shopuser(),
    });
    if (user.roles && user.roles.length > 0) {
        for (const role of user.roles) {
            builder = match(role).can(builder);
        }
    }
    return builder.build();
}
exports.createForUser = createForUser;
