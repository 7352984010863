import { recordingCreateByFormatLink } from './../../recordings-routing-helper';
import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { FormatsFacadeService } from 'src/app/formats/formats-facade.service';
import { RecordingsFacadeService } from '../../recordings-facade.service';
import { Recording } from 'src/app/core/graphql.model';
import { ROUTE_QUERY_PARAM_FORMAT_ID } from 'src/app/core/route-params';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-recording-create-page',
  templateUrl: './recording-create-page.component.html',
  styleUrls: ['./recording-create-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecordingCreatePageComponent implements OnInit {
  selectedFormatId: number;

  recordings: Recording[] = [];

  recordingCreateByFormatLink = recordingCreateByFormatLink;

  constructor(
    public formatsFacade: FormatsFacadeService,
    public recordingsFacade: RecordingsFacadeService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.selectedFormatId = +this.activatedRoute.snapshot.queryParams[
      ROUTE_QUERY_PARAM_FORMAT_ID
    ];
    if (this.selectedFormatId) {
      this.onFormatChange();
    }
  }

  onFormatChange(): void {
    this.recordingsFacade.loadRecordingsByFormat(this.selectedFormatId);
  }
}
