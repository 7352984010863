<div class="o-container u-height-1/1 u-flex u-fd-col u-pb-24 u-pt-12">
  <h1 class="o-type-h4 u-mb-24">
    {{ "RECORDINGS.RECORDINGS_PAGE.TITLE" | translate }}
  </h1>

  <app-recordings-list
    [columnDefs]="(facade.listOverview$ | async)?.columnDefs"
    [data]="(facade.recordings$ | async) || []"
    [groupableByFormat]="true"
  >
  </app-recordings-list>

  <div class="c-floating-button-wrapper">
    <button
      *ngIf="'create' | able: 'Recording'"
      mat-fab
      color="primary"
      matTooltip="Neue Aufzeichnung anlegen"
      matTooltipShowDelay="500"
      [routerLink]="recordingCreateLink"
    >
      <mat-icon>add</mat-icon>
    </button>
  </div>
  <!-- @TODO does not preview url on hover -->
</div>
