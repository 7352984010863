import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { delay, first, mergeMap, startWith } from 'rxjs/operators';
import { of } from 'rxjs';
import * as FormatListsActions from './format-lists.actions';
import { FormatColumnDefsService } from './format-list-column-defs.service';

@Injectable()
export class FormatListsEffects {
  constructor(
    private actions$: Actions,
    private baseColumnDefsService: FormatColumnDefsService
  ) {}

  init$ = createEffect(() =>
    this.actions$.pipe(
      startWith({ type: 'INIT_TRIGGER' }),
      first(),
      delay(100), // see https://github.com/ngrx/platform/issues/683#issuecomment-517888082
      mergeMap(() =>
        of(
          FormatListsActions.init({
            columnDefs: this.baseColumnDefsService.columnDefs,
          })
        )
      )
    )
  );
}
