<!-- Panel Content-->
<input type="hidden" id="id" />
<div class="o-grid u-mb-24" style="--mdc-shape-small: 0; --mdc-filled-button-container-shape: 0 4px 4px 0;">
  <div class="o-grid__col u-2/36">
    <div class="c-formfield-placeholder">
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M26.1855 11C26.1855 13.7614 24.447 15.5 21.6855 15.5C19.6855 15 17.1855 12.5 16.6855 10.5C16.6855 7.73858 18.4241 6 21.1855 6C23.947 6 26.1855 8.23858 26.1855 11Z" fill="#979797"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M17.1855 15L15.1093 12L6 22.8543L7.1855 25L9.25621 26.0693L19.9936 16.8225L17.1855 15ZM16.8534 16.8406L15.0195 15L12.1859 17.8233L14.0199 19.6639L16.8534 16.8406Z" fill="#979797"/>
      </svg>
    </div>
  </div>
  <div class="o-grid__col u-22/36">
    <mat-form-field class="dropdown__container u-1/1">
      <mat-label for="title">
        {{ "FORMATS.FORMAT_FORM.LABEL" | translate }}
      </mat-label>
      <mat-select
        name="Options"
        [(ngModel)]="selectedFormat"
      >
        <mat-option *ngFor="let format of formats" [value]="format">
          {{ format.title }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="o-grid__col u-9/36 u-pl-none">
    <button
      class="btn__size u-1/1"
      mat-flat-button
      color="accent"
      type="submit"
      [disabled]="!selectedFormat"
      (click)="onCreateWaitingList()"
    >
      <mat-icon>add</mat-icon>
      Warteliste hinzufügen
    </button>
  </div>
</div>
