import { ColDef, FirstDataRenderedEvent } from 'ag-grid-community';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  columnTypes,
  defaultColDef,
  sideBar,
  statusBar,
} from '../../../core/list/list.definitions';
import { WaitingListsListActionsCellRendererComponent } from './cell-renderers/waiting-lists-list-actions-cell-renderer.component';
import { WaitingListEntry } from 'src/app/core/graphql.model';

@Component({
  selector: 'app-waiting-lists-list',
  templateUrl: './waiting-lists-list.component.html',
  styleUrls: ['./waiting-lists-list.component.scss'],
})
export class WaitingListsListComponent {
  @Input() data: WaitingListEntry[] = [];
  @Input() columnDefs: ColDef[];

  @Output() cellValueChanged: EventEmitter<WaitingListEntry> = new EventEmitter();

  components = {
    actionsCellRenderer: WaitingListsListActionsCellRendererComponent,
  };

  columnTypes = columnTypes;
  defaultColDef = defaultColDef;
  sideBar = sideBar;
  statusBar = statusBar;

  constructor() {}

  onFirstDataRendered = (event: FirstDataRenderedEvent) => {
    event.columnApi.autoSizeAllColumns();
  }
}
