import { createAction, props } from '@ngrx/store';
import { Id } from 'src/app/core/model';
import { ListConfig } from '../../list-configs.model';

// CREATE

export const createListConfig = createAction(
  '[ListConfig] Create',
  props<{ data: ListConfig }>()
);
export const createListConfigSuccess = createAction(
  '[ListConfig] Create Success',
  props<{ data: ListConfig }>()
);
export const createListConfigFailure = createAction(
  '[ListConfig] Create Failure',
  props<{ error: any }>()
);

// READ

export const loadListConfigs = createAction('[ListConfig] Load All');
export const loadListConfigsSuccess = createAction(
  '[ListConfig] Load All Success',
  props<{ data: ListConfig[] }>()
);
export const loadListConfigsFailure = createAction(
  '[ListConfig] Load All Failure',
  props<{ error: any }>()
);

// UPDATE

export const updateListConfig = createAction(
  '[ListConfig] Update',
  props<{ data: ListConfig }>()
);
export const updateListConfigByRoute = createAction(
  '[ListConfig] Update By Route'
);
export const updateListConfigSuccess = createAction(
  '[ListConfig] Update Success',
  props<{ data: ListConfig }>()
);
export const updateListConfigFailure = createAction(
  '[ListConfig] Update Failure',
  props<{ error: any }>()
);

// DELETE

export const deleteListConfig = createAction(
  '[ListConfig] Delete',
  props<{ id: Id }>()
);
export const deleteListConfigSuccess = createAction(
  '[ListConfig] Delete Success',
  props<{ id: Id }>()
);
export const deleteListConfigFailure = createAction(
  '[ListConfig] Delete Failure',
  props<{ error: any }>()
);
