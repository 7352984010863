import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  CreateWaitingListEntryInput,
  Format,
  Recording,
  WaitingListEntryType,
} from 'src/app/core/graphql.model';
import { Id } from 'src/app/core/model';

@Component({
  selector: 'app-ticket-list-create-form',
  templateUrl: './ticket-list-create-form.component.html',
  styleUrls: ['./ticket-list-create-form.component.scss'],
})
export class TicketListCreateFormComponent {
  selectedFormatId: Id;
  selectedRecording: Recording;

  @Input() formats: Format[];
  @Input() recordings: Recording[];

  @Output() createTicketListEntry: EventEmitter<CreateWaitingListEntryInput> =
    new EventEmitter();
  @Output() formatSelected: EventEmitter<Id> = new EventEmitter();

  constructor() {}

  onFormatSelected(): void {
    this.formatSelected.emit(this.selectedFormatId);
  }

  onCreateTicketListEntry(): void {
    this.createTicketListEntry.emit({
      customerId: null, // will be set in effect from route
      formatOrRecordingId: this.selectedRecording.id,
      type: WaitingListEntryType.TICKETALARM,
    });
  }
}
