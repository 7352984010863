import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromWaitingList from './waiting-list.reducer';
import * as fromWaitingListEntities from './entities/waiting-list-entities.reducer';
import * as fromWaitingListLists from './lists/waiting-list-lists.reducer';
import * as EntitiesSelectors from './entities/waiting-list-entities.selectors';
import * as ListSelectors from './lists/waiting-list-lists.selectors';

export const selectWaitingListState =
  createFeatureSelector<fromWaitingList.State>(
    fromWaitingList.waitingListfeatureKey
  );

// ENTITIES

export const selectEntitiesState = createSelector(
  selectWaitingListState,
  (state) => state[fromWaitingListEntities.waitingListEntitiesFeatureKey]
);

export const getEntitiesWaitingList = createSelector(
  selectEntitiesState,
  EntitiesSelectors.getWaitingList
);

// LISTS

export const selectListState = createSelector(
  selectWaitingListState,
  (state) => state[fromWaitingListLists.waitingListListsFeatureKey]
);

export const getListCustomer = createSelector(
  selectListState,
  ListSelectors.getCustomerList
);

export const getListFormat = createSelector(
  selectListState,
  ListSelectors.getFormatList
);

export const getListRecording = createSelector(
  selectListState,
  ListSelectors.getRecordingList
);
