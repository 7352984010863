import { Routes } from '@angular/router';
import { Action, Subject } from '@blab/blab-authorization';
import { BookingsResolver } from 'src/app/bookings/bookings.resolver';
import { LOADER_ID_TAB } from 'src/app/core/base/base-tab-page.component';
import { CaslAuthGuard } from 'src/app/core/casl/casl-auth.guard';
import { Permission } from 'src/app/core/model';
import { FormatsResolver } from 'src/app/formats/formats.resolver';
import { TagsResolver } from 'src/app/tags/tags.resolver';
import { WaitingListsResolver } from 'src/app/waiting-lists/waiting-lists.resolver';
import { CustomerBookingsTabComponent } from './customer-bookings-tab/customer-bookings-tab.component';
import { CustomerProfilTabComponent } from './customer-profil-tab/customer-profil-tab.component';
import { RouteNames } from './customer-routing-helper';
import { CustomerWaitingListsTabComponent } from './customer-waiting-lists-tab/customer-waiting-lists-tab.component';

export const routes: Routes = [
  {
    path: RouteNames.PROFILE,
    component: CustomerProfilTabComponent,
    pathMatch: 'full',
    canActivate: [CaslAuthGuard],
    resolve: {
      tabs: TagsResolver,
    },
    data: {
      loader: LOADER_ID_TAB,
      auth: [
        {
          subject: Subject.Tag,
          action: Action.Read,
        },
      ] as Permission[],
    },
  },
  {
    path: RouteNames.BOOKINGS,
    component: CustomerBookingsTabComponent,
    canActivate: [CaslAuthGuard],
    resolve: {
      bookings: BookingsResolver,
    },
    data: {
      loader: LOADER_ID_TAB,
      auth: [
        {
          subject: Subject.Booking,
          action: Action.Read,
        },
      ] as Permission[],
    },
  },
  {
    path: RouteNames.WAITING_LISTS,
    component: CustomerWaitingListsTabComponent,
    canActivate: [CaslAuthGuard],
    resolve: {
      formats: FormatsResolver,
      waitingLists: WaitingListsResolver,
    },
    data: {
      loader: LOADER_ID_TAB,
      auth: [
        {
          subject: Subject.WaitingList,
          action: Action.Read,
        },
      ] as Permission[],
    },
  },
];
