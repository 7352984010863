<div class="customerRoles__container">
  <!--
  <mat-form-field appearance="outline">
    <mat-label>Kundengruppe wählen</mat-label>
    <mat-select>
      <mat-option *ngFor="let role of roles" [value]="role.value">
        {{ role.viewValue }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-icon>edit</mat-icon>
  -->

  <!-- @TODO does this refer to customer roles? If not move somewhere else -->
  <div class="customerRoles__items-container">
    <p class="u-mt-8">
      <span class="o-type-overline u-color-grey-80">
        Letztes Update: {{ customer?.updatedAt | date: "medium" }}
      </span>
    </p>
    <!-- <p class="customerRoles__items-margin">UserPlaceholder</p> -->
    <!-- <p class="customerRoles__items-margin">
      Datum:
      {{ customer?.createdAt | date: "medium" }}
    </p> -->

    <!-- <a class="customerRoles__items-margin" href="#">History anzeigen</a> -->
  </div>
</div>
