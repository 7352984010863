import { recordingCreateLink } from './../../recordings-routing-helper';
import { RecordingsFacadeService } from '../../recordings-facade.service';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-recordings-page',
  templateUrl: './recordings-page.component.html',
  styleUrls: ['./recordings-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecordingsPageComponent {
  recordingCreateLink = recordingCreateLink();

  constructor(public facade: RecordingsFacadeService, private router: Router) {}
}
