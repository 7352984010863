import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { select, Store } from '@ngrx/store';
import { map, take } from 'rxjs/operators';
import * as UserSelectors from './store/user.selectors';
import * as fromUsers from './store/user.reducer';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoginGuard implements CanActivate {
  constructor(private store: Store<fromUsers.State>, private router: Router) {}

  public canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    return this.store.pipe(
      select(UserSelectors.isAuthAuthenticated),
      map((isAuthenticated) => {
        if (isAuthenticated) {
          return this.router.parseUrl('/');
        }
        return true;
      }),
      take(1)
    );
  }
}
