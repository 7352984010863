import { ListConfig } from './list-configs.model';
import {
  removeDefaultFields,
  removeGraphQlFields,
} from '../core/graphql.helpers';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { ApolloQueryResult, FetchResult } from '@apollo/client/core';
import listConfigCreateMutation from './graphql/list-config-create-mutation.graphql';
import ListConfigsQuery from './graphql/list-configs-query.graphql';
import listConfigUpdateMutation from './graphql/list-config-update-mutation.graphql';
import listConfigDeleteMutation from './graphql/list-config-delete-mutation.graphql';
import { Id } from '../core/model';

@Injectable({
  providedIn: 'root',
})
export class ListConfigsService {
  constructor(private apollo: Apollo) {}

  // CREATE

  createListConfig = (listConfig: ListConfig): Observable<ListConfig> => {
    const { ...input } = removeDefaultFields(listConfig);
    return this.apollo
      .mutate({
        mutation: listConfigCreateMutation ,
        variables: {
          input: {
            ...input,
          },
        },
      })
      .pipe(
        map((result: FetchResult<any>) => {
          return result.data?.createListConfig?.listConfig as ListConfig;
        })
      );
  };

  // READ

  loadListConfigs = (): Observable<ListConfig[]> => {
    return this.apollo
      .watchQuery<ListConfig[]>({
        query: ListConfigsQuery ,
      })
      .valueChanges.pipe(
        map((result: ApolloQueryResult<any>) =>
          result.data?.listConfigs?.map((listConfig: ListConfig) =>
            removeGraphQlFields(listConfig)
          )
        )
      );
  };

  // UPDATE

  updateListConfig = (listConfig: ListConfig): Observable<ListConfig> => {
    const { ...input } = listConfig;
    return this.apollo
      .mutate({
        mutation: listConfigUpdateMutation ,
        variables: {
          input: {
            ...input,
          },
        },
      })
      .pipe(
        map((result: FetchResult<any>) =>
          removeGraphQlFields(
            result.data?.updateListConfig?.listConfig as ListConfig
          )
        )
      );
  };

  // DELETE

  deleteListConfig = (id: Id): Observable<boolean> => {
    return this.apollo
      .mutate({
        mutation: listConfigDeleteMutation ,
        variables: {
          input: {
            id,
          },
        },
      })
      .pipe(map((result: FetchResult<any>) => true));
  };
}
