import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ListModule } from '../core/list/list.module';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '../core/material.module';
import { RecordingsListComponent } from './components/recordings-list/recordings-list.component';

@NgModule({
  declarations: [RecordingsListComponent],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule.forChild({
      extend: true,
    }),
    MaterialModule,
    ListModule,
  ],
  exports: [RecordingsListComponent],
})
export class RecordingsListModule {}
