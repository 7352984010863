<div class="searchbar__container u-flex u-ai-center u-jc-between u-bgcolor-grey-110 u-pv-8 u-ph-8">
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.09402 0C0.489808 0 0 0.477563 0 1.06667V15.3333H24.0684C28.4489 15.3333 32 11.9008 32 7.66667C32 3.43248 28.4489 0 24.0684 0H1.09402Z" fill="#FF4370"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M24.0684 16.667H0V30.9337C0 31.5228 0.489809 32.0003 1.09402 32.0003H24.0684C28.4489 32.0003 32 28.5678 32 24.3337C32 20.0995 28.4489 16.667 24.0684 16.667Z" fill="#FF4370"/>
  </svg>

<!--  <div style="position: relative">-->
<!--    <mat-form-field class="example-form-field" appearance="outline">-->
<!--      <input matInput type="text" [(ngModel)]="searchBarValue" />-->
<!--      &lt;!&ndash; @TODO-->
<!--      in input : [matAutocomplete]="auto"-->
<!--      <mat-autocomplete #auto="matAutocomplete">-->
<!--      <mat-option *ngFor="let option of filteredOptions | async" [value]="option">-->
<!--        {{option}}-->
<!--      </mat-option>-->
<!--    </mat-autocomplete>-->
<!--    &ndash;&gt;-->
<!--      <button-->
<!--        *ngIf="searchBarValue"-->
<!--        matSuffix-->
<!--        mat-icon-button-->
<!--        aria-label="Clear"-->
<!--        (click)="searchBarValue = ''"-->
<!--      >-->
<!--        &lt;!&ndash;<mat-icon>search</mat-icon> @TODO&ndash;&gt;-->
<!--        <mat-icon>close</mat-icon>-->
<!--      </button>-->

<!--      <button-->
<!--        matSuffix-->
<!--        mat-icon-button-->
<!--        aria-label="Drop"-->
<!--        (click)="onDropdownClicked()"-->
<!--      >-->
<!--        <mat-icon>arrow_drop_down</mat-icon>-->
<!--      </button>-->
<!--    </mat-form-field>-->

<!--    <div class="casscadingForm_container" *ngIf="dropDownSelected">-->
<!--      <app-cascading-searchform-page></app-cascading-searchform-page>-->
<!--    </div>-->
<!--  </div>-->

<!--  <button class="searchbar__btn-search">Suchen</button>-->

  <div class="u-flex u-ai-center">
    <p class="o-type-body-small u-color-white u-mr-12">Vanessa</p>
    <img
      class="searchbar__profilePic-size"
      src="../../assets/profilePic.jpg"
      alt=""
    />
  </div>

</div>
