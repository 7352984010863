import { Component, Input } from '@angular/core';
import { roles } from 'src/app/core/tmp';
import { Customer } from 'src/app/core/graphql.model';

// @TODO rename this

@Component({
  selector: 'app-customer-roles',
  templateUrl: './customer-roles.component.html',
  styleUrls: ['./customer-roles.component.scss'],
})
export class CustomerRolesComponent {
  @Input() customer: Customer;

  roles = roles;

  constructor() {}
}
