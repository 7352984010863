import { AuthTokenInterceptor } from './auth-token.interceptor';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule, Store } from '@ngrx/store';
import * as fromUser from './store/user.reducer';
import { EffectsModule } from '@ngrx/effects';
import { UserEffects } from './store/user.effects';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { MaterialModule } from '../core/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UsersPageComponent } from './pages/users-page/users-page.component';
import { UsersListComponent } from './components/users-list/users-list.component';
import { UsersRoutingModule } from './users-routing.module';
import { UserActionsCellRendererComponent } from './components/users-list/cell-renderers/user-actions-cell-renderer.component';
import { UserPageComponent } from './pages/user-page/user-page.component';
import { UserHeaderComponent } from './components/user-header/user-header.component';
import { UserProfilTabComponent } from './pages/user-page/user-profil-tab/user-profil-tab.component';
import { CustomersModule } from '../customers/customers.module';
import { UserFormComponent } from './components/user-form/user-form.component';
import { ListModule } from '../core/list/list.module';
import { LayoutModule } from '../layout/layout.module';
import { CaslModule } from '../core/casl/casl.module';

export function authInitializer(
  store: Store<fromUser.State>
): () => Promise<any> {
  return () => {
    return Promise.resolve(null);
  };
}

@NgModule({
  declarations: [
    LoginPageComponent,
    UsersPageComponent,
    UsersListComponent,
    UserActionsCellRendererComponent,
    UserPageComponent,
    UserHeaderComponent,
    UserProfilTabComponent,
    UserFormComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    StoreModule.forFeature(fromUser.userFeatureKey, fromUser.reducers),
    EffectsModule.forFeature(UserEffects),
    UsersRoutingModule,
    LayoutModule,
    ListModule,
    CaslModule,
    CustomersModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthTokenInterceptor, multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: authInitializer,
      deps: [Store],
      multi: true,
    },
  ],
})
export class UsersModule {}
