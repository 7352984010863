import { mergeDateAndTime, replaceBaseDate } from './../../../core/i18n-helpers';
import { updateNestedFormElements } from './../../../core/form.helpers';
import {
  FormControl,
  Validators,
  FormArray,
  FormBuilder,
  FormGroup,
} from '@angular/forms';
import { Injectable } from '@angular/core';
import { Format, Product, Recording } from 'src/app/core/graphql.model';
import { formatTime } from 'src/app/core/i18n-helpers';
import { CId } from 'src/app/core/model';

interface RecordingForm
  extends Omit<
    Recording,
    'season' | 'productionStart' | 'eventStart' | 'eventEnd'
  > {
  seasonId: CId;
  productionDate: string;
  productionStartTime: string;
  eventStartTime: string;
  eventEndTime: string;
}

@Injectable()
export class RecordingFormService {
  readonly form: FormGroup = this.createRecording();

  set recording(recording: Recording) {
    if (!recording) {
      return;
    }

    updateNestedFormElements(
      this.form,
      'internalTickets',
      recording.internalTickets.length
    );
    updateNestedFormElements(
      this.form,
      'productVariants',
      recording.productVariants.length
    );

    // map recording to form
    const { productionStart, eventStart, eventEnd, season, ...rest } =
      recording;
    const recordingForm: RecordingForm = {
      ...rest,
      seasonId: season?.id,
      productionDate: new Date(productionStart).toISOString(),
      productionStartTime: formatTime(productionStart),
      eventStartTime: formatTime(eventStart),
      eventEndTime: formatTime(eventEnd),
    };

    // update form
    this.form.patchValue(recordingForm);
  }
  get recording(): Recording {
    // map form to recording
    const {
      productionDate,
      productionStartTime,
      eventStartTime,
      eventEndTime,
      seasonId,
      isDeletable,
      productVariants,
      ...rest
    } = this.form.value as RecordingForm;

    const productionDateDT = new Date(productionDate);
    const recording: Recording = {
      ...rest,
      format: this.format,
      season: this.format.cmsFormat?.data?.seasons?.iv?.find(
        (season) => season.id === seasonId
      ),
      productionStart: !productionDate
        ? null
        : mergeDateAndTime(productionDateDT, productionStartTime),
      eventStart: !productionDate
        ? null
        : mergeDateAndTime(productionDateDT, eventStartTime),
      eventEnd: !productionDate
        ? null
        : mergeDateAndTime(productionDateDT, eventEndTime),
      // adjustments in the form getter need to be duplicated, as the subform getter is not triggered when changing the base date from outside
      // @TODO base date logic can be removed
      productVariants: productVariants.map(productVariant => {
        const {isDeletable, ...rest} = productVariant;
        return {
          ...rest,
          eventStart: replaceBaseDate(new Date(productVariant.eventStart), productionDateDT), // @TODO should be dates already
          eventEnd: replaceBaseDate(new Date(productVariant.eventEnd), productionDateDT), // @TODO should be dates already
        }
      })
    };

    return recording;
  }

  public format: Format;

  constructor(private fb: FormBuilder) {}

  private createRecording(): FormGroup {
    return this.fb.group({
      id: [], // hidden
      version: [], // hidden
      title: ['', Validators.required],
      type: ['', Validators.required],
      seasonId: ['', Validators.required],
      checkInBegin: ['', Validators.required],
      checkInEnd: ['', Validators.required],
      productionDate: ['', Validators.required],
      productionStartTime: ['', Validators.required],
      eventStartTime: ['', Validators.required],
      eventEndTime: ['', Validators.required],
      internalTickets: this.fb.array([]),
      productVariants: this.fb.array([]),
      audienceCapacity: ['', Validators.required],
      wheelchairCapacity: ['', Validators.required],
      additionalCapacity: ['', Validators.required],
      isDeletable: [false], // hidden
    });
    // @TODO check required and validators
  }

  public addInternalTicket(): void {
    const InternalTicketFormArray = this.form.get(
      'internalTickets'
    ) as FormArray;
    InternalTicketFormArray.push(new FormControl(''));
  }

  public removeInternalTicket(idx: number): void {
    const InternalTicketFormArray = this.form.get(
      'internalTickets'
    ) as FormArray;
    InternalTicketFormArray.removeAt(idx);
  }

  public addProductVariant(product: Product): void {
    const ProductVariantFormArray = this.form.get(
      'productVariants'
    ) as FormArray;
    ProductVariantFormArray.push(
      new FormControl({
        product,
        recording: {
          format: this.format
        },
        isDeletable: true,
      })
    );
  }

  public removeProductVariant(idx: number): void {
    const ProductVariantFormArray = this.form.get(
      'productVariants'
    ) as FormArray;
    ProductVariantFormArray.removeAt(idx);
  }
}
