import { Customer, Salutation, Tag } from 'src/app/core/graphql.model';
import { FormArray, FormGroup } from '@angular/forms';
import { CustomerFormService } from './customer-form.service';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-customer-form',
  templateUrl: './customer-form.component.html',
  styleUrls: ['./customer-form.component.scss'],
  providers: [CustomerFormService],
})
export class CustomerFormComponent implements OnInit {
  form: FormGroup = this.formService.form;
  formDataInitialised = false;
  Salutation = Salutation;

  @Input() set customer(customer: Customer) {
    this.formDataInitialised = true;
    this.formService.customer = customer;
  }

  @Input() tags: Tag[];

  _disabled: boolean = false;
  @Input() set disabled(disabled: boolean) {
    this._disabled = disabled;
    this.setDisabledState(disabled);
  }
  get disabled(): boolean {
    return this._disabled;
  }

  @Output() formSubmit: EventEmitter<Customer> = new EventEmitter();

  get additionalAddressesFormItems(): FormArray {
    return this.form.controls.additionalAddresses as FormArray;
  }

  get emailOptionsFormItems(): FormArray {
    return this.form.controls.emailOptions as FormArray;
  }

  get phoneOptionsFormItems(): FormArray {
    return this.form.controls.phoneOptions as FormArray;
  }

  constructor(private formService: CustomerFormService) {}

  ngOnInit(): void {
    if (!this.formDataInitialised) {
      this.formService.initContactOptions();
      this.formDataInitialised = true;
    }
  }

  onSubmit(): void {
    if (this.form.invalid) {
      return;
    }
    this.formSubmit.emit(this.formService.customer);
  }

  onAddPrimaryAddress(): void {
    this.formService.addPrimaryAddress();
  }

  onRemovePrimaryAddress(): void {
    this.formService.removePrimaryAddress();
  }

  onRemoveAdditionalAddress(i: number): void {
    this.formService.removeAdditionalAddress(i);
  }

  onAddAdditionalAddress(): void {
    this.formService.addAdditionalAddress();
  }

  onAddAdditionalPhone(): void {
    this.formService.addAdditionalPhone();
  }

  onRemoveAdditionalPhone(i: number): void {
    this.formService.removeAdditionalPhone(i);
  }

  onAddAdditionalEmail(): void {
    this.formService.addAdditionalEmail();
  }

  onRemoveAdditionalEmail(i: number): void {
    this.formService.removeAdditionalEmail(i);
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.form.disable() : this.form.enable();
  }
}
