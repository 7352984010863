import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormatsSearchformService } from './formats-searchform.service';

@Component({
  selector: 'app-formats-searchform',
  templateUrl: './formats-searchform.component.html',
  styleUrls: ['./formats-searchform.component.scss'],
  providers: [FormatsSearchformService],
})
export class FormatsSearchformComponent {
  form: FormGroup = this.formService.form;

  constructor(private formService: FormatsSearchformService) {}
}
