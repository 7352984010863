import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { FetchResult } from '@apollo/client/core';
import { map } from 'rxjs/operators';
import { removeGraphQlFields } from '../users/users.helpers';
import { ApolloQueryResult } from '@apollo/client';
import usersQuery from './graphql/users-query.graphql';
import userQuery from './graphql/user-query.graphql';
import meQuery from './graphql/me-query.graphql';
import { Id } from '../core/model';
import userUpdateMutation from './graphql/user-update-mutation.graphql';
import { UpdateUserInput, User } from '../core/graphql.model';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  constructor(private apollo: Apollo) {}

  loadUsers = (): Observable<User[]> => {
    return this.apollo
      .watchQuery<User[]>({
        query: usersQuery,
      })
      .valueChanges.pipe(
        map((result: ApolloQueryResult<any>) =>
          result.data?.users.map((user: User) => removeGraphQlFields(user))
        )
      );
  };

  loadUser = (id: Id): Observable<User> => {
    return this.apollo
      .watchQuery<User>({
        query: userQuery,
        variables: {
          id,
        },
      })
      .valueChanges.pipe(
        map((result: ApolloQueryResult<any>) =>
          removeGraphQlFields(result.data?.user)
        )
      );
  };

  loadMe = (): Observable<User> => 
    this.apollo
      .watchQuery<User>({
        query: meQuery,
      })
      .valueChanges.pipe(
        map((result: ApolloQueryResult<any>) =>
          removeGraphQlFields(result.data?.me)
        ),
      );

  updateUser = (user: Partial<UpdateUserInput>): Observable<User> => {
    // execute mutation
    return this.apollo
      .mutate({
        mutation: userUpdateMutation,
        variables: {
          updateUserInput: user,
        },
      })
      .pipe(
        map((result: FetchResult<any>) =>
          removeGraphQlFields(result.data?.updateUser?.user as User)
        )
      );
  };
}
