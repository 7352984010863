import { FormArray, FormGroup, FormControl } from '@angular/forms';

export const strip = (text: string) => {
  return text.trim();
  // @TODO remove all whitespace characters 
};

export const updateNestedFormElements = (
  form: FormGroup,
  name: string,
  targetLength: number
) => {
  const formArray = form.get(name) as FormArray;
  const delta = targetLength - formArray.length;
  if (delta > 0) {
    // add the nested element
    for (let i = 0; i < delta; i++) {
      formArray.push(new FormControl(''));
    }
  } else if (delta < 0) {
    // remove nested element
  }
};

export const logForm = (form: FormGroup) => {
  console.log(form);
  if (form.invalid) {
    console.groupCollapsed('invalid');
    Object.keys(form.controls).forEach((name) => {
      if (form.controls[name].invalid) {
        console.log(name, form.controls[name]);
      }
    });
    console.groupEnd();
  }
};
