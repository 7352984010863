import { Action, combineReducers, ActionReducer } from '@ngrx/store';
import * as fromTagEntities from './entities/tag-entities.reducer';

export const tagFeatureKey = 'tag';

export interface State {
  [fromTagEntities.tagEntitiesFeatureKey]: fromTagEntities.State;
}

export function reducers(
  state: State | undefined,
  action: Action
): ActionReducer<State, any> {
  // @ts-ignore
  return combineReducers({
    [fromTagEntities.tagEntitiesFeatureKey]: fromTagEntities.reducer,
  })(state, action);
}
