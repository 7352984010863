import { createAction, props } from '@ngrx/store';
import { Recording, RecordingFilter } from 'src/app/core/graphql.model';
import { Id } from 'src/app/core/model';

// CREATE

export const createRecording = createAction(
  '[Recording] Create',
  props<{ data: Recording }>()
);
export const createRecordingSuccess = createAction(
  '[Recording] Create Success',
  props<{ data: Recording }>()
);
export const createRecordingFailure = createAction(
  '[Recording] Create Failure',
  props<{ error: any }>()
);

// READ

export const loadRecordings = createAction('[Recording] Load All');
export const loadRecordingsSuccess = createAction(
  '[Recording] Load All Success',
  props<{ data: Recording[] }>()
);
export const loadRecordingsFailure = createAction(
  '[Recording] Load All Failure',
  props<{ error: any }>()
);

export const loadRecordingsByFormat = createAction(
  '[Recording] Load by Format',
  props<{ formatId: number }>()
);
// @TODO execute via filter
export const loadRecordingsByFormatSuccess = createAction(
  '[Recording] Load by Format Success',
  props<{ data: Recording[] }>()
);
export const loadRecordingsByFormatFailure = createAction(
  '[Recording] Load by Format Failure',
  props<{ error: any }>()
);

export const loadRecordingsByCurrentFormat = createAction(
  '[Recording] Load by Current Format'
);

export const loadRecordingsByFilter = createAction(
  '[Recording] Load by Filter',
  props<{ filter: RecordingFilter }>()
);
export const loadRecordingsByFilterSuccess = createAction(
  '[Recording] Load by Filter Success',
  props<{ data: Recording[] }>()
);
export const loadRecordingsByFilterFailure = createAction(
  '[Recording] Load by Filter Failure',
  props<{ error: any }>()
);

export const loadCurrentRecording = createAction(
  '[Recording] Load Current'
);
export const loadRecordingSuccess = createAction(
  '[Recording] Load Single Success',
  props<{ data: Recording }>()
);
export const loadRecordingFailure = createAction(
  '[Recording] Load Single Failure',
  props<{ error: any }>()
);

// UPDATE

export const updateRecording = createAction(
  '[Recording] Update',
  props<{ data: Recording }>()
);
export const updateRecordingSuccess = createAction(
  '[Recording] Update Success',
  props<{ data: Recording }>()
);
export const updateRecordingFailure = createAction(
  '[Recording] Update Failure',
  props<{ error: any }>()
);

// DELETE

export const deleteRecording = createAction(
  '[Recording] Delete',
  props<{ id: Id }>()
);
export const deleteRecordingSuccess = createAction(
  '[Recording] Delete Success',
  props<{ id: Id }>()
);
export const deleteRecordingFailure = createAction(
  '[Recording] Delete Failure',
  props<{ error: any }>()
);
