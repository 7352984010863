<!-- Panel Content-->
<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <!--(ngSubmit)="onSubmit()" -->
  <input type="hidden" id="id" formControlName="id" />

  <div class="container">

    <mat-form-field>
      <mat-label for="name">
        Produktname
        <!-- {{ "FORMATS.FORMAT_FORM.LABEL" | translate }} -->
      </mat-label>
      <input
        matInput
        type="text"
        placeholder="Prdouktname"
        [id]="'name-' + idx"
        formControlName="name"
      />
    </mat-form-field>
    &nbsp;
    <mat-form-field>
      <mat-label>Typ</mat-label>
      <mat-select formControlName="type">
        <mat-option
          *ngFor="let productTypeOption of productTypeOptions | keyvalue"
          [value]="productTypeOption.key"
        >
          {{ productTypeOption.value }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <input type="hidden" id="isDeletable" formControlName="isDeletable" />

    <div class="c-formfield-placeholder u-inline-flex">
      <button
        [disabled]="!form.value.isDeletable || disabled"
        (click)="onRemove()"
        class="u-ml-12"
        mat-mini-fab
        color="accent"
        type="button"
      >
        <mat-icon>delete</mat-icon>
      </button>
    </div>
<!--    <button
      [disabled]="!form.value.isDeletable || disabled"
      (click)="onRemove()"
    >x</button>-->

  </div>
</form>

