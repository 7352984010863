<div *ngIf="!params.node.group">
  <button
    mat-icon-button
    color="primary"
    aria-label="Aufzeichnung Detailansicht anzeigen"
    matTooltip="Aufzeichnung Detailansicht anzeigen"
    matTooltipShowDelay="500"
    [routerLink]="link"
    (click)="onDetails($event)"
  >
    <mat-icon>arrow_circle_right</mat-icon>
  </button>
</div>
<!--<div *ngIf="params.node.group">some other actions</div>-->
