import { Component } from '@angular/core';
import { RecordingsFacadeService } from 'src/app/recordings/recordings-facade.service';

@Component({
  selector: 'app-format-recordings-tab',
  templateUrl: './format-recordings-tab.component.html',
  styleUrls: ['./format-recordings-tab.component.scss'],
})
export class FormatRecordingsTabComponent {
  constructor(public recordingsFacade: RecordingsFacadeService) {}
}
