<mat-sidenav-container class="matSideNav__container">
  <mat-sidenav mode="side" opened class="matSideNav__sideBar u-bgcolor-grey-110 u-pt-48">
    <!-- @TODO auth evaluate each time vs. update-->
    <ul class="c-navigation">
      <li
        class="c-navigation__item u-mb-16"
        *ngFor="let navItem of navItemsArr"
      >
        <a
          class="c-navigation__link"
          [routerLink]="navItem.link"
          [routerLinkActive]="'c-navigation__link--active'"
          [matTooltip]="navItem.label"
          matTooltipShowDelay="250"
        >
          <img class="c-navigation__icon" [src]="navItem.icon" />
<!--            {{ navItem.label }}-->
        </a>
      </li>
    </ul>
  </mat-sidenav>
  <mat-sidenav-content class="mat-sidenav-content" style="margin-left: 48px">
    <ng-content class="ng-sidenav-content"></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>
