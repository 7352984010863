"use strict";
/*
 * -------------------------------------------------------
 * THIS FILE WAS AUTOMATICALLY GENERATED (DO NOT MODIFY)
 * -------------------------------------------------------
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.WaitingListEntryType = exports.SortOrder = exports.LogicalOperator = exports.AdjustmentType = exports.RecordingProcessState = exports.RecordingReleaseState = exports.RecordingType = exports.ProductType = exports.FormatReleaseState = exports.Salutation = exports.ContactOptionCategory = exports.ContactOptionType = exports.UserRole = void 0;
/* tslint:disable */
/* eslint-disable */
var UserRole;
(function (UserRole) {
    UserRole["superadmin"] = "superadmin";
    UserRole["admin"] = "admin";
    UserRole["supervisor"] = "supervisor";
    UserRole["user"] = "user";
    UserRole["guest"] = "guest";
})(UserRole = exports.UserRole || (exports.UserRole = {}));
var ContactOptionType;
(function (ContactOptionType) {
    ContactOptionType["EMAIL"] = "EMAIL";
    ContactOptionType["PHONE"] = "PHONE";
    ContactOptionType["FAX"] = "FAX";
    ContactOptionType["SOCIAL_MEDIA"] = "SOCIAL_MEDIA";
    ContactOptionType["INTERNET"] = "INTERNET";
})(ContactOptionType = exports.ContactOptionType || (exports.ContactOptionType = {}));
var ContactOptionCategory;
(function (ContactOptionCategory) {
    ContactOptionCategory["BUSINESS"] = "BUSINESS";
    ContactOptionCategory["PRIVATE"] = "PRIVATE";
})(ContactOptionCategory = exports.ContactOptionCategory || (exports.ContactOptionCategory = {}));
var Salutation;
(function (Salutation) {
    Salutation["MRS"] = "MRS";
    Salutation["MR"] = "MR";
    Salutation["THEY"] = "THEY";
    Salutation["NOT_SPECIFIED"] = "NOT_SPECIFIED";
})(Salutation = exports.Salutation || (exports.Salutation = {}));
var FormatReleaseState;
(function (FormatReleaseState) {
    FormatReleaseState["ACTIVE"] = "ACTIVE";
    FormatReleaseState["ARCHIVED"] = "ARCHIVED";
})(FormatReleaseState = exports.FormatReleaseState || (exports.FormatReleaseState = {}));
var ProductType;
(function (ProductType) {
    ProductType["TICKET"] = "TICKET";
    ProductType["STUDIO_TOUR"] = "STUDIO_TOUR";
})(ProductType = exports.ProductType || (exports.ProductType = {}));
var RecordingType;
(function (RecordingType) {
    RecordingType["LIVE"] = "LIVE";
    RecordingType["SINGLE"] = "SINGLE";
    RecordingType["DUAL"] = "DUAL";
})(RecordingType = exports.RecordingType || (exports.RecordingType = {}));
var RecordingReleaseState;
(function (RecordingReleaseState) {
    RecordingReleaseState["DRAFT"] = "DRAFT";
    RecordingReleaseState["PUBLISHED"] = "PUBLISHED";
    RecordingReleaseState["ARCHIVED"] = "ARCHIVED";
})(RecordingReleaseState = exports.RecordingReleaseState || (exports.RecordingReleaseState = {}));
var RecordingProcessState;
(function (RecordingProcessState) {
    RecordingProcessState["PLANNED"] = "PLANNED";
    RecordingProcessState["BOOKING"] = "BOOKING";
    RecordingProcessState["PRE_PRODUCTION"] = "PRE_PRODUCTION";
    RecordingProcessState["PRODUCTION"] = "PRODUCTION";
    RecordingProcessState["POST_PRODUCTION"] = "POST_PRODUCTION";
})(RecordingProcessState = exports.RecordingProcessState || (exports.RecordingProcessState = {}));
var AdjustmentType;
(function (AdjustmentType) {
    AdjustmentType["PROMOTION"] = "PROMOTION";
    AdjustmentType["DISTRIBUTED_ORDER_PROMOTION"] = "DISTRIBUTED_ORDER_PROMOTION";
    AdjustmentType["OTHER"] = "OTHER";
})(AdjustmentType = exports.AdjustmentType || (exports.AdjustmentType = {}));
var LogicalOperator;
(function (LogicalOperator) {
    LogicalOperator["AND"] = "AND";
    LogicalOperator["OR"] = "OR";
})(LogicalOperator = exports.LogicalOperator || (exports.LogicalOperator = {}));
var SortOrder;
(function (SortOrder) {
    SortOrder["ASC"] = "ASC";
    SortOrder["DESC"] = "DESC";
})(SortOrder = exports.SortOrder || (exports.SortOrder = {}));
var WaitingListEntryType;
(function (WaitingListEntryType) {
    WaitingListEntryType["WAITINGLIST"] = "WAITINGLIST";
    WaitingListEntryType["TICKETALARM"] = "TICKETALARM";
})(WaitingListEntryType = exports.WaitingListEntryType || (exports.WaitingListEntryType = {}));
