import {
  ContactOption,
  ContactOptionType,
} from './../../../core/graphql.model';
import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Injectable()
export class ContactOptionFormService {
  form: FormGroup = this.createForm();

  set contactOption(contactOption: ContactOption) {
    this.form.patchValue(contactOption);
    switch (contactOption.type) {
      case ContactOptionType.EMAIL:
        this.form.get('value').setValidators([Validators.required, Validators.email]);
        this.form.get('category').setValidators([Validators.required]);
        break;
      case ContactOptionType.PHONE:
        this.form.get('value').setValidators([Validators.required]);
        this.form.get('category').setValidators([Validators.required]);
        break;
      default:
        this.form.get('value').setValidators([Validators.required]);
        this.form.get('category').setValidators([Validators.required]);
        break;
    }
    this.form.get('value').updateValueAndValidity();
  }
  get contactOption(): ContactOption {
    return this.form.value;
  }

  constructor(private fb: FormBuilder) {}

  private createForm(): FormGroup {
    return this.fb.group({
      id: [''], // hidden
      version: [''], // hidden
      prio: [''], // hidden
      type: [''],
      category: [''],
      value: [''],
    });
    // @TODO check required and validators
  }
}
