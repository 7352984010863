import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../core/material.module';
import { ListModule } from '../core/list/list.module';
import { TranslateModule } from '@ngx-translate/core';
import * as fromWaitingList from './store/waiting-list.reducer';
import { WaitingListEffects } from './store/waiting-list.effects';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { WaitingListsListComponent } from './components/waiting-lists-list/waiting-lists-list.component';
import { RouterModule } from '@angular/router';
import { WaitingListsListActionsCellRendererComponent } from './components/waiting-lists-list/cell-renderers/waiting-lists-list-actions-cell-renderer.component';
import { TicketListCreateFormComponent } from './components/ticket-list-create-form/ticket-list-create-form.component';
import { WaitingListCreateFormComponent } from './components/waiting-list-create-form/waiting-list-create-form.component';
import { CaslModule } from '../core/casl/casl.module';

@NgModule({
  declarations: [
    WaitingListCreateFormComponent,
    TicketListCreateFormComponent,
    WaitingListsListComponent,
    WaitingListsListActionsCellRendererComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    StoreModule.forFeature(
      fromWaitingList.waitingListfeatureKey,
      fromWaitingList.reducers
    ),
    EffectsModule.forFeature(WaitingListEffects),
    TranslateModule.forChild({
      extend: true,
    }),
    MaterialModule,
    CaslModule,
    ListModule,
    RouterModule,
  ],
  exports: [
    WaitingListCreateFormComponent,
    TicketListCreateFormComponent,
    WaitingListsListComponent,
  ],
})
export class WaitingListsModule {}
