import { getMergedRoute } from './../../core/store/router/router-state.selectors';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromFormat from './format.reducer';
import * as fromFormatEntities from './entities/format-entities.reducer';
import * as fromFormatLists from './lists/format-lists.reducer';
import { Id } from 'src/app/core/model';
import * as EntitiesSelectors from './entities/format-entities.selectors';
import * as ListsSelectors from './lists/format-lists.selectors';

export const selectFormatState = createFeatureSelector<fromFormat.State>(
  fromFormat.formatFeatureKey
);

// ENTITIES

export const selectEntitiesState = createSelector(
  selectFormatState,
  (state) => state[fromFormatEntities.formatEntitiesFeatureKey]
);

export const getEntitiesFormats = createSelector(
  selectEntitiesState,
  EntitiesSelectors.getFormats
);

export const getEntitiesCurrentFormatId = createSelector(
  getMergedRoute,
  EntitiesSelectors.getCurrentFormatId
);

export const getEntitiesCurrentFormat = createSelector(
  selectEntitiesState,
  getEntitiesCurrentFormatId,
  (state, id) => EntitiesSelectors.getFormat(state, id)
);

export const getEntitiesFormat = (id: Id) =>
  createSelector(selectEntitiesState, (state) =>
    EntitiesSelectors.getFormat(state, id)
  );

// LISTS

export const selectListState = createSelector(
  selectFormatState,
  (state) => state[fromFormatLists.formatListsFeatureKey]
);

export const getListOverview = createSelector(
  selectListState,
  ListsSelectors.getOverview
);
