import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Injectable()
export class RecordingsSearchformService {
  form: FormGroup = this.createForm();

  constructor(private fb: FormBuilder) {}

  private createForm(): FormGroup {
    return this.fb.group({
      title: [''],
      createdAt: [''],
      publishedAt: [''],
    });
    // @TODO check required and validators
  }
}
