import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { AppFacadeService } from 'src/app/app-facade.service';
import { LOADER_ID_ACTION } from 'src/app/app.component';
import { Recording } from 'src/app/core/graphql.model';
import { Id } from 'src/app/core/model';
import { RecordingsFacadeService } from 'src/app/recordings/recordings-facade.service';

@Component({
  selector: 'app-recording-data-tab',
  templateUrl: './recording-data-tab.component.html',
  styleUrls: ['./recording-data-tab.component.scss'],
})
export class RecordingDataTabComponent {
  actionLoaderActive$: Observable<boolean>;

  constructor(
    public facade: RecordingsFacadeService,
    public appFacade: AppFacadeService
  ) {
    this.actionLoaderActive$ = this.appFacade.isLoaderActive$(LOADER_ID_ACTION);
  }

  onRemove(id: Id): void {
    this.facade.deleteRecording(id);
  }

  onRecordingSubmit(recording: Recording): void {
    this.facade.updateRecording(recording);
  }
}
