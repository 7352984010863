import { Address, Customer } from 'src/app/core/graphql.model';
import { createAction, props } from '@ngrx/store';

// CREATE

export const createCustomer = createAction(
  '[Customer] Create',
  props<{ data: Customer }>()
);
export const createCustomerSuccess = createAction(
  '[Customer] Create Success',
  props<{ data: Customer }>()
);
export const createCustomerFailure = createAction(
  '[Customer] Create Failure',
  props<{ error: any }>()
);

// READ

/** @deprecated use pagination instead */
export const loadCustomers = createAction('[Customer] Load All');
/** @deprecated use pagination instead */
export const loadCustomersSuccess = createAction(
  '[Customer] Load All Success',
  props<{ data: Customer[] }>()
);
/** @deprecated use pagination instead */
export const loadCustomersFailure = createAction(
  '[Customer] Load All Failure',
  props<{ error: any }>()
);

export const loadCurrentCustomer = createAction('[Customer] Load Current');
export const loadCustomerSuccess = createAction(
  '[Customer] Load Single Success',
  props<{ data: Customer }>()
);
export const loadCustomerFailure = createAction(
  '[Customer] Load Single Failure',
  props<{ error: any }>()
);

// UPDATE

export const updateCustomer = createAction(
  '[Customer] Update',
  props<{ data: Customer }>()
);
export const updateCustomerSuccess = createAction(
  '[Customer] Update Success',
  props<{ data: Customer }>()
);
export const updateCustomerFailure = createAction(
  '[Customer] Update Failure',
  props<{ error: any }>()
);

export const addNewAddressToCurrentCustomer = createAction(
  '[Customer] add new address to current',
  props<{ data: Address }>()
);

// DELETE
