import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as UserEntitiesActions from './user-entities.actions';
import * as fromUser from '../user.reducer';
import { UsersService } from '../../users.service';
import {
  NotificationType,
  NotifyService,
} from 'src/app/core/notify/notify.service';
import {
  catchError,
  switchMap,
  withLatestFrom,
  map,
  tap,
  mergeMap,
} from 'rxjs/operators';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import {
  handleMutationError,
  handleQueryError,
} from 'src/app/core/graphql.helpers';
import * as UserSelectors from '../user.selectors';
import { User } from 'src/app/core/graphql.model';
import { loaderStart, loaderStop } from 'src/app/core/store/app.actions';
import { LOADER_ID_ACTION } from 'src/app/app.component';

@Injectable()
export class UserEntitiesEffects {
  constructor(
    private actions$: Actions,
    private store: Store<fromUser.State>,
    private service: UsersService,
    private notifyService: NotifyService
  ) {}

  // CREATE

  // READ

  loadUsers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserEntitiesActions.loadUsers),
      switchMap(() => {
        return this.service.loadUsers().pipe(
          switchMap((data) => [UserEntitiesActions.loadUsersSuccess({ data })]),
          catchError((error) => {
            return of(
              UserEntitiesActions.loadUsersFailure({ error: error.message })
            );
          })
        );
      })
    );
  });

  loadCurrentUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserEntitiesActions.loadCurrentUser),
      withLatestFrom(this.store.select(UserSelectors.getEntitiesCurrentUserId)),
      switchMap(([, id]) => {
        if (!id) {
          return of(UserEntitiesActions.loadUserFailure({ error: {} }));
        }
        return this.service.loadUser(id).pipe(
          map((user) => UserEntitiesActions.loadUserSuccess({ data: user })),
          catchError((error) => {
            return of(
              UserEntitiesActions.loadUserFailure({ error: error.message })
            );
          })
        );
      })
    );
  });

  // UPDATE

  updateUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserEntitiesActions.updateUser),
      map((action) => action.data),
      mergeMap((data) =>
        this.service.updateUser(data).pipe(
          map((user: User) =>
            UserEntitiesActions.updateUserSuccess({ data: user })
          ),
          catchError((error) =>
            of(
              UserEntitiesActions.updateUserFailure({
                error: error.message,
              })
            )
          )
        )
      )
    );
  });

  updateUserSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserEntitiesActions.updateUserSuccess),
        tap(() =>
          this.notifyService.notify({
            type: NotificationType.Toast,
            message: 'Successfully updated', // @TODO i18n
          })
        )
      ),
    { dispatch: false }
  );

  // DELETE

  // FAILURES

  queryFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          UserEntitiesActions.loadUsersFailure,
          UserEntitiesActions.loadUserFailure
        ),
        map((action) => action.error),
        tap((error) => handleQueryError(error, this.notifyService, this.store))
      ),
    { dispatch: false }
  );

  mutationFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserEntitiesActions.updateUserFailure),
        map((action) => action.error),
        tap((error) =>
          handleMutationError(error, this.notifyService, this.store)
        )
      ),
    { dispatch: false }
  );

  // LOADERS

  loaderStart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserEntitiesActions.updateUser),
      mergeMap(() => of(loaderStart({ id: LOADER_ID_ACTION })))
    )
  );

  loaderStop$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        UserEntitiesActions.updateUserSuccess,
        UserEntitiesActions.updateUserFailure
      ),
      mergeMap(() => of(loaderStop({ id: LOADER_ID_ACTION })))
    )
  );
}
