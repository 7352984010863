import { bookingOptions } from './../../../core/tmp';
import {Component, Input, Output, EventEmitter } from '@angular/core';
import { Product } from 'src/app/core/graphql.model';
import { VId } from 'src/app/core/model';


// @TODO rename this

@Component({
  selector: 'app-product-variant-type-menu',
  templateUrl: './product-variant-type-menu.component.html',
  styleUrls: ['./product-variant-type-menu.component.scss'],
})
export class ProductVariantTypeMenuComponent {

  selectedProductId: VId;

  @Input() products: Product[];
  @Output() addProductVariant: EventEmitter<Product> = new EventEmitter();

  bookingOptions = bookingOptions;

  constructor() {}

  // when productVariant is given as Type
  onAddProductVariant(): void {
    this.addProductVariant.emit(
      this.products.find(product => 
        product.id === this.selectedProductId
      )
    );
    this.selectedProductId = undefined;
  }

  // prevents accordion to open when dropdown is clicked
  onClickStopPropagation(event: Event): void {
    event.stopPropagation();
  }
}
