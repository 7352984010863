import { HttpClientModule, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { NgModule } from '@angular/core';
import { APOLLO_OPTIONS } from 'apollo-angular';
import { ApolloClientOptions, InMemoryCache, from } from '@apollo/client/core';
import { HttpLink } from 'apollo-angular/http';
import { onError } from '@apollo/client/link/error';

interface GqlError {
  message: string;
}

const errorLink = onError(({ networkError }) => {
  if (environment.production) {
    return;
  }

  const errors = (networkError as HttpErrorResponse)?.error.errors as GqlError[]; // @TODO improve this
  errors?.forEach(error => console.log(error.message));
});

export function createApollo(httpLink: HttpLink): ApolloClientOptions<any> {
  return {
    link: from([errorLink, httpLink.create({ uri: environment.api_base })]),
    cache: new InMemoryCache(),
  };
}

@NgModule({
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink],
    },
  ],
  imports: [HttpClientModule],
})
export class GraphqlModule {}
