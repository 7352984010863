import { getMergedRoute } from './../../core/store/router/router-state.selectors';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromCustomer from './customer.reducer';
import * as fromCustomerEntities from './entities/customer-entities.reducer';
import * as fromCustomerLists from './lists/customer-lists.reducer';
import { Id } from 'src/app/core/model';
import * as EntitiesSelectors from './entities/customer-entities.selectors';
import * as ListsSelectors from './lists/customer-lists.selectors';

export const selectCustomerState = createFeatureSelector<fromCustomer.State>(
  fromCustomer.customerFeatureKey
);

// ENTITIES

export const selectEntitiesState = createSelector(
  selectCustomerState,
  (state) => state[fromCustomerEntities.customerEntitiesFeatureKey]
);

export const getEntitiesCustomers = createSelector(
  selectEntitiesState,
  EntitiesSelectors.getCustomers
);

export const getEntitiesCurrentCustomerId = createSelector(
  getMergedRoute,
  EntitiesSelectors.getCurrentCustomerId
);

export const getEntitiesCurrentCustomer = createSelector(
  selectEntitiesState,
  getEntitiesCurrentCustomerId,
  EntitiesSelectors.getCustomer
);

export const getEntitiesCustomer = (id: Id) =>
  createSelector(selectEntitiesState, (state) =>
    EntitiesSelectors.getCustomer(state, id)
  );

// LISTS

export const selectListState = createSelector(
  selectCustomerState,
  (state) => state[fromCustomerLists.customerListsFeatureKey]
);

export const getListOverview = createSelector(
  selectListState,
  ListsSelectors.getOverview
);
