<div class="o-container u-height-1/1 u-flex u-fd-col u-pb-24 u-pt-12">
  <h1 class="o-type-h4 u-mb-24">
    {{ "CUSTOMERS.CUSTOMERS_PAGE.TITLE" | translate }}
  </h1>

  <app-loader-wrapper [active]="tabLoaderActive$ | async" style="height: 100%">
    <mat-tab-group
      animationDuration="0ms"
      [mat-stretch-tabs]="false"
      [selectedIndex]="tabIndex"
      (selectedTabChange)="onSelectedTabChange($event)"
      >
      <mat-tab label="Alle"> </mat-tab>
      <mat-tab label="Listen"> </mat-tab>
    </mat-tab-group>
      <router-outlet></router-outlet>
  </app-loader-wrapper>
  <div class="u-flex u-jc-end">
    <button
      mat-fab
      color="primary"
      matTooltip="Neuen Kunden anlegen"
      matTooltipShowDelay="500"
      *ngIf="'create' | able: 'Customer'"
      [routerLink]="customerCreateLink"
    >
      <mat-icon>add</mat-icon>
    </button>
  </div>
</div>

<!--<div style="height: 100%; display: flex; flex-direction: column">
  <h1>{{ "CUSTOMERS.CUSTOMERS_PAGE.TITLE" | translate }}</h1>

  <mat-tab-group
    animationDuration="0ms"
    style="height: 100%; display: flex; flex-direction: column"
  >
    <mat-tab label="Alle">
      <div style="height: 100%; display: flex; flex-direction: column">
        <div>
          <button [routerLink]="customerCreateLink">create new customer</button>
        </div>
        <app-customers-list
          style="flex: 1"
          [columnDefs]="(facade.listOverview$ | async)?.columnDefs"
          [datasource]="datasource"
          [filter]="(facade.listOverview$ | async)?.config.filter"
          [columnsState]="(facade.listOverview$ | async)?.config.columnsState"
          (filterChanged)="onFilterChanged($event)"
          (sortChanged)="onSortChanged($event)"
        >
        </app-customers-list>
        <div class="listBtn__container">
          <button
            mat-flat-button
            color="accent"
            type="button"
            (click)="openListConfigDialog()"
          >
            Liste speichern
          </button>

          <button mat-flat-button color="accent" type="button">
            Liste teilen
          </button>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Listen">
      <div style="height: 100%; display: flex; flex-direction: column">
        <app-list-configs-lists
          style="flex: 1"
          [columnDefs]="(listConfigFacade.listGeneral$ | async)?.columnDefs"
          [data]="(listConfigFacade.ListConfigs$ | async) || []"
        >
        </app-list-configs-lists>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>-->
