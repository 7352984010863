<form [formGroup]="form">
  <input type="hidden" [id]="'id-' + idx" formControlName="id" />
  <div class="o-grid">
    <div class="o-grid__col u-19/26">
      <mat-form-field class="u-1/1">
        <mat-label for="Name" [attr.for]="'name-' + idx"> Name </mat-label>
        <input
          matInput
          type="text"
          placeholder="Name"
          [id]="'name-' + idx"
          formControlName="name"
        />
      </mat-form-field>
    </div>

    <div class="o-grid__col u-4/26">
      <mat-form-field class="u-1/1">
        <mat-label [attr.for]="'quantity-' + idx"> Anzahl </mat-label>
        <input
          matInput
          type="number"
          min="0"
          placeholder="Anzahl"
          [id]="'quantity-' + idx"
          formControlName="quantity"
        />
      </mat-form-field>
    </div>
    <div class="o-grid__col u-2/26 u-text-left">
      <div class="c-formfield-placeholder u-inline-flex">
        <button
          mat-mini-fab
          color="accent"
          type="button"
          [disabled]="disabled"
          (click)="onRemove()"
        >
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>

  </div>
</form>

