import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import {
  ColDef,
  ValueFormatterParams,
  ValueGetterParams,
} from 'ag-grid-community';
import { Format, FormatReleaseState } from 'src/app/core/graphql.model';
import {
  BaseColumnIds,
  BaseColumnDefsService,
} from 'src/app/core/list/base-column-defs-service';

export enum FormatColumnIds {
  RELEASE_STATE = 'releaseState',
  TITLE = 'title',
  CURRENT_SEASON_NAME = 'currentSeasonName',
  CURRENT_SEASON_NUMBER = 'currentSeasonNumber',
  CURRENT_SEASON_START = 'currentSeasonStart',
  CURRENT_SEASON_END = 'currentSeasonEnd',
  CURRENT_SEASON_STUDIO_NAME = 'currentSeasonStudioName',
  ACTIVE_RECORDINGS = 'activeRecordings',
}

@Injectable({
  providedIn: 'root',
})
export class FormatColumnDefsService extends BaseColumnDefsService {
  columnDefsMap = new Map<string, ColDef>([
    [
      FormatColumnIds.RELEASE_STATE,
      {
        headerName: this.translate.instant('FORMATS.COLUMNS.RELEASE_STATE'),
        field: 'releaseState',
        valueFormatter: (params: ValueFormatterParams) =>
          this.translate.instant(`ENUM.FORMAT_RELEASE_STATE.${params.value}`),
        filter: 'agSetColumnFilter',
        filterParams: {
          values: Object.values(FormatReleaseState),
          valueFormatter: (params: ValueFormatterParams) =>
            this.translate.instant(`ENUM.FORMAT_RELEASE_STATE.${params.value}`),
        },
      },
    ],
    [BaseColumnIds.ID, this.baseColumnDefsMap.get(BaseColumnIds.ID)],
    [
      FormatColumnIds.TITLE,
      {
        headerName: this.translate.instant('FORMATS.COLUMNS.TITLE'),
        field: 'title',
      },
    ],
    [
      FormatColumnIds.CURRENT_SEASON_NAME,
      {
        headerName: this.translate.instant(
          'FORMATS.COLUMNS.CURRENT_SEASON_NAME'
        ),
        valueGetter: (params: ValueGetterParams) => {
          const format = params.data as Format;
          return format.cmsFormat?.data.currentSeason?.iv
            ? format.cmsFormat?.data.currentSeason?.iv[0]?.data.name.iv
            : '';
        }
      },
    ],
    [
      FormatColumnIds.CURRENT_SEASON_NUMBER,
      {
        headerName: this.translate.instant(
          'FORMATS.COLUMNS.CURRENT_SEASON_NUMBER'
        ),
        valueGetter: (params: ValueGetterParams) => {
          const format = params.data as Format;
          return format.cmsFormat?.data.currentSeason?.iv
            ? format.cmsFormat?.data.currentSeason?.iv[0]?.data.number.iv
            : ''
        }
      },
    ],
    [
      FormatColumnIds.CURRENT_SEASON_START,
      {
        headerName: this.translate.instant(
          'FORMATS.COLUMNS.CURRENT_SEASON_START'
        ),
        type: 'dateColumn',
        hide: true,
        valueGetter: (params: ValueGetterParams) =>
          (params.data as Format).cmsFormat?.data.currentSeason?.iv[0]?.data.start
            .iv,
      },
    ],
    [
      FormatColumnIds.CURRENT_SEASON_END,
      {
        headerName: this.translate.instant(
          'FORMATS.COLUMNS.CURRENT_SEASON_END'
        ),
        type: 'dateColumn',
        hide: true,
        valueGetter: (params: ValueGetterParams) =>
          (params.data as Format).cmsFormat?.data.currentSeason?.iv[0]?.data.end
            .iv,
      },
    ],
    [
      FormatColumnIds.CURRENT_SEASON_STUDIO_NAME,
      {
        headerName: this.translate.instant(
          'FORMATS.COLUMNS.CURRENT_SEASON_STUDIO_NAME'
        ),
        hide: true,
        valueGetter: (params: ValueGetterParams) =>
          (params.data as Format).cmsFormat?.data.currentSeason?.iv[0]?.data
            .studio.iv[0].data.name.iv,
      },
    ],
    [
      FormatColumnIds.ACTIVE_RECORDINGS,
      {
        headerName: this.translate.instant('FORMATS.COLUMNS.ACTIVE_RECORDINGS'),
        type: 'numberColumn',
        valueGetter: (params: ValueGetterParams) =>
          params.data.recordings.length, // @TODO filter for active recording. Filter in graphql in best case.
      },
    ],
    [
      BaseColumnIds.CREATED_AT,
      this.baseColumnDefsMap.get(BaseColumnIds.CREATED_AT),
    ],
    [BaseColumnIds.ACTIONS, this.baseColumnDefsMap.get(BaseColumnIds.ACTIONS)],
  ]);

  columnDefs: ColDef[] = Array.from(this.columnDefsMap.keys()).map((key) => ({
    colId: key,
    ...this.columnDefsMap.get(key),
  }));

  constructor(protected translate: TranslateService) {
    super(translate);
  }
}
