import { filter, take, tap } from 'rxjs/operators';
import { Actions, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { ListConfigConfig } from './../core/list/list.model';
import { Customer } from '../core/graphql.model';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as CustomerActions from './store/customer.actions';
import * as CustomerSelectors from './store/customer.selectors';
import {
  LoadSuccessParams,
  IServerSideGetRowsRequest,
} from 'ag-grid-community';
import { uniqueId } from 'lodash';
import { getMergedRoute } from '../core/store/router/router-state.selectors';

@Injectable({
  providedIn: 'root',
})
export class CustomersFacadeService {
  constructor(private store: Store, private actions$: Actions) {}

  // SELECTORS

  customers$ = this.store.select(CustomerSelectors.getEntitiesCustomers);
  currentCustomer$ = this.store.select(
    CustomerSelectors.getEntitiesCurrentCustomer
  );
  listOverview$ = this.store.select(CustomerSelectors.getListOverview);
  mergedRoute$ = this.store.select(getMergedRoute);

  // ENTITIES CREATE

  createCustomer = (customer: Customer) => {
    this.store.dispatch(
      CustomerActions.entities.createCustomer({ data: customer })
    );
  };

  // ENTITIES READ

  /** @deprecated use pagination instead */
  loadCustomers = () => {
    this.store.dispatch(CustomerActions.entities.loadCustomers());
  };

  loadCurrentCustomer = () => {
    this.store.dispatch(CustomerActions.entities.loadCurrentCustomer());
  };

  getCustomersRows$ = (
    request: IServerSideGetRowsRequest
  ): Observable<LoadSuccessParams> => {
    return new Observable((observer) => {
      const uuid = uniqueId();
      this.actions$
        .pipe(
          ofType(
            CustomerActions.lists.loadCustomerConnectionSuccess,
            CustomerActions.lists.loadCustomerConnectionFailure
          ),
          filter((action) => action.uuid === uuid),
          take(1),
          tap((action) => {
            if (
              action.type ===
              CustomerActions.lists.loadCustomerConnectionSuccess.type
            ) {
              observer.next({
                rowData: action.result.edges.map((edge) => edge.node),
                rowCount: action.result.totalCount,
              });
              observer.complete();
            } else {
              observer.error(action.error);
            }
          })
        )
        .subscribe();

      this.store.dispatch(
        CustomerActions.lists.loadCustomerConnection({ uuid, request })
      );
    });
  };

  // ENTITIES UPDATE

  updateCustomer = (customer: Customer) => {
    this.store.dispatch(
      CustomerActions.entities.updateCustomer({ data: customer })
    );
  };

  // ENTITIES DELETE

  // LISTS

  pushFilterCustomerConnection = (
    config: Partial<ListConfigConfig>,
    resetSsrm = false
  ) => {
    this.store.dispatch(
      CustomerActions.lists.pushConfig({ config, resetSsrm })
    );
  };

  pullConfigNewBlock = () => {
    this.store.dispatch(CustomerActions.lists.pullConfigNewBlock());
  };
}
