<ag-grid-angular
  style="width: 100%; height: 100%"
  class="ag-theme-material"
  [components]="components"
  [masterDetail]="true"
  [detailCellRenderer]="!!columnDefsParticipants ? null : 'detailCellRenderer'"
  [columnTypes]="columnTypes"
  [defaultColDef]="defaultColDef"
  [columnDefs]="columnDefs"
  [suppressClickEdit]="true"
  [sideBar]="sideBar"
  [statusBar]="statusBar"
  [detailCellRendererParams]="detailCellRendererParams"
  [rowData]="data"
  [detailRowAutoHeight]="true"
  [excelStyles]="excelStyles"
  (gridReady)="onGridReady($event)"
  (firstDataRendered)="onFirstDataRendered($event)"
>
</ag-grid-angular>
<button (click)="onExport()">Teilnehmerliste exportieren</button>
