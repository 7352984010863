import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { Format } from 'src/app/core/graphql.model';
import * as FormatEntitiesActions from './format-entities.actions';

export const formatEntitiesFeatureKey = 'entities';

export interface State extends EntityState<Format> {}

export const adapter: EntityAdapter<Format> = createEntityAdapter<Format>();

export const initialState: State = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,

  // CREATE

  on(FormatEntitiesActions.createFormatSuccess, (state, action) =>
    adapter.upsertOne(action.data, state)
  ),

  // READ

  on(FormatEntitiesActions.loadFormatsSuccess, (state, action) =>
    adapter.setAll(action.data, state)
  ),

  on(FormatEntitiesActions.loadFormatSuccess, (state, action) =>
    adapter.upsertOne(action.data, state)
  ),

  // UPDATE

  on(FormatEntitiesActions.updateFormatSuccess, (state, action) =>
    adapter.upsertOne(action.data, state)
    ),

    // DELETE
  
    on(FormatEntitiesActions.deleteFormatSuccess, (state, action) =>
      adapter.removeOne(action.id, state)
    ),
);
