import { persons } from 'src/app/core/tmp';
import {
  Component,
  Input,
  Output,
  EventEmitter,
  forwardRef,
} from '@angular/core';
import { BookingItemFormService } from './booking-item-form.service';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  Validator,
} from '@angular/forms';
import { BookingItemForm } from '../booking-form/booking-form.model';
import { BaseSubFormComponent } from 'src/app/core/base/base-sub-form.component';

@Component({
  selector: 'app-booking-item-form',
  templateUrl: './booking-item-form.component.html',
  styleUrls: ['./booking-item-form.component.scss'],
  providers: [
    BookingItemFormService,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BookingItemFormComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => BookingItemFormComponent),
      multi: true,
    },
  ],
})
export class BookingItemFormComponent
  extends BaseSubFormComponent
  implements ControlValueAccessor, Validator
{
  _bookingItem: BookingItemForm;

  @Input() set submitted(submitted: boolean) {
    if (submitted === true) {
      Object.values(this.form.controls).forEach((control) =>
        control.markAsTouched()
      );
    }
  }

  @Output() amountSelected: EventEmitter<BookingItemForm> = new EventEmitter();

  persons = persons;

  constructor(private formService: BookingItemFormService) {
    super();
    this.form = this.formService.form;
  }

  writeValue(val: any): void {
    if (val) {
      this.formService.bookingItem = val;
      this._bookingItem = val;
      this.form.setValue(this.formService.form.value, { emitEvent: false });
    }
  }

  onAmountSelected(): void {
    this.amountSelected.emit(this.formService.bookingItem);
  }
}
