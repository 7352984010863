import { ColDef } from 'ag-grid-community';
import { createReducer, on } from '@ngrx/store';
import * as WaitingListListsActions from './waiting-list-lists.actions';

export const waitingListListsFeatureKey = 'lists';

export interface State {
  customer: {
    columnDefs: ColDef[];
  };
  format: {
    columnDefs: ColDef[];
  };
  recording: {
    columnDefs: ColDef[];
  };
}

export const initialState: State = {
  customer: {
    columnDefs: [],
  },
  format: {
    columnDefs: [],
  },
  recording: {
    columnDefs: [],
  },
};

export const reducer = createReducer(
  initialState,
  on(WaitingListListsActions.init, (state, action) => ({
    ...state,
    customer: {
      ...state.customer,
      columnDefs: action.customerColumnDefs,
    },
    format: {
      ...state.customer,
      columnDefs: action.formatColumnDefs,
    },
    recording: {
      ...state.customer,
      columnDefs: action.recordingColumnDefs,
    },
  }))
);
