import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  routerCancelAction,
  routerErrorAction,
  routerNavigatedAction,
  routerRequestAction,
} from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { mergeMap, of, map, withLatestFrom, tap } from 'rxjs';
import { unauthorizedLink } from 'src/app/app-routing.module';
import { LOADER_ID_APP } from 'src/app/app.component';
import * as AppActions from './app.actions';
import { getActiveLoaders } from './app.selectors';

@Injectable()
export class AppEffects {
  constructor(
    private actions$: Actions,
    private router: Router,
    private store: Store
  ) {}

  unauthorizedRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AppActions.unauthorizedRedirect),
        tap((ac) => {
          this.router.navigate(unauthorizedLink());
        })
      ),
    { dispatch: false }
  );

  navigationStartLoader$ = createEffect(
    // get loader to start from current navigation
    () =>
      this.actions$.pipe(
        ofType(routerRequestAction),
        mergeMap(() => {
          const loader =
            this.router.getCurrentNavigation().extras?.state?.loader;
          return of(AppActions.loaderStart({ id: loader || LOADER_ID_APP }));
        })
      )
  );

  navigationStopLoader$ = createEffect(
    // get loader to (potentially) stop from target route configuration
    // check whether it is active and if not stop the app loader
    () =>
      this.actions$.pipe(
        ofType(routerNavigatedAction, routerCancelAction, routerErrorAction),
        map((action) => (action.payload.routerState as any).data?.loader),
        withLatestFrom(this.store.select(getActiveLoaders)),
        mergeMap(([loader, activeLoaders]) => {
          const isActive = activeLoaders.includes(loader);
          return of(
            AppActions.loaderStop({ id: isActive ? loader : LOADER_ID_APP })
          );
        })
      )
  );
}
