<div class="o-container u-pt-24">
  <h1 class="o-type-h4 u-mb-48">
    Neue Buchung anlegen
  </h1>
  <app-loader-wrapper *ngIf="!(bookingsFacade.currentDraftOrder$ | async) || (bookingsFacade.currentDraftOrder$ | async)?.state === DraftOrderState" [active]="actionLoaderActive$ | async" style="height: 100%">
    <app-booking-form
      [customer]="customersFacade.currentCustomer$ | async"
      [booking]="bookingsFacade.currentDraftOrder$ | async"
      (newAddress)="onNewAddress($event)"
      (formSubmit)="onSubmitCreateBooking($event)"
    >
    </app-booking-form>

    <app-booking-draft-coupon
      *ngIf="!!(bookingsFacade.currentDraftOrder$ | async)"
      [booking]="bookingsFacade.currentDraftOrder$ | async"
      (add)="onAddCouponCode($event)"
    >
    </app-booking-draft-coupon>

    <button
      class="u-mt-48 u-mb-24"
      mat-flat-button
      color="accent"
      type="button"
      [disabled]="!!(bookingsFacade.currentDraftOrder$ | async)"
      (click)="onCancel()"
    >
      abbrechen
    </button>
    <button
      class="u-mt-48 u-mb-24"
      mat-flat-button
      color="accent"
      type="button"
      [disabled]="!(bookingsFacade.currentDraftOrder$ | async)"
      (click)="onComplete()"
    >
      abschließen
    </button>

  </app-loader-wrapper>

  <app-booking-success
    *ngIf="(bookingsFacade.currentDraftOrder$ | async)?.state !== DraftOrderState"
  >
  </app-booking-success>
</div>

