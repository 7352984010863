<mat-form-field *ngIf="_tags?.length > 0 || allowEmpty; else empty" style="width: 100%;" appearance="fill">
  <mat-chip-grid #chipList>
    <mat-chip-row
      *ngFor="let tag of _selected"
      (removed)="onDelete(tag.id)"
    >
      {{tag.name}}
      <button
        *ngIf="enableDelete"
        matChipRemove
      >
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip-row>
    <input
      [style]="{opacity: enableCreate || (enableAdd && filteredTags.length > 0) ? 1 : 0}"
      placeholder="New tag ..."
      #input
      [formControl]="inputCtrl"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (matChipInputTokenEnd)="onCreate($event)">
  </mat-chip-grid>
  <!-- *ngIf="enableAdd && filteredTags.length > 0" -->
  <mat-autocomplete
    #auto="matAutocomplete"
    (optionSelected)="onSelected($event)"
  >
    <mat-option *ngFor="let tag of filteredTags" [value]="tag.name">
      {{tag.name}}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>

<ng-template #empty>
  <p >Keine verfügbar.</p>
</ng-template>
