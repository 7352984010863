import { recordingCreateLink } from './../../../recordings-routing-helper';
import { Router } from '@angular/router';
import { ICellRendererParams } from 'ag-grid-community';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Component } from '@angular/core';
import { ROUTE_QUERY_PARAM_FORMAT_ID } from 'src/app/core/route-params';
import { Link } from 'src/app/core/model';

@Component({
  selector: 'app-recording-full-with-cell',
  templateUrl: './recording-full-width-cell-renderer.component.html',
})
export class RecordingFullWidthCellRendererComponent
  implements ICellRendererAngularComp
{
  params: ICellRendererParams;
  link: Link;
  queryParams: any;

  constructor(private router: Router) {}

  agInit(params: ICellRendererParams): void {
    this.updateHref(params);
  }

  refresh(params: ICellRendererParams): boolean {
    this.updateHref(params);
    return true;
  }

  updateHref(params: ICellRendererParams): void {
    this.params = params;
    this.link = recordingCreateLink();
    this.queryParams = { [ROUTE_QUERY_PARAM_FORMAT_ID]: this.params.value };
  }

  onNewRecording(e: any): void {
    e.preventDefault();
    this.router.navigate(this.link, { queryParams: this.queryParams });
  }
}
