import { Recording } from 'src/app/core/graphql.model';
import { Id } from 'src/app/core/model';
import { ROUTE_PARAM_RECORDING_ID } from 'src/app/core/route-params';
import { MergedRoute } from 'src/app/core/store/router/merged-route';
import { State } from './recording-entities.reducer';

export const getRecordings = (state: State) =>
  Object.values(state.entities).filter(
    (recording): recording is Recording => !!recording
  );

export const getRecordingsByFormat = (state: State, formatId: Id) =>
  Object.values(state.entities).filter(
    (recording) => recording.format.id === formatId
  );

export const getRecording = (state: State, id: Id) => state.entities[id];

export const getCurrentRecordingId = (mergedRoute: MergedRoute) =>
  +mergedRoute?.params[ROUTE_PARAM_RECORDING_ID];
