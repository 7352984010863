import { Store } from '@ngrx/store';
import { BaseResolver } from '../core/base/base.resolver';
import { Actions } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import * as UsersActions from './store/user.actions';

@Injectable({
  providedIn: 'root',
})
export class CurrentUserResolver extends BaseResolver {
  requestAction = UsersActions.entities.loadCurrentUser;
  successAction = UsersActions.entities.loadUserSuccess;
  failureAction = UsersActions.entities.loadUserFailure;
  constructor(protected store: Store, protected actions$: Actions) {
    super();
  }
}
