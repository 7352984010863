import { Component, Input } from '@angular/core';
import { BookingAddressPaymentForm } from '../booking-form/booking-form.model';

@Component({
  selector: 'app-booking-address-payment-overview',
  templateUrl: './booking-address-payment-overview.component.html',
  styleUrls: ['./booking-address-payment-overview.component.scss'],
})
export class BookingAddressPaymentOverviewComponent {
  @Input() addressPayment: BookingAddressPaymentForm;

  constructor() {}
}
