import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { User } from '../core/graphql.model';
import * as UserActions from './store/user.actions';
import * as UserSelectors from './store/user.selectors';

@Injectable({
  providedIn: 'root',
})
export class UsersFacadeService {
  constructor(private store: Store) {}

  // SELECTORS

  rules$ = this.store.select(UserSelectors.getAuthRules);
  user$ = this.store.select(UserSelectors.getAuthUser);
  users$ = this.store.select(UserSelectors.getEntitiesUsers);
  currentUser$ = this.store.select(UserSelectors.getEntitiesCurrentUser);
  listOverview$ = this.store.select(UserSelectors.getListOverview);

  // AUTH

  login = () => {
    this.store.dispatch(UserActions.auth.login());
  };

  logout = () => {
    this.store.dispatch(UserActions.auth.logout({soft: false}));
  };

  // ENTITIES READ

  loadUsers = () => {
    this.store.dispatch(UserActions.entities.loadUsers());
  };

  loadCurrentUser = () => {
    this.store.dispatch(UserActions.entities.loadCurrentUser());
  };

  // ENTITIES UPDATE

  updateUser = (user: Partial<User>) => {
    this.store.dispatch(UserActions.entities.updateUser({ data: user }));
  };

  // ENTITIES DELETE

  // LISTS
}
