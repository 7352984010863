import {
  ListConfigConfig,
  getEmptyListConfigConfig as getEmptyListConfigConfigBase,
} from './../../../core/list/list.model';
import { createReducer, on } from '@ngrx/store';
import * as FormatListsActions from './format-lists.actions';
import { ColDef } from 'ag-grid-community';

export const formatListsFeatureKey = 'lists';

export interface State {
  overview: {
    columnDefs: ColDef[];
    config: ListConfigConfig;
  };
}

const getEmptyListConfigConfig = () => {
  const base = getEmptyListConfigConfigBase();
  return {
    ...base,
    filter: {
      ...base.filter,
      releaseState: {
        filterType: 'set',
        values: ['ACTIVE'],
      },
    },
  };
};

export const initialState: State = {
  overview: {
    columnDefs: [],
    config: getEmptyListConfigConfig(),
  },
};

export const reducer = createReducer(
  initialState,
  on(FormatListsActions.init, (state, action) => ({
    ...state,
    overview: {
      ...state.overview,
      columnDefs: action.columnDefs,
    },
  }))
);
