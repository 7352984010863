import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { map, first, takeUntil, take, switchMap } from 'rxjs/operators';
import * as UserActions from './store/user.actions';
import * as UserSelectors from './store/user.selectors';
import * as fromUsers from './store/user.reducer';
import { Actions, ofType } from '@ngrx/effects';

@Injectable({
  providedIn: 'root',
})
export class UserAuthGuard implements CanActivate {
  constructor(
    private store: Store<fromUsers.State>,
    private actions$: Actions,
  ) {}

  public canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    
    return this.store.select(UserSelectors.getAuthToken).pipe(
      switchMap((token) => {
        if (!token) {
          this.store.dispatch(UserActions.auth.loginRedirect());
          return of(false);
        } else {
          this.store.dispatch(UserActions.auth.getUser({isLogin: false }));

          // resolve user
          return this.actions$.pipe(
            ofType(UserActions.auth.getUserSuccess),
            takeUntil(this.actions$.pipe(ofType(UserActions.auth.getUserFailure))),
            take(1),
      
            // check auth
            switchMap(() => this.store.pipe(
              select(UserSelectors.isAuthAuthenticated),
              first(),
              map((isAuthenticated) => {
                if (!isAuthenticated) {
                  this.store.dispatch(UserActions.auth.loginRedirect());
                  return false;
                }
                return true;
              })
            ))
          )
        }
      })
    )
  }
}
