<div class="u-bgcolor-grey-20 u-mb-40">
  <div class="u-flex u-ai-center u-mb-16 u-pl-8 u-pt-12">
    <div class="o-circle o-type-body-small u-bgcolor-grey-60 u-color-white u-mr-8">
      1.
    </div>
    <div class="o-type-overline">
      Aufzeichnungstermin
    </div>
  </div>
  <div class="selectRecordingOverview__container o-grid u-ai-center">
    <div class="o-grid__col u-6/36 u-push-2/36">
      <p class="o-type-body-small">
        <span class="o-type-body u-mr-6">Format:</span>
        {{ createSelectRecording.format.title }}
      </p>
    </div>
    <div class="o-grid__col u-6/36">
      <p class="o-type-body-small dummy-data">
        <span class="o-type-body">Aktion:</span>
        {{ createSelectRecording.campain || "keine Aktion" }}
      </p>
    </div>
    <div class="o-grid__col u-6/36">
      <p class="o-type-body-small dummy-data">
        <span class="o-type-body u-mr-6">Anzahl Ticktes: </span>
        {{ createSelectRecording.amount || "keine Tickets" }}
      </p>
    </div>

    <div class="o-grid__col u-15/36 u-flex">
      <p class="o-type-body-small">
        <span class="o-type-body u-mr-6">Aufzeichnung: </span>
        {{ createSelectRecording.recording.eventStart }}
      </p>
      <p class="o-type-body-small u-ml-8">
        {{ createSelectRecording.recording.title }} {{ createSelectRecording.recording.type }}
      </p>
    </div>
    <!--  <p>Aufzeichnung: {{ createSelectRecording | json}}</p>-->
  </div>
</div>
