import { AdminBooking } from 'src/app/core/graphql.model';
import { VId } from 'src/app/core/model';
import { MergedRoute } from 'src/app/core/store/router/merged-route';
import { State } from './booking-entities.reducer';

export const getBookings = (state: State) =>
  Object.values(state.entities).filter(
    (booking): booking is AdminBooking => !!booking
  );

export const getCurrentBookingId = (mergedRoute: MergedRoute): VId =>
  mergedRoute?.params.bookingid;

export const getBooking = (state: State, id: VId) => state.entities[id];
