import { removeGraphQlFields as removeGraphQlFieldsBasic } from '../core/graphql.helpers';
import { AddProductVariantInput, CreateRecordingInput, ProductVariant, Recording, UpdateRecordingInput } from '../core/graphql.model';

export const removeGraphQlFields = (recording: Recording): Recording => {
  return {
    ...removeGraphQlFieldsBasic(recording),
    internalTickets: recording.internalTickets.map((internalTicket) =>
      removeGraphQlFieldsBasic(internalTicket)
    ),
    productVariants: recording.productVariants.map((productVariant) =>
      removeGraphQlFieldsBasic(productVariant)
    ),
    season: removeGraphQlFieldsBasic(recording.season),
    format: removeGraphQlFieldsBasic(recording.format),
  };
};

export const recordingToCreateRecordingInput = (recording: Recording): CreateRecordingInput => {
  const {id, version, format, season, productVariants, internalTickets, ...rest} = recording;
  const input = {
    ...rest,
    formatId: format.id,
    seasonId: season.id,
    productVariants: productVariants.map((productVariant: ProductVariant) => {
      // ASSUMPTION recording in product variant is identical to root product
      const {id, name, sku, recording: _, product, maxPerOrder, ...rest} = productVariant;
      const productId = product.id;
      const productOptionGroupId = recording.format.products.find(
          (product) => product.id === productId
        )?.optionGroups[0].id
      return {
        ...rest,
        maxPerOrder: maxPerOrder || null,
        productId,
        productOptionGroupId,
      };
    }),
    internalTickets: internalTickets.map(internalTicket => {
      const {id, ...rest} = internalTicket;
      return {
        name: '', // @TODO better make field required in type when it is required in creeate type
        ...rest
      };
    }),
  };
  return input;
}

export const recordingToUpdateRecordingInput = (recording: Recording): UpdateRecordingInput => {
  const {format, season, productVariants, internalTickets, ...rest} = recording;
  const input = {
    ...rest,
    formatId: format.id,
    seasonId: season.id,
  };
  return input;
}

export const productVariantToAddProductVariantInput = (productVariant: ProductVariant, recording: Recording): AddProductVariantInput => {
  // TODO the proper recording should be inside the product variant

  const {id, product, recording: recordingIncomplete, isDeletable, maxPerOrder, ...rest} = productVariant;
  return {
    ...rest,
    productId: product.id,
    productOptionGroupId: product.optionGroups[0].id,
    recordingId: recording.id,
    maxPerOrder: maxPerOrder || null,
  };
};
