import { Injectable } from '@angular/core';
import {
  ColDef,
  ValueFormatterParams,
  ValueGetterFunc,
  ValueGetterParams,
} from 'ag-grid-community';
import { TranslateService } from '@ngx-translate/core';
import { CustomerListColumnDefsService } from 'src/app/customers/store/lists/customer-list-column-defs.service';
import { WaitingListEntryType } from 'src/app/core/graphql.model';
import {
  BaseColumnIds,
  BaseColumnDefsService,
} from 'src/app/core/list/base-column-defs-service';

export enum WaitingListColumnIds {
  FORMAT = 'format',
  RECORDING = 'recording',
  PRODUCTION_START = 'productionStart',
  TYPE = 'type',
}

@Injectable({
  providedIn: 'root',
})
export class WaitingListListsColumnDefsService extends BaseColumnDefsService {
  columnDefsMap = new Map<string, ColDef>([
    [
      BaseColumnIds.SELECT,
      this.baseColumnDefsMap.get(BaseColumnIds.SELECT),
    ],
    [
      BaseColumnIds.ID,
      this.baseColumnDefsMap.get(BaseColumnIds.ID),
    ],
    [
      WaitingListColumnIds.FORMAT,
      {
        headerName: this.translate.instant('WAITING_LISTS.COLUMNS.FORMAT'),
        field: 'format.title',
      },
    ],
    [
      WaitingListColumnIds.RECORDING,
      {
        headerName: this.translate.instant('WAITING_LISTS.COLUMNS.RECORDING'),
        field: 'recording.title',
      },
    ],
    [
      BaseColumnIds.CREATED_AT,
      this.baseColumnDefsMap.get(BaseColumnIds.CREATED_AT),
    ],
    [
      WaitingListColumnIds.PRODUCTION_START,
      {
        headerName: this.translate.instant('WAITING_LISTS.COLUMNS.PRODUCTION_START'),
        field: 'recording.productionStart',
        type: 'dateColumn',
      },
    ],
    [
      WaitingListColumnIds.TYPE,
      {
        field: 'type',
        headerName: this.translate.instant('WAITING_LISTS.COLUMNS.TYPE'),
        valueFormatter: (params: ValueFormatterParams) =>
          this.translate.instant(`ENUM.WAITING_LIST_ENTRY_TYPE.${params.value}`),
        filter: 'agSetColumnFilter',
        filterParams: {
          values: Object.values(WaitingListEntryType),
          valueFormatter: (params: ValueFormatterParams) =>
            this.translate.instant(`ENUM.WAITING_LIST_ENTRY_TYPE.${params.value}`),
        },
      },
    ],
    [
      BaseColumnIds.ACTIONS,
      this.baseColumnDefsMap.get(BaseColumnIds.ACTIONS),
    ],
  ]);

  customerColumnDefs: ColDef[] = Array.from(this.columnDefsMap.keys()).map(
    (key) => ({
      colId: key,
      ...this.columnDefsMap.get(key),
    })
  );
  formatColumnDefs = this.customerListColmnDefsService.columnDefs.map(
    (customerColumnDef) => {
      return {
        ...customerColumnDef,
        field: !customerColumnDef.field
          ? customerColumnDef.field
          : 'customer.' + customerColumnDef.field,
        valueGetter: !customerColumnDef.valueGetter
          ? customerColumnDef.valueGetter
          : (params: ValueGetterParams) => {
              return (customerColumnDef.valueGetter as ValueGetterFunc)({
                ...params,
                data: params.data.customer,
              });
            },
      };
    }
  );

  recordingColumnDefs = this.formatColumnDefs;

  constructor(
    protected translate: TranslateService,
    private customerListColmnDefsService: CustomerListColumnDefsService
  ) {
    super(translate);
  }
}
