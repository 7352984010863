import { Router } from '@angular/router';
import { ICellRendererParams } from 'ag-grid-community';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Component } from '@angular/core';
import { Link } from 'src/app/core/model';
import { BookingsFacadeService } from 'src/app/bookings/bookings-facade.service';

@Component({
  selector: 'app-bookings-list-actions-cell',
  templateUrl: './bookings-list-actions-cell-renderer.component.html',
})
export class BookingsListActionsCellRendererComponent
  implements ICellRendererAngularComp
{
  params: ICellRendererParams;
  linkShow: Link;
  queryParamsShow: any;

  constructor(private router: Router, private facade: BookingsFacadeService) {}

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  refresh(params: ICellRendererParams): boolean {
    this.params = params;
    return true;
  }
}
