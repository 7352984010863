import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { Component, forwardRef, EventEmitter, Input, Output, ViewChild, ElementRef } from '@angular/core';
import { FormControl, ValidationErrors } from '@angular/forms';
import { Id } from 'src/app/core/model';
import { Tag } from '../../../core/graphql.model';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  Validator,
} from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { startWith, Subject, takeUntil, tap } from 'rxjs';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

// NOTE reference to #input is needed, otherwise you get an error, so we just hide the element

@Component({
  selector: 'app-tags-select',
  templateUrl: './tags-select.component.html',
  styleUrls: ['./tags-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TagsSelectComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => TagsSelectComponent),
      multi: true,
    },
  ],
})
export class TagsSelectComponent
  implements ControlValueAccessor, Validator
{

  _tags: Tag[];
  @Input() set tags(tags: Tag[]) {
    this._tags = tags;
    this._updateFilteredTags();
    this.inputCtrl.updateValueAndValidity({ onlySelf: false, emitEvent: true });
  };

  _selected: Tag[];
  // NOTE the setter is used when component is not used as CVA
  @Input() set selected(selected: Tag[]) {
    this._selected = selected;
    this._updateFilteredTags();
  };

  @Input() enableDelete: boolean;

  @Input() enableCreate: boolean;

  @Input() allowEmpty: boolean;

  @Input() enableAdd: boolean;

  @Output() delete: EventEmitter<Id> = new EventEmitter();

  @Output() create: EventEmitter<string> = new EventEmitter();

  separatorKeysCodes: number[] = [ENTER, COMMA];

  onChangeCallbacks: any[] = [];

  inputCtrl = new FormControl('');

  filteredTags: Tag[] = [];

  private _destroyed$: Subject<any> = new Subject();

  @ViewChild('input') input: ElementRef<HTMLInputElement>;

  // COMPONENT

  constructor() {
    this.inputCtrl.valueChanges.pipe(
      startWith(null),
      tap((name: string | null) => this._updateFilteredTags(name)),
    )
    .pipe(takeUntil(this._destroyed$))
    .subscribe();
  }

  ngOnDestroy(): void {
    this._destroyed$.next(null);
  }

  // FORM

  writeValue(val: any): void {
    if (val) {
      this._selected = val;
      this._updateFilteredTags();
    }
  }

  registerOnChange(fn: any): void {
    this.onChangeCallbacks.push(fn);
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState?(isDisabled: boolean): void {
  //   isDisabled ? this.form.disable() : this.form.enable();
  }

  validate(): ValidationErrors | null {
    return null;
  }

  // HANDLERS

  onDelete = (id: Id) => {
    if (!this.enableDelete) {
      return;
    }
    // for use outside a form use event, updated via input
    this.delete.emit(id);
    // for use in form change directly
    this._selected = this._selected.filter(
      (tag: Tag) => tag.id !== id
    );
    this.onChangeCallbacks.forEach((fn: any) => {
      fn(this._selected);
    });
    this._updateFilteredTags();
  }

  onCreate = (event: MatChipInputEvent) => {
    if (!this.enableCreate || !event.value) {
      return;
    }
    // for use outside a form use event
    const name = event.value;
    this.create.emit(name);

    event.chipInput.clear();
    this.inputCtrl.setValue(null);
  }

  onSelected = (event: MatAutocompleteSelectedEvent): void => {
    if (!this.enableAdd) {
      return;
    }

    // for use in form change directly
    const tag = this._tags.find((tag: Tag) => tag.name === event.option.value);
    // @TODO ASSUMPTION tag names are unique
    const selected = Object.assign([], this._selected); // avoid TypeError: Cannot add property 1, object is not extensible
    selected.push(tag);
    this._selected = selected;
    this.onChangeCallbacks.forEach((fn: any) => {
      fn(this._selected);
    });

    this.input.nativeElement.value = '';
    this.inputCtrl.setValue(null);
    this._updateFilteredTags();
  }

  // METHODS

  private _filter = (value: string): Tag[] => {
    if (!this._tags) {
      return undefined;
    }
    
    if (!value) {
      return this._tags.filter((tag: Tag) =>
        !this._selected?.map((tag: Tag) => tag.id).includes(tag.id)
      );
    }

    const filterValue = value.toLowerCase();
    return this._tags.filter((tag: Tag) =>
      tag.name.toLowerCase().includes(filterValue)
      && !this._selected.map((tag: Tag) => tag.id).includes(tag.id)
    );
  }

  private _updateFilteredTags = (value = undefined) => {
    this.filteredTags = this._filter(value || this.inputCtrl.value);
  }
}
