<div *ngIf="!params.node.group">
  <a [routerLink]="link" (click)="onClone($event)">
    <mat-icon color="primary">file_copy</mat-icon>
  </a>
<!--  <button-->
<!--    mat-icon-button-->
<!--    color="primary"-->
<!--    aria-label="als Vorlage auswählen"-->
<!--    matTooltip="als Vorlage auswählen"-->
<!--    matTooltipShowDelay="500"-->
<!--    [routerLink]="link"-->
<!--    (click)="onClone($event)"-->
<!--  >-->
<!--    <mat-icon>file_copy</mat-icon>-->
<!--  </button>-->
</div>
<!--<div *ngIf="params.node.group">some other actions</div>-->
