import { createAction, props } from '@ngrx/store';
import { Format } from 'src/app/core/graphql.model';
import { Id } from 'src/app/core/model';

// CREATE

export const createFormat = createAction(
  '[Format] Create',
  props<{ data: Format }>()
);
export const createFormatSuccess = createAction(
  '[Format] Create Success',
  props<{ data: Format }>()
);
export const createFormatFailure = createAction(
  '[Format] Create Failure',
  props<{ error: any }>()
);

// READ

export const loadFormats = createAction('[Format] Load All');

export const loadFormatsSuccess = createAction(
  '[Format] Load All Success',
  props<{ data: Format[] }>()
);
export const loadFormatsFailure = createAction(
  '[Format] Load All Failure',
  props<{ error: any }>()
);

export const loadCurrentFormat = createAction('[Format] Load Current');
export const loadFormatSuccess = createAction(
  '[Format] Load Single Success',
  props<{ data: Format }>()
);
export const loadFormatFailure = createAction(
  '[Format] Load Single Failure',
  props<{ error: any }>()
);

// UPDATE

export const updateFormat = createAction(
  '[Format] Update',
  props<{ data: Format }>()
);
export const updateFormatSuccess = createAction(
  '[Format] Update Success',
  props<{ data: Format }>()
);
export const updateFormatFailure = createAction(
  '[Format] Update Failure',
  props<{ error: any }>()
);

// DELETE

export const deleteFormat = createAction(
  '[Format] Delete',
  props<{ id: Id }>()
);
export const deleteFormatSuccess = createAction(
  '[Format] Delete Success',
  props<{ id: Id }>()
);
export const deleteFormatFailure = createAction(
  '[Format] Delete Failure',
  props<{ error: any }>()
);
