import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Tag } from '../core/graphql.model';
import { Id } from '../core/model';
import * as TagActions from './store/tag.actions';
import * as TagSelectors from './store/tag.selectors';

@Injectable({
  providedIn: 'root',
})
export class TagsFacadeService {
  constructor(private store: Store) {}

  // SELECTORS

  tags$ = this.store.select(TagSelectors.getEntitiesTags);

  // ENTITIES CREATE

  createTag = (tag: Tag) => {
    this.store.dispatch(TagActions.entities.createTag({ data: tag }));
  };

  // ENTITIES READ

  loadTags = () => {
    this.store.dispatch(TagActions.entities.loadTags());
  };

  // ENTITIES UPDATE

  // ENTITIES DELETE

  deleteTag = (id: Id) => {
    this.store.dispatch(TagActions.entities.deleteTag({ id }));
  }

}
