import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions } from '@ngrx/effects';
import * as BookingListsActions from './store/booking.actions';
import { BaseResolver } from '../core/base/base.resolver';

@Injectable({
  providedIn: 'root',
})
export class BookingsResolver extends BaseResolver {
  requestAction = BookingListsActions.entities.loadBookings;
  successAction = BookingListsActions.entities.loadBookingsSuccess;
  failureAction = BookingListsActions.entities.loadBookingsFailure;

  constructor(protected store: Store, protected actions$: Actions) {
    super();
  }
}
