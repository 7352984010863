import { FormatRecordingsTabComponent } from './pages/format-page/format-recordings-tab/format-recordings-tab.component';
import { FormatWaitingListsTabComponent } from './pages/format-page/format-waiting-lists-tab/format-waiting-lists-tab.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import * as fromFormat from './store/format.reducer';
import { FormatEffects } from './store/format.effects';
import { EffectsModule } from '@ngrx/effects';
import { ListModule } from '../core/list/list.module';
import { FormatActionsCellRendererComponent } from './components/formats-list/cell-renderers/format-actions-cell-renderer.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormatsListComponent } from './components/formats-list/formats-list.component';
import { MaterialModule } from '../core/material.module';
import { FormatsRoutingModule } from './formats-routing.module';
import { FormatsPageComponent } from './pages/formats-page/formats-page.component';
import { FormatHeaderComponent } from './components/format-header/format-header.component';
import { FormatPageComponent } from './pages/format-page/format-page.component';
import { FormatFormComponent } from './components/format-form/format-form.component';
import { FormatCreatePageComponent } from './pages/format-create-page/format-create-page.component';
import { FormatContactPersonFormComponent } from './components/format-contact-person-form/format-contact-person-form.component';
import { ProductFormComponent } from './components/product-form/product-form.component';
import { WaitingListsModule } from '../waiting-lists/waiting-lists.module';
import { FormatDataTabComponent } from './pages/format-page/format-data-tab/format-data-tab.component';
import { RecordingsListModule } from '../recordings/recordings-list.module';
import { LayoutModule } from '../layout/layout.module';
import { CaslModule } from '../core/casl/casl.module';
import { TagsModule } from '../tags/tags.module';

@NgModule({
  declarations: [
    FormatsPageComponent,
    FormatsListComponent,
    FormatActionsCellRendererComponent,
    FormatHeaderComponent,
    FormatPageComponent,
    FormatFormComponent,
    FormatCreatePageComponent,
    FormatDataTabComponent,
    FormatWaitingListsTabComponent,
    FormatRecordingsTabComponent,
    FormatContactPersonFormComponent,
    ProductFormComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FormatsRoutingModule,
    StoreModule.forFeature(fromFormat.formatFeatureKey, fromFormat.reducers),
    EffectsModule.forFeature(FormatEffects),
    TranslateModule.forChild({
      extend: true,
    }),
    LayoutModule,
    CaslModule,
    MaterialModule,
    ListModule,
    WaitingListsModule,
    RecordingsListModule,
    TagsModule,
  ],
})
export class FormatsModule {}
