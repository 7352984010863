import { Directive, OnDestroy } from '@angular/core';
import { FormGroup, ValidationErrors } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';

@Directive()
export abstract class BaseSubFormComponent implements OnDestroy {
  form: FormGroup;

  public onTouched: () => void = () => {};
  protected destroy$ = new Subject();

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  // writeValue

  registerOnChange(fn: any): void {
    this.form.valueChanges
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.form.disable() : this.form.enable();
  }

  validate(): ValidationErrors | null {
    return this.form.valid ? null : { invalid: true };
  }
}
