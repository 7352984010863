<app-customer-header [customer]="facade.currentCustomer$ | async">
</app-customer-header>

<div class="o-container">
  <app-customer-roles [customer]="facade.currentCustomer$ | async"></app-customer-roles>
</div>

<app-loader-wrapper [active]="tabLoaderActive$ | async">
  <div class="o-container u-height-1/1 u-flex u-fd-col u-pt-40 u-pb-40">
    <mat-tab-group
      animationDuration="0ms"
      [mat-stretch-tabs]="false"
      [selectedIndex]="tabIndex"
      (selectedTabChange)="onSelectedTabChange($event)"
    >
      <mat-tab label="Profil"> </mat-tab>
      <mat-tab label="Buchung"> </mat-tab>
      <mat-tab label="Wartelisten"> </mat-tab>
    </mat-tab-group>

    <router-outlet></router-outlet>
  </div>
</app-loader-wrapper>

