import { NotificationType, NotifyService } from './notify/notify.service';
import { DocumentNode } from 'graphql';
import { isEqual, range } from 'lodash';
import { CombinedQueryBuilder } from 'ts-blink-graphql-combine-query';
import { Id } from './model';
import { Store } from '@ngrx/store';
import * as UserActions from '../users/store/user.actions';

export const removeGraphQlFields = (object: any): any => {
  if (!object) {
    return object;
  }
  const { __typename = '', ...rest } = object;
  return rest;
};

export const removeDefaultFields = (object: any): any => {
  const { id, version, createdAt, updatedAt, ...rest } = object;
  return rest;
};

export const removeStatisticFields = (object: any): any => {
  const { createdAt, updatedAt, ...rest } = object;
  return rest;
};

export const getIds = (objects: any[]): Id[] => {
  return objects.map((object) => object.id);
};

export const diffInsertedObjects = (newObjects: any[], oldObjects: any[]) => {
  return newObjects.filter((object) => {
    if (!object.id) {
      return true;
    }
    const oldObject = oldObjects.find((oldObject) => oldObject.id === object.id);
    return !oldObject;
  });
};

export const diffDeletedIds = (newObjects: any[], oldObjects: any[]) => {
  const newIds = getIds(newObjects);
  return getIds(oldObjects).filter((oldId) => !newIds.includes(oldId));
};

export const diffUpdatedObjects = (
  newObjects: any[],
  oldObjects: any[]
): any[] => {
  return newObjects.filter((object) => {
    const oldObject = oldObjects.find((oldObject) => oldObject.id === object.id);
    if (!oldObject) {
      return false;
    }
    return (
      !!object.id &&
      !isEqual(
        object,
        oldObject
      )
    );
  });
};

export const appendArrayMutations = (
  cq: CombinedQueryBuilder<any, any>,
  name: string,
  newObjects: any[],
  oldObjects: any[],
  insertMutation: DocumentNode,
  insertMapper: (object: any) => any,
  deleteMutation: DocumentNode,
  deleteMapper: (object: any) => any,
  updateMutation: DocumentNode = null,
  updateMapper: (object: any) => any = null
): CombinedQueryBuilder<any, any> => {
  const inserted = diffInsertedObjects(newObjects, oldObjects);
  const deleted = diffDeletedIds(newObjects, oldObjects);
  const updated = diffUpdatedObjects(newObjects, oldObjects);

  cq = cq
    .addN(
      insertMutation,
      inserted.map(insertMapper),
      range(1, inserted.length).map((i) => `${name}_insert_${i}`)
    )
    .addN(
      deleteMutation,
      deleted.map(deleteMapper),
      range(1, deleted.length).map((i) => `${name}_delete_${i}`)
    );
  if (updateMutation && updateMapper) {
    cq = cq
      .addN(
        updateMutation,
        updated.map(updateMapper),
        range(1, updated.length).map((i) => `${name}_update_${i}`)
      );
  }
  return cq;
};

export const handleQueryError = (
  error: any,
  notifyService: NotifyService,
  store: Store
) => {
  if (error === 'Unauthorized') {
    store.dispatch(UserActions.auth.logout({soft: true}));
  } else {
    notifyService.notify({
      type: NotificationType.Toast,
      message: 'error: ' + error,
    });
  }
};

export const handleMutationError = (
  error: any,
  notifyService: NotifyService,
  store: Store
) => {
  if (error === 'Unauthorized') {
    store.dispatch(UserActions.auth.logout({soft: true}));
  } else {
    notifyService.notify({
      type: NotificationType.Toast,
      message: 'error: ' + error,
    });
  }
};
