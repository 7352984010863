import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CustomerSearchformService } from './customer-searchform.service';

@Component({
  selector: 'app-customer-searchform',
  templateUrl: './customer-searchform.component.html',
  styleUrls: ['./customer-searchform.component.scss'],
  providers: [CustomerSearchformService],
})
export class CustomerSearchformComponent {
  form: FormGroup = this.formService.form;

  constructor(private formService: CustomerSearchformService) {}

  // onSubmit() {
  //   alert(JSON.stringify(this.))
  // }
}
