
    
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"WaitingListEntryAllAttributes"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"WaitingListEntry"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"customer"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"CustomerAllAttributes"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"format"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"FormatAllAttributes"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"recording"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"RecordingAllAttributes"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"createdBy"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"UserAllAttributes"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"type"},"arguments":[],"directives":[]}]}}]};
  

    
  var names = {};
  function unique(defs) {
    return defs.filter(function (def) {
      if (def.kind !== 'FragmentDefinition') return true;
      var name = def.name.value;
      if (names[name]) {
        return false;
      } else {
        names[name] = true;
        return true;
      }
    });
  };

  doc.definitions = doc.definitions.concat(unique(require("../../formats/graphql/format-fragments.graphql").definitions));
doc.definitions = doc.definitions.concat(unique(require("../../customers/graphql/customer-fragments.graphql").definitions));
doc.definitions = doc.definitions.concat(unique(require("../../recordings/graphql/recording-fragments.graphql").definitions));
doc.definitions = doc.definitions.concat(unique(require("../../users/graphql/user-fragments.graphql").definitions));


    module.exports = doc
  
