import { createAction, props } from '@ngrx/store';
import {
  CreateWaitingListEntryInput,
  WaitingListEntry,
} from 'src/app/core/graphql.model';
import { Id } from 'src/app/core/model';

// CREATE

export const createWaitingListEntry = createAction(
  '[WaitingList] Create Entry',
  props<{ input: CreateWaitingListEntryInput }>()
);
export const createWaitingListEntrySuccess = createAction(
  '[WaitingList] Create Entry Success',
  props<{ data: WaitingListEntry }>()
);
export const createWaitingListEntryFailure = createAction(
  '[WaitingList] Create Entry Failure',
  props<{ error: any }>()
);

// READ

export const loadWaitingList = createAction('[WaitingList] Load ll');
export const loadWaitingListSuccess = createAction(
  '[WaitingList] Load All Success',
  props<{ data: WaitingListEntry[] }>()
);
export const loadWaitingListFailure = createAction(
  '[WaitingList] Load All Failure',
  props<{ error: any }>()
);

// UPDATE

// DELETE

export const deleteWaitingListEntry = createAction(
  '[WaitingList] Delete Entry',
  props<{ id: Id }>()
);
export const deleteWaitingListEntrySuccess = createAction(
  '[WaitingList] delete Entry Success',
  props<{ id: Id }>()
);
export const deleteWaitingListEntryFailure = createAction(
  '[WaitingList] Delete Entry Failure',
  props<{ error: any }>()
);
