import { Action, combineReducers, ActionReducer } from '@ngrx/store';
import * as fromBookingEntities from './entities/booking-entities.reducer';
import * as fromBookingLists from './lists/booking-lists.reducer';

export const bookingFeatureKey = 'booking';

export interface State {
  [fromBookingEntities.bookingEntitiesFeatureKey]: fromBookingEntities.State;
  [fromBookingLists.bookingListsFeatureKey]: fromBookingLists.State;
}

export function reducers(
  state: State | undefined,
  action: Action
): ActionReducer<State, any> {
  // @ts-ignore
  return combineReducers({
    [fromBookingEntities.bookingEntitiesFeatureKey]:
      fromBookingEntities.reducer,
    [fromBookingLists.bookingListsFeatureKey]: fromBookingLists.reducer,
  })(state, action);
}
