import { Component } from '@angular/core';
import { WaitingListsFacadeService } from 'src/app/waiting-lists/waiting-lists-facade.service';

@Component({
  selector: 'app-recording-waiting-lists-tab',
  templateUrl: './recording-waiting-lists-tab.component.html',
  styleUrls: ['./recording-waiting-lists-tab.component.scss'],
})
export class RecordingWaitingListsTabComponent {
  constructor(public facade: WaitingListsFacadeService) {}
}
