<div class="searchForm_container">
  <!-- <mat-card> -->
  <form class="cascading-form__container">
    <div class="form-group">
      <mat-label for="searchIn"> Suchen In: </mat-label>
      &nbsp;
      <mat-form-field appearance="outline">
        <mat-select
          [(ngModel)]="selectedValue"
          name="searchOptions"
          id="item"
          [placeholder]="default"
        >
          <mat-option
            *ngFor="let searchOption of searchOptions"
            [value]="searchOption.searchValue"
          >
            {{ searchOption.viewValue }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div *ngIf="selectedValue == 'Global'">
      <app-global-searchform></app-global-searchform>
    </div>
    <div *ngIf="selectedValue == 'Kunde'">
      <app-customer-searchform></app-customer-searchform>
    </div>

    <div *ngIf="selectedValue == 'Aufzeichnung'">
      <app-recordings-searchform></app-recordings-searchform>
    </div>

    <div *ngIf="selectedValue == 'Formate'">
      <app-formats-searchform></app-formats-searchform>
    </div>

    <p>Sie suchen in: {{ selectedValue }}</p>
  </form>
  <!-- </mat-card> -->
  <div class="cascadingSearchform__btn">
    <button
      class="cascadingSearchform__btn-styles"
      mat-flat-button
      color="accent"
      type="submit"
    >
      zurücksetzen
    </button>
    &nbsp;
    <button mat-flat-button color="accent" type="submit">suchen</button>
  </div>
</div>
