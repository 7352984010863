import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Observable } from 'rxjs';
import { AppFacadeService } from 'src/app/app-facade.service';
import { LOADER_ID_ACTION } from 'src/app/app.component';
import { Format } from 'src/app/core/graphql.model';
import { FormatsFacadeService } from '../../formats-facade.service';

@Component({
  selector: 'app-format-create-page',
  templateUrl: './format-create-page.component.html',
  styleUrls: ['./format-create-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormatCreatePageComponent {
  actionLoaderActive$: Observable<boolean>;

  constructor(
    public facade: FormatsFacadeService,
    public appFacade: AppFacadeService
  ) {
    this.actionLoaderActive$ = this.appFacade.isLoaderActive$(LOADER_ID_ACTION);
  }

  onSubmitCreate(format: Format): void {
    this.facade.createFormat(format);
  }
}
