import { Routes } from '@angular/router';
import { Action, Subject } from '@blab/blab-authorization';
import { LOADER_ID_TAB } from 'src/app/core/base/base-tab-page.component';
import { CaslAuthGuard } from 'src/app/core/casl/casl-auth.guard';
import { Permission } from 'src/app/core/model';
import { ListConfigsResolver } from 'src/app/list-configs/list-configs.resolver';
import { RouteNames } from './customers-list-routing-helper';
import { CustomersListsOverviewTabComponent } from './customers-lists-overview-tab/customers-lists-overview-tab.component';
import { CustomersOverviewTabComponent } from './customers-overview-tab/customers-overview-tab.component';

export const routes: Routes = [
  {
    path: RouteNames.CUSTOMERS_OVERVIEW,
    component: CustomersOverviewTabComponent,
    pathMatch: 'full',
    data: {
      loader: LOADER_ID_TAB,
    },
  },
  {
    path: RouteNames.LIST_CONFIG,
    component: CustomersListsOverviewTabComponent,
    canActivate: [CaslAuthGuard],
    resolve: {
      listConfig: ListConfigsResolver,
    },
    data: {
      loader: LOADER_ID_TAB,
      auth: [
        {
          subject: Subject.ListConfig,
          action: Action.Read,
        },
      ] as Permission[],
    },
  },
];
