import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundPageComponent } from '../core/errors/not-found-page/not-found-page.component';
import { RecordingPageComponent } from './pages/recording-page/recording-page.component';
import { CurrentRecordingResolver } from './recording-current.resolver';
import { RecordingsPageComponent } from './pages/recordings-page/recordings-page.component';
import { RecordingsResolver } from './recordings.resolver';
import { RecordingCreatePageComponent } from './pages/recording-create-page/recording-create-page.component';
import { RecordingCreateByFormatPageComponent } from './pages/recording-create-by-format-page/recording-create-by-format-page.component';
import { RecordingCreateByRecordingCloneComponent } from './pages/recording-create-by-recording-clone/recording-create-by-recording-clone.component';
import { FormatsResolver } from '../formats/formats.resolver';
import { CurrentFormatResolver } from '../formats/format-current.resolver';
import {
  ROUTE_PARAM_FORMAT_ID,
  ROUTE_PARAM_RECORDING_ID,
} from '../core/route-params';
import { RoutesNames } from './recordings-routing-helper';
import { routes as editRoutes } from './pages/recording-page/recording-routing';
import { CaslAuthGuard } from '../core/casl/casl-auth.guard';
import { Permission } from '../core/model';
import { Action, Subject } from '@blab/blab-authorization';

const routes: Routes = [
  {
    // LIST
    path: RoutesNames.LIST,
    component: RecordingsPageComponent,
    pathMatch: 'full',
    canActivate: [CaslAuthGuard],
    resolve: {
      recordings: RecordingsResolver,
    },
    data: {
      auth: [
        {
          subject: Subject.Recording,
          action: Action.Read,
        },
      ] as Permission[],
    },
  },
  {
    // CREATE
    path: RoutesNames.CREATE,
    component: RecordingCreatePageComponent,
    canActivate: [CaslAuthGuard],
    resolve: {
      formats: FormatsResolver,
    },
    data: {
      auth: [
        {
          subject: Subject.Format,
          action: Action.Read,
        },
        {
          subject: Subject.Recording,
          action: Action.Create,
        },
      ] as Permission[],
    },
  },
  {
    path: `${RoutesNames.CREATE_BY_FORMAT}/:${ROUTE_PARAM_FORMAT_ID}`,
    component: RecordingCreateByFormatPageComponent,
    canActivate: [CaslAuthGuard],
    resolve: {
      format: CurrentFormatResolver,
    },
    data: {
      auth: [
        {
          subject: Subject.Format,
          action: Action.Read,
        },
        {
          subject: Subject.Recording,
          action: Action.Create,
        },
      ] as Permission[],
    },
  },
  {
    path: `${RoutesNames.CREATE_BY_RECORDING_CLONE}/:${ROUTE_PARAM_RECORDING_ID}`,
    component: RecordingCreateByRecordingCloneComponent,
    canActivate: [CaslAuthGuard],
    resolve: {
      recording: CurrentRecordingResolver,
    },
    data: {
      auth: [
        {
          subject: Subject.Recording,
          action: Action.Create,
        },
      ] as Permission[],
    },
  },
  {
    // EDIT
    path: `:${ROUTE_PARAM_RECORDING_ID}`,
    component: RecordingPageComponent,
    canActivate: [CaslAuthGuard],
    resolve: {
      recording: CurrentRecordingResolver,
    },
    children: editRoutes,
    data: {
      auth: [
        {
          subject: Subject.Recording,
          action: Action.Read,
        },
      ] as Permission[],
    },
  },
  {
    path: '**',
    component: NotFoundPageComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class RecordingsRoutingModule {}
