import { customerEditLink } from '../../customers-routing-helper';
import { Id, Link } from './../../../core/model';

export enum RouteNames {
  PROFILE = '',
  BOOKINGS = 'bookings',
  WAITING_LISTS = 'waiting-lists',
}

export const customerEditLinkProfile = (id: Id): Link => customerEditLink(id)/*.concat(RouteNames.PROFILE)*/;
export const customerEditLinkBookings = (id: Id): Link => customerEditLink(id).concat(RouteNames.BOOKINGS);
export const customerEditLinkWaitingLists = (id: Id): Link => customerEditLink(id).concat(RouteNames.WAITING_LISTS);
