export * from '@blab/blab-graphql';

export enum MailCampaignStatus {
  DRAFT = 'draft',
  SENT = 'sent',
  ARCHIVE = 'archive',
  QUEUED = 'queued',
  SUSPENDED = 'suspended' ,
  IN_PROCESS = 'in_process',
};

export type Nullable<T> = T | null;
