import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InternalTicket } from 'src/app/core/graphql.model';

interface InternalTicketForm extends InternalTicket {};

@Injectable()
export class InternalTicketFormService {
  readonly form: FormGroup = this.createInternalTicket();

  set internalTicket(internalTicket: InternalTicket) {
    if (!internalTicket) {
      return;
    }

    // map internal ticket to form
    const internalTicketForm = internalTicket;

    // update form
    this.form.patchValue(internalTicketForm);
  }
  get internalTicket(): InternalTicket {
    return this.form.value as InternalTicket;
  }
  
  constructor(private fb: FormBuilder) {}

  private createInternalTicket(): FormGroup {
    return this.fb.group({
      id: [''], // hidden
      name: ['', [Validators.required]],
      quantity: ['', [Validators.required]],
    });
    // @TODO check required and validators
  }
}
