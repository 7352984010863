import { ColDef } from 'ag-grid-community';
import { createAction, props } from '@ngrx/store';

export const init = createAction(
  '[Booking] Init',
  props<{
    // columnDefs: ColDef[];
    customerColumnDefs: ColDef[];
    recordingColumnDefs: ColDef[];
    recordingParticipantColumnDefs: ColDef[];
  }>()
);
