<div class="o-container u-height-1/1 u-flex u-fd-col u-pb-24 u-pt-12">
  <h1 class="o-type-h4 u-mb-24">
    {{ "CUSTOMERS.CUSTOMERS_CREATE_PAGE.TITLE" | translate }}
  </h1>
  <app-loader-wrapper [active]="actionLoaderActive$ | async" style="height: 100%">
    <app-customer-form
      [tags]="tagsFacade.tags$ | async"
      (formSubmit)="onSubmitCreate($event)"
    ></app-customer-form>
  </app-loader-wrapper>
</div>
