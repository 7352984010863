<!-- Panel Content-->
<input type="hidden" id="id" />
<div class="o-grid" style="--mdc-shape-small: 0; --mdc-filled-button-container-shape: 0 4px 4px 0;">
    <div class="o-grid__col u-2/36">
      <div class="c-formfield-placeholder u-color-grey-60">
        <!--      @TODO ask: shall it be imported from assets, if yes how to fill an img with gray? should it be accessed through a model & json -- too much for one icon?-->
        <mat-icon>videocam</mat-icon>
        <!--      <img class="c-navigation__icon" [src]="example.icon" alt="">   -->
        <!--<img class="c-navigation__icon" src="/assets/icons/32px/ic_camera.svg" alt="">-->
        <!--      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">-->
        <!--        <path d="M2.25 5.625H14.25C15.0456 5.625 15.8087 5.94107 16.3713 6.50368C16.9339 7.06629 17.25 7.82935 17.25 8.625V17.625C17.25 17.8239 17.171 18.0147 17.0303 18.1553C16.8897 18.296 16.6989 18.375 16.5 18.375H4.5C3.70435 18.375 2.94129 18.0589 2.37868 17.4963C1.81607 16.9337 1.5 16.1706 1.5 15.375V6.375C1.5 6.17609 1.57902 5.98532 1.71967 5.84467C1.86032 5.70402 2.05109 5.625 2.25 5.625Z" fill="#979797"/>-->
        <!--        <path d="M17.25 10.5L22.5 7.5V16.5L17.25 13.5" fill="#979797"/>-->
        <!--      </svg>-->
      </div>
    </div>

    <div class="o-grid__col u-15/36">
      <!-- formats-dropdown -->
      <mat-form-field class="dropdown__container u-1/1">
        <mat-label for="title">
          {{ "RECORDINGS.RECORDING_FORM.PRODUCTION_START.LABEL" | translate }}
        </mat-label>
        <mat-select
          name="Options"
          [(ngModel)]="selectedFormatId"
          (selectionChange)="onFormatSelected()"
        >
          <mat-option *ngFor="let format of formats" [value]="format.id">
            {{ format.title }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="o-grid__col u-7/36 u-pl-none">
      <!-- recording-date -dropdown -->
      <mat-form-field class="dropdown__container u-1/1">
        <mat-select
          name="productionDates"
          [(ngModel)]="selectedRecording"
        >
          <mat-option *ngFor="let recording of recordings" [value]="recording">
            {{ recording.eventStart|date:'medium' }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="o-grid__col u-9/36 u-pl-none">
      <button
        class="btn__size u-1/1"
        mat-flat-button
        color="accent"
        type="submit"
        [disabled]="!(selectedRecording && selectedFormatId)"
        (click)="onCreateTicketListEntry()"
      >
        <mat-icon>add</mat-icon>
        Ticketalarm hinzufügen
      </button>
    </div>

  </div>


