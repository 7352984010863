  <app-loader-wrapper [active]="actionLoaderActive$ | async" style="height: 100%">
    <div class="o-container u-height-1/1 u-flex u-fd-col u-pb-24 u-pt-12">
      <h1 class="o-type-h4 u-mb-24">
        {{ "RECORDINGS.RECORDING_CREATE_PAGE.TITLE" | translate }} für Format "{{ (recordingsFacade.currentRecording$ | async).format?.title }}"
      </h1>
      <app-recording-form
        [recording]="recordingsFacade.currentRecording$ | async"
        [format]="(recordingsFacade.currentRecording$ | async).format"
        (formSubmit)="onClone($event)"
      >
      </app-recording-form>
    </div>
  </app-loader-wrapper>
