<div class="u-bgcolor-grey-20 u-p-12 u-mt-16">
  <div class="u-flex u-ai-center u-mb-24">
    <div class="o-circle o-type-body-small u-bgcolor-grey-60 u-color-white u-mr-8">
      3.
    </div>
    <div class="o-type-overline">
      Rechnung
    </div>
  </div>
  <p class="o-type-body-small u-mb-6">
    {{ addressPayment.invoiceAddress?.salutation }}
    {{ addressPayment.invoiceAddress?.firstName }}
    {{ addressPayment.invoiceAddress?.lastName }}
  </p>
  <p class="o-type-body-small u-mb-6">
    {{ addressPayment.invoiceAddress?.address }}
  </p>
  <p class="o-type-body-small u-mb-6">
    {{ addressPayment.invoiceAddress?.postCode }}
    {{ addressPayment.invoiceAddress?.city }}
  </p>
  <!-- @TODO get real data when added to bookingForm.service -->
<!--  <p class="o-type-body-small">-->
<!--    Zahlungsmethode:-->
<!--    {{ addressPayment?.paymentType }}-->
<!--  </p>-->
</div>
