import { Component, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Address, Customer } from 'src/app/core/graphql.model';
import { AddressCreateDialogFormService } from './address-create-dialog-form.service';

export interface AddressCreateDialogData {
  // IN:
  customer: Customer;
  // OUT:
  address: Address;
}

@Component({
  selector: 'app-address-create-dialog-form',
  templateUrl: './address-create-dialog-form.component.html',
  styleUrls: ['./address-create-dialog-form.component.scss'],
  providers: [AddressCreateDialogFormService],
})
export class AddressCreateDialogFormComponent {
  form: FormGroup = this.formService.form;

  constructor(
    public formService: AddressCreateDialogFormService,
    public dialogRef: MatDialogRef<AddressCreateDialogFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AddressCreateDialogData
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
