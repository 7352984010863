import { formatDate as baseFormatDate } from '@angular/common';
import { log } from 'console';

export const getLocale = () => 'de_DE';

export const formatDate = (date: Date): string => {
  if (!date) {
    return '';
  }
  return baseFormatDate(date, 'dd.MM.yyyy', 'de-DE');
};

export const formatTime = (date: Date): string => {
  if (!date) {
    return '';
  }
  return baseFormatDate(date, 'HH:mm', 'de-DE');
};

export const mergeDateAndTime = (date: Date, time: string): Date => {
  try {
    // time in format hh:mm local
    const timeBlocks = time.split(':');
    return new Date(date.setHours(+timeBlocks[0], +timeBlocks[1], 0, 0));
  } catch {
    return null;
  }
};

export const replaceBaseDate = (date: Date, baseDate: Date): Date => {
  try {
    const dateS = date?.toISOString();
    const baseDateS = baseDate?.toISOString();
    return new Date(`${baseDateS.split('T')[0]}T${dateS.split('T')[1]}`);
  } catch {
    return null;
  }
}

export const enforceDateString = (input: Date | string): string => {
  if (!input) {
    return null;
  }

  return typeof input === 'string' ? input : input.toISOString();
};
