import { Router } from '@angular/router';
import { ICellRendererParams } from 'ag-grid-community';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Component } from '@angular/core';
import { Link } from 'src/app/core/model';
import {
  ROUTE_QUERY_PARAM_LIST_CONFIG_CONFIG,
  ROUTE_QUERY_PARAM_LIST_CONFIG_ID,
  ROUTE_QUERY_PARAM_LIST_CONFIG_TITLE,
} from 'src/app/core/route-params';
import { customersListLink } from 'src/app/customers/customers-routing-helper';
import { ListConfigsFacadeService } from 'src/app/list-configs/list-configs-facade.service';

@Component({
  selector: 'app-list-config-list-actions-cell',
  templateUrl: './list-config-list-actions-cell-renderer.component.html',
})
export class ListConfigListsActionsCellRendererComponent
  implements ICellRendererAngularComp
{
  params: ICellRendererParams;
  linkShow: Link;
  queryParamsShow: any;

  constructor(
    private router: Router,
    private facade: ListConfigsFacadeService
  ) {}

  agInit(params: ICellRendererParams): void {
    this.updateHrefs(params);
  }

  refresh(params: ICellRendererParams): boolean {
    this.updateHrefs(params);
    return true;
  }

  updateHrefs(params: ICellRendererParams): void {
    this.params = params;
    this.linkShow = customersListLink(); // @TODO generic entities
    this.queryParamsShow = {
      [ROUTE_QUERY_PARAM_LIST_CONFIG_CONFIG]: this.params.data.config,
      [ROUTE_QUERY_PARAM_LIST_CONFIG_TITLE]: this.params.data.title,
      [ROUTE_QUERY_PARAM_LIST_CONFIG_ID]: this.params.data.id,
    };
  }

  onShow(e: any): void {
    e.preventDefault();
    this.router.navigate(this.linkShow, { queryParams: this.queryParamsShow });
  }

  onDelete(e: any): void {
    e.preventDefault();
    this.facade.deleteListConfig(this.params.data.id);
  }
}
