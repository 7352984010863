<!--<h1>{{ "FORMATS.FORMAT_PAGE.TITLE" | translate }}</h1>-->

<app-format-header [format]="facade.currentFormat$ | async">
</app-format-header>

<app-loader-wrapper [active]="tabLoaderActive$ | async">
  <div class="o-container u-height-1/1 u-flex u-fd-col u-pt-40 u-pb-40">
    <mat-tab-group
      animationDuration="0ms"
      [mat-stretch-tabs]="false"
      [selectedIndex]="tabIndex"
      (selectedTabChange)="onSelectedTabChange($event)"
    >
      <mat-tab label="Formatdaten"> </mat-tab>
      <mat-tab label="Aufzeichnungen"> </mat-tab>
      <mat-tab label="Warteliste"> </mat-tab>
    </mat-tab-group>

    <router-outlet></router-outlet>
  </div>
</app-loader-wrapper>
