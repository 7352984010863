import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/core/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { CustomerSearchformComponent } from './components/customer-searchform/customer-searchform.component';
import { RecordingsSearchformComponent } from './components/recordings-searchform/recordings-searchform.component';
import { FormatsSearchformComponent } from './components/formats-searchform/formats-searchform.component';
import { GlobalSearchformComponent } from './components/global-searchform/global-searchform.component';
import { CascadingSearchformPageComponent } from './pages/cascading-searchform-page/cascading-searchform-page.component';

// @TODO review/refactor whole module

@NgModule({
  declarations: [
    CustomerSearchformComponent,
    RecordingsSearchformComponent,
    FormatsSearchformComponent,
    GlobalSearchformComponent,
    CascadingSearchformPageComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    TranslateModule.forChild({
      extend: true,
    }),
  ],
  exports: [
    CustomerSearchformComponent,
    RecordingsSearchformComponent,
    FormatsSearchformComponent,
    GlobalSearchformComponent,
    CascadingSearchformPageComponent,
  ],
})
export class CascadingSearchformModule {}
