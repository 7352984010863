<!-- ids must be indexed -->

<!-- @TODO add missing fields -->
<form [formGroup]="form">
  <mat-accordion class="u-block u-mb-24">
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header class="dropdown__container">
          <div class="u-17/36">
            <mat-form-field class="u-1/1 display-none">
              <mat-label [attr.for]="'vat-' + idx"> Produktname </mat-label>
              <mat-select
                (click)="onClickStopPropagation($event)"
                class="ticketOption__bar"
                name="productOptions"
                [id]="'type-' + idx"
                placeholder="wähle Ticketart"
                formControlName="productId"
              >
                <!-- @TODO -->
                <!-- formControlName="type" -->
                <!-- formControlName="name" -->
                <mat-option *ngFor="let product of products" [value]="product.id">
                  {{ product.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="u-13/36 u-ml-16">
            <mat-form-field class="u-1/1 display-none">
              <mat-label [attr.for]="'vat-' + idx"> Ticket Status </mat-label>
              <mat-select
                (click)="onClickStopPropagation($event)"
                class="bookingOption__bar"
                name="bookingOptions"
                [id]="'bookingType-' + idx"
                placeholder="Multiselect"
              >
                <mat-option
                  *ngFor="let bookingOption of bookingOptions"
                  [value]="bookingOption.selectedValue"
                >
                  {{ bookingOption.viewValue }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
      </mat-expansion-panel-header>

      <input type="hidden" [id]="'id-' + idx" formControlName="id" />

      <div class="u-ph-16">

        <div class="o-grid">
          <div class="o-grid__col u-6/36">
            <mat-form-field class="u-1/1">
              <mat-label [attr.for]="'stockOnHand-' + idx"> Anzahl der Plätze </mat-label>
              <input
                [id]="'stockOnHand-' + idx"
                matInput
                type="number"
                formControlName="stockOnHand"
              />
            </mat-form-field>
          </div>
          <div class="o-grid__col u-6/36">
            <mat-form-field class="u-1/1">
              <mat-label [attr.for]="'wheelchairCapacity-' + idx"> davon Rollstuhlplätze </mat-label>
              <input [id]="'wheelchairCapacity-' + idx" matInput type="number"/>
              <!-- @TODO -->
              <!-- formControlName="wheelchairCapacity" -->
            </mat-form-field>
          </div>&nbsp;
          <div class="o-grid__col u-6/36">
            <mat-form-field class="u-1/1">
              <mat-label [attr.for]="'maxBookingCapacity-' + idx"> Max Plätze pro Buchung </mat-label>
              <input
                formControlName="maxPerOrder"
                min="0"
                [id]="'maxBookingCapacity-' + idx"
                matInput type="number"
              />
            </mat-form-field>
          </div>

<!--          <div class="o-grid__col u-7/36">-->
<!--            <div class="c-formfield-placeholder u-inline-flex">-->
<!--              <section>-->
<!--                <mat-checkbox disabled> nur in 2er Gruppen buchen-->
<!--                </mat-checkbox>-->
<!--              </section>-->
<!--            </div>-->
<!--          </div>-->
        </div>

        <div class="o-grid">
          <div class="o-grid__col u-6/36">
            <mat-form-field class="u-1/1">
              <mat-label [attr.for]="'grossPrice-' + idx"> Bruttopreis in Euro </mat-label>
              <input [id]="'grossPrice-' + idx" matInput currencyMask formControlName="price" />
            </mat-form-field>
          </div>

          <div class="o-grid__col u-6/36">
            <mat-form-field class="u-1/1">
              <mat-label [attr.for]="'vat-' + idx"> Mwst </mat-label>
              <mat-select name="Options" [id]="'vat-' + idx" disabled>
                <!-- @TODO -->
                <!-- formControlName="vat" -->
                <!-- <mat-option
                  *ngFor="let vatOption of vatOptions"
                  [value]="vatOption.selectedValue"
                >
                  {{ vatOption.selectedValue }}
                </mat-option> -->
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div class="o-grid">
          <div class="o-grid__col u-6/36">
            <mat-form-field class="u-1/1">
              <mat-label [attr.for]="'eventStart-' + idx"> Ankunft </mat-label>
              <input
                [id]="'eventStart-' + idx"
                matInput
                type="text"
                formControlName="eventStart"
              />
            </mat-form-field>
          </div>
          <div class="o-grid__col u-6/36">
            <mat-form-field class="u-1/1">
              <mat-label [attr.for]="'eventEnd-' + idx"> Ende </mat-label>
              <input
                [id]="'eventEnd-' + idx"
                matInput
                type="text"
                formControlName="eventEnd"
              />
            </mat-form-field>
          </div>
        </div>

<!--        <div class="o-grid">-->
<!--          <div class="o-grid__col u-13/36">-->
<!--            <mat-form-field class="u-1/1">-->
<!--              <mat-label [attr.for]="'voucher-' + idx"> Rabattcode </mat-label>-->
<!--              <input-->
<!--                [id]="'voucher-' + idx"-->
<!--                matInput-->
<!--                type="text"-->
<!--                formControlName="voucher"-->
<!--              />-->
<!--              &lt;!&ndash;@TODO add formControlName="voucher" in formControl.ts &ndash;&gt;-->
<!--            </mat-form-field>-->
<!--          </div>-->
<!--          <div class="o-grid__col u-6/36">-->
<!--            <mat-form-field class="u-1/1">-->
<!--              <mat-label [attr.for]="'bookingDate-' + idx">-->
<!--               Buchbar bis-->
<!--              </mat-label>-->
<!--              <input-->
<!--                [id]="'bookingDate-' + idx"-->
<!--                matInput-->
<!--                [matDatepicker]="bookingDatePicker"-->
<!--                formControlName="bookingDate"-->
<!--              />-->
<!--              &lt;!&ndash;@TODO add formControlName="bookingDate" in formControl.ts &ndash;&gt;-->
<!--              <mat-datepicker-toggle matSuffix [for]="bookingDatePicker">-->
<!--              </mat-datepicker-toggle>-->
<!--              <mat-datepicker #bookingDatePicker></mat-datepicker>-->
<!--            </mat-form-field>-->
<!--          </div>-->
<!--        </div>-->

<!--        <section>-->
<!--          <mat-checkbox disabled> Alle Buchungen müssen personalisiert sein </mat-checkbox>-->
<!--        </section>-->

<!--        <div class="u-bgcolor-teal-10 u-mt-24 voucher-text-container">-->
<!--          <div class="o-grid">-->
<!--            <div class="o-grid__col u-13/36">-->
<!--              <p class="u-pv-20 u-pl-24">-->
<!--                <span class="u-weight-medium">-->
<!--                  Für dieses Produkt liegt ein Gutschein vor:-->
<!--                </span> <br>-->
<!--                Code: CFGHAEGSG_2022, Limit x23, Wert 15%, Condition: min 200,00 Umsatz-->
<!--              </p>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->

        <input type="hidden" id="isDeletable" formControlName="isDeletable" />
        <button
          mat-mini-fab
          color="accent"
          type="button"
          [disabled]="!form.value.isDeletable || disabled"
          (click)="onRemove()"
        >
          <mat-icon>delete</mat-icon>
        </button>
        <!--        <button-->
        <!--          [disabled]="!form.value.isDeletable || disabled"-->
        <!--          (click)="onRemove()"-->
        <!--        >x</button>-->

      </div>

    </mat-expansion-panel>
  </mat-accordion>

</form>
