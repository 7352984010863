import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Address } from 'src/app/core/graphql.model';
import { getEmptyAddress } from '../../addresses.factory';

@Injectable()
export class AddressCreateDialogFormService {
  form: FormGroup = this.createForm();

  get address(): Address {
    return this.form.value.address;
  }

  constructor(private fb: FormBuilder) {}

  private createForm(): FormGroup {
    return this.fb.group({
      id: [''], // hidden
      address: [getEmptyAddress(), [Validators.required]],
    });
    // @TODO check required and validators
  }
}
