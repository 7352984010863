import { Component, Input } from '@angular/core';
import { Recording } from 'src/app/core/graphql.model';

@Component({
  selector: 'app-recording-header',
  templateUrl: './recording-header.component.html',
  styleUrls: ['./recording-header.component.scss'],
})
export class RecordingHeaderComponent {
  @Input() recording: Recording;

  constructor() {}
}
