<div class="u-height-1/1 u-flex u-ai-center u-jc-center u-pt-6">
  <a
    *ngIf="'create' | able: 'Recording'"
    mat-flat-button
    color="accent"
    [routerLink]="link"
    [queryParams]="queryParams"
    (click)="onNewRecording($event)"
  >
    + Neue Aufzeichnung anlegen
  </a>

  <!--! @TODO change mat-style later and delete button when finished -->
  <!--  <button mat-flat-button color="accent" type="button">
    + Neue Aufzeichnung anlegen
  </button> -->
</div>
