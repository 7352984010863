import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import * as WaitingListActions from './store/waiting-list.actions';
import { BaseResolver } from '../core/base/base.resolver';

@Injectable({
  providedIn: 'root',
})
export class WaitingListsResolver extends BaseResolver {
  requestAction = WaitingListActions.entities.loadWaitingList;
  successAction = WaitingListActions.entities.loadWaitingListSuccess;
  failureAction = WaitingListActions.entities.loadWaitingListFailure;
  constructor(protected store: Store, protected actions$: Actions) {
    super();
  }
}
