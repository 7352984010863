import { Customer } from 'src/app/core/graphql.model';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as CustomeEntitiesActions from './customer-entities.actions';

export const customerEntitiesFeatureKey = 'entities';

export interface State extends EntityState<Customer> {}

export const adapter: EntityAdapter<Customer> = createEntityAdapter<Customer>();

export const initialState: State = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,

  // CREATE

  on(CustomeEntitiesActions.createCustomerSuccess, (state, action) =>
    adapter.upsertOne(action.data, state)
  ),

  // READ

  on(CustomeEntitiesActions.loadCustomersSuccess, (state, action) =>
    adapter.setAll(action.data, state)
  ),

  on(CustomeEntitiesActions.loadCustomerSuccess, (state, action) =>
    adapter.upsertOne(action.data, state)
  ),

  // UPDATE

  on(CustomeEntitiesActions.updateCustomerSuccess, (state, action) =>
    adapter.upsertOne(action.data, state)
  )

  // DELETE
);
