import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import {
  Format,
  Product,
  Recording,
  RecordingType,
  ProductVariant,
  InternalTicket,
} from 'src/app/core/graphql.model';
import { RecordingFormService } from './recording-form.service';
import { TranslateService } from '@ngx-translate/core';
import { Id } from 'src/app/core/model';

@Component({
  selector: 'app-recording-form',
  templateUrl: './recording-form.component.html',
  styleUrls: ['./recording-form.component.scss'],
  providers: [RecordingFormService],
})
export class RecordingFormComponent {

  // INPUT PARAMETERS

  _recording: Recording;
  @Input() set recording(recording: Recording) {
    this.formService.recording = recording;
    this._recording = recording;
    this.updateUnboundProducts();
  }
  get recording(): Recording {
    return this._recording;
  }

  _format: Format;
  @Input() set format(format: Format) {
    this.formService.format = format;
    this._format = format;
    this.updateUnboundProducts();
  }
  get format(): Format {
    return this._format;
  }

  _disabled: boolean = false;
  @Input() set disabled(disabled: boolean) {
    this._disabled = disabled;
    this.setDisabledState(disabled);
  }
  get disabled(): boolean {
    return this._disabled;
  }

  @Output() delete: EventEmitter<Id> = new EventEmitter();


  // EVENT EMITTERS

  @Output() formSubmit: EventEmitter<Recording> = new EventEmitter();

  // DATA

  form: FormGroup = this.formService.form;

  unboundProducts: Product[];

  recordingOptions = new Map<RecordingType, string>();

  // DATA GETTERS

  get internalTicketsFormItems(): FormArray {
    return this.form.controls.internalTickets as FormArray;
  }

  get productVariantFormItems(): FormArray {
    return this.form.controls.productVariants as FormArray;
  }

  get productionDate(): Date {
    return this.form.controls.productionDate.value;
  }

  // CONSTRUCTOR

  constructor(
    public formService: RecordingFormService,
    private translate: TranslateService
  ) {
    Object.values(RecordingType).forEach((key) =>
      this.recordingOptions.set(
        key,
        this.translate.instant(`ENUM.RECORDING_TYPE.${key}`)
      )
    );
  };

  // EVENT HANDLERS

  onAddInternalTicket = (): void => {
    this.formService.addInternalTicket();
  };

  onRemoveInternalTicket(idx: number): void {
    this.formService.removeInternalTicket(idx);
  }

  getInternalTicketSum = (): number => {
    const reducer = (previousValue: number, internalTicket: InternalTicket) =>
      previousValue + (internalTicket.quantity || 0);

    return this.formService.recording.internalTickets.reduce(reducer, 0);
  };

  onAddProductVariant = (product: Product): void => {
    this.formService.addProductVariant(product);
    this.unboundProducts = this.unboundProducts.filter(
      (item: Product) => item.id !== product.id
    );
  };

  onRemoveProductVariant(idx: number): void {
    this.formService.removeProductVariant(idx);
  }

  onSubmit = (): void => {
    this.formSubmit.emit(this.formService.recording);
  };

  onDelete(): void {
    this.delete.emit(this.formService.recording.id);
  }

  // METHODS

  setDisabledState = (isDisabled: boolean): void => {
    isDisabled ? this.form.disable() : this.form.enable();
  };

  updateUnboundProducts = (): void => {
    if (!this._format?.products) {
      return;
    }
    if (!this.recording?.productVariants) {
      this.unboundProducts = this._format?.products;
      return;
    }
    const boundProductIds = this.recording?.productVariants.map(
      (productVariant: ProductVariant) => productVariant.product.id
    );
    this.unboundProducts = this._format.products.filter(
      (product: Product) => !boundProductIds.includes(product.id)
    );
  };
}
