import { Id } from './../../../core/model';
import { FilterModel } from '../../../core/list/list.model';
import { defaultColDef, sideBar, statusBar } from '../../../core/list/list.definitions';
import { CustomerActionsCellRendererComponent } from './cell-renderers/customer-actions-cell-renderer.component';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { columnTypes } from 'src/app/core/list/list.definitions';
import {
  ColDef,
  ColumnState,
  FilterChangedEvent,
  GridReadyEvent,
  IServerSideDatasource,
  RowNode,
  SelectionChangedEvent,
  SortChangedEvent,
  GetRowIdFunc,
  GetRowIdParams,
  ColumnVisibleEvent,
  FirstDataRenderedEvent,
} from 'ag-grid-community';
import { ListService } from 'src/app/core/list/list.service';

@Component({
  selector: 'app-customers-list',
  templateUrl: './customers-list.component.html',
  styleUrls: ['./customers-list.component.scss'],
  providers: [ListService],
})
export class CustomersListComponent {
  @Input() datasource: IServerSideDatasource;
  @Input() columnDefs: ColDef[];
  @Input() set filter(filter: FilterModel) {
    this.service.setFilter(filter);
  }
  @Input() set columnsState(columnsState: ColumnState[]) {
    this.service.setColumnsState(columnsState);
  }
  @Input() set selection(selection: Id[]) {
    this.service.setSelection(selection);
  }

  @Output() filterChanged: EventEmitter<FilterModel> = new EventEmitter();
  @Output() columnsStateChanged: EventEmitter<ColumnState[]> = new EventEmitter();
  @Output() selectionChanged: EventEmitter<Id[]> = new EventEmitter();

  components = {
    actionsCellRenderer: CustomerActionsCellRendererComponent,
  };

  columnTypes = columnTypes;
  defaultColDef = defaultColDef;
  sideBar = sideBar;
  statusBar = statusBar;

  constructor(public service: ListService) {}

  public getRowId: GetRowIdFunc = (params: GetRowIdParams) => {
    return params.data.id;
  };

  onGridReady = (params: GridReadyEvent): void => {
    this.service.setApis(params.api, params.columnApi);
    setTimeout(() => {
      this.service.gridApi.setServerSideDatasource(this.datasource);
    }, 100);
  };

  onFirstDataRendered = (event: FirstDataRenderedEvent) => {
    event.columnApi.autoSizeAllColumns();
  }

  onFilterChanged = (event: FilterChangedEvent) => {
    // @TODO check and simplify condition
    if (
      event.hasOwnProperty('afterDataChange') &&
      event.hasOwnProperty('afterFloatingFilter') &&
      !event.afterDataChange
    ) {
      // only trigger when changed via cell edit and not via setFilterModel
      const filter = this.service.gridApi.getFilterModel();
      this.filterChanged.emit(filter);
      // } else {
      // triggered when the filter model is changed (occurs always after first condition)
      //     this.filteredDataChanged.emit((this.listApi.getModel() as any).rootNode.childrenAfterFilter );
    }
  };

  onSortChanged = (event: SortChangedEvent) => {
    this.columnsStateChanged.emit(this.service.columnApi.getColumnState()); // @TODO duplicate with src/app/formats/components/formats-list/formats-list.component.ts
  };

  onSelectionChanged = (event: SelectionChangedEvent) => {
    this.selectionChanged.emit(
      this.service.gridApi
        .getSelectedNodes()
        .map((rowNode: RowNode) => rowNode.data.id)
    );
  };

  onColumnVisible = (params: ColumnVisibleEvent) =>  {
    if (params.source !== 'toolPanelUi') {
        return;
    }
    this.columnsStateChanged.emit(this.service.columnApi.getColumnState());
  }
}
