import { createAction, props } from '@ngrx/store';

export const unauthorizedRedirect = createAction(
  '[APP] Unauthorized Redirect',
  props<{ url: string }>()
);

export const loaderStart = createAction(
  '[APP] Loader Start',
  props<{ id: string }>()
);

export const loaderStop = createAction(
  '[APP] Loader Stop',
  props<{ id: string }>()
);
