<app-user-header
  [user]="facade.currentUser$ | async"
  [actionLoaderActive]="actionLoaderActive$ | async"
  (formSubmit)="onProfilSubmit($event)"
></app-user-header>

<div class="o-container u-mt-24">
  <button
    mat-fab
    extended
    color="accent"
    [routerLink]="['/administration/users']"
  >
    <mat-icon><span class="material-symbols-outlined">
arrow_back
</span></mat-icon>
    zurück
  </button>
</div>
<div class="o-container u-pt-40 u-pb-40 u-fg-1">
  <mat-tab-group animationDuration="0ms" [mat-stretch-tabs]="false" class="u-height-1/1">
      <mat-tab label="Profil" bodyClass="test">
        <app-user-profil-tab
          [user]="facade.currentUser$ | async"
          (formSubmit)="onProfilSubmit($event)"
        >
        </app-user-profil-tab>
      </mat-tab>
<!--      <mat-tab label="To Dos">-->
        <!-- @TODO user-toDo-component doesn't exist yet-->
        <!-- <app-user-toDo-tab> </app-user-toDo-tab> -->
<!--      </mat-tab>-->
<!--      <mat-tab label="Kommunikation">-->
        <!-- @TODO user-communication-component doesn't exist yet-->
        <!--<app-user-communication-tab> </app-user-communication-tab> -->
<!--      </mat-tab>-->
  </mat-tab-group>
</div>
