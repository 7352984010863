import { Nullable } from './../graphql.model';
import { IServerSideGetRowsRequest } from 'ag-grid-community';
import {
  EnumFilter,
  NumberFilter,
  StringNullableFilter,
} from './connection-filter.model';

export interface CombinedFilter<T> {
  AND?: Nullable<Nullable<T>[]>;
  NOT?: Nullable<Nullable<T>[]>;
}

export type CustomFilterMappers = {
  [key: string]: (filter: any) => CombinedFilter<any>;
};

export const filterModelToFilter = (
  filterModel: any, 
  customFilterMappers: CustomFilterMappers = {},
): any => {
  const filter: { [key: string]: any } = { AND: [], NOT: [] };
  Object.keys(filterModel).forEach((key: string) => {
    let keyFilter: CombinedFilter<any>;
    if (Object.keys(customFilterMappers).includes(key)) {
      keyFilter = customFilterMappers[key](filterModel[key]);
    } else {
      keyFilter = mapFilter(key, filterModel[key]);
    }
    filter.AND = filter.AND.concat(keyFilter.AND || []);
    filter.NOT = filter.NOT.concat(keyFilter.NOT || []);
  });
  return filter;
};

export const requestToFilter = (
  request: IServerSideGetRowsRequest,
  customFilterMappers: CustomFilterMappers
): any => {
  return filterModelToFilter(request.filterModel, customFilterMappers);
};

// @ts-ignore
export const mapFilter = (key: string, filter: any): CombinedFilter<any> => {
  if (!filter.hasOwnProperty('filterType')) {
    console.error('No filterType set');
    // @ts-ignore
    return;
  }
  switch (filter.filterType) {
    case 'text':
      return mapTextFilter(key, filter);
    case 'number':
      return mapNumberFilter(key, filter);
    case 'date':
      return mapDateFilter(key, filter);
    case 'set':
      return mapSetFilter(key, filter);
    default:
      console.error('Undefined filterType');
      break;
  }
};

const mapTextFilter = (
  key: string,
  filter: any
): CombinedFilter<StringNullableFilter> => {
  if (!filter.hasOwnProperty('type')) {
    console.error('No type set');
    return undefined;
  }
  if (!filter.hasOwnProperty('filter')) {
    console.error('No filter set');
    return undefined;
  }
  switch (filter.type) {
    case 'startsWith':
    case 'endsWith':
    case 'equals':
    case 'contains':
      return { AND: [{ [key]: { [filter.type]: filter.filter } }] };
    case 'notEqual':
      return { AND: [{ [key]: { not: filter.filter } }] };
    case 'notContains':
      return { NOT: [{ [key]: { contains: filter.filter } }] };
    default:
      console.error('Undefined type');
      return undefined;
  }
};

const mapNumberFilter = (
  key: string,
  filter: any
): CombinedFilter<NumberFilter<any>> => {
  if (!filter.hasOwnProperty('type')) {
    console.error('No type set');
    return undefined;
  }
  if (!filter.hasOwnProperty('filter')) {
    console.error('No filter set');
    return undefined;
  }
  if (filter.type === 'inRange' && !filter.hasOwnProperty('filterTo')) {
    console.error('No filterTo set');
    return undefined;
  }

  switch (filter.type) {
    case 'equals':
      return { AND: [{ [key]: { [filter.type]: filter.filter } }] };
    case 'greaterThan':
      return { AND: [{ [key]: { gt: filter.filter } }] };
    case 'greaterThanOrEqual':
      return { AND: [{ [key]: { gte: filter.filter } }] };
    case 'lessThan':
      return { AND: [{ [key]: { lt: filter.filter } }] };
    case 'lessThanOrEqual':
      return { AND: [{ [key]: { lte: filter.filter } }] };
    case 'notEqual':
      return { AND: [{ [key]: { not: filter.filter } }] };
    case 'inRange':
      return {
        AND: [
          { [key]: { gte: filter.filter } },
          { [key]: { lte: filter.filterTo } },
        ],
      };
    default:
      console.error('Undefined type');
      return undefined;
  }
};

const mapDateFilter = (
  key: string,
  filter: any
): CombinedFilter<NumberFilter<any>> => {
  if (!filter.hasOwnProperty('type')) {
    console.error('No type set');
    return undefined;
  }
  if (!filter.hasOwnProperty('dateFrom')) {
    console.error('No dateFrom set');
    return undefined;
  }
  if (filter.type === 'inRange' && !filter.hasOwnProperty('dateTo')) {
    console.error('No dateTo set');
    return undefined;
  }

  switch (filter.type) {
    case 'equals':
      return {
        AND: [
          { [key]: { [filter.type]: new Date(filter.dateFrom).toISOString() } },
        ],
      };
    case 'greaterThan':
      return {
        AND: [{ [key]: { gt: new Date(filter.dateFrom).toISOString() } }],
      };
    case 'lessThan':
      return {
        AND: [{ [key]: { lt: new Date(filter.dateFrom).toISOString() } }],
      };
    case 'notEqual':
      return {
        AND: [{ [key]: { not: new Date(filter.dateFrom).toISOString() } }],
      };
    case 'inRange':
      return {
        AND: [
          { [key]: { gte: new Date(filter.dateFrom).toISOString() } },
          { [key]: { lte: new Date(filter.dateTo).toISOString() } },
        ],
      };
    default:
      console.error('Undefined type');
      return undefined;
  }
};

const mapSetFilter = (
  key: string,
  filter: any
): CombinedFilter<EnumFilter<any>> => {
  if (!filter.hasOwnProperty('values')) {
    console.error('No values set');
    return undefined;
  }
  return { AND: [{ [key]: { in: filter.values } }] };
};
