<div class="c-format-header u-bgcolor-secondary-90 u-pv-12">
  <div class="o-container">
    <div class="u-flex u-jc-between">
      <h1 class="o-type-h5 u-color-white">
        {{ format?.title }} -
        {{ format?.cmsFormat?.data?.currentSeason?.iv ? format?.cmsFormat?.data?.currentSeason?.iv[0]?.data?.name?.iv : '' }}
      </h1>

      <div class="btn_container dummy-data">
        <mat-slide-toggle
          *ngIf="'update' | able: 'Format'"
          [checked]="checked"
          (change)="onToggleArchived($event)"
        >
          archivieren
        </mat-slide-toggle>
        <a
          class="u-ml-24"
          mat-flat-button
          [href]="formatCmsHref(format?.cmsId)"
          target="_blank"
        >
          im CMS bearbeiten
        </a>
      </div>
    </div>
  </div>
</div>
