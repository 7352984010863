<!-- customer booking tab -->
<div class="u-flex u-jc-end u-bgcolor-grey-40 u-pv-8 u-ph-20 u-mb-32">
  <a
    mat-fab
    extended
    [routerLink]="customerBookingsCreateLink"
  >
    <mat-icon>add</mat-icon>
    Buchung für Kunden durchführen
  </a>
</div>

<app-bookings-list
  [columnDefs]="(bookingsFacade.listCustomer$ | async)?.columnDefs"
  [data]="(bookingsFacade.bookings$ | async) || []"
></app-bookings-list>
