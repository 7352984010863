import { Action, combineReducers, ActionReducer } from '@ngrx/store';
import * as fromRecordingEntities from './entities/recording-entities.reducer';
import * as fromRecordingLists from './lists/recording-lists.reducer';

export const recordingFeatureKey = 'recording';

export interface State {
  [fromRecordingEntities.recordingEntitiesFeatureKey]: fromRecordingEntities.State;
  [fromRecordingLists.recordingListsFeatureKey]: fromRecordingLists.State;
}

export function reducers(
  state: State | undefined,
  action: Action
): ActionReducer<State, any> {
  // @ts-ignore
  return combineReducers({
    [fromRecordingEntities.recordingEntitiesFeatureKey]:
      fromRecordingEntities.reducer,
    [fromRecordingLists.recordingListsFeatureKey]: fromRecordingLists.reducer,
  })(state, action);
}
