import { pickColums } from './../../../core/list/list-helpers';
import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { delay, first, mergeMap, startWith } from 'rxjs/operators';
import { of } from 'rxjs';
import * as RecordingListsActions from './recording-lists.actions';
import {
  RecordingColumnIds,
  RecordingColumnDefsService,
} from './recording-list-column-defs.service';
import { omitColums } from 'src/app/core/list/list-helpers';

@Injectable()
export class RecordingListsEffects {
  constructor(
    private actions$: Actions,
    private baseColumnDefsService: RecordingColumnDefsService
  ) {}

  init$ = createEffect(() =>
    this.actions$.pipe(
      startWith({ type: 'INIT_TRIGGER' }),
      first(),
      delay(100), // see https://github.com/ngrx/platform/issues/683#issuecomment-517888082
      mergeMap(() =>
        of(
          RecordingListsActions.init({
            columnDefsOverview: omitColums(
              this.baseColumnDefsService.columnDefs,
              [RecordingColumnIds.CLONE_ACTIONS]
            ),
            columnDefsClone: pickColums(this.baseColumnDefsService.columnDefs, [
              RecordingColumnIds.TITLE,
              RecordingColumnIds.TYPE,
              RecordingColumnIds.PRODUCTION_START,
              RecordingColumnIds.CHECK_IN_BEGIN,
              RecordingColumnIds.CHECK_IN_END,
              RecordingColumnIds.EVENT_START_TIME,
              RecordingColumnIds.PRODUCTION_START_TIME,
              RecordingColumnIds.EVENT_END_TIME,
              RecordingColumnIds.CLONE_ACTIONS,
            ]),
            columnDefsFormat: omitColums(
              this.baseColumnDefsService.columnDefs,
              [RecordingColumnIds.FORMAT, RecordingColumnIds.CLONE_ACTIONS]
            ),
          })
        )
      )
    )
  );
}
