import { Component, Input } from '@angular/core';
import { BookingNotesForm } from '../booking-form/booking-form.model';

@Component({
  selector: 'app-booking-notes-overview',
  templateUrl: './booking-notes-overview.component.html',
  styleUrls: ['./booking-notes-overview.component.scss'],
})
export class BookingNotesOverviewComponent {
  @Input() bookingNote: BookingNotesForm;

  constructor() {}
}
