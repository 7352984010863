<ag-grid-angular
  style="width: 100%; height: 100%"
  class="ag-theme-material"
  [getLocaleText]="service.getLocaleText"
  [components]="components"
  [columnTypes]="columnTypes"
  [defaultColDef]="defaultColDef"
  [columnDefs]="columnDefs"
  [sideBar]="sideBar"
  [statusBar]="statusBar"
  [enableCellTextSelection]="true"
  [rowMultiSelectWithClick]="true"
  [suppressRowClickSelection]="true"
  [rowSelection]="'multiple'"
  [getRowId]="getRowId"
  rowModelType="serverSide"
  serverSideStoreType="partial"
  [cacheBlockSize]="50"
  (gridReady)="onGridReady($event)"
  (filterChanged)="onFilterChanged($event)"
  (sortChanged)="onSortChanged($event)"
  (selectionChanged)="onSelectionChanged($event)"
  (columnVisible)="onColumnVisible($event)"
  (firstDataRendered)="onFirstDataRendered($event)"
></ag-grid-angular>
