<!--<mat-accordion>
  <mat-expansion-panel [expanded]="true">
    <mat-expansion-panel-header>
      <mat-panel-title>Produkte auswählen: Schritt 2</mat-panel-title>
    </mat-expansion-panel-header>
  </mat-expansion-panel>
</mat-accordion> -->

<!-- @TODO either relocate to the accordion above or in the page-->
<!--
 -->
<form [formGroup]="form">
  <input type="hidden" id="id" />
  <div class="address__container-border u-bgcolor-white u-p-12 u-mt-24 u-mb-24">
    <div class="u-flex u-jc-between">
      <mat-form-field appearance="outline" class="input__container">
        <mat-label>Rechnungsadresse</mat-label>
        <mat-select formControlName="invoiceAddress">
          <mat-option *ngFor="let address of addresses" [value]="address">
            {{ address.address }}, {{ address.postCode }} {{ address.city }},
            {{ address.country }}
            <!-- @TODO -->
          </mat-option>
          <!--
              <mat-option *ngFor="let address of addresses" [value]="address.id">
                {{address}}
              </mat-option>-->
        </mat-select>
      </mat-form-field>

      <button
        *ngIf="'update' | able: 'Customer'"
        mat-flat-button
        color="accent"
        type="button"
        (click)="openDialog()"
      >
        + neue Rechnungsadresse anlegen
      </button>
      <!--{{ form.value | json}} -->
    </div>

    <div *ngIf="form.value?.invoiceAddress" class="u-bgcolor-grey-20 u-p-12">
      <p class="o-type-body-small u-mb-4">
        {{ form.value.invoiceAddress.salutation }}
        {{ form.value.invoiceAddress.firstName }}
        {{ form.value.invoiceAddress.lastName }}
      </p>
      <p class="o-type-body-small u-mb-4">
        {{ form.value.invoiceAddress.address }}
      </p>
      <p class="o-type-body-small">
        {{ form.value.invoiceAddress.postCode }}
        {{ form.value.invoiceAddress.city }}
      </p>
    </div>
    <div class="u-mt-32">
      <!--
      @TODO
      <mat-form-field appearance="outline">
        <mat-label>Zahlungsmethode</mat-label>
        <mat-select formControlName="paymentType" [disabled]="true">
          <mat-option
            *ngFor="let paymentOption of paymentOptions | keyvalue"
            [value]="paymentOption.key"
          >
            {{ paymentOption.value }}
          </mat-option>
          <! -- <mat-option *ngFor="let addressVariant of addressVariants" [value]="addressVariant">
            {{ productVariant.name }}</mat-option> -- >
        </mat-select>
      </mat-form-field>
    -->
    </div>
  </div>
</form>
